<div class="nav-tabs-boxed">
  <ul class="nav nav-tabs" role="tablist">
    <li class="nav-item" (click)="setTab(0)">
      <a class="nav-link" data-toggle="tab" [ngClass]="{'active': currentTab === 0}" role="tab" aria-controls="home">
        {{ 'personal_data_tab' | translate}}
      </a>
    </li>
    <li class="nav-item" (click)="setTab(1)">
      <a class="nav-link" data-toggle="tab" [ngClass]="{'active': currentTab === 1}" role="tab" aria-controls="home">
        {{ 'user_config_tab' | translate}}
      </a>
    </li>
    <li class="nav-item" (click)="setTab(2)">
      <a class="nav-link" data-toggle="tab" [ngClass]="{'active': currentTab === 2}" role="tab" aria-controls="home">
        {{ 'groups' | translate}}
      </a>
    </li>
    <li class="nav-item" (click)="setTab(3)" *ngIf="isSuperAdmin">
      <a class="nav-link" data-toggle="tab" [ngClass]="{'active': currentTab === 3}" role="tab" aria-controls="home">
        Treezor
      </a>
    </li>
    <li class="nav-item" (click)="setTab(4)" *ngIf="isEmployee">
      <a class="nav-link" data-toggle="tab" [ngClass]="{'active': currentTab === 4}" role="tab" aria-controls="home">
        <span *ngIf="isSuperAdmin">
          Transactions Carte
        </span>
        <span *ngIf="!isSuperAdmin">
          {{'refunds_and_transactions'|translate}}
        </span>
      </a>
    </li>
    <li class="nav-item" (click)="setTab(5)" *ngIf="isSuperAdmin">
      <a class="nav-link" data-toggle="tab" [ngClass]="{'active': currentTab === 5}" role="tab" aria-controls="home">
        {{'refund_requests'|translate}}
      </a>
    </li>
  </ul>
  <div class="tab-content">
    <div [ngClass]="{'active': currentTab === 0}" class="tab-pane">
      <mat-progress-bar [mode]="'indeterminate'" *ngIf="loading"></mat-progress-bar>
      <rm-reactive-forms 
        *ngIf="objectDef && targetObject" 
        [objectDefinition]="objectDef"
        [objectToUpdate]="targetObject" 
        [setLoading]="loading"
        (saveEmitter)="sendObjectToSender($event)"
      >
      </rm-reactive-forms>
    </div>
    <div [ngClass]="{'active': currentTab === 1}" class="tab-pane">
      <mat-progress-bar *ngIf="loadingCards" [mode]="'indeterminate'"></mat-progress-bar>
      <div class="row" *ngIf="hasNoCard; else cardPresentation">
        <ng-container *ngIf="isInvitedButNotSubscribed; else noCardYet">
          <div class="col-12">
            <p>
              {{'this_user_is_invited_but_not_subscribed' | translate}}
            </p>
          </div>
          <div class="col-3 detached">
            <button class="btn btn-primary" type="button" (click)="deleteUser($event)">
              {{'delete_user' | translate}}
            </button>
          </div>
          <div class="col-9"></div>
          <div class="col-12">
            <p>
              {{'warning_if_deleted_user_will_not_be_able_to_subscribe' | translate}}
            </p>
          </div>
        </ng-container>
        <ng-template #noCardYet>
          <div class="col-12">
            <p *ngIf="!isInvitedButNotSubscribed && !loadingCards">
              {{'this_user_has_no_card_yet' | translate}}
            </p>
          </div>
          <div class="col-12">

          </div>
        </ng-template>

        <div class="col-12">
          <div class="detached">
            <div class="col3">
              <button *ngIf="isSuperAdmin" class="btn btn-primary" type="button" (click)="orderNewCard()">
                {{'order_new_card' | translate}}
              </button>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="detached">
            <div class="col3">
              <button class="btn btn-primary" type="button" (click)="sendNewInvitation()">
                {{'send_invitation' | translate}}
              </button>
            </div>
          </div>
        </div>
      </div>
      <ng-template #cardPresentation>
        <p-carousel [value]="cards" [numVisible]="1" [numScroll]="1">
          <ng-template let-card pTemplate="item">
            <div class="rm-column" *ngIf="card">
              <div class="centered-container">
                <rm-flip-card [expiryDate]="'XX/XX'" [lastFourDigits]="'XXXX'"
                  [name]="targetObject.firstname + ' ' + targetObject.lastname" [cvc]="'XXX'">
                </rm-flip-card>
              </div>
            </div>
          </ng-template>
        </p-carousel>
        <p *ngIf="!loadingCards && hasNoCard">
          {{'user_has_no_cards' | translate}}
        </p>
      </ng-template>
      <ul class="list-group detached">
        <li #aclTemplate="var" [var]="true" class="list-group-item rm-column list-group-item-action" *ngIf="userACL">
          <table class="table table-striped">
            <tbody>
              <tr>
                <td>
                  <strong>{{'user_status' | translate}}:</strong>
                </td>
                <td>
                  <strong>{{targetObject.accountStatus | translate}}</strong>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>{{'employee' | translate}}:</strong>
                </td>
                <td>
                  <strong>{{(userACL.employee? 'yes' : 'non') | translate}}</strong>
                </td>
              </tr>
              <tr *ngIf="!userACL.employee">
                <td></td>
                <td>
                  <button class="btn btn-primary" type="button" (click)="makeEmployee($event)">
                    {{'make_employee' | translate}}
                  </button>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>{{'admin' | translate}}:</strong>
                </td>
                <td>
                  <strong>{{(userACL.admin? 'yes' : 'non') | translate}}</strong>
                </td>
              </tr>
              <tr *ngIf="!isSelf">
                <td></td>
                <td>
                  <button class="btn btn-primary" type="button" (click)="makeAdmin($event, !userACL.admin)">
                    {{(userACL.admin ? 'revoke_admin': 'make_admin') | translate}}
                  </button>
                </td>
              </tr>
              <tr *ngIf="!isSelf  && isAccountManager">
                <td>
                  <strong>{{'accountmanager' | translate}}:</strong>
                </td>
                <td>
                  <strong>{{(userACL.accountmanager? 'yes' : 'non') | translate}}</strong>
                </td>
              </tr>
              <tr *ngIf="!isSelf  && isAccountManager">
                <td></td>
                <td>
                  <button class="btn btn-primary" type="button"
                    (click)="makeAccountManager($event, !userACL.accountmanager)">
                    {{(userACL.accountmanager ? 'revoke_accountmanager': 'make_accountmanager') | translate}}
                  </button>
                </td>
              </tr>
              <tr>
                <td>
                  <strong *ngIf="!suspended">
                    {{'suspend_user_explanation'|translate}}
                  </strong>
                  <strong *ngIf="suspended">
                    {{'user_is_currently_suspended'|translate}}
                  </strong>
                </td>
                <td>
                  <button class="btn btn-danger" type="button" (click)="suspendUser($event, !suspended)">
                    <span *ngIf="!suspended">{{'suspend_user' | translate}}</span>
                    <span *ngIf="suspended">
                      {{'unsuspend_user' | translate}}
                    </span>
                  </button>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>
                    {{'delete_user_explanation'|translate}}
                  </strong>
                </td>
                <td>
                  <button class="btn btn-danger" type="button" (click)="deleteUser($event)">
                    {{'delete_user' | translate}}
                  </button>
                </td>
              </tr>
              <tr *ngIf="isSuperAdmin && !hasNoCard">
                <td>
                  <strong>
                    {{'user_did_not_receive_card'|translate}}
                  </strong>
                </td>
                <td>
                  <button class="btn btn-danger" type="button" (click)="deleteCard()">
                    {{'DESTROY_CARD_ACTION' | translate}}
                  </button>
                </td>
              </tr>
              <tr *ngIf="isSuperAdmin && !hasNoCard">
                <td>
                  <strong>
                    {{'declare_card_lost'|translate}}
                  </strong>
                </td>
                <td>
                  <button class="btn btn-danger" type="button" (click)="deleteCard(false)">
                    {{'Déclarer la carte perdue (irréversible)' | translate}}
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </li>
      </ul>
    </div>
    <div [ngClass]="{'active': currentTab === 2}" class="tab-pane">
      <h3>
        {{'users_groups' | translate}}
      </h3>
      <div class="row detached">
        <div class="col-sm-12 col-lg-6">
          <mat-progress-bar [mode]="'indeterminate'"
            *ngIf="!ordersLoaded || savingLoader || loadingCards"></mat-progress-bar>
          <p *ngIf="ordersLoaded && orders.length === 0; else displayGroups">
            {{'you_have_not_created_orders' | translate}}
          </p>
          <ng-template #displayGroups>
            <div class="rm-column" style="height: auto; width: 100%; padding-bottom: 250px;">
              <p *ngIf="orders && targetObject && (!targetObject.userGroupIds || !targetObject.userGroupIds.length)">
                {{'no_groups_associated_to_user' | translate}}
              </p>
              <p *ngIf="savedSuccess">
                {{'group_assiciated' | translate}}
              </p>
              <rm-grouped-multi-select [groups]="groups"
                [selectedItems]="targetObject.userGroupIds ? targetObject.userGroupIds : []"
                [placeholder]="'group_assiciated'" (changeEmitter)="updateUserGroups($event)">
              </rm-grouped-multi-select>
              <button class="btn btn-primary detached" type="button" (click)="saveGroups()"
                [disabled]="!groupSelectionDirty || savingLoader">
                {{'save' | translate}}
              </button>
              <h3 class="detached">
                {{'expense_lines' | translate}}
              </h3>
              <button class="btn btn-primary detached" type="button" (click)="uploadExpenseLines()"
                *ngIf="!expenseLines">
                {{'display' | translate}}
              </button>
              <p *ngIf="expenseLines && expenseLines.length === 0">
                {{ 'no_expense_lines_so_far' | translate}}
              </p>
              <div *ngIf="expenseLines && expenseLines.length" class="dropdown-menu-lg pt-0 rm-column">
                <div class="row" *ngFor="let expense of expenseLines">
                  <div class="col-8" style="height: 70px;">
                    <div class="dropdown-item d-block" [ngClass]="{'suspended': expense.suspended}">
                      <div class="small mb-1">
                        {{expense.productName | translate}}
                        <span class="float-right">
                          <strong>{{expense.amountSpent}} € / {{expense.initialBalance}} €</strong>
                        </span>
                      </div>
                      <span class="progress progress-xs">
                        <div class="progress-bar bg-info" role="progressbar"
                          [ngStyle]="{'width': (100 * expense.remainingBalance/expense.initialBalance) + '%'}"></div>
                      </span>
                      <div>
                        <rm-expenseline-status [status]="expense.status"></rm-expenseline-status>
                      </div>
                    </div>
                  </div>
                  <div class="col-4 btn-centerd">
                    <button class="btn btn-danger" type="button" (click)="suspendExpenseLine(expense)"
                      *ngIf="expense.status === 'Active' || expense.status === 'Available' ">
                      {{'suspend' | translate}}
                    </button>
                    <button class="btn btn-success" type="button" (click)="suspendExpenseLine(expense, false)"
                      *ngIf="expense.status === 'Suspended'">
                      {{'activate' | translate}}
                    </button>
                  </div>
                </div>

              </div>
              <mat-progress-bar [mode]="'indeterminate'" *ngIf="loading"></mat-progress-bar>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
    <div *ngIf="isSuperAdmin" [ngClass]="{'active': currentTab === 3}" class="tab-pane">
      <h3>
        {{'treezor_details' | translate}}
      </h3>
      <button class="btn btn-primary" [disabled]="loading" type="button" (click)="syncTreezorUser()">
        {{'sync_user_treezor' | translate}}
      </button>
      <mat-progress-bar [mode]="'indeterminate'" *ngIf="loading"></mat-progress-bar>
      <rm-reactive-forms *ngIf="treezorUserDef && treezorUser" [objectDefinition]="treezorUserDef"
        [objectToUpdate]="treezorUser" [displayOnly]="false" (saveEmitter)="updateTreezorUser($event)">
      </rm-reactive-forms>
      <ng-container *ngIf="!loading && (!treezorUser || !treezorUser.userId)">
        <p>
          {{'this_user_doent_exist_yet' | translate}}
        </p>
        <button class="btn btn-primary" type="button" [disabled]="loading" (click)="createTreezorUser()">
          {{'add_user_treezor' | translate}}
        </button>
      </ng-container>
    </div>
    <div *ngIf="isAdminOrAccountManager" [ngClass]="{'active': currentTab === 4}" class="tab-pane">
      <rm-user-refunds-transactions
        [appUser]="targetObject"
      >
      </rm-user-refunds-transactions>
    </div>
    <div *ngIf="isSuperAdmin" [ngClass]="{'active': currentTab === 4}" class="tab-pane">
      <rm-transactions-viewer
        *ngIf="targetObject"
        [agentRef]="targetObject.agentRef"
        [companyRef]="targetObject.companyRef"
        [email]="targetObject.email"
      ></rm-transactions-viewer>
    </div>
    <div *ngIf="isSuperAdmin" [ngClass]="{'active': currentTab === 5}" class="tab-pane">
      <h3>
        Demandes de remboursement
      </h3>
      <mat-progress-bar [mode]="'indeterminate'" *ngIf="loading || !requests"></mat-progress-bar>
      <ng2-smart-table
          *ngIf="requests?.length && requestsSettings"
          [settings]="requestsSettings"
          [source]="requests"
        >
        </ng2-smart-table>
    </div>
  </div>
</div>