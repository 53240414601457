import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({ name: 'rm_array_sort' })
export class SortArrayByTimePipe implements PipeTransform {
  transform(arr: any[], property: string, direction: 'asc'|'desc' = 'desc'): any[] {
    if (!arr || !arr.length || !property) {
      return [];
    }
    arr.sort((a, b) => {
      const dateA = moment(a[property]);
      const dateB = moment(b[property]);
      if (dateA.isBefore(dateB)) {
        return direction === 'asc' ? -1 : 1;
      }
      if (dateA.isAfter(dateB)) {
        return direction === 'asc' ? 1 : -1;
      }
      return 0;
    });
    return arr;
  }
}
