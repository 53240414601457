import { Component, Input, OnInit } from '@angular/core';

// import { DropDownListOption } from '@roadmate/roadmate-common';

@Component({
  template: `
    {{renderValue}}
  `,
})
export class DdlRenderComponent implements OnInit {

  renderValue: string;

  @Input() value: {label: string; value: string;};
  @Input() rowData: any;

  ngOnInit() {
    this.renderValue = this.value?.label ?? '';
  }

}
