import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'rm-email-sent-svg',
    templateUrl: './email-sent-svg.component.html',
    styleUrls: ['./email-sent-svg.component.scss']
})
export class EmailSentSvgComponent implements OnInit {
    constructor() { }

    ngOnInit(): void { }
}
