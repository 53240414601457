import { Component, OnInit } from '@angular/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'mat-card-footer',
  templateUrl: './mat-card-footer.component.html',
  styleUrls: ['./mat-card-footer.component.scss']
})
export class CardFooterComponent implements OnInit {
  constructor() { }

  ngOnInit(): void { }
}
