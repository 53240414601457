export const getChartBoilerPlate = 
(type = 'line', label = 'Solde en €', backgroundColor= 'rgba(255,255,255,.2)', borderColor= 'rgba(255,255,255,.55)'): any => {
  return {
    type,
    labels: [],
    colors: [{
      backgroundColor,
      borderColor,
    }],
    datasets: [
      {
        label
      }
    ],
    data: [],
    options: {
      maintainAspectRatio: false,
      legend: {
        display: false
      },
      scales: {
        xAxes: [{
          display: false,
          barPercentage: 0.4
        }],
        yAxes: [{
          display: false,
          ticks: {
            beginAtZero: true,
            min: 0
          }
        }]
      },
      elements: {
        line: {
          borderWidth: 2
        },
        point: {
          radius: 0,
          hitRadius: 10,
          hoverRadius: 4
        }
      }
    }
  };
}

export const getPieChartBoilerPlate = (title: string) => {
  return {
    type: 'pie',
    labels: [],
    colors: [{
      backgroundColor: 'rgba(255,255,255,.2)',
      borderColor: 'rgba(255,255,255,.55)',
    }],
    datasets: [
      {
        label: title
      }
    ],
    data: [],
    options: {
      maintainAspectRatio: true,
      legend: {
        display: false
      },
      scales: {
        xAxes: [{
          display: false
        }],
        yAxes: [{
          display: false
        }]
      },
      elements: {
        line: {
          borderWidth: 2
        },
        point: {
          radius: 0,
          hitRadius: 10,
          hoverRadius: 4
        }
      }
    }
  };
}