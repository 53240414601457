import { Component, Input, OnInit } from '@angular/core';
import { MerchantService } from '@roadmate/roadmate-common';

@Component({
  selector: 'rm-offer-card',
  template: `
    <div class="centered-container">
      <div class="card mb-3">
        <div class="row g-0">
          <div class="col-md-4" [ngStyle]="{'background-image': 'url(' +(voucher.img | rm_image_render)+ ')', 'background-size': 'cover'}">

          </div>
          <div class="col-md-8">
            <div class="card-body">
              <h5 class="card-title">{{voucher.name}}</h5>
              <p class="card-text max-five-lines">{{voucher.description}}</p>
              <a [href]="voucher.url"  target="_blank" class="btn btn-primary" *ngIf="!voucher.paymentType || !voucher.price">
                {{voucher.priceLabel}}
              </a>
              <a [href]="voucher.url" target="_blank" class="btn btn-primary" *ngIf="voucher.paymentType && voucher.price">
                <span style="text-decoration: line-through;">
                  {{voucher.price | rm_currency}}
                </span>
                <span>
                  {{(voucher.price - monthlyBudget > 0 ? voucher.price - monthlyBudget : 0) | rm_currency}} *
                </span>
              </a>
              <div *ngIf="voucher.paymentType && voucher.price && monthlyBudget">
                <small>(*) {{'when_using_your_budget' | translate}}</small>
              </div> 
            </div>
          </div>
        </div>
      </div>
    </div>
  `
})
export class OfferCardComponent implements OnInit {
  @Input() voucher: MerchantService;
  @Input() monthlyBudget: number;
  constructor() { }

  ngOnInit(): void {
    
  }
}
