<div class="file-uploader" button>
  <div *ngIf="!loading" button (click)="addPhoto()">
      <input [attr.id]="fileInputName" class="fileinput" type="file" [attr.name]="fileInputName" (change)="uploadHandler($event.target.files)" accept="application/pdf,image/*,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
      <label [attr.for]="fileInputName">
        <i class="cil-plus"></i>
      </label>
  </div>
  <rm-upload-task
    *ngIf="loading && file"
    [file]="file"
    [fileSavingWorkFlow]="workflow"
    [agentRef]="agentRef"
    [companyRef]="companyRef"
    (finishEmitter)="pictureReady($event)"
  >
  </rm-upload-task>
</div>
