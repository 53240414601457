<div class="row push-down" *ngIf="list && displayDownload">
  <div class="col-6 file-to-download containter-center-left">
    <button *ngIf="showExporter" class="btn btn-primary" type="button" (click)="exportSelection()" [disabled]="loading">
      <span *ngIf="loading" class="c-loading-button-spinner spinner-border spinner-border-sm"></span>
      {{'Exporter la sélection' | translate}} ({{arrayOnDisplay.length}})
    </button>
    <img *ngIf="currentFile" src="/assets/svg/xlsx.svg">
    <a *ngIf="currentFile" href="{{currentFile}}" target="_blank">
      {{'download_selection'|translate}}
    </a>
  </div>
  <div class="col-6 container-center-right" *ngIf="showCount && list?.count">
    {{list.count()}} {{'elements_in_this_table'|translate}}
  </div>
</div>
<div class="row" *ngIf="list && settings">
  <div class="col-12" *ngIf="list && settings; else nodata">
    <ng2-smart-table
      *ngIf="list"
      [settings]="settings"
      [source]="list"
      (userRowSelect)="userSelectRow($event)"
      (rowSelect)="selectItem($event)"
      (delete)="deleteAction($event)"
      (edit)="editAction($event)"
    >
    </ng2-smart-table>
  </div>
  <ng-template #nodata>
    <div class="col-4">
      <p style="margin-bottom: 20px;">
        {{'no_data_available_to_display'|translate}}
      </p>
      <img src="/assets/svg/empty.svg">
    </div>
    <div class="col-8"></div>
  </ng-template>
</div>