import { Component, OnInit } from '@angular/core';
import { FireStoreService } from '../../../services/fire.store';
import { Group } from '@roadmate/roadmate-common';
import { FireAuthService } from '../../../services/fire.auth';
import { AbstractEntryComponent } from '../abstract.entry-component';

@Component({
  selector: 'rm-adduserstogroup',
  templateUrl: './adduserstogroup.component.html',
  styleUrls: ['./adduserstogroup.component.scss']
})
export class AddUsersToGroupComponent extends AbstractEntryComponent<any> implements OnInit {
  public groups: Group[];
  public selectedGroup: Group;
  public nbUsersSelected: number;
  public set data(value) {
    if (!value) {
      return;
    }
    this.nbUsersSelected = value.nbUsersSelected;
  }
  constructor(private auth: FireAuthService,
              private fs: FireStoreService) {
    super();
  }

  ngOnInit(): void {
    this.getGroups();
  }

  private async getGroups() {
    // this.groups = await this.fs.getGroups(this.auth.appUser.companyRef);
  }

  public selectGroup(group: Group) {
    this.selectedGroup = group;
  }

  public cancel() {
    this.close(null);
  }

  public validateSelection() {
    this.close({data: this.selectedGroup});
  }
}
