import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { PipeModule } from '../pipes/pipe.module';
import { MultiSelectModule } from '../multi-select/multi-select.module';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { MigrationModule } from '../migration/migration.module';
import { FormsModule } from '@angular/forms';
import { AttestationComponent } from './attestation.component';



@NgModule({
  declarations: [AttestationComponent],
  imports: [
    CommonModule,
    TranslateModule,
    PipeModule,
    MultiSelectModule,
    Ng2SmartTableModule,
    MigrationModule,
    FormsModule
  ],
  exports: [AttestationComponent],
  providers: [],
})
export class AttestationModule {}
