import { Component, Input, OnInit } from '@angular/core';
import { ExpenseLineStatus } from '@roadmate/roadmate-common';

@Component({
  selector: 'rm-expenseline-status',
  templateUrl: './expenseline-status.component.html',
  styleUrls: ['./expenseline-status.component.scss']
})
export class ExpenseLineStatusComponent implements OnInit {
  @Input() status: ExpenseLineStatus;
  public statuses = ExpenseLineStatus
  constructor() { }

  ngOnInit(): void { }
}
