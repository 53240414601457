import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { entryComponentList } from '../modules/entry-components/entrycomponents.list';
import { randomId } from '../utils';

export enum enumModalModes {
  warning = 'warning',
  success = 'success',
  danger = 'danger',
  primary = 'primary'
}

export interface ModalParcel {
  component?: entryComponentList | '';
  isOpen: boolean;
  mode?: enumModalModes;
  modalTitle?: string;
  actionlbl?: string;
  data?: any;
  response?: any;
  id?: string;
  dismissFooter?: boolean
  headerSuffix?: string
  size?: 'small' | 'regular';
}

@Injectable({ providedIn: 'root' })
export class ModalService {
  private currentState: ModalParcel = {
    isOpen: false,
  }

  public messenger: Subject<ModalParcel> = new Subject<ModalParcel>();

  public open(parcel: ModalParcel) {
    this.currentState = parcel;
    if (!this.currentState.mode) {
      this.currentState.mode = enumModalModes.primary;
    }
    if (!parcel.size) {
      this.currentState.size = 'regular';
    }
    this.messenger.next(this.currentState);
  }

  public close(response: any) {
    this.currentState.isOpen = false;
    this.currentState.response = response;
    this.messenger.next(this.currentState);
  }

  public displayMessage(modalTitle: string, message: string, mode = enumModalModes.primary) {
    const id = randomId();
    this.currentState = {
      modalTitle,
      isOpen: true,
      component: entryComponentList.askQuestion,
      data: {
        question: message
      },
      mode,
      id,
      actionlbl: 'ok'
    };
    this.messenger.next(this.currentState);
    return id;
  }
}
