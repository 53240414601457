import { Pipe, PipeTransform } from '@angular/core';
import { DropDownListOption } from '@roadmate/roadmate-common';

@Pipe({name: 'rm_is_item_selected'})
export class IsItemSelectedPipe implements PipeTransform {
  transform(item: DropDownListOption, allItems: DropDownListOption[]): boolean {
    if (!Array.isArray(allItems) || !item) {
      return false;
    }
    return !!allItems.find(el => el.label === item.label && el.value === item.value);
  }
}
