import { Component, OnInit } from '@angular/core';
import { AbstractEntryComponent } from '../abstract.entry-component';
import { IKV, RoadMateTimeZone } from '@roadmate/roadmate-common';
import { FireStoreService } from '../../../services';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'rm-ikv',
  templateUrl: './ikv.component.html',
  styleUrls: ['./ikv.component.scss']
})
export class IkvComponent extends AbstractEntryComponent<string> implements OnInit  {
  public request: IKV;
  public html = '';
  public companyName: string;
  public pdfText = '';
  public beforSignatureText = '';
  public loading = false;
  public set data(value) {
    this.request = value.request;
    this.companyName = value.companyName;
    this.setHtml();
  }
  constructor(
    private fs: FireStoreService,
    private translate: TranslateService,
  ) {
    super();
  }

  ngOnInit(): void { }

  private setHtml() {
    const employee = this.fs.currentEmployee;
    const transform = (value: string): string => {
      if (!value || value.length < 10) {
        return '';
      }
      const date = value.substring(8, 10);
      const month = RoadMateTimeZone.months[value.substring(5, 7)];
      const year = value.substring(0, 4);
      return `${date === '01' ? '1er' : date} ${month} ${year}`;
    }
    const today = transform(new Date().toISOString());
    this.translate.get(['I_UNDERSIGN', 'born_date', 'AT', 
        'LIVING_IN', 'IKV_PARAGRAPH_1', 'IKV_PARAGRAPH_2',
        'DONE_AT', 'THE', 'SIGNATURE', 'TO_BE_USED_FOR_LEGAL_MATTERS',
        'I_HEREBY', 'FOR_A_TOTAL_OF', 'GIVING_RIGHT_TO_AN_INDEMNITY_OF',
        'I_UNDERSTANT_IKV_AMOUNT_WILL_BE_DEDUCTED_FROM_MY_BALANCE',
      ]).subscribe(
      values => {
        this.html = `
        <div class="rm-column">
          <p>
            ${values.I_UNDERSIGN} ${employee.firstname} ${employee.lastname}, 
            ${values.born_date} ${transform(employee.birthday)} 
            ${values.AT} ${employee.placeOfBirth},
            ${values.LIVING_IN} ${employee.address1}, ${employee.city}, 
            ${values.IKV_PARAGRAPH_1} ${transform(this.request.from)} et le ${transform(this.request.to)} pour un total de ${this.request.distance} km ${values.GIVING_RIGHT_TO_AN_INDEMNITY_OF} ${this.request.amount}€ soit (0.25€ le kilomètre).<br>
            ${values.IKV_PARAGRAPH_2} ${this.companyName}.
          </p>
          <p>
            ${values.DONE_AT} ${employee.city}, ${values.THE} ${today} 
          </p>
          <p>
            ${values.TO_BE_USED_FOR_LEGAL_MATTERS}
          </p>
        </div>
        `;
        this.pdfText = `
        ${values.I_HEREBY} ${employee.firstname} ${employee.lastname}, ${values.born_date} ${transform(employee.birthday)}, ${values.AT}  ${employee.placeOfBirth}, ${values.LIVING_IN} ${employee.address1}, ${employee.city}, j'atteste sur l'honneur avoir utilisé mon vélo personnel pour effectuer tout ou partie de mon trajet domicile – travail entre le ${transform(this.request.from)} et le ${transform(this.request.to)} pour un total de ${this.request.distance} km donnant droit à une indémnité de ${this.request.amount}€ soit (0.25€ le kilomètre).
        ${values.I_UNDERSTANT_IKV_AMOUNT_WILL_BE_DEDUCTED_FROM_MY_BALANCE} ${this.companyName}.
        `;
        this.beforSignatureText = `Fait à ${employee.city}, le ${today}`;
      }
    );
  }
  public saveAttestation(url) {
    this.close(url);
  }
}
