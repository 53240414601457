import { Component, OnInit } from '@angular/core';
import { actions, fileStoringWorkflows } from '@roadmate/roadmate-common';
import { AbstractEntryComponent } from '../abstract.entry-component';

@Component({
  selector: 'rm-edit-object',
  templateUrl: './edit-object.component.html',
  styleUrls: ['./edit-object.component.scss']
})
export class UpdateObjectComponent extends AbstractEntryComponent<any> implements OnInit {
  public targetObject;
  public objectDef;
  public isReadOnly = false;
  public description = '';
  public btns: {label: string, value:string}[] =[];
  public fileStoringWorkflow: fileStoringWorkflows;
  public set data(value) {
    if (!value || !value.objectDef) {
      return;
    } else {
      this.objectDef = value.objectDef;
      this.targetObject = value.targetObject;
      this.isReadOnly = !!value.isReadOnly;
      this.description = value.description;
      this.fileStoringWorkflow = value.fileStoringWorkflow ?? fileStoringWorkflows.assets;
      if (value.btns) {
        this.btns = value.btns;
      }
    }
  }
  constructor() {
    super();
  }

  ngOnInit(): void { }

  public sendObjectToSender(objectUpdated) {
    this.close(objectUpdated);
  }

  public btnClick(btn: {label: string, value:string}) {
    this.close({
      action: actions.syncCompany,
      data: this.targetObject
    });
  }
}
