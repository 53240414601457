import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DoubleRangeComponent } from './double-range.component';
import { FormsModule } from '@angular/forms';
import { LoaderSpinnerComponent } from '../edit-user/loader/loader.component';

const tools = [
  DoubleRangeComponent,
  LoaderSpinnerComponent
]

@NgModule({
  declarations: [...tools],
  imports: [
    CommonModule,
    FormsModule
  ],
  exports: [...tools],
  providers: [],
})
export class CustomToolseModule {}
