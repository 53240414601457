import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'rm-html-render',
  template: `
    <div [innerHTML]="value"></div>
  `,
})
export class HTMLRenderComponent implements OnInit {

  @Input() value: string;

  ngOnInit() {
  }

}
