<h3>{{'add_new_users_subtitle' | translate}}</h3>
<mat-progress-bar [mode]="'indeterminate'" *ngIf="loading"></mat-progress-bar>
<div *ngIf="!dispayUsersToAdd">
  <div class="rm-grid table-row" *ngIf="!loading">
    <div class="container-center-v">
      <img class="file-extension" src="../../../../assets/svg/csv.svg" alt="CSV logo">
    </div>
    <div class="rm-column">
      <div>
        <img class="file-example" src="../../../../assets/img/examples/csv.png" alt="">
      </div>
      <div>
        {{'csv_import_instructions' | translate}}
      </div>
    </div>
    <div class="rm-column">
      <button class="btn btn-primary" type="button" (click)="selectFileInput.click()">{{'import' | translate}}</button>
      <button class="btn btn-primary" type="button" (click)="downloadTemplate('csv')">{{'download_model' | translate}}</button>
    </div>
    <div class="container-center-v">
      <img class="file-extension" src="../../../../assets/svg/xlsx.svg" alt="XLSX logo">
    </div>
    <div class="rm-column">
      <div>
        <img class="file-example"  src="../../../../assets/img/examples/excel.png" alt="">
      </div>
      <div>
        {{'excel_import_instructions' | translate}}
      </div>
    </div>
    <div class="rm-column">
      <button class="btn btn-primary" type="button" (click)="selectFileInput.click()">{{'import' | translate}}</button>
      <button class="btn btn-primary" type="button" (click)="downloadTemplate('xls')">{{'download_model' | translate}}</button>
    </div>
    <ng-container *ngFor="let integ of integrations">
      <div class="container-center-v">
        <img class="file-extension" [src]="integ.logo | rm_image_render" alt="integration logo">
      </div>
      <div class="rm-column">
        <p>
          {{integ.description}}
        </p>
      </div>
      <div class="rm-column">
        <button class="btn btn-primary" type="button" (click)="useIntegration(integ)" [disabled]="!integ.isInstalled || loading">{{'import_from' | translate}} {{integ.name}}</button>
        <button class="btn btn-primary" type="button" *ngIf="!integ.isInstalled" (click)="install()">{{'install' | translate}}</button>
      </div>
    </ng-container>
    
  </div>
  <rm-upload-task
    *ngIf="loading && file"
    [companyRef]="companyRef"
    [agentRef]="agentRef"
    [file]="file"
    [fileSavingWorkFlow]="workflow"
    (finishEmitter)="onFileUploaded($event)"
  >
  </rm-upload-task>
</div>
<div *ngIf="dispayUsersToAdd">
  <div class="form-group">
    {{'you_have_selected' | translate}} {{'the' | translate}} {{'group' | translate}} 
    <strong>
      {{products[0].ddl[0].label}} 
    </strong>
  </div>
  <div class="form-group">
    <rm-multi-check-box
      [setItems]="allEligibleUsers"
      (selectedItemsChangeEmitter)="updateSelectedAppUsers($event)"
    >
    </rm-multi-check-box>
  </div>
  <div class="input-group detached">
    <button
      (click)="confirmAddUsers()"
      [disabled]="loading || !appUsersToAdd.length"
      class="btn btn-primary"
      type="button"
    >
      {{'add_selected_user' | translate}} ({{appUsersToAdd.length}})
      <i class="cil-chevron-right"></i>
    </button>
  </div>
</div>
<input #selectFileInput hidden="true" type="file" onclick="this.value=null" (change)="selectFile($event.target.files)" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"/>
