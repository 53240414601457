import { Pipe, PipeTransform } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Pipe({name: 'get_field_value'})
export class GetFieldValuePipe implements PipeTransform {
  transform(form: FormGroup, fieldName: string): any {
    if (form && form instanceof FormGroup && form.get(fieldName)) {
      return form.get(fieldName).value;
    }
  }
}
