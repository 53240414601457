import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'rm-svg-loading',
    templateUrl: './loading.component.html',
    styleUrls: ['./loading.component.scss']
})
export class LoadingSVGComponent implements OnInit {
    constructor() { }
    ngOnInit(): void { }
}
