<h3>
  {{'please_read_and_sign'|translate}}
</h3>
<rm-attestation
      *ngIf="!loading && html && beforSignatureText"
      [attestationType]="'IKV'"
      [htmlText]="html"
      [beforSignatureText]="beforSignatureText"
      [pdfText]="pdfText"
      [attestationObject]="'ikv_refund_request'"
      [isIKV]="true"
      (urlReadyEmitter)="saveAttestation($event)"
    >
</rm-attestation>
<mat-progress-bar [mode]="'indeterminate'" *ngIf="loading"></mat-progress-bar>