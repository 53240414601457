<app-header
  [navbarBrandRouterLink]="['/']"
  [fixed]="true"
  [navbarBrandFull]="navbarBrandFull"
  [navbarBrandMinimized]="navbarBrandMinimized"
  [sidebarToggler]="'lg'"
  [asideMenuToggler]="false"
  [mobileAsideMenuToggler]="false"
  *ngIf="flash"
>
  <div class="ml-auto">
  </div>
  <div _ngcontent-ijv-c1="" class="col-6 desktop-only">
    <h2 *ngIf="companyName; else roadmateTitle">
      {{companyName}}
    </h2>
    <ng-template #roadmateTitle>
      <ng-container *ngIf="isSuperAdmin">
        <h2>RoadMate BackOffice</h2>
      </ng-container>
      <ng-container *ngIf="isAgent && agent">
        <h2>{{agent.name}} BackOffice</h2>
      </ng-container>
    </ng-template>
  </div>
  <ul class="c-header-nav" *ngIf="isLoggedIn">
    <li class="c-header-nav-item dropdown d-md-down-none mx-2" [hidden]="!isAdmin">
      <div class="c-header-nav-link" role="button" (click)="toggleMenu('menu1')" aria-expanded="false">
        <i class="cil-dinner rm-menu-icons"></i>
      </div>
      <div #menu1 class="dropdown-menu dropdown-menu-right dropdown-menu-lg pt-0" [ngClass]="{'show': toggle1}">
        <div class="dropdown-header bg-light"><strong>{{'quick_access_link' | translate}}</strong></div>
        <a class="dropdown-item" *ngFor="let link of navItems" [routerLink]="link.url" routerLinkActive="rm-selected">
          <i class="{{link.icon}}"></i>
          {{link.name}}
        </a>
        <!-- <div class="dropdown-header bg-light"><strong>L'état de vos comptes</strong></div>
        <a class="dropdown-item d-block" href="#">
          <div class="text-uppercase mb-1"><small><b>FMD</b></small></div><span class="progress progress-xs">
            <div class="progress-bar bg-info" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0"
              aria-valuemax="100"></div>
          </span><small class="text-muted">3510 €</small>
        </a> -->
      </div>
    </li>
    <!-- <li [hidden]="!isSuperAdmin" class="c-header-nav-item dropdown d-md-down-none mx-2">
      <div class="c-header-nav-link" (click)="toggleMenu('menu2')">
        <i class="cil-list-rich"></i>
        <span class="badge badge-pill badge-warning">15</span>
      </div>
      <div #menu2 [ngClass]="{'show': toggle2}" class="dropdown-menu dropdown-menu-right dropdown-menu-lg pt-0">
        <div class="dropdown-header bg-light"><strong>You have 5 pending tasks</strong></div>
          <a class="dropdown-item d-block" href="#">
            <div class="small mb-1">Upgrade NPM &amp; Bower<span class="float-right"><strong>0%</strong></span></div>
            <span class="progress progress-xs">
              <div
                class="progress-barbg-info"
                role="progressbar"
                style="width: 0%"
                aria-valuenow="0"
                aria-valuemin="0"
                aria-valuemax="100"></div>
          </span>
        </a><a class="dropdown-item d-block" href="#">
          <div class="small mb-1">ReactJS Version<span class="float-right"><strong>25%</strong></span></div><span
            class="progress progress-xs">
            <div class="progress-bar bg-danger" role="progressbar" style="width: 25%" aria-valuenow="25"
              aria-valuemin="0" aria-valuemax="100"></div>
          </span>
        </a><a class="dropdown-item d-block" href="#">
          <div class="small mb-1">VueJS Version<span class="float-right"><strong>50%</strong></span></div><span
            class="progress progress-xs">
            <div class="progress-bar bg-warning" role="progressbar" style="width: 50%" aria-valuenow="50"
              aria-valuemin="0" aria-valuemax="100"></div>
          </span>
        </a><a class="dropdown-item d-block" href="#">
          <div class="small mb-1">Add new layouts<span class="float-right"><strong>75%</strong></span></div><span
            class="progress progress-xs">
            <div class="progress-bar bg-info" role="progressbar" style="width: 75%" aria-valuenow="75" aria-valuemin="0"
              aria-valuemax="100"></div>
          </span>
        </a><a class="dropdown-item d-block" href="#">
          <div class="small mb-1">Angular 8 Version<span class="float-right"><strong>100%</strong></span></div><span
            class="progress progress-xs">
            <div class="progress-bar bg-success" role="progressbar" style="width: 100%" aria-valuenow="100"
              aria-valuemin="0" aria-valuemax="100"></div>
          </span>
        </a><a class="dropdown-item text-center border-top" href="#"><strong>View all tasks</strong></a>
      </div>
    </li> -->
    <li class="c-header-nav-item dropdown d-md-down-none mx-2">
      <div class="c-header-nav-link" data-toggle="dropdown" (click)="toggleMenu('menu3')">
        <i class="cil-briefcase rm-menu-icons"></i>
      </div>
      <div #menu3 [ngClass]="{'show': toggle3}" class="dropdown-menu dropdown-menu-right dropdown-menu-lg pt-0">
        <div class="dropdown-header bg-light"><strong>{{'your_account_short' | translate}}</strong></div>
        <div class="rm-profile">
          <div class="message">
            <div class="rm-row center">
              <div class="c-avatar">
                <i *ngIf="!companyImg && isAdmin" class="cil-factory" style="font-size: 2.5em;"></i>
                <img *ngIf="companyImg" class="c-avatar-img" [src]="companyImg" alt="The company's logo">
                <img *ngIf="isSuperAdmin" class="c-avatar-img" src="/assets/img/logo2.png" alt="The company's logo">
                <img *ngIf="(isAgent || isAccountManager) && agentLogo" class="c-avatar-img" [src]="agentLogo" alt="The agent's logo">
              </div>
            </div>
            <div class="text-truncate font-weight-bold rm-row center">
              <ng-container *ngIf="companyName && isAdmin">
                {{companyName}}
              </ng-container>
              <ng-container *ngIf="agent && (isAgent || isAccountManager)">
                {{agent.name}}
              </ng-container>
              <ng-container *ngIf="isSuperAdmin">
                RoadMate
              </ng-container>
            </div>
            <div class="small text-truncate center">
              {{fullName}}
            </div>
            <div class="small text-truncate center">
              {{email}}
            </div>
            <div class="small text-truncate center">
              {{userAppRole | translate}}
            </div>
          </div>
        </div>
        <div class="dropdown-item text-center border-top rm-clickable" (click)="updateProfile()">
          <strong>{{'update_profile' | translate}}</strong>
        </div>
        <div class="dropdown-item" *ngIf="isEmployee && (isAccountManager || isAdmin)" (click)="updateProfile()">
          <button class="btn btn-sm wrap" type="button" (click)="toggleLoginAs()">
            <label *ngIf="loginAs !== 'employee'">
              {{'show_as_employee'|translate}}
            </label>
            <label *ngIf="loginAs === 'employee' && (isAdmin || isAccountManager)">
              <span *ngIf="isAccountManager">
                {{'show_as_accountmanager'|translate}}
              </span>
              <span *ngIf="isAdmin">
                {{'show_as_admin'|translate}}
              </span>
            </label>
          </button>
        </div>
      </div>
    </li>
  </ul>
</app-header>
<div class="app-body">
  <app-sidebar [fixed]="true" [display]="'lg'" [minimized]="minimized" #sidebar
    (minimizedChange)="toggleMinimize($event)">
    <app-sidebar-nav [navItems]="navItems" [perfectScrollbar] [disabled]="sidebar.minimized">
    </app-sidebar-nav>
    <app-sidebar-minimizer></app-sidebar-minimizer>
  </app-sidebar>
  <!-- Main content -->
  <main class="main" [ngClass]="{'connecting-svg': !isLoggedIn}">
    <!-- Breadcrumb -->
    <cui-breadcrumb>
      <!-- Breadcrumb Menu-->
    </cui-breadcrumb>
    <div>
      <router-outlet></router-outlet>
    </div>
    <ng-container *ngIf="parcel | async as parcelObject">
      <rm-modal
        *ngIf="parcelObject.isOpen"
        class="modal"
        [modalTitle]="parcelObject.modalTitle"
        [mode]="parcelObject.mode"
        [actionlbl]="parcelObject.actionlbl"
        [componentName]="parcelObject.component"
        [dismissFooter]="!!parcelObject.dismissFooter"
        [data]="parcelObject.data"
        [headerSuffix]="parcelObject.headerSuffix"
        [size]="parcelObject.size"
      >
      </rm-modal>
    </ng-container>
  </main>
  <!-- <app-aside [fixed]="true" [display]="false"> </app-aside> -->
</div>
<app-footer>
  <div class="rm-row end">
    <a href="https://roadmate.io" target="_blank">RoadMate  &copy; {{year}} - {{dashVersion}}</a>
    <a class="col-auto" href="https://www.roadmate.io/politique-de-confidentialite" target="_blank">Vie privée</a>
    <a class="col-auto" href="https://www.roadmate.io/cgu" target="_blank">CGU</a>
  </div>
</app-footer>
