import { Component, OnInit } from '@angular/core';
import { AbstractEntryComponent } from '../abstract.entry-component';
import * as moment from 'moment-timezone';
import { RoadMateTransfer } from '@roadmate/roadmate-common';

@Component({
  selector: 'rm-confirm-refund',
  templateUrl: './confirm-refund.component.html',
  styleUrls: ['./confirm-refund.component.scss']
})
export class ConfirmRefundComponent extends AbstractEntryComponent<boolean> implements OnInit {
  public transfer: RoadMateTransfer;
  public set data(value) {
    if (!value) {
      return;
    }
    this.transfer = value;
  }
  constructor() {
    super();
  }

  ngOnInit(): void {

  }

}
