import { Pipe, PipeTransform } from '@angular/core';
import { RoadMateDocument, RoadMateFile } from '@roadmate/roadmate-common';

@Pipe({name: 'rm_image_render'})
export class ImageRenderPipe implements PipeTransform {
    transform(value: RoadMateDocument[] | RoadMateFile[]): string {
      if (!Array.isArray(value)) {
        return '/assets/img/placeholder.png';
      }
      return value[0]?.url ?? '';
    }
}