import { AfterViewInit, Component, HostListener, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Observable } from 'rxjs';
import { actions, Pages } from '@roadmate/roadmate-common';
import { entryComponentList } from './modules/entry-components/entrycomponents.list';
import { Idle } from 'idlejs';
import { ModalService } from './services';
import { MessengerService } from './services/messenger.service';
import { RMToast, RMToasterService } from './services/toaster.service';
import { idleValues } from './static';
import { randomId } from './utils';
import { FireAnalyticsService } from './services/fire.analytics';

@Component({
  // tslint:disable-next-line
  selector: 'body',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'RoadMate dashboard';
  public toaster$: Observable<RMToast>;
  public idle: Idle;
  @HostListener('document:click', ['$event'])
  documentClick(event: any): void {
    this.messenger.dom.next(event.target);
  }
  constructor(
    private router: Router,
    private toaster: RMToasterService,
    private messenger: MessengerService,
    private modal: ModalService,
    private analytics: FireAnalyticsService
  ) {
    this.toaster$ = this.toaster.toaster.asObservable();
  }

  ngOnInit() {
    this.analytics.logEvent('App Starting', {});
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });

    this.messenger.parcel.subscribe(
      parcel => {
        switch (parcel.action) {
          case actions.login:
            this.startWatching();
            break;
          case actions.logout:
            this.stopWatching();
            break;
        }
      }
    )
  }

  ngAfterViewInit() {
    // if (document.location.href.indexOf('localhost') === -1) {
    //   const script = document.createElement('script');
    //   script.type = 'text/javascript';
    //   script.id = 'hs-script-loader';
    //   script.src = '//js.hs-scripts.com/9024738.js';
    //   document.body.appendChild(script);
    // }
  }

  private startWatching() {
    this.idle = new Idle()
    .whenNotInteractive()
    .within(idleValues.timeout)
    .do(() => {
      this.openModal();
    })
    .start();
  }

  private openModal() {
    const id = randomId();
    this.modal.open({
      isOpen: true,
      component: entryComponentList.logoutWarning,
      dismissFooter: true,
      modalTitle: 'are_you_there',
      id
    });
    this.stopWatching();
    const sub = this.modal.messenger.subscribe(
      parcel => {
        if (parcel.id === id && parcel.response === false) {
          this.startWatching();
          sub.unsubscribe();
        }
      }
    );
  }

  private stopWatching() {
    if (this.idle) {
      this.idle.stop();
    }
  }
}
