import { Pipe, PipeTransform } from '@angular/core';
import { Field } from '@roadmate/roadmate-common';
import { FireAuthService } from '../../../services';

@Pipe({name: 'rm_shouldHideField'})
export class ShouldHidePipe implements PipeTransform {
  constructor(private fa: FireAuthService) {}

  transform(field: Field): any {
    return !(
      field.show
      &&
      (
        (!field.onlyAgent && !field.onlySuperAdmin)
        || (field.onlyAgent && this.fa.userRoles.agent)
        || (field.onlySuperAdmin && this.fa.userRoles.superadmin)
      )
    )
  }
}
