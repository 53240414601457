import { Pipe, PipeTransform } from '@angular/core';
// import 

@Pipe({ name: 'rm_currency' })
export class CurrencyPipe implements PipeTransform {
  transform(value: number, userCurrency='€'): any {
    const formatCurrency = new Intl.NumberFormat('fr-FR', {
      style: 'currency',
      currency: 'EUR'
    });
    if (userCurrency === '$' || userCurrency === '£') {
      return `${userCurrency}${value}`;
    } else {
      return `${formatCurrency.format(value)}`;
    }
  }
}
