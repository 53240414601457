import { Component, OnInit, Input } from '@angular/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'mat-card-content',
  templateUrl: './mat-card-content.component.html',
  styleUrls: ['./mat-card-content.component.scss']
})
export class CardContentComponent implements OnInit {
  @Input()
  bodyPadding = true;
  constructor() { }

  ngOnInit(): void { }
}
