<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 681 686.689">
    <g id="Group_25" data-name="Group 25" transform="translate(-228 -105)">
      <ellipse id="Ellipse_92" data-name="Ellipse 92" cx="340.5" cy="20" rx="340.5" ry="20" transform="translate(228 751.689)" fill="#e6e6e6"/>
      <path id="Path_607" data-name="Path 607" d="M614,263.086h-3.663V162.735a58.08,58.08,0,0,0-58.08-58.08H339.651a58.08,58.08,0,0,0-58.08,58.08V713.265a58.08,58.08,0,0,0,58.08,58.08H552.257a58.08,58.08,0,0,0,58.08-58.08V334.517H614Z" transform="translate(0 0.345)" fill="#3f3d56"/>
      <path id="Path_608" data-name="Path 608" d="M597.975,163.141V712.86A43.376,43.376,0,0,1,554.6,756.235H340.974A43.376,43.376,0,0,1,297.6,712.861V163.141a43.376,43.376,0,0,1,43.375-43.375h25.916a20.617,20.617,0,0,0,19.081,28.389h121.8a20.617,20.617,0,0,0,19.082-28.389H554.6a43.376,43.376,0,0,1,43.375,43.375Z" transform="translate(0 0.345)" fill="#fff"/>
      <path id="Path_609" data-name="Path 609" d="M212.286,474.693h-4.493l1.5-87.756h1.5Z" transform="translate(184 105)" fill="#e6e6e6"/>
      <path id="Path_610" data-name="Path 610" d="M400.029,436.342l7.252-11.207a168.035,168.035,0,0,0-7.44-17.416l-4.689,3.788,3.713-5.738c-3.539-6.965-6.323-11.354-6.323-11.354S378,417.332,373.139,441.6l9.306,14.382-10.3-8.321a67.979,67.979,0,0,0-.565,8.524c0,28.739,9.386,52.036,20.964,52.036s20.964-23.3,20.964-52.036c0-8.909-2.016-18.23-4.8-26.853Z" transform="translate(0 0.345)" fill="#ccc"/>
      <path id="Path_611" data-name="Path 611" d="M329.245,474.693h-4.493l1.5-87.756h1.5Z" transform="translate(184 105)" fill="#e6e6e6"/>
      <path id="Path_612" data-name="Path 612" d="M516.988,436.342l7.252-11.207a168.022,168.022,0,0,0-7.44-17.416l-4.689,3.788,3.713-5.738c-3.539-6.965-6.323-11.354-6.323-11.354s-14.544,22.919-19.4,47.185L499.4,455.98l-10.3-8.321a67.979,67.979,0,0,0-.565,8.524c0,28.739,9.386,52.036,20.964,52.036s20.964-23.3,20.964-52.036c0-8.909-2.016-18.23-4.8-26.853Z" transform="translate(0 0.345)" fill="#ccc"/>
      <circle id="Ellipse_94" data-name="Ellipse 94" cx="41.28" cy="41.28" r="41.28" transform="translate(351.587 206.238)" fill="#e6e6e6"/>
      <path id="Path_613" data-name="Path 613" d="M266.043,461.916H256.78l3.088-180.945h3.087Z" transform="translate(184 105)" fill="#e6e6e6"/>
      <path id="Path_614" data-name="Path 614" d="M457.762,271.706,472.715,248.6a346.45,346.45,0,0,0-15.341-35.911l-9.669,7.809,7.656-11.832c-7.3-14.362-13.037-23.411-13.037-23.411s-29.988,47.256-40.007,97.29L421.5,312.2l-21.242-17.157a140.16,140.16,0,0,0-1.165,17.576c0,59.257,19.353,107.294,43.226,107.294s43.226-48.037,43.226-107.294c0-18.37-4.156-37.589-9.889-55.368Z" transform="translate(0 0.345)" fill="#ccc"/>
      <path id="Path_615" data-name="Path 615" d="M591.743,592.653V715.661c0,24.949-19.419,45.174-43.375,45.175H334.742c-23.955,0-43.375-20.225-43.376-45.175V604.645c37.038-45.282,90.589-72.345,147.662-74.624S551.485,550.456,591.743,592.653Z" transform="translate(6.232 0.345)" fill="#e6e6e6"/>
      <circle id="Ellipse_95" data-name="Ellipse 95" cx="63.208" cy="63.208" r="63.208" transform="translate(384.812 587.922)" fill="#0F4B9A" opacity="0.2"/>
      <circle id="Ellipse_96" data-name="Ellipse 96" cx="50.895" cy="50.895" r="50.895" transform="translate(397.125 600.235)" fill="#0F4B9A"/>
      <path id="Path_616" data-name="Path 616" d="M258.084,567.89l-17.937-23.061,10.431-8.113,8.492,10.919,28.689-30.284,9.594,9.088Z" transform="translate(184 105)" fill="#fff"/>
      <g id="Group_24" data-name="Group 24">
        <circle id="Ellipse_97" data-name="Ellipse 97" cx="33" cy="33" r="33" transform="translate(685.486 505.969)" fill="#2f2e41"/>
        <path id="Path_624" data-name="Path 624" d="M553.859,383.226l2.542,11.993,47.47-4.1-3.751-17.7Z" transform="translate(178 341)" fill="#ffb8b8"/>
        <path id="Path_625" data-name="Path 625" d="M789.188,618.5h38.531v14.887H804.075A14.887,14.887,0,0,1,789.188,618.5Z" transform="translate(286.71 1661.973) rotate(-101.965)" fill="#2f2e41"/>
        <path id="Path_626" data-name="Path 626" d="M524.12,382.586l-5.343,11.035-45.1-15.357,7.886-16.285Z" transform="translate(178 341)" fill="#ffb8b8"/>
        <path id="Path_627" data-name="Path 627" d="M771.037,617.456h23.644v14.887H756.151A14.887,14.887,0,0,1,771.037,617.456Z" transform="translate(-201.941 1165.868) rotate(-64.164)" fill="#2f2e41"/>
        <path id="Path_628" data-name="Path 628" d="M690.259,586.414a10.743,10.743,0,0,0,12.709-10.48l74.374-68.571-18.47-14.305L691.535,565a10.8,10.8,0,0,0-1.275,21.413Z" transform="translate(-77 115.379)" fill="#ffb8b8"/>
        <circle id="Ellipse_98" data-name="Ellipse 98" cx="24.561" cy="24.561" r="24.561" transform="translate(689.783 524.337)" fill="#ffb8b8"/>
        <path id="Path_629" data-name="Path 629" d="M813.386,577.893c-17.229,0-37.978-3.628-50.779-18.477l-.288-.334.3-.328c.1-.107,9.514-10.869.111-30.061L749.8,532.67l-12.87-16.989,7.13-21.389,29.177-23.5A26.751,26.751,0,0,1,787.846,465a80.211,80.211,0,0,0,27.785-7.913,27.906,27.906,0,0,1,12.8-2.8l.574.016a9.95,9.95,0,0,1,9.641,10.708c-1.979,25.63-5.472,87.543,4.792,108.861l.265.551-.592.15a136.109,136.109,0,0,1-29.723,3.319Z" transform="translate(-77 115.379)" fill="#0F4B9A"/>
        <path id="Path_630" data-name="Path 630" d="M765.986,559.09s-65-6-72,13,1,28,13,32,41,9,41,9l13-16,34,2s37.885,21.973,48.36,45.474A30.762,30.762,0,0,0,869.123,662.8c8.4.584,15.863-2.587,15.863-15.712,0-30-42-73-42-73Z" transform="translate(-77 115.379)" fill="#2f2e41"/>
        <path id="Path_631" data-name="Path 631" d="M716.486,588.59s17-5,44,8" transform="translate(-77 115.379)" fill="#2f2e41"/>
        <path id="Path_632" data-name="Path 632" d="M766.843,417.855a73.042,73.042,0,0,0,31.6,10.412l-3.331-3.991a24.476,24.476,0,0,0,7.561,1.5,8.281,8.281,0,0,0,6.75-3.159,7.7,7.7,0,0,0,.516-7.115,14.589,14.589,0,0,0-4.589-5.739,27.323,27.323,0,0,0-25.431-4.545,16.33,16.33,0,0,0-7.6,4.872,9.236,9.236,0,0,0-1.863,8.561" transform="translate(-77 115.379)" fill="#2f2e41"/>
        <path id="Path_633" data-name="Path 633" d="M795.309,398.224A75.485,75.485,0,0,1,814.446,371.7c5.292-4.7,11.473-8.743,18.446-9.963s14.833.87,19.111,6.511c3.5,4.613,4.152,10.793,3.767,16.569s-1.676,11.5-1.553,17.284a35.468,35.468,0,0,0,50.527,31.351c-6.022,3.329-10.714,8.6-16.3,12.608s-12.963,6.76-19.312,4.11c-6.718-2.8-9.8-10.419-12.206-17.29l-10.732-30.64c-1.824-5.209-3.739-10.572-7.462-14.645s-9.765-6.557-14.889-4.509c-3.884,1.552-6.413,5.258-8.63,8.8s-4.557,7.32-8.3,9.179-9.3.715-10.523-3.285" transform="translate(-77 115.379)" fill="#2f2e41"/>
        <path id="Path_636" data-name="Path 636" d="M803.736,577.979a11.57,11.57,0,0,1-1.268-.07,11,11,0,0,1,1.251-21.931,10.626,10.626,0,0,1,1.466.109l33.578-35.745-3.505-11.685,17.6-7.892,4.934,12.06A20.49,20.49,0,0,1,851.62,536.5l-36.961,29.4a10.238,10.238,0,0,1,.06,1.079,11.018,11.018,0,0,1-3.664,8.2A10.872,10.872,0,0,1,803.736,577.979Z" transform="translate(-77 115.379)" fill="#ffb8b8"/>
        <path id="Path_637" data-name="Path 637" d="M829.986,459.09h0a9.453,9.453,0,0,1,12.628,5.156l17.372,43.844-27,18Z" transform="translate(-77 115.379)" fill="#0F4B9A"/>
      </g>
    </g>
  </svg>
