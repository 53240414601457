// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  env: 'Dev',
  rootUrl: 'https://dashdev.roadmate.io',
  localrootUrl: 'http://localhost:4400',
  esfForm: 'https://firebasestorage.googleapis.com/v0/b/roadmate-mobility-prod.appspot.com/o/assets%2FESF-Questionnaire-Sanctions.pdf?alt=media',
  w9Form: 'https://firebasestorage.googleapis.com/v0/b/roadmate-mobility-prod.appspot.com/o/assets%2Fw9-form.pdf?alt=media',
  payoutSupportingLink: 'https://firebasestorage.googleapis.com/v0/b/roadmate-mobility-prod.appspot.com/o/assets%2FAttestation-Sur-Lhonneur.pdf?alt=media',
  stripePublicKey: 'pk_test_51IWTPmAGSHkvuELZ2xBTp2MIZJ7qZArVY8gpMDx3pwtnfcsjAeTIh924RehWFkFkG7BeIiu0a2plnRWPRwlyUIXb00dkDkDb2w',
  firebase: {
    apiKey: 'AIzaSyDIcytLMp_fxEDCyG2EYYYkwpYvWnl6FmY',
    authDomain: 'dashboard-dd475.firebaseapp.com',
    databaseURL: 'https://dashboard-dd475.firebaseio.com',
    projectId: 'dashboard-dd475',
    storageBucket: 'dashboard-dd475.appspot.com',
    messagingSenderId: '263458502155',
    appId: '1:263458502155:web:c8a09ba3d9d6ddf6ceb90e',
    measurementId: 'G-0JREDLEM1V'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
