import { Component, Input, OnInit } from '@angular/core';

import {RoadMateDocument } from '@roadmate/roadmate-common';

@Component({
  selector: 'rm-file-render',
  template: `
    <label (click)="openFile()" style="cursor:pointer; text-decoration: underline">
    {{renderValue | truncate:10}}
    <i class="cil-external-link"></i>
    </label>
  `,
})
export class FileRenderComponent implements OnInit {

  renderValue: string;
  url: string;
  @Input() value: any;
  @Input() rowData: any;

  ngOnInit() {
    if (!this.value) {
      return;
    }
    if (Array.isArray(this.value) && this.value.length) {
      this.renderValue = this.value[0]?.name ?? '';
      this.url =  this.value[0]?.url ?? '';
    } else if (this.value.name) {
      this.renderValue = this.value.name ?? '';
      this.url =  this.value.url ?? '';
    }
  }

  public openFile() {
    if(!this.url) {
      return;
    }
    window.open(this.url, '_system', 'location=yes');
  }
}
