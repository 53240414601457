<div class="tree-container">
  <div class="space-down">
    <button class="btn btn-primary" type="button" label="Expand all" (click)="expandAll()" style="margin-right: .5rem" >
      {{'expand_all' | translate}}
    </button>
    <button class="btn btn-primary" type="button" label="Collapse all" (click)="collapseAll()">
      {{'collapse_all' | translate}}
    </button>
  </div>
  <div class="form-group">
    <h3>{{whiteList.name}}</h3>
    <p>
      {{whiteList.description}}
    </p>
  </div>
  <div class="form-group">
    <p>
      {{'here_are_the_authorized_list' | translate}}
    </p>
  </div>
  <div class="space-down">
    <p-tree [value]="treeNode">
      <ng-template let-node  pTemplate="category">
        <div class="rm-grid tree-line category" [ngClass]="{'sa': isSuperAdmin, 'regular': !isSuperAdmin}">
          <img [src]="node.key" alt="Category pic">
          <h5>
            {{node.label | translate}}
          </h5>
          <div class="form-group" *ngIf="isSuperAdmin">
            <button class="btn btn-primary" type="button" (click)="propagateCategory(node)" [disabled]="loading">
              <span *ngIf="loading" class="c-loading-button-spinner spinner-border spinner-border-sm"></span>
              {{'propagate_category' | translate}}
            </button>
          </div>
          <div class="item-checkbox" [pTooltip]="">
            <label class="c-switch c-switch-label c-switch-primary">
              <input
                disabled="true"
                class="c-switch-input"
                type="checkbox"
                [checked]="!!node.isSelected">
              <span class="c-switch-slider" data-checked="✓" data-unchecked="✕"></span>
            </label>
          </div>
        </div>
      </ng-template>
      <ng-template let-node  pTemplate="subCategory">
        <div class="rm-grid tree-line sub-category">
          <img [src]="node.key" alt="Category pic">
          <h5>
            {{node.label | translate}}
          </h5>
          <div class="item-checkbox">
            <label class="c-switch c-switch-label c-switch-primary">
              <input
                class="c-switch-input"
                type="checkbox"
                disabled="true"
                [checked]="!!node.isSelected">
              <span class="c-switch-slider" data-checked="✓" data-unchecked="✕"></span>
            </label>
          </div>
        </div>
      </ng-template>
      <ng-template let-node  pTemplate="merchant">
        <div class="rm-grid tree-line merchant">
          <img [src]="node.key" alt="Merchant pic">
          <h5>
            {{node.label | translate}}
          </h5>
          <div class="item-check">
            <label class="c-switch c-switch-label c-switch-primary">
              <input
                class="c-switch-input"
                type="checkbox"
                disabled="true"
                [checked]="!!node.isSelected">
              <span class="c-switch-slider" data-checked="✓" data-unchecked="✕"></span>
            </label>
          </div>
        </div>
      </ng-template>
      <ng-template let-node  pTemplate="default">
        <div class="rm-grid tree-line custom-rules">
          <h5>
            {{node.label | translate}}
          </h5>
          <div class="item-check">
            <label class="c-switch c-switch-label c-switch-primary">
              <input
                class="c-switch-input"
                type="checkbox"
                disabled="true"
                [checked]="!!node.isSelected">
              <span class="c-switch-slider" data-checked="✓" data-unchecked="✕"></span>
            </label>
          </div>
        </div>
        <div class="rm-grid tree-line custom-rules">
          <textarea
            class="form-control"
            name="custom-rules"
            rows="3"
          ></textarea>
        </div>
      </ng-template>
    </p-tree>
  </div>
  <div class="form-group">
    <label >
      {{'custom_rules' | translate}}
    </label>
    <textarea
      [(ngModel)]="whiteList.customRules"
      class="form-control"
      rows="3"
    ></textarea>
  </div>
  <div class="form-group">
    <button
      [disabled]="loading"
      class="btn btn-block btn-primary"
      (click)="save()"
      type="button"
    >
      {{'save' | translate}}
      <span *ngIf="loading" class="c-loading-button-spinner spinner-border spinner-border-sm"></span>
    </button>
  </div>
</div>
