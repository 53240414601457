import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { FireStoreService } from '../../services/fire.store';
import { Field, fieldTypes, RoadMateFile, Treezor, FormDefinition,
  fileStoringWorkflows, RoadMateResponse, treezorDorpDownLists,
  DropDownListOption, FieldFeilds, emailRegex, passwordRegex,
  numberRegex, dateRegex, companyLists, VehicleTypeDDL, PowerSourceDDL, TransportCategoryListDDL, ObjectDefList, saLists } from '@roadmate/roadmate-common';
import { FireFunctionsService } from '../../services/fire.functions';
import { FireAuthService } from '../../services/fire.auth';
import { verifyDate, verifyPhone } from './validators/date.validator';
import { customRuleLegalSectorEmargo, customTriggers } from './custom-trigers/custom.triggers';
import { verifyiban } from './validators/iban.validator';
import { alertEditors } from '../../static';

@Component({
  selector: 'rm-reactive-forms',
  templateUrl: './reactive-forms.component.html',
  styleUrls: ['./reactive-forms.component.scss']
})
export class ReactiveFormsComponent implements OnInit {
  @Input() saveBtnLabel: string;
  @Input() objectDefinition: {[key: string]: Field};
  @Input() objectToUpdate: any;
  @Input() reactiveForm: FormGroup;
  @Input() objectKey: string;
  @Input() fileStoringWorkflow: fileStoringWorkflows;
  @Input() displayOnly = false;
  @Input() showSaveTop = false;
  public companyRef: string;
  public agentRef: string;
  @Input() set setLoading(value) {
    this.loading = !!value;
  }
  @Input() configuredFields: Field[];
  @Input() isThisADefinitionObject = false;
  @Output() saveEmitter = new EventEmitter<any>();
  @Output() isDirtyEmitter = new EventEmitter<boolean>();
  @Output() selectValueChangeEmitter: EventEmitter<{fieldName: string, value: any}> = new EventEmitter<{fieldName: string, value: any}>();
  private subscriptions = [];
  public timeOutId;
  public escaped = false;
  public hiddenForms = [];
  public autocompleteLists: {[key: string]: any[]} = {};
  public autocompleteLoaders: {[key: string]: boolean} = {};
  public autocompleteListObjects: {[key: string]: any[]} = {};
  public isSubForm = false;
  public loading = false;
  public error = false;
  public fields: Field[] = [];
  public allTypes = fieldTypes;
  public isSuperAdmin = false;
  public isAgent = false;
  public customDisabled = false;
  public metadata;
  public formIsReady = false;
  constructor(
    private auth: FireAuthService,
    private fs: FireStoreService,
    private fct: FireFunctionsService
  ) {
    this.isSuperAdmin = this.auth.userRoles.superadmin;
    this.isAgent = this.auth.userRoles.agent;
  }
  // Add Conditional fields that becomes visible when select value changes
  ngOnInit(): void {
    this.companyRef = this.fs.currentCompany?.ref ?? '';
    this.agentRef = this.fs.currentAgent?.ref ?? '';
    this.init();
  }
  private init() {
    this.loading = true;
    try {
      if (!this.reactiveForm) {
        const result = this.setupForm(this.objectDefinition, this.objectToUpdate);
        this.reactiveForm = result.reactiveForm;
        this.fields = result.fields;
        this.setUpTriggers();
        this.setDrityEmitter();
      } else {
        const currentFormDef: FormDefinition = this.fs.getObjectDefinitionByRef(this.objectKey);
        this.fields = this.configuredFields;
        // Object.keys(currentFormDef)
        // .filter(k => k !== 'ref')
        // .sort((key1: string, key2: string) => currentFormDef[key1].order - currentFormDef[key2].order)
        // .map(key => currentFormDef[key]);
        this.setUpTriggers();
        this.isSubForm = true;
        this.objectDefinition = currentFormDef;
      }
    } catch (e) {
      console.error('Could not load form for object ' + this.objectDefinition, e);
    } finally {
      this.loading = false;
    }
  }

  public setupForm(objectDefinition: {[fieldName: string]: Field}, objectToUpdate) {
    const reactiveForm = new FormGroup({});
    Object.keys(objectDefinition).forEach(
      key => {
        const field: Field = objectDefinition[key] as Field;
        field.value = objectToUpdate && this.hasValue(objectToUpdate[key]) ?
        objectToUpdate[key] :
        (this.hasValue(field.defaultValue) ? field.defaultValue : '');
        if (!this.isSuperAdmin && field.onlySuperAdmin) {
          field.mandatory = false;
        }
        switch (field.formtype) {
          case fieldTypes.ddlObject:
            if (field.objectReference) {
              field.values = this.fs.getListByName(field.objectReference);
            }
            reactiveForm.addControl(field.name, new FormControl(
              {
                value: field.value,
                disabled: field.readonly || this.displayOnly
              },
              field.mandatory ? [Validators.required] : [])
            );
            break;
          case fieldTypes.address:
            reactiveForm.addControl(field.name, new FormControl(
              {
                value: field.value,
                disabled: field.readonly || this.displayOnly
              },
              field.mandatory ? [Validators.required] : [])
            );
            break;
          case fieldTypes.checkbox:
            reactiveForm.addControl(field.name, new FormControl(
              {
                value: !!field.value,
                disabled: field.readonly || this.displayOnly
              },
              field.mandatory ? [Validators.required] : [])
            );
            break;
          case fieldTypes.multiselect:
            field.values = this.processLongLists(field);
            if (field.value && field.value.length) {
              const newformArray = new FormArray([]);
              field.value.forEach((item: DropDownListOption) => {
                newformArray.push(new FormControl({
                  label: item.label,
                  value: item.value,
                  img: item.img ?? '',
                  isSelected: !!item.isSelected
                }));
              });
              reactiveForm.addControl(field.name, newformArray);
            } else {
              reactiveForm.addControl(field.name, new FormArray([]));
            }
            break;
          case fieldTypes.boolean:
            if (treezorBooleans.indexOf(field.name) > -1) {
              reactiveForm.addControl(field.name, new FormControl(
                {
                  value: field.value === '1' ? true : false,
                  disabled: field.readonly || this.displayOnly
                },
                field.mandatory ? [Validators.required] : [])
              );
            } else {
              reactiveForm.addControl(field.name, new FormControl(
                {
                  value: !!field.value,
                  disabled: field.readonly || this.displayOnly
                },
                field.mandatory ? [Validators.required] : [])
              );
            }
            break;
          case fieldTypes.date:
            field.value = this.handleDateValue(field.value, 'in');
            reactiveForm.addControl(field.name, new FormControl(
              {
                value: field.value,
                disabled: field.readonly || this.displayOnly
              },
              field.mandatory ?
                [ Validators.required, Validators.pattern(dateRegex), verifyDate()]
              : [verifyDate()]
              )
            );
            break;
          case fieldTypes.date:
            field.value = this.handleDateTimeValue(field.value, 'in');
            reactiveForm.addControl(field.name, new FormControl(
              {
                value: field.value,
                disabled: field.readonly || this.displayOnly
              },
              field.mandatory ?
                [ Validators.required, verifyDate()]
              : [verifyDate()]
              )
            );
            break;
          case fieldTypes.email:
            reactiveForm.addControl(field.name, new FormControl(
              {
                value: field.value,
                disabled: field.readonly || this.displayOnly
              },
              field.mandatory ? [Validators.required, Validators.pattern(emailRegex)] :
              [Validators.pattern(emailRegex)])
            );
            break;
          case fieldTypes.password:
            reactiveForm.addControl(field.name, new FormControl(
              {
                value: field.value,
                disabled: field.readonly || this.displayOnly
              },
              [Validators.required, Validators.pattern(field.pattern ? field.pattern : passwordRegex)]
            ));
            break;
          case fieldTypes.phone:
            reactiveForm.addControl(field.name, new FormControl(
              {
                value: field.value,
                disabled: field.readonly || this.displayOnly
              },
              field.mandatory ? [Validators.required, verifyPhone()] : [verifyPhone()]
            ));
            break;
          case fieldTypes.autocomplete:
            this.autocompleteLists[field.name] = [];
            this.autocompleteListObjects[field.name] = [];
            reactiveForm.addControl(field.name, new FormControl(
              {
                value: field.value,
                disabled: field.readonly || this.displayOnly
              },
              field.mandatory ? [Validators.required] : [])
            );
            break;
          case fieldTypes.number:
            reactiveForm.addControl(field.name, new FormControl(
                {
                  value: field.value,
                  disabled: field.readonly || this.displayOnly
                },
                field.mandatory ? [Validators.required] : []
              )
            );
            break;
          case fieldTypes.iban:
            reactiveForm.addControl(field.name, new FormControl(
              {
                value: field.value,
                disabled: field.readonly || this.displayOnly
              },
              field.mandatory ? [Validators.required,
                verifyiban()]
                : [verifyiban()])
            );
            break;
          case fieldTypes.select:
              field.values = this.processLongLists(field);
              reactiveForm.addControl(field.name, new FormControl(
                {
                  value: field.value ? field.value : '',
                  disabled: field.readonly || this.displayOnly
                },
                field.mandatory ? [Validators.required] : [])
              );
              break;
          case fieldTypes.stringArray:
            if (!field.value) {
              field.value = [];
            }
            const formArray = new FormArray([]);
            field.value.forEach(element => {
                formArray.push(new FormControl(element));
            });
            reactiveForm.addControl(field.name, formArray);
            break;
          case fieldTypes.object:
            if (this.isThisADefinitionObject) {
              const fieldDefinition: any = new FieldFeilds();
              const objectDefinitionForm = this.setupForm(fieldDefinition, objectToUpdate[field.name]);
              reactiveForm.addControl(field.name, objectDefinitionForm.reactiveForm);
              break;
            }
            if (!field.objectReference || !this.fs.getObjectDefinitionByRef(field.objectReference)) {
              break;
            } else {
              const objectDef = this.fs.getObjectDefinitionByRef(field.objectReference);
              const objectForm = this.setupForm(objectDef, objectToUpdate && objectToUpdate[key] ? objectToUpdate[key] : null);
              reactiveForm.addControl(field.name, objectForm.reactiveForm);
              field.metadata = objectForm.fields;
            }
            break;
          case fieldTypes.objectArray:
            if (!field.objectReference || !this.fs.getObjectDefinitionByRef(field.objectReference)) {
              break;
            } else {
              const objectArray = new FormArray([]);
              if (objectToUpdate && Array.isArray(objectToUpdate[key])) {
                const objDef2 = this.fs.getObjectDefinitionByRef(field.objectReference);
                if (!objDef2) {
                  return;
                }
                const array: any[] = objectToUpdate[key];
                if (!Array.isArray(field.metadata)) {
                  field.metadata = [];
                }
                array.forEach((item, subItemIndex) => {
                  console.log(field.name, 'SubItem index: ', subItemIndex);
                  const subForm = this.setupForm(objDef2, item);
                  field.metadata.push(JSON.parse(JSON.stringify(subForm.fields)));
                  objectArray.push(subForm.reactiveForm);
                });
              }
              reactiveForm.addControl(field.name, objectArray);
            }
            break;
          case fieldTypes.file:
              const fileArray = new FormArray([]);
              const objDef = this.fs.getObjectDefinitionByRef('roadmate-document');
              if (!objDef) {
                return;
              }
              const docArray: any[] = objectToUpdate && Array.isArray(objectToUpdate[key]) ? objectToUpdate[key] : [];
              if (Array.isArray(docArray) && docArray.length) {
                docArray.forEach(item => {
                  const subForm = this.setupForm(objDef, item);
                  field.metadata = subForm.fields;
                  fileArray.push(subForm.reactiveForm);
                });
              }
              reactiveForm.addControl(field.name, fileArray);
              break;
          case fieldTypes.time:
            reactiveForm.addControl(field.name, new FormControl(
              {
                value: field.value,
                disabled: field.readonly || this.displayOnly
              },
              field.mandatory ? [Validators.required] : []));
            break;
          case fieldTypes.metadata:
            this.metadata = field.value;
            // display a <pre></pre>
            break;
          default:
            reactiveForm.addControl(field.name,  new FormControl(
              {
                  value: this.hasValue(field.value) ? field.value : '',
                  disabled: field.readonly || this.displayOnly
              }, field.mandatory ? [Validators.required] : []));
            break;
        }
      }
    );
    const fields = Object.keys(objectDefinition)
    .filter(k => k !== 'ref')
    .sort((key1: string, key2: string) => objectDefinition[key1].order - objectDefinition[key2].order)
    .map(key => objectDefinition[key]);
    return {
      reactiveForm,
      fields
    };
  }
  public clear(fieldName: string) {
    this.reactiveForm.get(fieldName).reset();
  }

  public hideAutocomplete() {
    this.autocompleteLists = {};
    this.escaped = true;
    setTimeout(() => this.escaped = false, 1000);
  }

  private setDrityEmitter() {
    this.subscriptions.push(
      this.reactiveForm.statusChanges.subscribe(
        status => this.isDirtyEmitter.emit(this.reactiveForm.dirty)
      )
    )
  }

  private setUpTriggers() {
    this.fields.forEach(
      field => {
        if (field.actionTrigger && field.actionTrigger.length) {
          field.actionTrigger.forEach(
            trigger => {
              if ([customTriggers.embargoRule].indexOf(trigger.targetField as customTriggers) > -1) {
                const subs = this.reactiveForm.get(field.name).valueChanges.subscribe(
                  newValue => {
                    customRuleLegalSectorEmargo(newValue, this.fields, field);
                  }
                );
                this.subscriptions.push(subs);
              }
              if (
                (this.reactiveForm.get(trigger.targetField) && this.reactiveForm.get(field.name))
                || trigger.targetField === 'all'
              ) {
                if (trigger.targetField === 'all') {
                  this.customDisabled = true;
                }
                const sub = this.reactiveForm.get(field.name).valueChanges.subscribe(
                  (newValue) => {
                    const values: any[] = Array.isArray(trigger.onValues) ? trigger.onValues : [];
                    if (trigger.targetField === 'all') {
                      if (values.indexOf(newValue) > -1) {
                        this.customDisabled = true;
                      } else {
                        this.customDisabled = false;
                      }
                      return;
                    }
                    if (values.indexOf(newValue) > -1) {
                      const targetField = this.fields.find(fi => fi.name === trigger.targetField);
                      if (targetField) {
                        targetField.show = !!trigger.transformations.show;
                        targetField.mandatory = !!trigger.transformations.mandatory;
                        targetField.readonly = !!trigger.transformations.readonly;
                      }
                    }
                  }
                );
                this.subscriptions.push(sub);
              }
            }
          );
        }
    });
  }

  private hasValue(someValue) {
    const type = typeof someValue;
    switch (type) {
      case 'bigint':
      case 'boolean':
      case 'number':
      case 'string':
      case 'object':
        return true;
      default:
        return false;
    }
  }

  public setFeild(data: {doc: RoadMateFile, fieldName: string}) {
    const array = this.reactiveForm.get(data.fieldName) as FormArray;
    const newForm = new FormGroup({
      name: new FormControl(data.doc.name),
      type: new FormControl(data.doc.type),
      size: new FormControl(data.doc.size),
      uploadedBy: new FormControl(data.doc.uploadedBy),
      addedAt: new FormControl(data.doc.addedAt),
      url: new FormControl(data.doc.url)
    });
    array.push(newForm);
    this.reactiveForm.get(data.fieldName).markAsDirty();
  }

  public removeItemFormArray(fieldName: string, index: number) {
    const array = this.reactiveForm.get(fieldName) as FormArray;
    array.removeAt(index);
  }

  public addItemToArray(fieldName: string) {
    const array = this.reactiveForm.get(fieldName) as FormArray;
    array.push(new FormControl(''));
  }

  private recusevlyCleanObject(formValue: any, objectDefinition: {[fieldName: string]: Field}) {
    if (!formValue || typeof formValue !== 'object') {
      return;
    }
    Object.keys(formValue).forEach(key => {
      if (!objectDefinition[key]) {
        return;
      }
      if (formValue[key] === undefined) {
        formValue[key] = '';
      }
      switch(objectDefinition[key].formtype) {
        case fieldTypes.date:
          formValue[key] = this.handleDateValue(formValue[key], 'out');
          break;
        case fieldTypes.datetime:
          formValue[key] = this.handleDateTimeValue(formValue[key], 'out');
          break;
        case fieldTypes.ddlObject:
        case fieldTypes.multiselect:
          break;
        case fieldTypes.boolean:
          if (treezorBooleans.indexOf(key) > -1) {
            formValue[key] = formValue[key] ? '1' : '0';
          }
          break;
        case fieldTypes.object:
        case fieldTypes.objectArray:
          const subObjDef = this.fs.getObjectDefinitionByRef(objectDefinition[key].objectReference);
          if (!subObjDef) {
            return;
          }
          if (Array.isArray(formValue[key]) && formValue[key].length) {
            const array: any[] = formValue[key];
            array.forEach(el => {
              this.recusevlyCleanObject(el, subObjDef);
            });
          } else {
            this.recusevlyCleanObject(formValue[key], subObjDef);
          }
          break;
      }
    });
  }

  public save() {
    let value = this.reactiveForm.getRawValue();
    this.recusevlyCleanObject(value, this.objectDefinition);
    if (this.objectToUpdate && Object.keys(this.objectToUpdate).length) {
      value = {...this.objectToUpdate, ...value};
    }
    this.saveEmitter.emit(value);
    this.isDirtyEmitter.emit(false);
  }

  private handleDateValue(dateString: string, direction: 'in' | 'out') {
    // tslint:disable-next-line:one-variable-per-declaration
    let year, month, day;
    let dateFragments: string[] = [];
    if (direction === 'out') {
      if (!dateString || dateString.split('/').length !== 3) {
        return '';
      }
      dateFragments = dateString.split('/');
      year = dateFragments[2];
      month = dateFragments[1];
      day = dateFragments[0];
      return `${year}-${month}-${day}`;
    } else {
      if (!dateString || dateString.split('-').length !== 3) {
        return '';
      }
      dateFragments = dateString.split('-');
      year = dateFragments[0];
      month = dateFragments[1];
      day = dateFragments[2].substr(0,2);
      return `${day}/${month}/${year}`
    }
  }

  private handleDateTimeValue(dateString: string, direction: 'in' | 'out') {
    let dateFragments: string[];
    // tslint:disable-next-line:one-variable-per-declaration
    let year, month, day, time;
    if (direction === 'out') {
      if (!dateString || dateString.split('/').length !== 3) {
        return '';
      }
      dateFragments = dateString.split('/');
      year = dateFragments[2].substr(0, 4);
      time = dateFragments[2].substr(5, 5);
      month = dateFragments[1];
      day = dateFragments[0];
      return `${year}-${month}-${day} ${time}`;
    } else {
      if (!dateString || dateString.split('-').length !== 3) {
        return '';
      }
      dateFragments = dateString.split('-');
      year = dateFragments[0];
      month = dateFragments[1];
      day = dateFragments[2].substr(0, 2);
      time = dateFragments[2].substr(3, 5);
      return `${day}/${month}/${year} ${time}`
    }
  }

  private processLongLists(field: Field): DropDownListOption[]  {
    if (!field || !field.name) {
      return [];
    }
    if (field.objectReference && this.fs.lists.find(el => el.name === field.objectReference)) {
      const ddl = this.fs.lists.find(el => el.name === field.objectReference);
      if (ddl.list?.length) {
        ddl.list.sort((a, b) => `${a.label}`.localeCompare(`${b.label}`));
      }
      return ddl.list;
    }
    switch (field.name) {
      case 'userStatus':
        return treezorDorpDownLists.userStatus;
      case 'kycReview':
        return treezorDorpDownLists.kycReview;
      case 'kycLevel':
        return treezorDorpDownLists.kycLevel;
      case 'employeeType':
        return treezorDorpDownLists.employeeType;
      case 'legalForm':
        return treezorDorpDownLists.legalForm;
      case 'controllingPersonType':
        return treezorDorpDownLists.controlingParentType;
      case 'entityType':
        return treezorDorpDownLists.entityType;
      case 'parentType':
        return treezorDorpDownLists.parentType;
      case 'userTypeId':
        return treezorDorpDownLists.userType;
      case 'title':
        return treezorDorpDownLists.title;
      case 'personalAssets':
        return treezorDorpDownLists.personalAssets;
      case 'legalNumberOfEmployeeRange':
        return treezorDorpDownLists.legalNumberOfEmployeeRange;
      case 'incomeRange':
        return treezorDorpDownLists.incomeRange;
      case 'legalNetIncomeRange':
        return treezorDorpDownLists.legalNetIncomeRange;
      case 'legalAnnualTurnOver':
        return treezorDorpDownLists.legalAnnualTurnOver;
      case 'country':
      case 'birthCountry':
      case 'nationality':
        return treezorDorpDownLists.country;
      case 'affectedTo':
        return alertEditors;
      case 'companyRef':{
        const companies = this.fs.lists.find(el => el.name === ObjectDefList.companies);
        if (companies && companies.list && companies.list.length) {
          return companies.list.map(item => {
            return {
              label: item.legalName,
              value: item.ref
            }
          });
        }
        return [];
      }
      case 'roadmateProduct': {
        const ordersList = this.fs.lists.find(el => el.name === companyLists.orders);
        if (ordersList && ordersList.list && ordersList.list.length) {
          return ordersList.list;
        }
        return [];
      }
      case 'salesAgent': {
        const agentsList = this.fs.lists.find(el => el.name === saLists.salesAgents);
        if (agentsList && agentsList.list && agentsList.list.length) {
          return agentsList.list;
        }
        return [];
      }
      case 'addHtmlSnippet': {
        const snippets = this.fs.salists.find(el => el.name === saLists.htmlSnippets);
        if (snippets?.list?.length) {
          return snippets.list;
        }
        return [];
      }
      case 'agentRef':{
        const agentsList = this.fs.lists.find(el => el.name === 'agents');
        if (agentsList && agentsList.list && agentsList.list.length) {
          return agentsList.list.map(item => {
            return {
              label: item.legalName,
              value: item.ref
            }
          });
        }
        return [];
      }
      case 'beneficiaryGroup':
        return this.fs.getListByName(companyLists.groups);
      case 'merchants':
        return this.fs.getListByName('merchants');
      case 'vehiculeType':
        return VehicleTypeDDL;
      case 'powerSource':
        return PowerSourceDDL;
      case 'transportCategoryCode':
      case 'transportType':
        return TransportCategoryListDDL;
      default:
        return field.values;
    }
  }

  public openDocument(fieldName: string, index: number) {
    const array = this.reactiveForm.get(fieldName) as FormArray;
    const file = array.at(index).value;
    if (file.url) {
      window.open(file.url, 'system', null);
    }
  }

  public deleteDocument(fieldName: string, index: number) {
    const array = this.reactiveForm.get(fieldName) as FormArray;
    array.removeAt(index);
  }

  public addItemToObjectArray(field: Field) {
    const array = this.reactiveForm.get(field.name) as FormArray;
    const objDef = this.fs.getObjectDefinitionByRef(field.objectReference);
    if (!objDef) {
      return;
    }
    const formGroup = this.setupForm(objDef, null);
    if (field.metadata?.length) {
      field.metadata.push(formGroup.fields);
    } else {
      field.metadata = [formGroup.fields]
    }
    array.push(formGroup.reactiveForm);
  }

  public removeItemToObjectArray(field: Field, index: number) {
    const array = this.reactiveForm.get(field.name) as FormArray;
    if (array.length > index && field.metadata?.length) {
      array.removeAt(index);
      field.metadata = field.metadata.filter((el, i) => i!==index); 
    }
  }

  public async updateSearch(typedText: string, field: Field) {
    if (this.escaped) {
      return;
    }
    if (!typedText || typedText.length < 3) {
      if (typedText.length === 0) {
        this.autocompleteLists[field.name] = [];
      }
      return;
    }
    if (this.timeOutId) {
      clearTimeout(this.timeOutId);
    }
    // Wait until user has stopped typing 750ms
    this.timeOutId = setTimeout( async () => {
      try {
        this.autocompleteLoaders[field.name] = true;
        const response: RoadMateResponse<any[]> = await this.fct.getSearchResults({searchText: typedText}, field.objectReference);
        this.autocompleteListObjects[field.name] = response.result;
        this.autocompleteLists[field.name] = response.result.map(item => {
          // some autocomplete fields may specify other fields to be added to the suggestions
          // the values array contains the name of the properties we want to add
          if (field.values && Array.isArray(field.values) && field.values.length) {
            let metaData = '';
            field.values.forEach(option => {
              if (item[option.value] && this.hasValue(item[option.value])) {
                metaData = `${metaData ? metaData + ', ' : ''}${item[option.value]}`;
              }
            });
            return metaData;
          } else {
            return item[field.name];
          }
        });
      } catch (e) {
        console.error('Error While fetching data for Auto complete', e);
      } finally {
        this.autocompleteLoaders[field.name] = false;
      }
    }, 750);
  }

  public clearForm() {
    const newForm = this.setupForm(this.objectDefinition, {});
    this.reactiveForm = newForm.reactiveForm;
  }

  public async selectAutoCompleteElement(index: number, field: Field) {
    const currentList = this.autocompleteListObjects[field.name];
    if (!currentList || currentList.length === 0) {
      return;
    }
    const selectedItem = currentList[index];
    if (!selectedItem || typeof selectedItem !== 'object') {
      return;
    }
    this.autocompleteLists[field.name] = [];
    if (field.objectReference === 'businessSearch') {
      try {
        this.autocompleteLoaders[field.name] = true;
        const results: RoadMateResponse<Treezor.Business.InformationResonpse> = await this.fct.getInformationResults(selectedItem);
        if (results && results.result && results.result.businessinformations && results.result.businessinformations.length) {
          this.setValue(results.result.businessinformations[0]);
        }
      } catch (e) {
        console.error('Getting More data from autocomplete failed', e);
      } finally {
        this.autocompleteLoaders[field.name] = false;
      }
    } else if (field.name === 'legalSector' && this.reactiveForm.get(field.name)) {
      const selectedNaf = currentList[index];
      this.reactiveForm.get(field.name).setValue(selectedNaf.name);
    } else {
      this.setValue(selectedItem);
    }
  }

  public hideForm(propertyName: string, index: number) {
    this.hiddenForms.push(`${propertyName}-${index}`);
  }

  public showForm(propertyName: string, index: number) {
    const i = this.hiddenForms.indexOf(`${propertyName}-${index}`);
    if (i > -1) {
      this.hiddenForms.splice(i, 1);
    }
  }

  public isHidden(propertyName: string, index: number): boolean {
    return this.hiddenForms.indexOf(`${propertyName}-${index}`) > -1;
  }

  private setValue(selectedItem) {
    if (!selectedItem || Array.isArray(selectedItem) || typeof selectedItem !== 'object') {
      return;
    }
    Object.keys(selectedItem).forEach(key => {
      const control = this.reactiveForm.get(key);
      if (control) {
        if (this.hasValue(selectedItem[key])) {
          if (key === 'legalRegistrationDate' && selectedItem[key] && selectedItem[key].split('/').length === 3) {
            const dateComponents = selectedItem[key].split('/') as string[];
            control.setValue(`${dateComponents[2]}/${dateComponents[1]}/${dateComponents[0]}`);
          } else {
            control.setValue(selectedItem[key]);
          }
        } else if (Array.isArray(selectedItem[key])) {
          const currentField = this.fields.find(f => f.name === key);
          if (!currentField) {
            return;
          }
          const objDef = this.fs.getObjectDefinitionByRef(currentField.objectReference);
          if (!objDef) {
            return;
          }
          const formArray = control as FormArray;
          const array: any[] = selectedItem[key];
          array.forEach(item => {
            if (item.fullnames) {
              item.lastname = item.fullnames;
            }
            const formGroup = this.setupForm(objDef, item);
            formArray.push(formGroup.reactiveForm);
          });
        }
      }
    });
  }

  public listenToChildEvent(data) {
    this.selectValueChangeEmitter.emit(data);
  }

  public onSelectValueChanged(value, fieldName: string) {
    this.selectValueChangeEmitter.emit({
      fieldName,
      value
    });
  }

  public onCheckBoxChanged(value, field: Field) {
    this.selectValueChangeEmitter.emit({fieldName: field.name, value});
  }

  public downloadFile(fileUrl: string) {
    window.open(fileUrl, '_system', 'location=yes');
  }
}

const treezorBooleans = [
  'activityOutsideEu',
  'economicSanctions',
  'residentCountriesSanctions',
  'involvedSanctions',
  'specifiedUSPerson'
];
