import { Pipe, PipeTransform } from '@angular/core';
import { Widget } from '@roadmate/roadmate-common';

enum kpis {
  co2 = 'CO2',
  km = 'KM',
  time = 'TIME',
  euro = 'EURO'
}
@Pipe({name: 'rm_widget_percent'})
export class WidgetPercentPipe implements PipeTransform {
  transform(widget: number, totalWidget: number): any {
    return Math.floor(100 * widget / totalWidget);
  }
}

@Pipe({name: 'rm_sort_widget'})
export class SortWidgetPipe implements PipeTransform {
  transform(widget: Widget[], kpi: kpis): any {    
    switch (kpi) {
      case kpis.co2:
        return widget.sort((a,b) => b.co2 - a.co2);
      case kpis.km:
        return widget.sort((a,b) => b.km - a.km);
      case kpis.time:
        return widget.sort((a,b) => b.time - a.time);
      case kpis.euro:
        return widget.sort((a,b) => b.totalCost - a.totalCost);
    }
  }
}
