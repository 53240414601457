<p [innerHTML]="'logout_warning' | translate">
</p>
<p>
{{'remaining_time' | translate}} {{remaingTime}}s
</p>
<div class="rm-row">
  <button class="btn btn-secondary" type="button" (click)="logout(true)">
    {{'logout_btn' | translate}}
  </button>
  <button class="btn btn-primary" type="button" (click)="logout(false)">
    {{'keep_connction_alive' | translate}}
  </button>
</div>
