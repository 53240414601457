<div class="rm-colum">
  <div class="form-group save" *ngIf="!isSubForm && showSaveTop">
    <button
      *ngIf="!displayOnly && reactiveForm"
      [disabled]="!reactiveForm.valid || !reactiveForm.dirty || customDisabled || loading"
      class="btn btn-block btn-primary"
      (click)="save()"
      type="button"
    >
      <span *ngIf="saveBtnLabel && !loading">
        {{saveBtnLabel | translate}}
      </span>
      <span *ngIf="!saveBtnLabel && !loading">
        {{'save' | translate}}
      </span>
      <span *ngIf="loading" class="c-loading-button-spinner spinner-border spinner-border-sm"></span>
    </button>
  </div>
  <form *ngIf="reactiveForm && fields && fields.length"
        [formGroup]="reactiveForm">
    <ng-container *ngFor="let field of fields; let n=index">
      <ng-container [ngSwitch]="field.formtype">
        <ng-container *ngSwitchCase="allTypes.email">
          <div class="form-group" [attr.name]="field.name">
            <label  >
              {{(field.description?field.description:field.name) | translate}} <span class="rm-warning" *ngIf="field.mandatory">*</span>
            </label>
            <div class="input-group">
              <div class="input-group-append">
                <span class="btn btn-primary" type="button">
                  <i class="cil-at"></i>
                </span>
              </div>
              <input class="form-control"  type="email" formControlName="{{field.name}}">
            </div>
            <em class="error invalid-feedback" *ngIf="!(reactiveForm | formcontrol_valid:field.name)">
              Merci de saisir une adresse email valide.
            </em>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="allTypes.password">
          <div class="form-group" [attr.name]="field.name" [ngClass]="{'rm-hide': !field.show || (field.onlySuperAdmin && !isSuperAdmin)}">
            <label>
              {{(field.description?field.description:field.name) | translate}} <span class="rm-warning" *ngIf="field.mandatory">*</span>
            </label>
            <div class="input-group">
              <div class="input-group-append">
                <span class="btn btn-primary" type="button">
                  <i class="cil-lock-locked"></i>
                </span>
              </div>
              <input class="form-control validate-equalTo-blur"
                    type="password"
                    formControlName="{{field.name}}"
                    (keyup.enter)="save()">
            </div>
            <em class="error invalid-feedback" *ngIf="!(reactiveForm | formcontrol_valid:field.name) && field.objectReference">
                {{field.objectReference | translate}}
            </em>
            <!-- <em class="error invalid-feedback" *ngIf="!(reactiveForm | formcontrol_valid:field.name) && !field.objectReference">
              {{'password_validation_error' | translate}}field.objectReference
            </em> -->
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="allTypes.boolean">
          <div class="form-group" [attr.name]="field.name" [ngClass]="{'rm-hide': !field.show || (field.onlySuperAdmin && !isSuperAdmin)}">
            <div class="rm-row checkbox">
              <label class="c-switch c-switch-primary">
                <input
                  formControlName="{{field.name}}"
                  (change)="onCheckBoxChanged($event.target.checked, field)"
                  class="c-switch-input"
                  type="checkbox"
                  checked=""
                > <span class="c-switch-slider"></span>
              </label>
              <label class="form-check-label" *ngIf="!field.objectReference" for="check-{{field.name}}-{{n}}">
                {{(field.description?field.description:field.name) | translate}}
              </label>
              <label class="form-check-label" *ngIf="field.objectReference" for="check-{{field.name}}-{{n}}">
                <a [href]="field.objectReference" target="_blank">
                  {{(field.description?field.description:field.name) | translate}}
                </a>
              </label>
            </div>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="allTypes.date">
          <fieldset class="form-group" [attr.name]="field.name" [ngClass]="{'rm-hide': !field.show || (field.onlySuperAdmin && !isSuperAdmin)}">
            <label>
              {{(field.description?field.description:field.name) | translate}} <span class="rm-warning" *ngIf="field.mandatory">*</span>
            </label>
            <div class="input-group">
              <span class="input-group-prepend">
                <span class="btn btn-primary" type="button">
                  <i class="cil-calendar"></i>
                </span>
              </span>
              <input class="form-control" type="text" formControlName="{{field.name}}" placeholder="01/03/2021">
            </div>
            <div class="rm-column">
              <small class="text-muted">ex. 01/03/2020</small>
              <em *ngFor="let error of reactiveForm | rm_control_errors:field.name" class="invalid-feedback">
                {{error.error | translate}}
              </em>
            </div>
          </fieldset>
        </ng-container>
        <ng-container *ngSwitchCase="allTypes.datetime">
          <fieldset class="form-group" [attr.name]="field.name" [ngClass]="{'rm-hide': !field.show || (field.onlySuperAdmin && !isSuperAdmin)}">
            <label>
              {{(field.description?field.description:field.name) | translate}} <span class="rm-warning" *ngIf="field.mandatory">*</span>
            </label>
            <div class="input-group">
              <span class="input-group-prepend">
                <span class="btn btn-primary" type="button">
                  <i class="cil-calendar"></i>
                </span>
              </span>
              <input class="form-control" type="text" formControlName="{{field.name}}" placeholder="31/03/2021">
            </div>
            <small class="text-muted">ex. 31/03/2020 14:30</small>
            <em *ngFor="let error of reactiveForm | rm_control_errors:field.name"  class="invalid-feedback">
              {{error.error | translate}}
            </em>
          </fieldset>
        </ng-container>
        <ng-container *ngSwitchCase="allTypes.address">
          <div class="form-group" [attr.name]="field.name" [ngClass]="{'rm-hide': !field.show || (field.onlySuperAdmin && !isSuperAdmin)}">
            <label >
              {{(field.description?field.description:field.name) | translate}} <span class="rm-warning" *ngIf="field.mandatory">*</span>
            </label>
            <input
              class="form-control"
              type="text"
              formControlName="{{field.name}}"
            >
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="allTypes.checkbox">
          <div class="form-group" [attr.name]="field.name" [ngClass]="{'rm-hide': !field.show || (field.onlySuperAdmin && !isSuperAdmin)}">
            <div class="rm-row checkbox">
              <label class="c-switch c-switch-primary">
                <input
                  formControlName="{{field.name}}"
                  (change)="onCheckBoxChanged($event.target.checked, field)"
                  class="c-switch-input"
                  type="checkbox"
                >
                  <span class="c-switch-slider"></span>
              </label>
              <label class="form-check-label" *ngIf="!field.objectReference" for="check-{{field.name}}-{{n}}">
                {{(field.description?field.description:field.name) | translate}}
              </label>
              <label class="form-check-label" *ngIf="field.objectReference" for="check-{{field.name}}-{{n}}">
                <a [href]="field.objectReference" target="_blank">
                  {{(field.description?field.description:field.name) | translate}}
                </a>
              </label>
            </div>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="allTypes.textarea">
          <div class="form-group" [attr.name]="field.name" [ngClass]="{'rm-hide': !field.show || (field.onlySuperAdmin && !isSuperAdmin)}">
            <label >
              {{(field.description?field.description:field.name) | translate}} <span class="rm-warning" *ngIf="field.mandatory">*</span>
            </label>
            <textarea
              formControlName="{{field.name}}"
              class="form-control"
              name="{{field.name}}"
              rows="9"
            ></textarea>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="allTypes.autocomplete">
          <div class="form-group rm-autocomplete-form" [ngClass]="{'rm-hide': !field.show || (field.onlySuperAdmin && !isSuperAdmin)}">
            <label>
              {{(field.description?field.description:field.name) | translate}} <span class="rm-warning" *ngIf="field.mandatory">*</span>
            </label>
            <div class="c-multi-select c-show">
              <div class="c-multi-select-search input-group">
                <span class="input-group-prepend">
                  <button class="btn btn-primary" type="button">
                    <i *ngIf="!autocompleteLoaders[field.name]" class="cil-magnifying-glass"></i>
                    <rm-spinner *ngIf="autocompleteLoaders[field.name]"></rm-spinner>
                  </button>
                </span>
                <input
                  class="form-control"
                  formControlName="{{field.name}}"
                  (keyup)="updateSearch($event.target.value, field)"
                  (keydown.esc)="hideAutocomplete()"
                  (keydown.tab)="hideAutocomplete()"
                  type="text" formControlName="{{field.name}}"
                >
              </div>
              <em *ngIf="autocompleteLoaders[field.name]">
                <small class="text-muted">{{'please_wait_while_loading' | translate}}</small>
              </em>
              <div class="c-multi-select-options" *ngIf="autocompleteLists[field.name] && autocompleteLists[field.name].length">
                <div *ngFor="let option of autocompleteLists[field.name]; let i = index"
                    (click)="selectAutoCompleteElement(i, field)"
                    class="c-multi-select-option">
                  {{option}}
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="allTypes.ddlObject">
          <div class="form-group" [attr.name]="field.name" [ngClass]="{'rm-hide': !field.show || (field.onlySuperAdmin && !isSuperAdmin)}">
          
            <label >
              {{(field.description?field.description:field.name) | translate}} <span class="rm-warning" *ngIf="field.mandatory">*</span>
            </label>
            <select
              class="form-control"
              formControlName="{{field.name}}"
            >
              <option *ngFor="let item of field.values" [value]="item.value">
                {{item.label | translate}}
              </option>
            </select>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="allTypes.file">
          <div class="form-group" [attr.name]="field.name" [ngClass]="{'rm-hide': !field.show || (field.onlySuperAdmin && !isSuperAdmin)}">
            <label>
              <i class="cil-info"></i>
              {{(field.name) | translate}}
              <span class="rm-warning" *ngIf="field.mandatory">*</span>
              <span>{{'seven_files_max' | translate}}</span>
              <div class="rm-tooltip">
                <span class="rm-tooltiptext" [innerHTML]="field.description | translate"></span>
              </div>
            </label>
            <div
              class="rm-row start link-download"
              (click)="downloadFile(field.objectReference)"
              *ngIf="field.objectReference && field.objectReference.indexOf('http') > -1"
            >
              <i class="cil-external-link"></i>
              {{'download_template_file' | translate}}
            </div>
            <rm-upload-input
              *ngIf="(reactiveForm | rm_get_controls_array: field.name).length < 7"
              [ngClass]="{'rm-hide': displayOnly}"
              [attr.name]="field.name"
              [fieldName]="field.name"
              (urlReadyEmitter)="setFeild($event)"
              [workflow]="fileStoringWorkflow"
              [companyRef]="companyRef"
              [agentRef]="agentRef"
            >
            </rm-upload-input>
          </div>
          <div formArrayName="{{field.name}}"  class="rm-row push-down">
            <div
              class="rm-colum file-link uploaded-file"
              *ngFor="let fileForm of reactiveForm | rm_get_controls_array: field.name; let i=index"
            >
              <div
                *ngIf="!field.readonly"
                (click)="deleteDocument(field.name, i)"
                class="clickable rm-row end"
              >
                <i class="cil-x-circle font-1-5em"></i>
              </div>
              <div class="clickable rm-row start" (click)="openDocument(field.name, i)">
                <!-- <i class="cil-file" style="font-size: 2em;"></i> -->
                <img [src]="(fileForm | get_field_value: 'name') | rm_img_file_extension" [title]="(fileForm | get_field_value: 'name')" style="width:45px">
                <label>
                  {{(fileForm | get_field_value: 'name') | truncate:15:false:true}}
                  <div class="rm-tooltip">
                    <span class="rm-tooltiptext" [innerHTML]="fileForm | get_field_value: 'name'"></span>
                  </div>
                </label>
              </div>
            </div>

          </div>
        </ng-container>
        <ng-container *ngSwitchCase="allTypes.number">
          <div class="form-group" [attr.name]="field.name" [ngClass]="{'rm-hide': !field.show || (field.onlySuperAdmin && !isSuperAdmin)}">
            <label >
              {{(field.description?field.description:field.name) | translate}} <span class="rm-warning" *ngIf="field.mandatory">*</span>
            </label>
            <input 
              class="form-control"
              type="number"
              formControlName="{{field.name}}"
            >
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="allTypes.phone">
          <fieldset class="form-group" [attr.name]="field.name" [ngClass]="{'rm-hide': !field.show || (field.onlySuperAdmin && !isSuperAdmin)}">
            <label>
              {{(field.description?field.description:field.name) | translate}} <span class="rm-warning" *ngIf="field.mandatory">*</span>
            </label>
            <div class="input-group"
                 [ngClass]="{'is-invalid': !(reactiveForm | formcontrol_valid:field.name), 'is-valid': (reactiveForm | formcontrol_valid:field.name)}">
              <span class="input-group-prepend">
                <span class="btn btn-primary" type="button">
                  <i class="cil-phone"></i>
                </span>
              </span>
              <input class="form-control" type="text" formControlName="{{field.name}}">
            </div>
            <small class="text-muted">ex. +33611223344</small>
            <em class="error invalid-feedback" *ngIf="!(reactiveForm | formcontrol_valid:field.name)">
              Le numéro de téléphone doit respecter le format ci-dessus.
            </em>
          </fieldset>
        </ng-container>
        <ng-container *ngSwitchCase="allTypes.select">
          <div class="form-group" [attr.name]="field.name" [ngClass]="{'rm-hide': (field | rm_shouldHideField)}">
            <label >
              {{(field.description?field.description:field.name) | translate}} <span class="rm-warning" *ngIf="field.mandatory">*</span>
            </label>
            <select class="form-control"
                    formControlName="{{field.name}}"

                    (change)="onSelectValueChanged($event.target.value, field.name)">
              <option *ngFor="let item of field.values" [value]="item.value">
                {{item.label | translate}}
              </option>
            </select>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="allTypes.multiselect">
          <div class="form-group" [attr.name]="field.name" [ngClass]="{'rm-hide': !field.show || (field.onlySuperAdmin && !isSuperAdmin)}">
            <label>
              {{(field.description?field.description:field.name) | translate}} <span class="rm-warning" *ngIf="field.mandatory">*</span>
            </label>
            <rm-multi-select
              [array]="field.values"
              [selectedItems]="field.value && field.value.length ? field.value : []"
              [parentForm]="reactiveForm"
              [property]="field.name"
              [autoSelect]="false"
            ></rm-multi-select>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="allTypes.string">
          <div class="form-group" [attr.name]="field.name" [ngClass]="{'rm-hide': !field.show || (field.onlySuperAdmin && !isSuperAdmin)}">
            <label >
              {{(field.description?field.description:field.name) | translate}} <span class="rm-warning" *ngIf="field.mandatory">*</span>
            </label>
            <input
              class="form-control"
              type="text"
              formControlName="{{field.name}}"
            >
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="allTypes.iban">
          <div class="form-group" [attr.name]="field.name" [ngClass]="{'rm-hide': !field.show || (field.onlySuperAdmin && !isSuperAdmin)}">
            <label >
              {{(field.description?field.description:field.name) | translate}} <span class="rm-warning" *ngIf="field.mandatory">*</span>
            </label>
            <input class="form-control"
                  type="text"
                  formControlName="{{field.name}}">
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="allTypes.metadata">
          <div class="form-group" [attr.name]="field.name" *ngIf="field.value">
            <pre [innerHTML]="field.value | prettyjson"></pre>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="allTypes.stringArray">
          <div class="form-group" [attr.name]="field.name" formArrayName="{{field.name}}" [ngClass]="{'rm-hide': !field.show || (field.onlySuperAdmin && !isSuperAdmin)}">
            <label class="field-name">
              {{((field.description?field.description:field.name) | translate) | rm_uppercase}}
               <span class="rm-warning" *ngIf="field.mandatory">*</span>
            </label>
            <div class="rm-field row" [ngClass]="{'rm-hide': !field.show || (field.onlySuperAdmin && !isSuperAdmin)}"
                *ngFor="let control of reactiveForm | rm_get_controls_array:field.name; let i = index">
              <div class="col-md-10">
                <input class="form-control" id="{{field.name}}-{{i}}" type="text" formControlName="{{i}}" [disabled]="field.readonly">
              </div>
              <div class="col-md-2">
                <button class="btn btn-danger" type="button" (click)="removeItemFormArray(field.name, i)" [disabled]="field.readonly">
                  <i class="cil-trash"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="rm-field" [ngClass]="{'rm-hide': field.readonly || !field.show || (field.onlySuperAdmin && !isSuperAdmin)}">
            <button
              class="btn btn-primary"
              type="button"
              (click)="addItemToArray(field.name)"
            >
              <i class="cil-plus"></i> {{'add_new_element' | translate}}
            </button>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="allTypes.object">
          <div class="sub-form-container">
            <div class="rm-column" [ngClass]="{'rm-hide': !field.show || (field.onlySuperAdmin && !isSuperAdmin)}">
              <label class="field-name">
                {{((field.name) | translate) | rm_uppercase}} <span class="rm-warning" *ngIf="field.mandatory">*</span>
                 <span class="rm-warning" *ngIf="field.mandatory">*</span>
              </label>
              <label class="field-name">
                {{((field.description && field.name !== field.description?field.description:'') | translate) | rm_uppercase}}
                 <span class="rm-warning" *ngIf="field.mandatory">*</span>
              </label>
            </div>
            <rm-reactive-forms
              [objectKey]="field.objectReference"
              [configuredFields]="field.metadata"
              [reactiveForm]="reactiveForm.controls[field.name]"
              [displayOnly]="displayOnly"
              (selectValueChangeEmitter)="listenToChildEvent($event)"
              [fileStoringWorkflow]="fileStoringWorkflow"
            >
            </rm-reactive-forms>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="allTypes.objectArray">
          <div class="rm-column" [ngClass]="{'rm-hide': !field.show || (field.onlySuperAdmin && !isSuperAdmin)}">
            <label class="field-name">
              {{((field.description?field.description:field.name) | translate) | rm_uppercase}} <span class="rm-warning" *ngIf="field.mandatory">*</span>
            </label>
          </div>
          <div class="rm-column" formArrayName="{{field.name}}">
            <label class="titleh3" *ngIf="(reactiveForm | rm_get_controls_array: field.name).length">
              {{(reactiveForm | rm_get_controls_array: field.name).length}} {{field.objectReference | translate}} :
            </label>
            <div class="sub-form-container rm-column"
                *ngFor="let subForm of reactiveForm | rm_get_controls_array: field.name; let i = index">
              <div class="rm-grid one-fr-2">
                <span class="titleh3">
                    {{field.objectReference | translate}} ({{i+1}})
                </span>
                <div class="rm-row end">
                  <button class="btn btn-pill btn-ghost-dark active" type="button"
                          (click)="hideForm(field.name, i)"
                          aria-label="collapse form"
                          [ngClass]="{'rm-hide': isHidden(field.name, i)}">
                    <i class="cil-chevron-circle-up-alt"></i>
                  </button>
                  <button class="btn btn-pill btn-ghost-dark active" type="button"
                          (click)="showForm(field.name, i)"
                          [ngClass]="{'rm-hide': !isHidden(field.name, i)}"
                          aria-label="collapse form">
                    <i class="cil-chevron-circle-down-alt"></i>
                  </button>
                </div>
              </div>
              <div class="rm-colum" [ngClass]="{'rm-hide': isHidden(field.name, i)}">
                <rm-reactive-forms
                  [configuredFields]="field.metadata[i]"
                  [reactiveForm]="subForm"
                  [objectKey]="field.objectReference"
                  [displayOnly]="displayOnly"
                  (selectValueChangeEmitter)="listenToChildEvent($event)"
                  [fileStoringWorkflow]="fileStoringWorkflow"
                >
                </rm-reactive-forms>
                <div class="rm-column remove-form-btn">
                  <button *ngIf="!displayOnly || (reactiveForm.controls[field.name] && reactiveForm.controls[field.name]['controls'].length)"
                          class="btn btn-block btn-primary" type="button"
                          (click)="removeItemToObjectArray(field, i)">
                    {{'delete' | translate}} {{'this' | translate}} {{field.objectReference | translate}}
                  </button>
                </div>
              </div>
            </div>
            <div class="rm-colum add-form-btn">
              <button  class="btn btn-block btn-primary" type="button"
                      *ngIf="!displayOnly"
                      [disabled]="!(reactiveForm | formcontrol_valid:field.name)"
                      (click)="addItemToObjectArray(field)">
                {{'add' | translate}} {{'a' | translate}} {{field.objectReference | translate}}
              </button>
            </div>
          </div>
        </ng-container>
        <ng-container *ngSwitchDefault>
          {{field.name | translate}}
        </ng-container>
      </ng-container>
    </ng-container>
  </form>
  <div class="form-group save" *ngIf="!isSubForm">
    <button
      *ngIf="!displayOnly && reactiveForm"
      [disabled]="!reactiveForm.valid || !reactiveForm.dirty || customDisabled || loading"
      class="btn btn-block btn-primary"
      (click)="save()"
      type="button"
    >
      <span *ngIf="saveBtnLabel && !loading">
        {{saveBtnLabel | translate}}
      </span>
      <span *ngIf="!saveBtnLabel && !loading">
        {{'save' | translate}}
      </span>
      <span *ngIf="loading" class="c-loading-button-spinner spinner-border spinner-border-sm"></span>
    </button>
  </div>
</div>
