import { Component, OnInit } from '@angular/core';
import { AbstractEntryComponent } from '../abstract.entry-component';

@Component({
    selector: 'rm-ask-question',
    templateUrl: './ask-question.component.html'
})
export class AskQuestionComponent extends AbstractEntryComponent<any> {
    public question: string;
    public suffix: string = '';
    public title: string;
    public set data(value) {
      if (!value) {
        return;
      }
      this.question = value.question ? value.question : '';
      this.title = value.title ?? 'confirmation';
      this.suffix = value.suffix ?? '';
    }
    constructor() {
      super();
    }
}
