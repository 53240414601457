<div class="row">
  <div class="col-12 detached push-down">
    <ng-container *ngIf="!viewingMerchant && !setTripDetails">
      <!-- PAYMENT DETAILS -->
      <div class="card detached">
        <div class="card-body">
          <div class="row">
            <div class="col-12 container-center-v bold-text">
              {{paymentType | translate}}
            </div>
          </div>
          <div class="row">
            <div 
              *ngIf="paymentType === 'decline' && !isSportingGoods" 
              class="col-12 container-center-v"
              style="font-size: 0.7em;"
              [innerHTML]="'SUBMIT_INVOICE_TO_GET_REFUND' | translate"
            ></div>
            <div 
              *ngIf="paymentType === 'decline' && isSportingGoods" 
              class="col-12 container-center-v"
              style="font-size: 0.7em;"
              [innerHTML]="'TRANSACTION_DECLINED_AT_SPORTING_GOODS' | translate"
            ></div>
          </div>
          <div class="row">
            <div class="col-12 container-center-v smaller-text grey-text">
              {{paymentDate | rm_date}}
            </div>
          </div>
        </div>
      </div>
      
      <div class="card detached">
        <div class="card-body">
          <div class="row">
            <div class="col-4"></div>
            <div class="col-4 rm-relative container-center-v"><img class="circled" [src]="logo | rm_default_img : fallbackurl"></div>
            <div class="col-4"></div>
          </div>
          <div class="row detached">
            <div class="col-4"></div>
            <div class="col-4 container-center-v bold-text two-em">{{amount | number:'1.2-2'}}€</div>
            <div class="col-4"></div>
          </div>
        </div>
      </div>

      <div class="card detached">
        <div class="card-body">
          <div class="row" *ngIf="merchantName">
            <div class="col-4"></div>
            <div class="col-4 container-center-v bold-text two-em">{{merchantName}}</div>
            <div class="col-4"></div>
          </div>
          <div class="row" *ngIf="paymentType === 'refund_request'">
            <div class="col-1"></div>
            <div class="col-10 container-center-v">{{transaction.list[0].description}}</div>
            <div class="col-1"></div>
          </div>
          <div class="row">
            <div class="col-1"></div>
            <div class="col-10 container-center-v bold-text">
              {{('PRODUCT_' + productName) | translate}}
            </div>
            <div class="col-1"></div>
          </div>
          <div class="row rm-clickable" *ngIf="supportingFileLink" (click)="openApp(supportingFileLink)">
            <div class="col-1"></div>
            <div class="col-10 container-center-v">
              <i class="icon cil-share-boxed"></i>
              {{'provided_invoice' | translate}}
            </div>
            <div class="col-1"></div>
          </div>
        </div>
      </div>
      <!-- REFUND -->
      <div class="card detached" *ngIf="refundRequest">
        <div class="card-body">
          <ng-container [ngSwitch]="refundRequest.type">
            <ng-container *ngSwitchCase="'REFUND_REQUEST_IKV'">
              <div class="row">
                <div class="col-12">
                  <h5 class="font-blue">
                    {{'IKV_ALL_LETTERS' | translate}}
                  </h5>
                </div>
              </div>
              <div class="row">
                <div class="col-12 container-center-v">
                  {{refundRequest.distance}} km {{'distance_cycled' | translate}}
                </div>
              </div>
              <div class="row">
                <div class="col-12 container-center-v">
                  {{'DU' | translate}} {{refundRequest.from | rm_date:true}} {{'TO' | translate}} {{refundRequest.to | rm_date:true}}
                </div>
              </div>
              <div class="row" *ngIf="refundRequest.status === 'DONE'">
                <div class="col-12 container-center-v">
                  {{refundRequest.amount | number:'1.2-2'}}€ {{refundRequest.refundDestination|translate}}
                </div>
              </div>
              <!-- <div class="row rm-clickable" *ngIf="refundRequest.attestation?.url" (click)="openAttestation(refundRequest.attestation.url)">
                <div class="col-12 container-center-v">
                  {{'your_attestation' | translate}}
                </div>
              </div> -->
            </ng-container>
            <ng-container *ngSwitchDefault>
              <div class="row" >
                <div class="col-12 container-center-v">
                  <h5 class="font-blue">
                    {{refundRequest.type | translate}}
                  </h5>
                </div>
              </div>
              <div class="row" >
                <div class="col-1"></div>
                <div class="col-10 container-center-v">
                  {{refundRequest.merchantName}}
                </div>
                <div class="col-1"></div>
              </div>
              <div class="row" >
                <div class="col-1"></div>
                <div class="col-10 container-center-v">
                  {{refundRequest.amount | number:'1.2-2'}}€ {{refundRequest.refundDestination|translate}}
                </div>
                <div class="col-1"></div>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>
      <!-- INVOICES -->
      <div class="card detached" *ngIf="transaction.list?.length && paymentType !== 'refund_request'">
        <div class="card-body">
          <div class="row">
            <div class="col-12" class="bold-text grey-text size-twelve-pad">
              {{'INVOICES' | translate}}
            </div>
          </div>
          <div class="row push-down"  *ngIf="invoices?.files?.length">
            <ng-container *ngFor="let file of invoices.files">
              <div class="col-2">
                <div class="center">
                  <img class="invoice-preview" style="max-width: 50px; height: auto;" [src]="file.name | rm_img_file_extension" (click)="openFile(file)"/>
                </div>
                <div class="left smaller-text center">
                  {{file.name|truncate:15:false:true}}
                </div>
                <button class="btn btn-primary" type="button" (click)="removeFile(file)" [disabled]="removingFileUrl === file.url">
                  <span *ngIf="removingFileUrl !== file.url">
                    {{'delete'|translate}}
                  </span>
                  <span *ngIf="removingFileUrl === file.url" class="c-loading-button-spinner spinner-border spinner-border-sm"></span>
                </button>
              </div>
            </ng-container>
          </div>
          <div class="row">
            <div class="col-12">
              <button class="btn btn-primary" type="button" (click)="addFile()" [disabled]="loading">
                <span *ngIf="loading" class="c-loading-button-spinner spinner-border spinner-border-sm"></span>
                {{'ADD_INVOICE' | translate}}
              </button>
            </div>
          </div>
          <div class="row">
            <ng-container *ngIf="(progress | async)?.length">
              <ng-container *ngFor="let prog of progress | async">
                <div class="col-6" class="smaller-text grey-text">
                  {{'LOADGIN_FILE' | translate}} {{prog.progress}}%
                </div>
                <div size="col-6">
                  <mat-progress-bar color="primary" [progress]="prog.progress"></mat-progress-bar>
                </div>
              </ng-container>
            </ng-container>
            <div class="col-12" *ngIf="resizingInProgress"  class="smaller-text grey-text container-center-v">
              {{'PROCESSING_INVOICE' | translate}}
            </div>
          </div>
        </div>
      </div>
    
      <!-- FOOTER -->
      <div class="card detached" *ngIf="transaction && transaction.list?.length && paymentType !== 'refund_request'">
        <div class="card-body">
          <div class="row">
            <div class="col-12 container-center-v smaller-text">
              {{'PAYMENT_ID' | translate}} {{transaction.list[0].paymentId}}
            </div>
          </div>
        </div>
      </div>
      <!-- FILE INPUT -->
      <input type="file" #fileInput (change)="selectFile($event.target.files)" hidden="true" accept="application/pdf,image/*">
    </ng-container>
  </div>
</div>