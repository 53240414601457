import { Pipe, PipeTransform } from '@angular/core';
import { RoadMateTimeZone } from '@roadmate/roadmate-common';

@Pipe({name: 'rm_display_month'})
export class DisplayMonthPipe implements PipeTransform {
  transform(value: string): string {
    if (!value || value.length !== 7 || value.indexOf('-') === -1) {
      return '';
    }
    const year = value.split('-')[0];
    const month = value.split('-')[1];
    return `${RoadMateTimeZone.months[month]} ${year}`;
  }
}


@Pipe({name: 'rm_display_month_day'})
export class DisplayMonthAndDatePipe implements PipeTransform {
  transform(value: string): string {
    if (!value || value.length < 10 || value.indexOf('-') === -1) {
      return '';
    }
    const date = value.substring(0, 10);
    const year = date.split('-')[0];
    const month = date.split('-')[1];
    const day = parseInt(date.split('-')[2], 10);
    return `${day === 1 ? '1er ' : `${day} `}${RoadMateTimeZone.months[month]} ${year}`;
  }
}

@Pipe({name: 'display_int_month'})
export class DisplayIntMonthPipe implements PipeTransform {
  transform(value: number): string {
    if (value < 0 || value > 11) {
      return 'N/A'
    }
    return `${RoadMateTimeZone.months[`${value + 1}`.padStart(2, '0')]}`;
  }
}