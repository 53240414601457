<h3>{{'confirm_delete_users_subtitle' | translate}}
  <span *ngIf="schedule">{{'scheduled_for' | translate}} {{startDate | rm_display_month_day}}</span>
  <span *ngIf="!schedule">{{'immediately' | translate}}</span>
  ?
</h3>
<table class="detached">
  <thead>
    <tr>
      <td>{{'email' | translate}}</td>
      <td>{{'validity' | translate}}</td>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let el of emailList">
      <td>{{el.data}}</td>
      <td style="text-align: center;">
        <i *ngIf="el.isSelected"  class="icon icon-2xl mt-5 mb-2 cil-check green"></i>
        <i *ngIf="!el.isSelected" class="icon icon-2xl mt-5 mb-2 cil-x-circle red"></i>
      </td>
    </tr>
  </tbody>
</table>

<div class="row detached">
  <div class="col-12" *ngIf="notifyUsers">
    {{'email_will_be_sent_to_scheduled_users' | translate}}
  </div>
  <div class="col-6">
    <button class="btn btn-primary" type="button" (click)="confirm()">
      <!--<span *ngIf="loading" class="c-loading-button-spinner spinner-border spinner-border-sm"></span>-->
      {{'confirm' | translate}}
    </button>
  </div>
</div>
