import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DropDownListOption } from '@roadmate/roadmate-common';
import { MessengerService } from '../../../services/messenger.service';

@Component({
  selector: 'rm-single-select',
  templateUrl: './single-select.component.html',
  styleUrls: ['./single-select.component.scss']
})
export class SingleSelectComponent implements OnInit, OnDestroy {
  @Input() array: DropDownListOption[] = [];
  @Input() placeholder: string;
  @Input() parentForm: FormGroup;
  @Input() property: string;
  @Input() fullWidth: boolean;
  @Input() set unSelectAll(value) {
    if (value) {
      this.selectedItem = null;
    }
  }
  @Input() set resetSelectedITem(ddl: any) {
    if (ddl?.value) {
      this.selectedItem = ddl;
    } else if (typeof ddl === 'string' || typeof ddl === 'number') {
      const el = this.array.find(item => item.value === `${ddl}`);
      if (el) {
        this.selectedItem = el;
      }
    }
  };
  @Input() showDescription = false;
  @Output() changeEmitter: EventEmitter<DropDownListOption> = new EventEmitter<DropDownListOption>();
  @ViewChild('sddl', { read: ElementRef, static: false })
  public ddl: ElementRef;
  public showDDL = false;
  private sub
  public selectedItem: DropDownListOption;
  constructor(private messenger: MessengerService) { }

  ngOnInit(): void {
    this.sub = this.messenger.dom.subscribe(
      dom => {
        if (this.ddl && this.ddl.nativeElement &&  !this.ddl.nativeElement.contains(dom)) {
          this.showDDL = false;
        }
      }
    );
  }

  public selectItem(item: DropDownListOption) {
    this.selectedItem = item;
    if (this.parentForm && this.property && this.parentForm.get(this.property)) {
      this.parentForm.get(this.property).setValue(item.value);
    } else {
      this.changeEmitter.emit(item);
    }
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
