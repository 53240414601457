<h3>
  {{'assign_new_mid' | translate}}
</h3>

<div class="rm-row">
  <label>
    {{'mid_input_to_assign' | translate}}
  </label>
  <label *ngIf="seletedMids">
    {{seletedMids.length}} {{'mids_selected' | translate}}
  </label>
</div>

<div class="rm-row">
  <p>
    {{'click_on_one_to_assign' | translate}}
  </p>
</div>
<mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
<ng2-smart-table
  *ngIf="merchants && merchants.length && settings"
  [settings]="settings"
  [source]="merchants"
  (userRowSelect)="selectMerchant($event)"
>
</ng2-smart-table>
