import { Pipe, PipeTransform } from '@angular/core';
import { DropDownListOption } from '@roadmate/roadmate-common';

@Pipe({name: 'display_ddl_value'})
export class DisplayDDLValuePipe implements PipeTransform {
  transform(value: string, ddl: DropDownListOption[]): string {
    if (!value) {
      return 'EMPTY'
    }
    if (!Array.isArray(ddl) || ddl.length === 0) {
      return `${value} (liste vide)`; 
    }
    return ddl.find((item: DropDownListOption) => item.value === value).label ?? 'NOT_FOUND';
  }
}