import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'rm-working-onit',
    templateUrl: './working-onit.component.html',
    styleUrls: ['./working-onit.component.scss']
})
export class WorkingOnItComponent implements OnInit {
    constructor() { }

    ngOnInit(): void { }
}
