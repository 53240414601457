<h4>
  {{'confirm_transfer_description' | translate}}
</h4>
<div class="row">
  <div class="col-sm-12">
    <div class="form-group">
      <label for="name">{{'transfer_from' | translate}}</label>
      <div class="form-control" disabled="">
        {{transfer.from.eventName | translate}}
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-sm-12">
    <div class="form-group">
      <label for="name">{{'transfer_to' | translate}}</label>
      <div class="form-control" disabled="">
        {{transfer.to.eventName | translate}}
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-sm-12">
    <div class="form-group">
      <label for="name">{{'transfer_amount' | translate}}</label>
      <div class="form-control" disabled="">
        {{transfer.amount| currency:'EUR'}}
      </div>
    </div>
  </div>
</div>



