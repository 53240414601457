import { Component, Input, OnInit } from '@angular/core';
import { Merchant } from '@roadmate/roadmate-common';
import { AbstractEntryComponent } from '../abstract.entry-component';

@Component({
  selector: 'rm-display-mobility-partner',
  templateUrl: './display-mobility-partner.component.html',
  styleUrls: ['./display-mobility-partner.component.scss']
})
export class DisplayMobilityPartnerComponent extends AbstractEntryComponent<boolean>  implements OnInit {
  @Input() merchant: Merchant;
  @Input() monthlyBudget: number;
  public set data(value) {
    if (!value || !value.merchant) {
      return;
    } else {
      this.merchant = value.merchant;
      this.monthlyBudget = value.monthlyBudget;
      console.log('this.merchant', this.merchant);
    }
  }
  constructor() {
    super();
  }

  public openUrl(url: string) {
    window.open(url, '_blank');
  }

  ngOnInit(): void { }
}
