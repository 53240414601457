import { Pipe, PipeTransform } from '@angular/core';
import { RoadMateTimeZone } from '@roadmate/roadmate-common';
@Pipe({ name: 'rm_month' })
export class MonthPipe implements PipeTransform {
  transform(value: string): any {
    if (!value || value.length <7) {
      return value;
    }
    const year = value.substring(0,4);
    const month = value.substring(5, 7);
    return `${RoadMateTimeZone.months[month]} ${year}`;
  }
}
