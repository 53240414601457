import { Field, Treezor } from '@roadmate/roadmate-common';

export enum customTriggers {
  embargoRule = 'custom_rule_embargo'
}

export const customRuleLegalSectorEmargo = (newValue: string, fields: Field[], field: Field) => {
  if (Treezor.User.embargoNafCheckList.map(el => el.name).indexOf(newValue) > -1) {
    const activityOutsideEu = fields.find(el => el.name === 'activityOutsideEu');
    if (activityOutsideEu) {
      activityOutsideEu.show = true;
    }
  }
  return;
}
