import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Parcel } from '@roadmate/roadmate-common';


@Injectable({
    providedIn: 'root'
})
export class MessengerService {
    parcel: Subject<Parcel<any>> = new Subject<Parcel<any>>();
    dom: Subject<HTMLElement> = new Subject<HTMLElement>();
}
