import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { FireAuthService } from '..';

@Injectable({
  providedIn: 'root'
})
export class MasterCanActivate {
  constructor(private fa: FireAuthService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean|UrlTree>|Promise<boolean|UrlTree>|boolean|UrlTree {
    return (!route.data
      || route.data.admin && this.fa.userRoles.admin
      || route.data.agent && this.fa.userRoles.agent
      || route.data.superadmin && this.fa.userRoles.superadmin
      || route.data.accountmanager && this.fa.userRoles.accountmanager
      || route.data.employee && this.fa.userRoles.employee
    );
  }
}
