
<h3>{{'roadmate_rejection_actions' | translate}}</h3>
<form [formGroup]="stringArrayForm">
  <label class="field-name">{{ 'kyc_observations' | translate}}</label>
  <div class="form-group rm-column" formArrayName="comments">
    <div class="row" *ngFor="let control of stringArrayForm | rm_get_controls_array:'comments'; let i = index">
      <div class="col-10">
        <input class="form-control" type="text" formControlName="{{i}}">
      </div>
      <div class="col-2">
        <button class="btn btn-primary" type="button" (click)="removeItemFormArray(i)">
          <i class="cil-trash"></i>
        </button>
      </div>
    </div>
  </div>
  <div class="form-group detached">
    <button class="btn btn-primary" type="button" (click)="addItemToArray()">
      <i class="cil-plus"></i>
    </button>
  </div>
  <div class="form-group detached">
    <button class="btn btn-primary col-4" type="button" (click)="save()">
      {{'save' | translate}}
    </button>
    <button class="btn btn-secondary col-4" type="button" (click)="close(null)">
      {{'cancel' | translate}}
    </button>
  </div>
</form>
