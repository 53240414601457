<h3 *ngIf="nbUsersSelected">
  {{'you_have_selected' | translate}} {{nbUsersSelected}}
  <span *ngIf="nbUsersSelected === 1">{{'added_user' | translate}}</span>
  <span *ngIf="nbUsersSelected > 1">{{'added_users' | translate}}</span>
</h3>
<div>
  <!-- <rm-tiles-component *ngIf="groups && groups.length"
                      [isLargeDisplay]="true"
                      [multiSelection]="false"
                      [items]="groups"
                      (selectItemEmitter)="selectGroup($event)">
  </rm-tiles-component> -->
</div>
<div>
  <button  class="btn btn-primary" type="button"
          [disabled]="!selectedGroup"
          (click)="validateSelection()">
        {{'validate' | translate}}
  </button>
  <button class="btn btn-primary" type="button"
          (click)="close(null)">
        {{'cancele' | translate}}
  </button>
</div>
