import { Component, Input, OnInit, Output, EventEmitter, ComponentFactoryResolver, ViewChild, OnDestroy } from '@angular/core';
import { ModalService } from '../../services/modal.service';
import { entryComponentList } from '../entry-components/entrycomponents.list';
import { getComponent } from '../entry-components/getcomponents';
import { RMDynamicDirective } from './modal-holder.directive';

@Component({
    selector: 'rm-modal',
    templateUrl: 'rm-modal.component.html',
    styleUrls: ['rm-modal.component.scss']
})
export class ModalComponent implements OnInit, OnDestroy {
    @Input() modalTitle: string;
    @Input() mode: 'warning' | 'success' | 'danger' | 'primary';
    @Input() actionlbl: string;
    @Input() componentName: entryComponentList;
    @Input() data: any;
    @Input() dismissFooter = false;
    @Input() headerSuffix = '';
    @Input() size: 'small' | 'regular' = 'regular';
    @Output()
    public actionClickEmitter:EventEmitter<any> = new EventEmitter<any>();
    public id: string;
    private subs = [];
    @ViewChild(RMDynamicDirective, {static: true}) adHost: RMDynamicDirective;

    constructor(
      private modalService: ModalService,
      private componentFactoryResolver: ComponentFactoryResolver) {
    }

    ngOnInit(): void {
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
        getComponent(this.componentName)
      );
      const viewContainerRef = this.adHost.viewContainerRef;
      viewContainerRef.clear();
      const componentRef = viewContainerRef.createComponent<any>(componentFactory);
      componentRef.instance.data = this.data;
      this.subs.push(
        componentRef.instance.closeModal.subscribe(
          response => {
            this.close(response);
          }
        )
      );
    }

    public close(response): void {
      this.modalService.close(response);
    }

    public ngOnDestroy(): void {
      this.subs.forEach(s => s.unsubscribe());
    }
}
