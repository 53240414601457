import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UploadInputComponent } from './upload-input.component';
import { UploadTaskComponent } from './upload-task/upload-task.component';
import { MigrationModule } from '../migration/migration.module';

@NgModule({
  declarations: [
    UploadInputComponent,
    UploadTaskComponent
  ],
  imports: [
    CommonModule,
    MigrationModule
  ],
  exports: [
    UploadInputComponent,
    UploadTaskComponent
  ],
  providers: [],
})
export class UploadInputModule {}
