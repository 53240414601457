import * as moment from 'moment';
import { DropDownListOption, RoadMateTimeZone } from '@roadmate/roadmate-common';


export const getStartDates = (): DropDownListOption[] => {
  const nowString = (new Date()).toISOString();
  const year = parseInt(nowString.substring(0, 4), 10);
  const month = parseInt(nowString.substring(5, 7), 10);
  const dates: DropDownListOption[] = [];
  for (let i=0; i< 12; i++) {
    const date = (month + i) > 12 ? `${year+1}-${getMonthString(month - 12 + i)}-01` : `${year}-${getMonthString(month+i)}-01`;
    dates.push(
      {
        value: date,
        label: transformDate(date)
      }
    )
  }
  return dates;
}


export const getEndDates = (): DropDownListOption[] => {
  const nowString = (new Date()).toISOString();
  const year = parseInt(nowString.substr(0, 4), 10);
  const month = parseInt(nowString.substr(5, 2), 10);
  const dates: DropDownListOption[] = [];
  for (let i=0; i< 23 - month + 2; i++) {
    const monthDates = (month + i) > 12 ? `${year+1}-${getMonthString(month - 12 + i)}` : `${year}-${getMonthString(month+i)}`;
    const daysInMonth = moment(monthDates, 'YYYY-MM').daysInMonth();
    const date = (month + i) > 12 ? `${year+1}-${getMonthString(month - 12 + i)}-${daysInMonth}` : `${year}-${getMonthString(month+i)}-${daysInMonth}`;
    dates.push(
      {
        value: date,
        label: transformDate(date)
      }
    );
  }
  return dates;
}

const getMonthString = (monthIndex: number): string => {
  return monthIndex > 9 ? `${monthIndex}` : `0${monthIndex}`;
}


const transformDate = (value: string): string => {
  if (!value || value.length !== 10 || value.indexOf('-') === -1) {
    return '';
  }
  const year = value.split('-')[0];
  const month = value.split('-')[1];
  const day = parseInt(value.split('-')[2], 10);
  return `${day === 1 ? 'Le 1er ' : `Le ${day} `}${RoadMateTimeZone.months[month]} ${year}`;
}
