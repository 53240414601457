import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MultiSelectComponent } from './multi-select.component';
import { TranslateModule } from '@ngx-translate/core';
import { PipeModule } from '../pipes/pipe.module';
import { SingleSelectComponent } from './single-select/single-select.component';
import { GroupedMultiSelectComponent } from './grouped-multi-select/grouped-multi-select.component';
import { MultiCheckBoxComponent } from './multi-check-box/multi-check-box.component';
import { IsItemSelectedPipe } from './multi-check-box/multi-check-pipe';
import { FormsModule } from '@angular/forms';
import { SearchePipe } from './multi-check-box/search.pipe';
import { TooltipModule } from 'primeng-lts/tooltip';

const components = [
    MultiSelectComponent,
    SingleSelectComponent,
    GroupedMultiSelectComponent,
    MultiCheckBoxComponent,
    SearchePipe,
    IsItemSelectedPipe
  ];

@NgModule({
  declarations: [
    ...components
  ],
  imports: [
    CommonModule,
    TranslateModule,
    PipeModule,
    FormsModule,
    TooltipModule,
  ],
  exports: [...components],
  providers: [],
})
export class MultiSelectModule {}
