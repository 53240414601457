<div>
  <ng-container [ngSwitch]="status">
    <ng-container *ngSwitchCase="statuses.Active">
      <span class="badge badge-success">
        {{'expenseline_active' | translate}}
      </span>
    </ng-container>
    <ng-container *ngSwitchCase="statuses.Available">
      <span class="badge badge-success">
        {{'expenseline_available' | translate}}
      </span>
    </ng-container>
    <ng-container *ngSwitchCase="statuses.Suspended">
      <span class="badge badge-danger">
        {{'expenseline_suspended' | translate}}
      </span>
    </ng-container>
    <ng-container *ngSwitchCase="statuses.Expired">
      <span class="badge badge-secondary">
        {{'expenseline_expired' | translate}}
      </span>
    </ng-container>
    <ng-container *ngSwitchCase="statuses.Scheduled">
      <span class="badge badge-primary">
        {{'expenseline_scheduled' | translate}}
      </span>
    </ng-container>
    <ng-container *ngSwitchCase="statuses.Provisionned">
      <span class="badge badge-warning">
        {{'expenseline_provisionned' | translate}}
      </span>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <span class="badge badge-warning">
        {{status | translate}}
      </span>
    </ng-container>
  </ng-container>
</div>
