import { Pipe, PipeTransform } from "@angular/core";

@Pipe({name: 'rm_default_img'})
export class RoadMateImagePipe implements PipeTransform {

  transform(initialImage: string, fallbackUrl: string): any {
    if (
      !navigator.onLine && 
      initialImage?.indexOf('http') > -1
    ) {
      return fallbackUrl ?? '/assets/img/placeholder.png';
    }
    return initialImage;
  }
}