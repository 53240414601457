import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import {
  Treezor, FormDefinition, Kyb, kybStatus, RoadMateProduct, Kyc, UserCardCompanyWallet, appRoles, BeneficiaryGroup,
  actions, FaqCategory, RoadMateFaq, RoadMateAlert, TransportWhiteList, RelationList, DropDownListOption, AppRoles, ObjectDefList,
  RoadMateTrip, ExpenseLine, ExpenseLineStatus, Agent, PathTo, RoadMateOrders, BankAccountStatement, BankTransaction,
  CollectionItem, Collections, Setting, AgentHTMLTemplate, RoadMateRefundRequest, RoadMateTransfer, UserDeletionStatus,
  QueueNames, QueueWatcher, InsuranceOffer, History, error_messages, HtmlSnippet, RMInvoice, EmployeeInvoice, UserBalance,
  IKV, RoadMateFile, RejectedTransaction, RefundRequestType, MobilityAccount, AvailableIntegration, RoadMateEnvironmentNameSpace,
  roadmateProducts, AutoRefund, SupportConversation, SupportMessage, SupportMessageReactions, InternalNote, RoadMateKpi, TimedItem,
  InstallmentRefund, InvoiceBundle, SettingNames, RoadMateSalesAgent, Merchant, Mid, TransportCategory, asyncForEach, MonthlyTripsKpi, RoadMateWalletHelper, TransferReminder,
  CompanyCashflow, GeneralPdfInterface, Attestation
} from '@roadmate/roadmate-common';
import * as moment from 'moment-timezone';
import { MessengerService } from './messenger.service';
import { Observable, Subscription, of } from 'rxjs';
import firebase from 'firebase';
import { RmSpecialRequest } from '../static';


interface Counter {
  count: number;
}

export enum listLevel {
  sa = 'sa',
  agent = 'agent',
  company = 'company'
}
export interface Paginator<T> {
  size: number;
  page: number;
  items: T[];
  lastItem?: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>
}

@Injectable({
  providedIn: 'root'
})
export class FireStoreService {
  public lists: { name: string, list: any[] }[] = [];
  public agentlists: { name: string, list: any[] }[] = [];
  public salists: { name: string, list: any[] }[] = [];
  public companyLists: { name: string, list: DropDownListOption[] }[] = [];
  public objectsList: {[key: string]: FormDefinition} = {};
  public products: RoadMateProduct[];
  public currentCompany: Treezor.User.Definition;
  public currentAppUser: Treezor.AppUser;
  public currentAgent: Agent;
  public currentEmployee: Treezor.User.EmployeeProfile;
  public compnyOrderGroups: BeneficiaryGroup[] = [];
  private appUserList: Treezor.AppUser[] = [];
  private companies: Treezor.User.Definition[] = [];
  private merchants: Merchant[];
  private subs = [];
  private appRoles: AppRoles;
  private wallets: Treezor.Wallet.Definition[];
  private walletsSub: Subscription;
  private lastUser;
  public agentTemplates: AgentHTMLTemplate[] = [];
  public sa_currentAgentRef: string;
  public companySettings: Setting[] = [];
  public employeeExpenLines: ExpenseLine[] = [];
  constructor(
    private db: AngularFirestore,
    private messenger: MessengerService
  ) {
    this.messenger.parcel.subscribe(parcel => {
      switch(parcel.action) {
        case actions.roles: {
          this.appRoles = parcel.data;
          this.subscribeToAdminElements();
          break;
        }
        case actions.logout: {
          this.subs.forEach(s => s.unsubscribe());
          this.currentAppUser = null;
          this.currentCompany = null;
          this.currentEmployee = null;
          this.companyLists = [];
          this.lists = [];
          this.products = [];
          this.companies = [];
          this.merchants = [];
          this.appUserList = [];
          this.subs = [];
          this.compnyOrderGroups = [];
          break;
        }
        case actions.amSelectCompany: {
          this.selectNewCurrentCompany(parcel.data);
          break;
        }
      }
    });
  }

  public async getSaTripKpis() {
    const trips = await this.listItemsFromCollection<MonthlyTripsKpi>(PathTo.saTripKpis());
    return trips;
  }

  public async getPdfTemplate(agentRef: string, companyRef: string, templateId: string) {
    return await this.getOneDocByRef<GeneralPdfInterface>(PathTo.pdfTemplate(agentRef, companyRef, templateId));
  }

  public async getEmployeeNotifications(agentRef: string, companyRef: string, uid: string) {
    return await this.listItemsFromCollection<any>(PathTo.employeeNotifications(agentRef, companyRef, uid));
  }

  public async getWalletHelpers(agentRef: string, companyRef: string) {
    const wallets = await this.listItemsFromCollection<RoadMateWalletHelper>(PathTo.companyWalletHelpers(agentRef, companyRef));
    return wallets;
  }

  public async getRMVS() {
    return await this.listItemsFromCollection<RoadMateKpi>('roadmate-kpis');
  }

  public async getAMWalletHelpers(agentRef: string, companyRef: string) {

  }

  public async getSAWalletHelpers(agentRef: string, companyRef: string) {

  }

  public async getAccountManagerTripKpis(agentRef: string) {
    const trips = await this.listItemsFromCollection<MonthlyTripsKpi>(PathTo.agentTripKpis(agentRef));
    return trips;
  }

  public async getCompanyTripsKpis(agentRef: string, companyRef: string) {
    const trips = await this.listItemsFromCollection<MonthlyTripsKpi>(PathTo.companyTripKpis(agentRef, companyRef));
    return trips;
  }

  public async getInvoiceBundle(agentRef: string, companyRef: string, invoiceRef: string) {
    return await this.getOneDocByRef<InvoiceBundle>(PathTo.companyInvoiceBundle(
      agentRef,
      companyRef,
      invoiceRef
    ));
  }

  public async getConversationsByEmail(email: string) {
    const documents = await this.db.firestore.collectionGroup(Collections.conversations).where('email', '==', email).get();
    const conversations: SupportConversation[] = [];
    documents.forEach(doc => {
      conversations.push(doc.data() as SupportConversation);
    });
    return conversations;
  }

  public getMessagesFromConversation(uid: string, conversationRef: string) {
    return this.db.collection<SupportMessage>(
      PathTo.messages(uid, conversationRef)
    ).valueChanges();
  }

  public getOpenSupportConversations() {
    return new Observable<SupportConversation[]>(subscriber => {
      this
      .db
      .firestore
      .collectionGroup(Collections.conversations)
      .where('status', '==', 'open')
      .onSnapshot(next => {
        const exps: SupportConversation[] = next.docs.map(doc => doc.data() as SupportConversation);
        subscriber.next(exps);
      });
    });
  }

  public async createSupportMessage(uid: string, conversationRef: string, message: SupportMessage) {
    await this.addObjectToCollection<SupportMessage>(
      PathTo.messages(uid, conversationRef),
      message
    );
  }

  public async getDeletionListByQueueRef(agentRef: string, companyRef: string, queueRef: string) {
    return await this.listItemsFromCollectionWithCondition<UserDeletionStatus>(
      PathTo.companyUserDeletions(agentRef, companyRef),
      'queueWatcherRef',
      queueRef
    );
  }

  public async setMessageAsRead(uid: string, conversationRef: string, messageRef: string) {
    await this.setMergeObjectByRef<SupportMessage>(
      PathTo.message(uid, conversationRef, messageRef),
      {
        isRead: true,
        updatedAt: moment().toISOString()
      }
    );
  }

  public async reactToSupportMessage(uid: string, conversationRef: string, messageRef: string, reaction: SupportMessageReactions) {
    await this.setMergeObjectByRef<SupportMessage>(
      PathTo.message(uid, conversationRef, messageRef),
      {
        reaction,
        updatedAt: moment().toISOString()
      }
    );
  }

  public async updateConversation(uid: string, conversationRef: string, conversation: Partial<SupportConversation>) {
    await this.setMergeObjectByRef<SupportConversation>(
      PathTo.conversation(uid, conversationRef),
      conversation
    );
  }

  public async getUserConversations(uid: string) {
    return await this.listItemsFromCollection<SupportConversation>(
      PathTo.conversations(uid)
    );
  }

  public async createSupportConversation(conversation: SupportConversation) {
    const ref = await this.addObjectToCollection<SupportConversation>(
      PathTo.conversations(this.currentAppUser.uid),
      conversation
    );
    return ref;
  }

  public async initSupportProfile() {
    const support = await this.getOneDocByRef<any>(
      PathTo.support(this.currentAppUser.uid)
    );
    if (support) {
      return;
    }
    await this.setMergeObjectByRef<any>(
      PathTo.support(this.currentAppUser.uid),
      {
        uid: this.currentAppUser.uid,
        createdAt: moment().local().format('YYYY-MM-DD HH:mm:ss'),
        companyName: this.currentAppUser.companyName ?? '',
        companyRef: this.currentAppUser.companyRef,
        agentRef: this.currentAppUser.agentRef,
        email: this.currentAppUser.email
      }
    )
  }

  public async getRecurrentTransfer(agentRef: string, companyRef: string, transferRef: string) {
    const transfer = await this.getOneDocByRef<RoadMateTransfer>(
      PathTo.companyRecurrentTransfer(agentRef, companyRef, transferRef)
    );
    return transfer;
  }

  public async getTransferReminders(agentRef: string, companyRef: string) {
    const reminders = await this.listItemsFromCollection<TransferReminder>(
      PathTo.companyTransfersReminders(agentRef, companyRef)
    );
    return reminders;
  }

  public async addTransferReminders(agentRef: string, companyRef: string, reminder: TransferReminder) {
    await this.addObjectToCollection<TransferReminder>(
      PathTo.companyTransfersReminders(agentRef, companyRef),
      reminder
    );
  }

  public async deleteTransferReminder(agentRef: string, companyRef: string, ref: string) {
    await this.deleteOneItemByRef(
      PathTo.companyTransfersReminder(
        agentRef,
        companyRef,
        ref
      )
    );
  }

  public async getUserRefundsAndTransactions(agentRef: string, companyRef:string, email: string) {
    return await this.listItemsFromCollectionWithCondition<Treezor.Card.CardTransaction>(
      PathTo.companyCardTransactions(agentRef, companyRef),
      'email',
      email
    );
  }

  public getEmployeeInternalNotes(agentRef: string, companyRef: string, uid: string) {
    return this.listItemsFromCollection<InternalNote>(PathTo.employeeInternalNotes(agentRef, companyRef, uid));
  }

  public async addEmployeeInternalNote (agentRef: string, companyRef: string, uid: string, note: InternalNote) {
    await this.addObjectToCollection<InternalNote>(
      PathTo.employeeInternalNotes(agentRef, companyRef, uid),
      note
    );
  }

  public getMobilityAccountYears(agentRef: string, companyRef: string) {
    return this.listItemsFromCollection<{
      createdAt: string;
      year: number;
    }>(PathTo.companyMobilityAccountYears(agentRef, companyRef));
  }

  public getMobilityAccountYearEmployee(agentRef: string, companyRef: string, year: number, uid: string) {
    return this.getOneDocByRef<MobilityAccount>(PathTo.companyMobilityAccountYearEmployee(agentRef, companyRef, year, uid));
  }

  public async setPreviousTransferAsDone(agentRef: string, companyRef: string, transferRef: string, data: Partial<RoadMateTransfer>) {
    
  }
  public async updateAutoRefund(autoRefund: AutoRefund) {
    const {agentRef, companyRef, uid} = this.currentAppUser;
    autoRefund.updatedAt = moment().toISOString();
    await this.setMergeObjectByRef<AutoRefund>(`${PathTo.employeeAutoRefund(agentRef, companyRef, uid, autoRefund.ref)}`, autoRefund);
  }

  public async getCurrentEmployeeMobilityAccount() {
    const {agentRef, companyRef, uid} = this.currentAppUser;
    const currentYear = (new Date()).getFullYear();
    const mobAccount = await this.getOneDocByRef<MobilityAccount>(
      PathTo.mobilityAccountYearEmployee(
        agentRef, companyRef, currentYear, uid
      )
    );
    return mobAccount;
  }

  public async getAllAutoRefunds() {
    const {agentRef, companyRef, uid} = this.currentAppUser;
    return await this.listItemsFromCollection<AutoRefund>(`${PathTo.employeeAutoRefunds(agentRef, companyRef, uid)}`);
  }

  public getPreviousAutoRefunds(email: string) {
    return this.listItemsFromCollectionWithCondition<AutoRefund>(
      Collections.autoRefunds,
      'email',
      email
    );
  }

  public getPreviousInstallmentRefunds(email: string) {
    return this.listItemsFromCollectionWithCondition<InstallmentRefund>(
      Collections.installmentRefunds,
      'email',
      email
    );
  }

  public async addNewAutoRefund(autoRefund: AutoRefund) {
    await this.addObjectToCollection(Collections.autoRefunds, autoRefund);
  }

  public async addNewInstallementRefund(installementRefund: InstallmentRefund) {
    await this.addObjectToCollection(`${Collections.installmentRefunds}`, installementRefund);
  }

  public async selectCurrentCompanyByRef(agentRef: string, companyRef: string) {
    const currentCompany = await this.getOneDocByRef<Treezor.User.Definition>(PathTo.company(agentRef, companyRef));
    if (!currentCompany) {
      return;
    }
    await this.selectNewCurrentCompany(currentCompany);
  }

  public saGetInvoices() {
    return this.db.collection<RMInvoice>(PathTo.saInvoices()).valueChanges();
  }

  public saGetRmSR() {
    return this.listItemsFromCollection<RmSpecialRequest>('rmsr');
  }

  public async saGetRejectedTransactions() {
    const trs = await this.listItemsFromCollectionWithCondition<RejectedTransaction>(
      'rejected-transactions',
      'isHandled',
      false
    );
    return trs;
  }

  public async saGetRefundRequestsByEmail(email: string) {
    return this.listItemsFromCollectionWithCondition<RoadMateRefundRequest>(
      PathTo.refundRequests(),
      'email',
      email
    );
  }

  public adminGetInvoices (agentRef: string, companyRef: string) {
    return this.db.collection<RMInvoice>(PathTo.companyInvoices(agentRef, companyRef)).valueChanges();
  }

  public agentGetInvoices (agentRef: string) {
    return this.db.collection<RMInvoice>(PathTo.agentInvoices(agentRef)).valueChanges();
  }

  public updateRMInvoice(invoice: RMInvoice) {
    if (!invoice?.ref) {
      throw error_messages.MISSING_REF;
    }
    return this.setMergeObjectByRef<RMInvoice>(PathTo.saInvoice(invoice.ref), invoice);
  }

  public async selectNewCurrentCompany (company: Treezor.User.Definition) {
    this.currentCompany = company;
    if (!this.currentAgent || !this.currentAgent.ref || this.currentAgent.ref !== company.agentRef) {
      await this.switchtoAgent(company.agentRef)
    }
    if (this.walletsSub) {
      this.walletsSub.unsubscribe();
      this.wallets = [];
    }
    const agentRef = company.agentRef;
    await this.getLists(agentRef, company.ref);
  }

  public getMonthWalletTransactions(agentRef: string, companyRef: string, walletId: string, month: string) {
    return this.listItemsFromCollection<BankTransaction>(PathTo.walletTransactions(agentRef, companyRef, month, walletId));
  }

  public getMonthStatements(agentRef: string, companyRef: string, month: string) {
    return this.listItemsFromCollection<BankAccountStatement>(PathTo.bankAccountStatementMonthWallets(agentRef, companyRef, month));
  }

  public getBankStatements(agentRef: string, companyRef: string) {
    return this.listItemsFromCollection<BankAccountStatement>(PathTo.bankAccountStatementMonths(agentRef, companyRef));
  }

  public async saUpdateWhiteList(ref: string, whiteList: Partial<TransportWhiteList>) {
    await this.setMergeObjectByRef(
      PathTo.whiteList(ref),
      whiteList
    );
  }

  public async updateCompanyWhiteList(ref: string, whiteList: Partial<TransportWhiteList>) {
    const {companyRef, agentRef, email} = this.currentAppUser;
    const history: History<TransportWhiteList> = {
      when: moment().local().format('YYYY-MM-DD HH:mm'),
      theChange: whiteList,
      timestamp: (new Date()).getTime(),
      updatedBy: email,
      objectName: ObjectDefList.whiteLists
    };
    await this.addObjectToCollection(
      `${PathTo.companyWhitList(agentRef, companyRef, ref)}/history`,
      history
    );
    await this.setMergeObjectByRef(
      PathTo.companyWhitList(agentRef, companyRef, ref),
      whiteList
    );
  }

  public getMobilityAccounts(year: number) {
    const agentRef = this.currentAgent.ref;
    const companyRef = this.currentCompany.ref;
    return this.listItemsFromCollection<MobilityAccount>(PathTo.mobilityAccountYearEmployees(agentRef, companyRef, year));
  }

  public async updateRefundRequest(request: RoadMateRefundRequest) {
    if (!request.ref) {
      throw error_messages.MISSING_REF;
    }
    let path = PathTo.refundRequest(request.ref);
    if (request.type === RefundRequestType.REFUND_REQUEST_IKV) {
      path = PathTo.ikvRequest(request.ref);
    }
    await this.setMergeObjectByRef(path, request);
  }

  public getSARefundRequests(ref: string) {
    return this.getOneDocByRef<RoadMateRefundRequest>(PathTo.refundRequest(ref));
  }

  public async getInsuranceOffers() {
    return this.listItemsFromCollection<InsuranceOffer>('insurance-offers');
  }

  public async deleteInsuranceOffer(offer: InsuranceOffer) {
    if (!offer?.ref) {
      return;
    }
    return this.deleteOneItemByRef(`insurance-offers/${offer.ref}`);
  }

  public async createOneInsuranceOffer(offer: InsuranceOffer) {
    const ref = await this.addObjectToCollection('insurance-offers', offer);
    return ref;
  }

  public async updateInsuranceOffer(offer: InsuranceOffer) {
    if (!offer?.ref) {
      return;
    }
    return this.setMergeObjectByRef(`insurance-offers/${offer.ref}`, offer);
  }

  public async getRoadMateInvoices(agentRef: string, companyRef: string) {
    return this.listItemsFromCollection<RMInvoice>(PathTo.companyRoadMateInvoices(agentRef, companyRef));
  }

  public getQueueWatcher(agentRef, companyRef, name: QueueNames) {
    return this.listItemsFromCollectionWithCondition<QueueWatcher>(
      PathTo.companyQueueWatchers(agentRef, companyRef),
      'name',
      name
    );
  }

  public getuserDeletionList$(agentRef: string, companyRef: string) {
    return this.db.collection<UserDeletionStatus>(
      PathTo.companyUserDeletions(agentRef, companyRef)
      ).valueChanges();
  }

  public getAgentTemplates() {
    if (!this.agentTemplates.length) {
      return this.listItemsFromCollection<AgentHTMLTemplate>(
        PathTo.agentHTMLTemplates(this.currentAppUser.agentRef)
      );
    }
  }

  public getAgentAdmins(agentRef: string) {
    return this.listItemsFromCollection<Treezor.AppUser>(PathTo.agentAdmins(agentRef));
  }

  public getDefaultSettings() {
    return this.listItemsFromCollection<Setting>(PathTo.defaultSettings());
  }

  public getAgentDefaultSettings() {
    return this.listItemsFromCollection<Setting>(PathTo.agentDefaultSettings(this.currentAppUser.agentRef));
  }

  public async getAgentDefaultSetting(agentRef: string, name: SettingNames) {
    const setting = await this.listItemsFromCollectionWithCondition<Setting>(
      PathTo.agentDefaultSettings(agentRef),
      'name',
      name
    );
    return setting.find(el => el.name === name);
  }

  public async getCompanyDefaultSettings(forceRefresh = true) {
    const agentRef = this.currentAgent.ref;
    const companyRef = this.currentCompany.ref;
    if (!forceRefresh && this.companySettings.length) {
      return [...this.companySettings];
    }
    this.companySettings = await this.listItemsFromCollection<Setting>(PathTo.companyDefaultSettings(agentRef, companyRef));
    return [...this.companySettings];
  }

  public async getCompanyDefaultSetting(agentRef: string, companyRef: string, settingName: SettingNames) {
    // const agentRef = this.currentAgent.ref;
    // const companyRef = this.currentCompany.ref;
    const companySettings = await this.listItemsFromCollectionWithCondition<Setting>(
      PathTo.companyDefaultSettings(agentRef, companyRef),
      'name',
      settingName
    );
    return companySettings.find(el => el.name === settingName);
  }

  public async getMerchantsByIds(ids: string[]) {
    return await this.listItemsFromCollectionWithCustomCondition<Merchant>(
      PathTo.merchants(),
      'ref',
      'in',
      ids
    );
  }

  public updateAgentDefaultSettings(agentRef: string, setting: Setting) {
    if (!setting.ref) {
      return false;
    }
    return this.setMergeObjectByRef<Setting>(PathTo.agentDefaultSetting(agentRef, setting.ref), setting);
  }

  public updateCompanyDefaultSettings(agentRef: string, companyRef: string, setting: Setting) {
    if (!setting.ref || !companyRef || !agentRef) {
      return false;
    }
    // const agentRef = this.currentAppUser.agentRef;
    return this.setMergeObjectByRef<Setting>(PathTo.companyDefaultSetting(agentRef, companyRef, setting.ref), setting);
  }

  public updateDefaultSetting(setting: Setting) {
    if (!setting.ref) {
      return false;
    }
    return this.setMergeObjectByRef<Setting>(`${PathTo.defaultSettings()}/${setting.ref}`, setting);
  }

  public getDefaultSettings$() {
    return this.db.collection<Setting>(PathTo.defaultSettings()).valueChanges();
  }

  public getSnippets$() {
    return this.db.collection<HtmlSnippet>(PathTo.snippets()).valueChanges();
  }

  public getSalesAgents$() {
    return this.db.collection<RoadMateSalesAgent>(PathTo.salesAgents()).valueChanges();
  }

  public getCompanyDefaultSettings$(agentRef: string, companyRef: string) {
    return this.db.collection<Setting>(PathTo.companyDefaultSettings(agentRef, companyRef)).valueChanges();
  }

  public addDefaultSettings(setting: Setting) {
    return this.addObjectToCollection(PathTo.defaultSettings(), setting);
  }

  public addSnippet(snippet: HtmlSnippet) {
    return this.addObjectToCollection(PathTo.snippets(), snippet);
  }

  public addSalesAgent(salesAgent: RoadMateSalesAgent) {
    return this.createOneDocByRef(PathTo.salesAgent(salesAgent.email), salesAgent);
  }

  public getAgents() {
    return this.listItemsFromCollection<Agent>(PathTo.agents());
  }

  public updateAgent(agent: Agent) {
    if (!agent?.ref) {
      throw new Error ('Agent passed is null or missing Ref');
    }
    return this.setMergeObjectByRef(PathTo.agent(agent.ref), agent);
  }

  public async removeGroupFromUser(agentRef: string, companyRef: string, email: string, groupRef: string, orderRef: string) {
    await this.deleteOneItemByRef(PathTo.inviteeGroup(agentRef, companyRef, email, groupRef));
    await this.deleteOneItemByRef(PathTo.inviteeOrder(agentRef, companyRef, email, orderRef));
  }

  public async getUsersInGroups(agentRef: string, companyRef: string) {
    const groups = await this.getGroups(agentRef, companyRef);
    if (groups.length) {
      const pms = groups.map(group =>
        this.listItemsFromCollection<DropDownListOption>(PathTo.groupInvitees(agentRef, companyRef, group.ref))
      );
      const results = await Promise.all(pms);
      if (results.length) {
        results.forEach((ddl ,i) => {
          groups[i].usersCount = ddl.length;
          groups[i].usersDdl = ddl;
        })
      }
    }
    return groups;
  }

  public getUsersInGroup(agentRef: string, companyRef: string, groupRef: string) {
    return this.listItemsFromCollection<DropDownListOption>(PathTo.groupInvitees(agentRef, companyRef, groupRef));
  }

  public async getAllExepenseLines(agentRef: string, companyRef: string) {
    return this.listItemsFromCollection<ExpenseLine>(PathTo.companyUserExpenseLines(agentRef, companyRef));
  }

  public getCompanyProvisionnedExpenseLines(agentRef: string, companyRef: string) {
    return new Observable<ExpenseLine[]>(subscriber => {
      this.db.firestore.collection(PathTo.companyUserExpenseLines(agentRef, companyRef))
      .where(
        'status',
        '==',
        ExpenseLineStatus.Provisionned
      ).onSnapshot(next => {
          const exps: ExpenseLine[] = next.docs.map(doc => doc.data() as ExpenseLine);
          subscriber.next(exps);
      });
    });
  }

  public async getCompanyExpenseLines(agentRef: string, companyRef: string) {
    return this.listItemsFromCollection<ExpenseLine>(
      PathTo.companyUserExpenseLines(agentRef, companyRef)
    );
  }

  public async getEmployeeExpenselines(agentRef: string, companyRef: string, uid: string) {
    const exp =  await this.listItemsFromCollection<ExpenseLine>(
      PathTo.employeeExpenseLines(agentRef, companyRef, uid)
    );
    return exp.filter(d =>
      [roadmateProducts.FMD, roadmateProducts.DP, roadmateProducts.CM].indexOf(d.productName as roadmateProducts) > -1 &&
      (d.status === ExpenseLineStatus.Active || d.status === ExpenseLineStatus.Available)
    );
  }

  public getEmployeeExpenselines$(agentRef: string, companyRef: string, uid: string) {
    return this.db.collection<ExpenseLine>(PathTo.employeeExpenseLines(agentRef, companyRef, uid)).valueChanges();
  }

  public subscribeToCompanyExpenseLines (agentRef: string, companyRef: string) {
    return this.db.collection<ExpenseLine>(PathTo.companyUserExpenseLines(agentRef, companyRef)).valueChanges();
  }

  public subscribeToCompanyExpenseLinesForEmail (agentRef: string, companyRef: string, email: string) {
    return new Observable<ExpenseLine[]>(subscriber => {
      this.db.firestore.collection(PathTo.companyUserExpenseLines(agentRef, companyRef))
        .where('email', '==', email)
        .onSnapshot(next => {
          const exps: ExpenseLine[] = next.docs.map(doc => doc.data() as ExpenseLine);
          subscriber.next(exps);
        })
    });
  }

  public async getOldInvitees(
    agentRef: string,
    companyRef: string
  ){
    return this.listItemsFromCollection<Treezor.AppUser>(PathTo.companyOldInvitees(agentRef, companyRef));
  }

  public async queryFirstUserPage(agentRef: string, companyRef: string, limit = 50): Promise<{size: number, users: Treezor.AppUser[]}> {
    const response = {
      size: 0,
      users: []
    }
    const counter = await this.getOneDocByRef<Counter>(PathTo.companyCountersInvitees(agentRef, companyRef));
    const query = await this.db.firestore.collection(PathTo.invitees(agentRef, companyRef))
    .orderBy('email')
    .limit(limit)
    .get();
    if (!query || query.empty) {
      return response;
    }
    response.size = counter?.count ?? query.size;
    this.lastUser = query.docs[query.docs.length -1];
    query.docs.forEach(doc => {
      response.users.push({
        ...doc.data() as Treezor.AppUser,
        ref: doc.id
      });
    });
    return response;
  }

  public async getNextUsersPage(agentRef: string, companyRef: string, limit = 50, size: number, page: number) {
    const users: Treezor.AppUser[] = [];
    if (limit * page > size) {
      return users;
    }
    const query = await this.db.firestore.collection(PathTo.invitees(agentRef, companyRef))
    .orderBy('email')
    .startAfter(this.lastUser)
    .limit(limit)
    .get();
    if (!query || query.empty) {
      return users;
    }
    this.lastUser = query.docs[query.docs.length -1];
    query.docs.forEach(doc => {
      users.push({
        ...doc.data() as Treezor.AppUser,
        ref: doc.id
      });
    });
    return users;
  }

  public async addUpSaleOpportunity(product: RoadMateProduct) {
    return await this.addObjectToCollection('opportunities', {
      title: `${this.currentAppUser.title}`,
      name: `${this.currentAppUser.firstname} ${this.currentAppUser.lastname}`,
      email: this.currentAppUser.email,
      company: this.currentCompany.legalName,
      agent: this.currentAgent.name,
      product
    });
  }

  public getCompanyTrips (agentRef: string, companyRef: string) {
    // console.log(`getting ${PathTo.companyTrips(agentRef, companyRef)}`);
    return this.listItemsFromCollection<RoadMateTrip>(PathTo.companyTrips(agentRef, companyRef));
  }

  public getkybUsers(companyRef: string, agentRef: string = '') {
    const currentAgentRef = agentRef ? agentRef : this.currentAgent.ref;
    if (!currentAgentRef) {
      throw 'No Agent Ref';
    }
    // console.log(`getting ${PathTo.company(currentAgentRef, companyRef)}/beneficiaries`);
    return this.listItemsFromCollection<Treezor.User.EmployeeProfile>(`${PathTo.company(currentAgentRef, companyRef)}/beneficiaries`);
  }

  public async getCardTransactions(agentRef: string, companyRef: string, end: string, start: string): Promise<Treezor.Card.CardTransaction[]> {
    const transactions: Treezor.Card.CardTransaction[] = [];
    const docs = await this
    .db
    .firestore
    .collection(PathTo.companyCardTransactions(agentRef, companyRef))
    .orderBy('authorizationIssuerTime', 'desc')
    .startAfter(start)
    .endBefore(end)
    .get();
    if (docs.empty) {
      return transactions;
    }

    docs.forEach(doc => {
      transactions.push({
        ...doc.data() as Treezor.Card.CardTransaction,
        ref: doc.id
      });
    });
    return transactions;
  }

  public async getCardTransactionsTimespan(agentRef: string, companyRef: string, startDate: string, endDate: string): Promise<Treezor.Card.CardTransaction[]> {
    const transactions: Treezor.Card.CardTransaction[] = [];
    const docs = await this
    .db
    .firestore
    .collection(PathTo.companyCardTransactions(agentRef, companyRef))
    .where(
      'authorizationIssuerTime', '>=', startDate
    )
    .where(
      'authorizationIssuerTime', '<=', endDate
    )
    .orderBy('authorizationIssuerTime', 'desc')
    .get();
    if (docs.empty) {
      return transactions;
    }

    docs.forEach(doc => {
      transactions.push({
        ...doc.data() as Treezor.Card.CardTransaction,
        ref: doc.id
      });
    });
    return transactions;
  }

  public async saveAbstractObject(objectName: string, item: CollectionItem) {
    const {role} = this.currentAppUser;
    if (!item) {
      return;
    }
    switch(objectName) {
      case ObjectDefList.transportCategory:{
        if (role !== appRoles.superadmin) {
          throw new Error('NOT_ALLOWED');
        }
        if (item.ref) {
          return await this.setMergeObjectByRef(`${PathTo.transportCategory(item.ref)}`, item);
        } else {
          return await this.addObjectToCollection(`${PathTo.transportCategories()}`, item);
        }
      }
      case ObjectDefList.BeneficiaryGroup:{
        const agentRef = this.currentAgent.ref;
        const companyRef = this.currentCompany.ref;
        if (!companyRef || !agentRef) {
          throw error_messages.PLEASE_SELECT_ONE_COMPANY;
        }
        if (item.ref) {
          return await this.setMergeObjectByRef(`${PathTo.group(agentRef, companyRef, item.ref)}`, item);
        } else {
          return await this.addObjectToCollection(`${PathTo.groups(agentRef, companyRef)}`, item);
        }
      }
      case ObjectDefList.faq:{
        if (role !== appRoles.superadmin) {
          throw new Error('NOT_ALLOWED');
        }
        if (item.ref) {
          return await this.setMergeObjectByRef(`${PathTo.faq(item.ref)}`, item);
        } else {
          return await this.addObjectToCollection(`${PathTo.faqs()}`, item);
        }
      }
      case ObjectDefList.faqCategories:{
        if (role !== appRoles.superadmin) {
          throw new Error('NOT_ALLOWED');
        }
        if (item.ref) {
          return await this.setMergeObjectByRef(`${PathTo.faqCategory(item.ref)}`, item);
        } else {
          return await this.addObjectToCollection(`${PathTo.faqCategories()}`, item);
        }
      }
      case ObjectDefList.merchant: {
        const merchant = item as Merchant;
        if (merchant.ref) {
          if (role === appRoles.superadmin || (role === appRoles.agent)) {
            return await this.setMergeObjectByRef(`${PathTo.merchant(item.ref)}`, item);
          }
          throw new Error('NOT_ALLOWED');
        } else {
          if (role !== appRoles.superadmin && role !== appRoles.agent) {
            throw new Error('NOT_ALLOWED');
          }
          if (role === appRoles.agent) {
            const agentRef = this.currentAgent.ref;
            merchant.agentRef = agentRef;
          }
          return await this.addObjectToCollection(`${PathTo.merchants()}`, merchant);
        }
      }
      case ObjectDefList.mid:{
        if (role !== appRoles.superadmin) {
          throw new Error('NOT_ALLOWED');
        }
        if (item.ref)  {
          return await this.setMergeObjectByRef(`${PathTo.mid(item.ref)}`, item);
        } else {
          return await this.addObjectToCollection(`${PathTo.mids()}`, item);
        }
      }
      case ObjectDefList.products:{
        if (role !== appRoles.superadmin) {
          throw new Error('NOT_ALLOWED');
        }
        if (item.ref) {
          return await this.setMergeObjectByRef(`${PathTo.product(item.ref)}`, item);
        } else {
          return await this.addObjectToCollection(`${PathTo.products()}`, item);
        }
      }
      case ObjectDefList.whiteLists: {
        const whiteList = item as TransportWhiteList;
        if (role === appRoles.superadmin) {
          if (item.ref) {
            return await this.setMergeObjectByRef(`${PathTo.whiteList(item.ref)}`, item);
          } else {
            whiteList.isGlobal = true;
            return await this.addObjectToCollection(`${PathTo.whiteLists()}`, whiteList);
          }
        } else {
          throw new Error('NOT_ALLOWED');
        }
      }
      case ObjectDefList.availableIntegrations: {
        const intergration = item as AvailableIntegration;
        if (role !== appRoles.superadmin) {
          throw new Error('NOT_ALLOWED');
        }
        if (item.ref) {
          return await this.setMergeObjectByRef(`${PathTo.availableIntegrations()}/${item.ref}`, intergration);
        } else {
          return await this.addObjectToCollection(PathTo.availableIntegrations(), intergration);
        }
      }
      default: {
        return Promise.reject('Unkown Object Name');
      }
    }
  }

  public getAbstractValueChanges(objectName: string, customProperty = '', customValue = ''): Observable<any[]> {
    const role = this.currentAppUser.role;
    switch(objectName) {
      case ObjectDefList.BeneficiaryGroup: {
        const agentRef = this.currentAgent.ref;
        const companyRef = this.currentCompany.ref;
        return this.getCollectionValueChange(PathTo.groups(agentRef, companyRef));
        }
      case ObjectDefList.faq:
        return this.getCollectionValueChange(PathTo.faqs());
      case ObjectDefList.faqCategories:
        return this.getCollectionValueChange(PathTo.faqCategories());
      case ObjectDefList.merchant:
        return this.getCollectionValueChange(PathTo.merchants());
      case ObjectDefList.mid:
        return this.getCollectionValueChange(PathTo.mids());
      case ObjectDefList.products:
        return this.getCollectionValueChange(PathTo.products());
      case ObjectDefList.whiteLists: {
        if (role === appRoles.superadmin) {
          return this.getCollectionValueChange(PathTo.whiteLists());
        } else {
          const agentRef = this.currentAgent.ref;
          const companyRef = this.currentCompany.ref;
          return this.getCollectionValueChange(PathTo.companyWhitLists(agentRef, companyRef));
        }
      }
      case ObjectDefList.transportCategory: {
        if (role === appRoles.superadmin) {
          return this.getCollectionValueChange(PathTo.transportCategories());
        } else {
          const agentRef = this.currentAgent.ref;
          return this.getCollectionValueChange(PathTo.agentTransportCategories(agentRef));
        }
      }
      case ObjectDefList.refundRequests: {
        if (role === appRoles.superadmin) {
          return this.getCollectionValueChangeWithCondition<RoadMateRefundRequest>(
            PathTo.refundRequests(),
            'status',
            Treezor.Status.PENDING
          );
        } else if ([appRoles.agent, appRoles.accountManager].indexOf(role) > -1){
          const agentRef = this.currentAgent.ref;
          return this.getCollectionValueChangeWithCondition<RoadMateRefundRequest>(
            PathTo.agentRefundRequests(agentRef),
            'agentRef',
            this.currentAppUser.agentRef
          );
        } else if (role === appRoles.admin) {
          const agentRef = this.currentAgent.ref;
          const companyRef = this.currentCompany.ref;
          return this.getCollectionValueChangeWithCondition<RoadMateRefundRequest>(
            PathTo.companyRefundRequests(agentRef, companyRef),
            'companyRef',
            this.currentAppUser.companyRef
          );
        }
      }
      case ObjectDefList.ikvRequest:
        if (role === appRoles.superadmin) {
          return this.getCollectionValueChangeWithCondition<RoadMateRefundRequest>(
            PathTo.ikvRequests(),
            'status',
            Treezor.Status.PENDING
          );
        } else if ([appRoles.agent, appRoles.accountManager].indexOf(role) > -1){
          const agentRef = this.currentAgent.ref;
          return this.getCollectionValueChangeWithCondition<RoadMateRefundRequest>(
            PathTo.agentIkvRequests(agentRef),
            'agentRef',
            this.currentAppUser.agentRef
          );
        } else if (role === appRoles.admin) {
          const agentRef = this.currentAgent.ref;
          const companyRef = this.currentCompany.ref;
          return this.getCollectionValueChangeWithCondition<RoadMateRefundRequest>(
            PathTo.companyIkvRequests(agentRef, companyRef),
            'companyRef',
            this.currentAppUser.companyRef
          );
        }
        return of([]);
      case ObjectDefList.transferHistory: {
        if (role === appRoles.superadmin) {
          return this.getCollectionValueChangeWithCondition<RoadMateTransfer>(
            ObjectDefList.transferHistory,
            'repeat',
            true
          );
        }
      }
      case ObjectDefList.rmInvoice: {
        if (customProperty && customValue) {
          return this.getCollectionValueChangeWithCondition<RoadMateRefundRequest>(
            PathTo.saInvoices(),
            customProperty,
            customValue
          );
        } else {
          return this.getCollectionValueChange(PathTo.saInvoices());
        }
      }
      case ObjectDefList.availableIntegrations: {
        return this.getCollectionValueChange(PathTo.availableIntegrations());
      }
      case ObjectDefList.conversation: {
        if (customProperty === 'assignedTo') {
          return new Observable<SupportConversation[]>(subscriber => {
            this
            .db
            .firestore
            .collectionGroup(Collections.conversations)
            .where('status', '==', 'open')
            .onSnapshot(next => {
              const exps: SupportConversation[] = next.docs.map(doc => doc.data() as SupportConversation);
              subscriber.next(exps);
            });
          });
        } else {
          return new Observable<SupportConversation[]>(subscriber => {
            this
            .db
            .firestore
            .collectionGroup(Collections.conversations)
            .where(customProperty, '==', customValue)
            .onSnapshot(next => {
              const exps: SupportConversation[] = next.docs.map(doc => doc.data() as SupportConversation);
              subscriber.next(exps);
            });
          });
        }
      }
      default:
        return of([]);
    }
  }

  public async getCompanyAvailableIntegrations(agentRef: string, companyRef: string) {
    return await this.listItemsFromCollection<AvailableIntegration>(
      `${PathTo.companyAvailableIntegrations(agentRef, companyRef)}`
    )
  }

  public subscribeToAdminElements() {
    if (!this.currentAppUser?.companyRef) {
      setTimeout(() => {
        this.subscribeToAdminElements();
      }, 1000);
      return;
    }
    const {agentRef, companyRef} = this.currentAppUser;
    switch (this.currentAppUser.role) {
      case appRoles.admin:
        this.subs.push(
          this.db.collection<RelationList>(PathTo.companyLists(agentRef, companyRef)).valueChanges()
          .subscribe(
            lists => {
              this.companyLists = lists;
              this.messenger.parcel.next({
                action: actions.companyLists,
                data: lists
              });
            }
          )
        );
        this.subs.push(
          this.db.collection<BeneficiaryGroup>(PathTo.groups(agentRef, companyRef)).valueChanges()
          .subscribe(
            groups => this.compnyOrderGroups = groups
          )
        );
        break;
      case appRoles.agent:
        break;
      case appRoles.superadmin:
        break;
    }
    if (this.appRoles.admin) {

    }
    if (this.appRoles.superadmin) {
      this.subs.push(
        this.db.collection<RelationList>(`${Collections.lists}`).valueChanges().subscribe(
          lists => this.lists = lists
        )
      );
    }
  }

  public getListByName (listName: string): DropDownListOption[] {
    if (this.lists.find(el => el.name === listName)) {
      const list = this.lists.find(el => el.name === listName).list;
      return JSON.parse(JSON.stringify(list));
    } else if (this.companyLists.find(el => el.name === listName)) {
      const list = this.companyLists.find(el => el.name === listName).list;
      return JSON.parse(JSON.stringify(list));
    }
    console.error(`List named ${listName} was not found lists`);
    return [];
  }

  public async getComapnyWhitelists(agentRef: string, companyRef: string) {
    return await this.listItemsFromCollection<TransportWhiteList>(PathTo.companyWhitLists(agentRef, companyRef));
  }

  public async getGlobalWhitelistes() {
    if (this.currentAppUser.role === appRoles.superadmin) {
      return await this.listItemsFromCollection<TransportWhiteList>(PathTo.whiteLists());
    }
    const agentRef = this.currentAppUser.agentRef;
    return await this.listItemsFromCollection<TransportWhiteList>(PathTo.agentWhiteLists(agentRef));
  }

  public async deleteWhiteList(whitelistRef: string) {
    if (this.currentAppUser.role === appRoles.superadmin) {
      return await this.deleteOneItemByRef(PathTo.whiteList(whitelistRef));
    }
    const agentRef = this.currentAppUser.agentRef;
    return await this.deleteOneItemByRef(PathTo.agentWhiteList(agentRef, whitelistRef));
  }

  public async updateTransportCategory(transport: TransportCategory) {
    try {
      if (this.currentAppUser.role === appRoles.superadmin) {
        await this.setMergeObjectByRef<TransportCategory>(PathTo.transportCategory(transport.ref), transport);
      } else {
        const agentRef = this.currentAppUser.agentRef;
        await this.setMergeObjectByRef<TransportCategory>(PathTo.agentTransportCategory(agentRef, transport.ref), transport);
      }
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  public async getAllTransportCategories() {
    return await this.listItemsFromCollection<TransportCategory>(PathTo.transportCategories());
  }

  public async getAgentTransportCategories() {
    const agentRef = this.currentAppUser.agentRef;
    return await this.listItemsFromCollection<TransportCategory>(PathTo.agentTransportCategories(agentRef));
  }

  public getMids() {
    return this.db.collection<Mid>(PathTo.mids()).valueChanges();
  }

  public async saveMerchant(merchant: Merchant) {
    if (this.currentAppUser.role !== appRoles.superadmin) {
      return;
    }
    if (merchant.ref) {
      await this.setMergeObjectByRef<Merchant>(PathTo.merchant(merchant.ref), merchant);
      return;
    }
    await this.addObjectToCollection(PathTo.merchants(), merchant);

  }

  public async addMidToMerchant(merchant: Merchant, mids: Mid[]) {
    if (this.currentAppUser.role !== appRoles.superadmin) {
      return;
    }
    if (merchant.ref) {
      await asyncForEach(mids, async (mid: Mid) => {
        mid.mid = mid.mid?.trim() ?? '';
        if (!mid.mid || !merchant.ref) {
          return;
        }
        await Promise.all([
          this.setMergeObjectByRef<Mid>(`${PathTo.merchant(merchant.ref)}/${PathTo.mid(mid.ref)}`, mid),
          this.setMergeObjectByRef<Partial<Mid>>(PathTo.mid(mid.mid), {
            merchantRef: merchant.ref,
            isQualified: true
          })
        ])
      })
    }
  }

  public async disqualifyMid(mid: Mid) {
    if (this.currentAppUser.role !== appRoles.superadmin) {
      return;
    }
    if (mid && mid.ref) {
      await this.setMergeObjectByRef<Partial<Mid>>(PathTo.mid(mid.ref), {
        merchantRef: '',
        isQualified: true
      });
    }
  }

  public async disqualifyMids(mids: Mid[]) {
    if (mids.length < 1) {
      return;
    }
    for(let i = 0; i < mids.length; i++) {
      await this.disqualifyMid(mids[i]);
    }
  }

  public async getMerchants() {
    if (this.currentAppUser.role !== appRoles.superadmin) {
      return [];
    }
    if (this.merchants && this.merchants.length) {
      return this.merchants;
    }
    this.merchants = await this.listItemsFromCollection<Merchant>(PathTo.merchants());
    this.listenToMerchants();
    return this.merchants;
  }

  private listenToMerchants() {
    this.db.collection<Merchant>(PathTo.merchants()).valueChanges().subscribe(
      merchantDocs => {
        if (!merchantDocs.length) {
          return;
        }
        this.merchants = [...merchantDocs];
      }
    );
  }

  public async saveTransportCategory(category: TransportCategory) {
    if (category.ref) {
      await this.setMergeObjectByRef<TransportCategory>(PathTo.transportCategory(category.ref), category);
    } else {
      const id = await this.addObjectToCollection(PathTo.transportCategories(), category);
    }
  }

  public async updateAlert(alert: RoadMateAlert) {
    await this.db.doc(`${PathTo.alerts()}/${alert.ref}`).update(alert);
  }

  public async getAlerts() {
    const timestamp = (new Date()).getTime() - (1000 * 3600 * 24 * 7);
    const docs = await this.db.firestore.collection(`${PathTo.alerts()}`)
    .limit(20)
    .where('timestamp', '>', timestamp)
    .where('status', 'in', ['PENDING', 'OPEN', 'ESCALATED'])
    .orderBy('timestamp', 'desc')
    .get();

    const alerts: RoadMateAlert[] = [];
    docs.forEach(doc => {
      alerts.push({
        ...doc.data() as RoadMateAlert,
        ref: doc.id
      });
    });
    return alerts;
  }

  // private listenToAlerts() {
  //   this.db.collection<RoadMateAlert>(`${PathTo.alerts()}`)
  //   .valueChanges()
  //   .subscribe(
  //     alerts => {
  //       const lastAlert = [...alerts]
  //       .filter(el => el.timestamp)
  //       .sort((a, b) => b.timestamp - a.timestamp).pop();
  //       this.messenger.parcel.next({
  //         action: actions.newAlert,
  //         data: lastAlert
  //       });
  //       this.messenger.parcel.next({
  //         action: actions.newAlertCount,
  //         data: alerts.length
  //       });
  //     }
  //   )
  // }

  public async getNextAlerts() {

  }

  public async updateAppUser(appUser: Treezor.AppUser) {
    return await this.setMergeObjectByRef(PathTo.appUser(appUser.email), appUser);
  }

  public async getFaqCategories() {
    const today = (new Date()).toISOString().substr(0, 10);
    const lastUpdate = localStorage.getItem('lastUpdate-faq-categories');
    if (lastUpdate !== today) {
      localStorage.setItem('lastUpdate-faq-categories', today);
      const cats = await this.listItemsFromCollection<FaqCategory>('faq-categories');
      localStorage.setItem('FaqCategory', JSON.stringify(cats));
      return cats;
    }
    return JSON.parse(localStorage.getItem('FaqCategory')) as FaqCategory[];
  }

  public async saveFaqCategory(ref: string, category: FaqCategory) {
    await this.setMergeObjectByRef(PathTo.faqCategory(ref), category);
  }

  public async saveFaq(faq: RoadMateFaq) {
    await this.addObjectToCollection(PathTo.faqs(), faq);
  }

  public async getFaqs() {
    const today = (new Date()).toISOString().substr(0, 10);
    const lastUpdate = localStorage.getItem('lastUpdate-faqs');
    if (lastUpdate !== today) {
      localStorage.setItem('lastUpdate-faqs', today);
      const faqs = await this.listItemsFromCollection<RoadMateFaq>(PathTo.faqs());
      localStorage.setItem('RoadMateFaq', JSON.stringify(faqs));
      return faqs;
    }
    return JSON.parse(localStorage.getItem('RoadMateFaq')) as RoadMateFaq[];
  }

  public async getCardDetailsAsSuperAdmin(email) {
    return this.listItemsFromCollectionWithCondition<UserCardCompanyWallet>(PathTo.carduserwalletcompany(), 'email', email);
  }

  public async getBankBeneficiaires(agentRef: string, compnayRef: string) {
    return await this.listItemsFromCollection<Treezor.Beneficiary.Definition>(PathTo.companyBankBeneficiaries(agentRef, compnayRef));
  }

  public async getCardDetailsAsAdmin(agentRef: string, companyRef: string, email: string) {
    return this.listItemsFromCollectionWithCondition<UserCardCompanyWallet>(PathTo.companyCards(agentRef, companyRef), 'email', email);
  }

  public async getCompanyUsers(agentRef: string, companyRef: string): Promise<Treezor.AppUser[]> {
    let currentAgentRef = '';
    if (this.currentAppUser.role === appRoles.superadmin) {
      currentAgentRef = agentRef;
    } else {
      currentAgentRef = this.currentAppUser.agentRef;
    }
    if (!currentAgentRef) {
      throw 'No Agent Ref found';
    }
    return await this.listItemsFromCollection<Treezor.AppUser>(PathTo.invitees(currentAgentRef, companyRef));
  }

  public async updateProduct(product: RoadMateProduct) {
    await this.setMergeObjectByRef(PathTo.product(product.ref), product);
  }

  public async updateCurrentCompany(agentRef: string, companyRef: string, partialObject: Treezor.User.Definition) {
    try {
      if (partialObject && Object.keys(partialObject).length && !Array.isArray(partialObject)) {
        await this.setMergeObjectByRef(PathTo.company(agentRef, companyRef), partialObject);
        this.currentCompany = {...this.currentCompany, ...partialObject};
        return true;
      }
      return false;
    } catch (e) {
      console.error('', e);
      return false;
    }
  }
  public async updateCurrentUser(partialObject) {
    try {
      if (partialObject && Object.keys(partialObject).length && !Array.isArray(partialObject)) {
        await this.setMergeObjectByRef(PathTo.appUser(this.currentAppUser.ref), partialObject);
        this.currentAppUser = {...this.currentAppUser, ...partialObject};
      }
    } catch (e) {
      console.error('', e);
    }
  }

  public async getWallets(agentRef: string, companyRef: string) {
    // if (this.wallets?.length) {
    //   return this.wallets;
    // }
    this.wallets = await this.listItemsFromCollection<Treezor.Wallet.Definition>(PathTo.companyWallets(agentRef, companyRef));
    // this.walletsSub = this.listToWalletChanges(agentRef, companyRef).subscribe(
    //   changes => {
    //     const wallets: Treezor.Wallet.Definition[] = [...changes];
    //     wallets.forEach(wallet => wallet.ref = `${wallet.walletId}`);
    //     this.wallets = wallets;
    //   }
    // );
    return this.wallets;
  }
  public listToWalletChanges(agentRef: string, companyRef: string) {
    return this.db.collection<Treezor.Wallet.Definition>(PathTo.companyWallets(agentRef, companyRef))
    .valueChanges();
  }

  public async addNewOrders(orders: Partial<RoadMateOrders>[], agentRef: string, companyRef: string) {
    if (!orders.length) {
      return;
    }
    if (!agentRef || !companyRef) {
      throw 'Missing agentRef or companyRef';
    }
    const poms = orders.map(order => {
      return this.createOneDocByRef(PathTo.order(agentRef, companyRef, order.code), order);
    });
    await Promise.all(poms);
  }

  public async updateOrder(agentRef: string, companyRef: string, order: Partial<RoadMateOrders>) {
    if (!order.code) {
      return false;
    }
    await this.setMergeObjectByRef(PathTo.order(agentRef, companyRef, order.code), order);
    return true;
  }

  public async addGroupToUsers(agentRef: string, companyRef: string, groups: DropDownListOption[], email) {
    await Promise.all(
      groups.map(group => this.setMergeObjectByRef(PathTo.inviteeGroup(agentRef, companyRef, email, group.value), group))
    );
  }

  public async removeUserFromGroups(agentRef: string, companyRef: string, groups: DropDownListOption[], email) {
    await Promise.all(groups.map(group => this.deleteOneItemByRef(PathTo.inviteeGroup(agentRef, companyRef, email, group.value))));
  }

  public async addUserToOrders(agentRef: string, companyRef: string, orders: DropDownListOption[], email) {
    await Promise.all(
      orders.map(order => this.setMergeObjectByRef(PathTo.inviteeOrder(agentRef, companyRef, email, order.value), order)));
  }

  public async removeUserFromOrders(agentRef: string, companyRef: string, orders: DropDownListOption[], email) {
    await Promise.all(orders.map(order => this.deleteOneItemByRef(PathTo.inviteeOrder(agentRef, companyRef, email, order.value))));
  }

  public listenToOrders(agentRef: string, companyRef: string, targetCompanyRef = '') {
    return this.db.collection<RoadMateOrders>(PathTo.orders(agentRef, targetCompanyRef ? targetCompanyRef : companyRef)).valueChanges();
  }

  public async getClientOrders(agentRef: string, companyRef: string): Promise<RoadMateOrders[]> {
    const orders = await this.listItemsFromCollection<RoadMateOrders>(PathTo.orders(agentRef, companyRef));
    return orders;
  }

  public async getRoadMateProducts(): Promise<RoadMateProduct[]> {
    if (this.products && this.products.length) {
      return JSON.parse(JSON.stringify(this.products));
    }
    this.products = await this.listItemsFromCollection<RoadMateProduct>(PathTo.products());
    return JSON.parse(JSON.stringify(this.products));
  }

  /**
   * Used only by super admins
   */
  public async getAppUsers(startAfter?: string, limit?: number): Promise<Treezor.AppUser[]> {
    if (this.appUserList && this.appUserList.length) {
      return this.appUserList;
    }
    this.appUserList = [];
    const docs = await this.db.firestore.collection(PathTo.appUsers())
    .orderBy('createdAt', 'asc')
    .startAfter(startAfter ? startAfter : '2020-01-01 00:00')
    .limit(limit ? limit : 15)
    .get();
    if (docs.empty) {
      return this.appUserList;
    }
    docs.forEach(doc => {
      this.appUserList.push(
        {
          ...doc.data() as Treezor.AppUser,
          ref: doc.id
        }
      );
    });
    return this.appUserList;
  }

  public async getAppUserByEmail(email): Promise<Treezor.AppUser> {
    const agentRef = this.currentAppUser.agentRef;
    const companyRef = this.currentAppUser.companyRef;
    return await this.getOneDocByRef<Treezor.AppUser>(
      PathTo.invitee(agentRef, companyRef, email)
    );
  }

  public setNewAppUserList(users: Treezor.AppUser[]) {
    this.appUserList = users;
  }

  public async saveGroup(agentRef: string, companyRef: string, group: BeneficiaryGroup) {
    const pathToCollection = PathTo.groups(agentRef, companyRef);
    if (group.ref) {
      await this.setMergeObjectByRef(`${pathToCollection}/${group.ref}`, group);
      return group.ref;
    }
    const docId = await this.addObjectToCollection(pathToCollection, group)
    this.compnyOrderGroups.push({
      ref: docId,
      ...group
    });
    this.messenger.parcel.next({
      action: actions.groupChange,
      data: this.compnyOrderGroups
    });
    return docId;
  }

  public listenToGroups(agentRef: string, companyRef: string) {
    return this.db.collection<BeneficiaryGroup>(PathTo.groups(agentRef, companyRef)).valueChanges();
  }

  public async getGroups(agentRef: string, companyRef: string) {
    const groups: BeneficiaryGroup[] = await this.listItemsFromCollection<BeneficiaryGroup>(PathTo.groups(agentRef, companyRef));
    this.compnyOrderGroups = groups;
    return groups;
  }
  public async getObjectsList() {
    if (!this.objectsList || !Object.keys(this.objectsList).length) {
      await this.listFormObjects();
    }
    return this.objectsList;
  }

  public async checkIfAlreadyInvited(agentRef, companyRef, email) {
    return await this.getOneDocByRef<Treezor.AppUser>(PathTo.invitee(agentRef, companyRef, email));
  }

  /**
   * To be used by admin accounts only and account managers
   */
  public async getInviteesUsersForCompany(agentRef: string, companyRef: string): Promise<Treezor.AppUser[]> {
    if (this.appUserList && this.appUserList.length) {
      return this.appUserList;
    }
    this.appUserList = [];
    const inviteeDocs = await this.db.collection(PathTo.invitees(agentRef, companyRef))
    .get().toPromise();
    if (!inviteeDocs.empty) {
      inviteeDocs.forEach(doc => {
        this.appUserList.push({
          ...doc.data() as Treezor.AppUser,
          ref: doc.id
        });
      });
    }
    return this.appUserList;
  }

  public getInviteesChanges(agentRef: string, companyRef: string) {
    return this.db.collection<Treezor.AppUser>(PathTo.invitees(agentRef, companyRef)).valueChanges();
  }

  public async createAgentUser(user: Treezor.AppUser): Promise<boolean> {
    if (!user.agentRef || !user.email) {
      throw 'Missing critical data [agentRef or email]';
    }
    try {
      user.email = user.email.toLowerCase();
      await this.db.doc(PathTo.agentAdmin(user.agentRef, user.email)).set(user);
      return true;
    } catch (e) {
      console.error('createAppUser could not create AppUser', e);
      throw 'User already exists';
    }
  }

  public async getCompanies(): Promise<Treezor.User.Definition[]> {
    if (this.companies.length) {
      return this.companies;
    }
    this.companies = await this.listItemsFromCollection<Treezor.User.Definition>(PathTo.companies(this.currentAppUser.agentRef));
    this.listenToCompanyChanges();
    return [...this.companies];
  }

  public async sa_getCompanies(agentRef: string): Promise<Treezor.User.Definition[]> {
    return this.listItemsFromCollection<Treezor.User.Definition>(PathTo.companies(agentRef));

  }

  private listenToCompanyChanges() {
    this.db.collection<Treezor.User.Definition>(PathTo.companies(this.currentAppUser.agentRef)).valueChanges()
    .subscribe(
      data => {
        this.companies = data;
        this.messenger.parcel.next({
          action: actions.companies,
          data: [...this.companies]
        });
      }
    )
  }

  public async getAllKybsToReview(isKyb = true): Promise<Kyb[] | Kyc[]> {
    let path = isKyb ? PathTo.kybs() : PathTo.kycs();
    if (this.currentAppUser.role === appRoles.agent) {
      path = `${PathTo.agent(this.currentAppUser.agentRef)}/${path}`;
    }
    const kyxDocs = await this.db.firestore
    .collection(path)
    .where('status', 'in', [kybStatus.kyb_form_completed_by_user, kybStatus.kyb_rejected_by_treezor])
    .get();
    if (kyxDocs && !kyxDocs.empty) {
      const kyxs = [];
      kyxDocs.forEach(doc => {
        kyxs.push({
          ...doc.data(),
          ref: doc.id,
          source: 'user'
        });
      });
      return kyxs;
    }
    return [];
  }

  public listenToKybChanges(isKyb = true) {
    let path = isKyb ? PathTo.kybs() : PathTo.kycs();
    if (this.currentAppUser.role === appRoles.agent) {
      path = `${PathTo.agent(this.currentAppUser.agentRef)}/${path}`;
    }
    // console.log(path);
    return this.db.firestore
    .collection(path)
    .where('status', '!=', kybStatus.kyb_validate_by_treezor);
  }


  public async getKyb(agentRef: string, userCompanyRef: string): Promise<Kyb> {
    let path = PathTo.kybs();
    const kyb = await this.getItemByNameFromCollection<Kyb>(path, userCompanyRef);
    if (!kyb) {
        const newKyb: Kyb = {
          business_beneficiaries: [],
          physical_beneficiaries: [],
          physical_rl: [],
          business_rl: [],
          equityDocument: [],
          kbis: [],
          status: kybStatus.kyb_not_submitted,
          createdAt: moment().local().format('YYYY-MM-DD HH:mm'),
          createdBy: this.currentAppUser.email,
          companyName: this.currentCompany ? this.currentCompany.legalName : '',
          source: 'user',
          legalSector: this.currentCompany.legalSector,
          phone: this.currentCompany.phone,
          legalNetIncomeRange: this.currentCompany.legalNetIncomeRange,
          agentRef
        };
        await this.saveKyb(newKyb, userCompanyRef);
        return newKyb;
    }
    return kyb;
  }

  public getKybStateChanges(userCompanyRef: string) {
    return this.db.doc<Kyb>(`${PathTo.kybs()}/${userCompanyRef}`);
  }

  public async createKyb(kyb: Partial<Kyb>, ref: string) {
    if (!kyb || !ref) {
      throw 'No Kyb to create';
    }
    await this.createOneDocByRef(`${PathTo.kybs()}/${ref}`, kyb);
  }

  public async saveKyb(kyb: Kyb, userCompanyRef: string) {
    if (!kyb) {
      return;
    }
    await this.db.doc(`${PathTo.kybs()}/${userCompanyRef}`).set(kyb);
  }

  public async updateKyb(kyb: Kyb) {
    await this.db.doc(`${PathTo.kybs()}/${kyb.ref}`).update(kyb);
  }

  // Employee Functions

  public async saveKyc(kyc: Partial<Kyc>) {
    if (!kyc || this.currentAppUser.role !== appRoles.superadmin) {
      return;
    }
    await this.db.doc(`${PathTo.kycs()}/${kyc.ref}`).update(kyc);
  }

  public async getKyc(uuid: string): Promise<Kyc> {
    const kyc = await this.getOneDocByRef<Kyc>(`kyc/${uuid}`);
    this.messenger.parcel.next({
      action: actions.kyc,
      data: kyc
    });
    if (kyc.status !== kybStatus.kyb_validate_by_treezor) {
      const sub = this.db.doc<Kyc>(`kyc/${uuid}`).valueChanges().subscribe(
        el => {
          this.messenger.parcel.next({
            action: actions.kyc,
            data: kyc
          });
          if (el.status === kybStatus.kyb_validate_by_treezor) {
            sub.unsubscribe();
          }
        }
      )
    }
    return kyc;
  }

  public getKycRef(uuid: string) {
    return this.db.doc<Kyc>(`kyc/${uuid}`);
  }

  public async getUserCard(): Promise<Treezor.Card.Definition> {
    const {agentRef, companyRef, uid} = this.currentAppUser;
    const cards = await this.listItemsFromCollection<Treezor.Card.Definition>(PathTo.employeeCards(agentRef, companyRef, uid));
    // const docs = await this.db.collection().get().toPromise();
    if (!cards.length) {
      return null;
    }
    const expired = (card: Treezor.Card.Definition) => moment().isAfter(moment(card.expiryDate, 'YYYY-MM-DD'));
    const activeCards = cards.filter(el => [
      Treezor.Card.StatusCode.STOLEN,
      Treezor.Card.StatusCode.LOST,
      Treezor.Card.StatusCode.DESTROYED
    ].indexOf(el.statusCode) === -1 && !expired(el));
    if (activeCards.length) {
      return activeCards[0];
    }
    return null;
  }

  public getUserCardChanges(cardId: number) {
    const {agentRef, companyRef, uid} = this.currentAppUser;
    return this.db.doc<Treezor.Card.Definition>(`${PathTo.employeeCards(agentRef, companyRef, uid)}/${cardId}`).valueChanges();
  }

  public async getEmployeeTransactions(start: string, expenseline: string): Promise<Treezor.Card.CardTransaction[]> {
    const transactions: Treezor.Card.CardTransaction[] = [];
    const {agentRef, companyRef, uid} = this.currentAppUser;
    const docs = await this
    .db
    .firestore
    .collection(PathTo.employeeTransactions(agentRef, companyRef, uid))
    .where(
      'productName', '==', expenseline
    )
    .orderBy('authorizationIssuerTime', 'desc')
    .startAfter(start)
    .limit(20)
    .get();
    if (docs.empty) {
      return transactions;
    }

    docs.forEach(doc => {
      transactions.push({
        ...doc.data() as Treezor.Card.CardTransaction,
        ref: doc.id
      });
    });
    return transactions;
  }

  public async getOneEmployeeExpenseLine(expenseLineRef: string): Promise<ExpenseLine> {
    const {agentRef, companyRef, uid} = this.currentAppUser;
    return await this.getOneDocByRef<ExpenseLine>(PathTo.employeeExpenseLine(agentRef, companyRef, uid, expenseLineRef));
  }

  public async getUserExpenseLine() {
    if (this.employeeExpenLines.length) {
      return [...this.employeeExpenLines];
    }
    const {agentRef, companyRef, uid} = this.currentAppUser;
    const expl = await this.listItemsFromCollection<ExpenseLine>(PathTo.employeeExpenseLines(agentRef, companyRef, uid));
    if (!expl.length) {
      return [];
    }
    this.employeeExpenLines = expl.filter(d =>
      [roadmateProducts.FMD, roadmateProducts.DP, roadmateProducts.CM].indexOf(d.productName as roadmateProducts) > -1 &&
      [ExpenseLineStatus.Available, ExpenseLineStatus.Active, ExpenseLineStatus.Reliquat].indexOf(d.status) > -1 
    );
    this.getExpenseLineChange();
    return this.employeeExpenLines;
  }

  private getExpenseLineChange() {
    if (!this.currentAppUser) {
      return [];
    }
    const {agentRef, companyRef, uid} = this.currentAppUser;
    return this.db.collection<ExpenseLine>(PathTo.employeeExpenseLines(agentRef, companyRef, uid))
    .valueChanges()
    .subscribe(
      data => {
        if (!data || !data.length) {
          return;
        }
        this.employeeExpenLines = data.filter(d => 
          [roadmateProducts.FMD, roadmateProducts.DP, roadmateProducts.CM].indexOf(d.productName as roadmateProducts) > -1 &&
          [ExpenseLineStatus.Available, ExpenseLineStatus.Active, ExpenseLineStatus.Reliquat].indexOf(d.status) > -1 
        );
        this.messenger.parcel.next({
          action: actions.expenselines,
          data: [...this.employeeExpenLines]
        });
      }
    );
  }

  public async getUserBalanceList() {
    const {agentRef, companyRef, uid} = this.currentAppUser;
    return await this.listItemsFromCollection<UserBalance>(PathTo.employeeBalances(agentRef, companyRef, uid));
  }

  public async getEmployeeExpenseLine() {
    if (this.employeeExpenLines.length) {
      return [...this.employeeExpenLines];
    }
    const {agentRef, companyRef, uid} = this.currentAppUser;
    const expl = await this.listItemsFromCollection<ExpenseLine>(PathTo.employeeExpenseLines(agentRef, companyRef, uid));
    if (!expl.length) {
      return [];
    }
    this.employeeExpenLines = expl.filter(d => d.status === ExpenseLineStatus.Active || d.status === ExpenseLineStatus.Available);
    // this.getExpenseLineChange();
    return this.employeeExpenLines;
  }

  public async getWhiteListe(whiteListRef: string) {
    const {agentRef, companyRef} = this.currentAppUser;
    return await this.getOneDocByRef<TransportWhiteList>(PathTo.companyWhitList(agentRef, companyRef, whiteListRef));
  }

  public async getGroup(groupRef: string) {
    const {agentRef, companyRef} = this.currentAppUser;
    return await this.getOneDocByRef<BeneficiaryGroup>(PathTo.group(agentRef, companyRef, groupRef));
  }

  public async getMerchantByRef(ref: string) {
    return await this.getOneDocByRef<Merchant>(PathTo.merchant(ref));
  }

  public listenToTransactionChange() {
    const {agentRef, companyRef, uid} = this.currentAppUser;
    return this.db.collection<Treezor.Card.CardTransaction>(PathTo.employeeTransactions(agentRef, companyRef, uid)).valueChanges();
  }

  public async getAppUser(email: string): Promise<Treezor.AppUser> {
    return await this.getOneDocByRef<Treezor.AppUser>(PathTo.appUser(email));
  }

  public async getCurrentAppUser(email: string): Promise<Treezor.AppUser> {
    const appUser: Treezor.AppUser = await this.getOneDocByRef<Treezor.AppUser>(PathTo.appUser(email));
    if (appUser) {
      this.currentAppUser = appUser;
      switch(appUser.role) {
        case appRoles.employee:
        case appRoles.admin: {
          const poms = [];
          if (this.currentAppUser.userId) {
            poms.push(this.getCurrentEmployee());
          }
          poms.push(this.getAgent(this.currentAppUser.agentRef));
          poms.push(this.getGroups(this.currentAppUser.agentRef, this.currentAppUser.companyRef));
          await Promise.all(poms);
          break;
        }
        case appRoles.agent:
        case appRoles.accountManager:
          await this.getAgent(this.currentAppUser.agentRef);
          break;
        case appRoles.superadmin:
          // this.listenToAlerts();
          break;
      }
    }
    return appUser;
  }

  public async saveAttestationReference(url: string, product: 'FMD'|'CM'|'DP', type: 'inhouse' | 'yousign' = 'inhouse', status: 'pending' | 'completed' | 'failed' = 'pending', details='') {
    const {agentRef, companyRef, uid} = this.currentAppUser;
    const year = (new Date()).getFullYear();
    const attestation: Attestation = {
      createdAt: (new Date()).toISOString(),
      product,
      url,
      year,
      type,
      status,
      details
    };
    const poms = [];
    if (this.currentAppUser.attestations?.length) {
      const old = this.currentAppUser.attestations.find(el => el.year === attestation.year && el.product === product);
      if (old) {
        old.url = url;
      } else {
        this.currentAppUser.attestations.push(attestation);
      }
      poms.push(
        this.setMergeObjectByRef<Treezor.AppUser>(PathTo.appUser(this.currentAppUser.email), {
          attestations: this.currentAppUser.attestations
        })
      );
    } else {
      poms.push(
        this.setMergeObjectByRef<Treezor.AppUser>(PathTo.appUser(this.currentAppUser.email), {
          attestations: [attestation]
        })
      );
      this.currentAppUser.attestations = [attestation];
    }
    poms.push(
      this.setMergeObjectByRef<any>(
        PathTo.attestation(agentRef, companyRef, `${year}`, uid),
        attestation
      )
    );
    await Promise.all(poms);
    return attestation;
  }

  public async getMidByRef(mid: string) {
    return await this.getOneDocByRef<Mid>(PathTo.mid(mid));
  }

  public async getInvoiceByPaymentId(paymentId: string) {
    const {agentRef, companyRef} = this.currentAppUser;
    return await this.getOneDocByRef<EmployeeInvoice>(`${PathTo.companyEmployeeInvoices(agentRef, companyRef)}/${paymentId}`);
  }

  public async getikvRequest(ref: string) {
    const {agentRef, companyRef, uid} = this.currentAppUser;
    const path = PathTo.employeeIkvRequest(agentRef, companyRef, uid, ref);
    const ikvRequest = this.getOneDocByRef<IKV>(path);
    return ikvRequest;
  }

  public async getRefundRequest(ref: string): Promise<IKV> {
    const {agentRef, companyRef, uid} = this.currentAppUser;
    return this.getOneDocByRef<IKV>(PathTo.employeeRefundRequest(agentRef, companyRef, uid, ref));
  }

  public async getTripByPaymentId(paymentId: string) {
    const {agentRef, companyRef, uid} = this.currentAppUser;
    return await this.getItemFromCollectionWithCondition<RoadMateTrip>(
      PathTo.employeeTrips(agentRef, companyRef, uid),
      'paymentId',
      paymentId
    );
  }

  public createRefundRequest(request: RoadMateRefundRequest) {
    const {agentRef, companyRef, uid} = this.currentAppUser;
    return this.addObjectToCollection(PathTo.employeeRefundRequests(agentRef, companyRef, uid), request);
  }

  public saveIKVRequest(ikv: IKV) {
    const {agentRef, companyRef, uid} = this.currentAppUser;
    return this.addObjectToCollection(
      PathTo.employeeIkvRequests(agentRef, companyRef, uid),
      ikv
    );
  }

  public async removeFileFromInvoice(invoice: EmployeeInvoice, file: RoadMateFile) {
    // This will only delete the reference to the file.
    // TODO remove file from storage also.
    const {agentRef, companyRef} = this.currentAppUser;
    await this.setMergeObjectByRef<EmployeeInvoice>(PathTo.companyEmployeeInvoice(agentRef, companyRef, invoice.ref), {
      files: invoice.files.filter(f => f.url !== file.url)
    });
  }

  public listenToIkvRequests() {
    const {agentRef, companyRef, uid} = this.currentAppUser;
    return this.listenToItemsInCollection<IKV>(PathTo.employeeIkvRequests(agentRef, companyRef, uid));
  }

  public listenToRefundRequests() {
    const {agentRef, companyRef, uid} = this.currentAppUser;
    return this.listenToItemsInCollection<RoadMateRefundRequest>(PathTo.employeeRefundRequests(agentRef, companyRef, uid));
  }

  public getRefundRequests() {
    const {agentRef, companyRef, uid} = this.currentAppUser;
    return this.listItemsFromCollection<RoadMateRefundRequest>(PathTo.employeeRefundRequests(agentRef, companyRef, uid));
  }

  public getRefundRequestsForUser(email: string) {
    return this.listItemsFromCollectionWithCondition<RoadMateRefundRequest>(
      PathTo.refundRequests(),
      'email',
      email
    );
  }

  public getIKVRequests() {
    const {agentRef, companyRef, uid} = this.currentAppUser;
    return this.listItemsFromCollection<IKV>(PathTo.employeeIkvRequests(agentRef, companyRef, uid));
  }

  public saveBeneficiaryBankAccount(beneficiary: Treezor.Beneficiary.Definition) {
    const {agentRef, companyRef, uid} = this.currentAppUser;
    return this.addObjectToCollection(PathTo.employeeBankBeneficiaries(agentRef, companyRef, uid), beneficiary);
  }

  public async getBeneficiaryBankAccount() {
    const {agentRef, companyRef, uid} = this.currentAppUser;
    return this.listItemsFromCollection<Treezor.Beneficiary.Definition>(PathTo.employeeBankBeneficiaries(agentRef, companyRef, uid));
  }

  public listenToBeneficiaryChange(ref: string) {
    const {agentRef, companyRef, uid} = this.currentAppUser;
    const path = PathTo.employeeBankBeneficiaries(agentRef, companyRef, uid);
    return this.db.doc<Treezor.Beneficiary.Definition>(`${path}/${ref}`).valueChanges();
  }

  public async deleteBeneficiary(ref: string) {
    const {agentRef, companyRef, uid} = this.currentAppUser;
    const path = PathTo.employeeBankBeneficiaries(agentRef, companyRef, uid);
    await this.db.doc<Treezor.Beneficiary.Definition>(`${path}/${ref}`).delete();
  }

  // END Employee Functions

  public async switchtoAgent(agentRef: string) {
    await this.getAgent(agentRef);
  }

  private async getAgent(agentRef: string) {
    this.currentAgent = await this.getOneDocByRef<Agent>(PathTo.agent(agentRef));
    if (this.currentAgent) {
      this.messenger.parcel.next({
        action: actions.agent,
        data: this.currentAgent
      });
    }
  }

  public async getCurrentEmployee(forceRefresh = false) {
    if (this.currentEmployee && !forceRefresh) {
      return this.currentEmployee;
    }
    const {agentRef, companyRef, uid} = this.currentAppUser;
    this.currentEmployee = await this.getOneDocByRef<Treezor.User.EmployeeProfile>(PathTo.employee(agentRef, companyRef, uid));
    return this.currentEmployee;
  }

  public async getCompanyByRef(agentRef: string, companyRef: string): Promise<Treezor.User.Definition> {

    if (!agentRef) {
      throw 'Agent Ref not selected';
    }
    const company: Treezor.User.Definition = await this.getOneDocByRef<Treezor.User.Definition>(
      PathTo.company(agentRef, companyRef)
    );
    return company;
  }
  public async getLists(agentRef: string, companyRef: string) {
    try {
      const path = PathTo.companyLists(agentRef, companyRef);
      const lists = await this.listItemsFromCollection<RelationList>(path);
      if (this.lists.length) {
        lists.forEach(list => {
          const index = this.lists.findIndex(el => el.name === list.name);
          if (index > -1) {
            this.lists[index] = list;
          } else {
            this.lists.push(list);
          }
        });
        // for (const key of Object.keys(lists)) {
        //   this.lists[key] = lists[key];
        // }
      } else {
        this.lists = lists;
      }
    } catch (e) {
      console.error(e);
    }
  }

  public async getAgentLists(agentRef: string) {
    this.agentlists = await this.listItemsFromCollection<RelationList>(PathTo.agentLists(agentRef));
  }

  public async getSALists() {
    this.salists = await this.listItemsFromCollection<RelationList>(Collections.lists);
    this.lists = this.salists;
  }

  public async getOrderSettings (): Promise<BeneficiaryGroup[]> {
    if (this.currentAppUser.role !== appRoles.admin) {
      return;
    }
    if (this.compnyOrderGroups && this.compnyOrderGroups.length) {
      return this.compnyOrderGroups;
    }
    const {agentRef, companyRef} = this.currentAppUser;
    const groups: BeneficiaryGroup[] = await this.listItemsFromCollection<BeneficiaryGroup>(PathTo.groups(agentRef, companyRef));
    this.compnyOrderGroups = groups;
    return groups;
  }

  public async getOneList(name: string, level: listLevel, agentRef?: string, companyRef?: string) {
    switch (level) {
      case listLevel.sa: {
        if (!this.salists.length) {
          await this.getSALists();
        }
        const item = this.salists.find(el => el.name === name);
        if (!item) {
          return [];
        }
        return JSON.parse(JSON.stringify(item.list));
      }
      case listLevel.agent: {
        if (!agentRef) {
          throw error_messages.MISSING_AGENT_REF;
        }
        if (!this.agentlists.length) {
          await this.getAgentLists(agentRef);
        }
        const item = this.agentlists.find(el => el.name === name);
        if (!item) {
          return [];
        }
        return JSON.parse(JSON.stringify(item.list));
      }
      case listLevel.company: {
        if (!agentRef || !companyRef) {
          throw error_messages.MISSING_COMPANY_REF;
        }
        if (!this.lists.length) {
          await this.getLists(agentRef, companyRef);
        }
        const item = this.lists.find(el => el.name === name);
        if (!item) {
          return [];
        }
        return JSON.parse(JSON.stringify(item.list));
      }
    }
  }

  public async listFormObjects() {
    const result = await this.db.collection<FormDefinition>(`${Collections.objects}`).get().toPromise();
    if (result && result.empty) {
      return;
    }
    result.forEach((item)  => {
      this.objectsList[item.id] = item.data();
    });
  }

  public getObjectDefinitionByRef(ref: string): FormDefinition {
    if (this.objectsList[ref]) {
      const object = JSON.parse(JSON.stringify(this.objectsList[ref]));
      return object;
    }
    throw new Error('Object Not Defined');
  }

  public async createObjectInGivenCollection(collectionName: string, object: any) {
    try {
      await this.addObjectToCollection(collectionName, object);
      return true;
    } catch (e) {
      console.error('Could not create new object: ', object, ' in Collection: ', collectionName, e);
      return false;
    }
  }

  public async saveNewObject(objectName: string, obj: any) {
    try {
      const ref = this.db.doc(`${Collections.objects}/${objectName}`);
      await ref.set(obj, {merge: true});
      return true;
    } catch (e) {
      console.error('Could not create obj', obj,  e);
      return false;
    }
  }

  public async updateIntegration(
    agentRef: string,
    companyRef: string,
    integration: AvailableIntegration
  ) {
    if (!integration?.ref) {
      return;
    }
    await this.setMergeObjectByRef<AvailableIntegration>(
      PathTo.companyAvailableIntegration(agentRef, companyRef, integration.ref),
      integration
    );
  }

  public async getAllCashFlows() {
    return await this.listItemsFromCollection<CompanyCashflow>(Collections.cashflows);
  }

  public async getAllCashFlowsForWithCondition(property: string, value: any) {
    return await this.listItemsFromCollectionWithCondition<CompanyCashflow>(Collections.cashflows, property, value);
  }

  //----------AM Functions
  public getAMCompanies() {
    return this.getCompanies();
  }
  //----------End of AM Functions

  private async setMergeObjectByRef<T>(docRef: string, object: Partial<T>) {
    try {
      (object as CollectionItem).deviceType = 'dashboard';
      (object as CollectionItem).version = RoadMateEnvironmentNameSpace.dashVersion;
      (object as TimedItem).updatedAt = moment().local().format('YYYY-MM-DD HH:mm:ss');
      await this.db.doc(docRef).set(object, {merge: true});
      return true;
    } catch (e) {
      // console.log(e);
      return false;
    }
  }

  private async createOneDocByRef<T>(ref: string, data: T) {
    (data as CollectionItem).createdByDevice = 'dashboard';
    (data as CollectionItem).createdByVersion = RoadMateEnvironmentNameSpace.dashVersion;
    (data as TimedItem).createdAt = moment().local().format('YYYY-MM-DD HH:mm:ss');
    await this.db.doc(ref).set(data);
    return true;
  }

  private async addObjectToCollection<T>(collection: string, object:T) {
    try {
      (object as CollectionItem).createdByDevice = 'dashboard';
      (object as CollectionItem).createdByVersion = RoadMateEnvironmentNameSpace.dashVersion;
      (object as TimedItem).createdAt = moment().local().format('YYYY-MM-DD HH:mm:ss');
      const doc = await this.db.collection(collection).add(object);
      await this.setMergeObjectByRef(`${collection}/${doc.id}`, {ref: doc.id});
      return doc.id;
    } catch (e) {
      console.error('Could not create new object: ', object, ' in Collection: ', collection, e);
      return '';
    }
  }

  private async getItemByNameFromCollection<T>(collectionName: string, itemName: string): Promise<T> {
    const doc = await this.db.doc(`${collectionName}/${itemName}`).get().toPromise();
    if (!doc.exists) {
      return null;
    }
    const item: T = {
      ...doc.data() as T,
      ref: doc.id
    };
    return item;
  }

  private async getOneDocByRef<T>(ref: string) {
    const doc = await this.db.doc(ref).get().toPromise();
    if (!doc.exists) {
      return null;
    }
    const item: T = {
      ...doc.data() as T,
      ref: doc.id
    };
    return item;
  }

  private async getItemFromCollectionWithCondition<T>(collectionName: string, field: string, value: any): Promise<T> {
    const docs = await this.db.firestore.collection(`${collectionName}`).where(field, '==', value).get();
    if (docs.empty) {
      return null;
    }
    const results: T[] = [];
    docs.forEach(doc => {
      results.push({
        ...doc.data() as T,
        ref: doc.id
      });
    });
    return results.pop();
  }

  private listenToItemsInCollection<T>(collectionName: string) {
    return this.db.collection<T>(collectionName).valueChanges();
  }

  private async deleteOneItemByRef(path: string): Promise<void> {
    await this.db.doc(`${path}`).delete();
  }

  private async listItemsFromCollection<T>(collectionName: string): Promise<T[]> {
    const docs = await this.db.collection<T>(`${collectionName}`).get().toPromise();
    if (docs && docs.empty) {
      return [];
    }
    const items: T[] = [];
    docs.forEach((item)  => {
      items.push({
        ...item.data() as T,
        ref: item.id});
    });
    return items;
  }

  private getCollectionValueChange(collection: string) {
    return this.db.collection(collection).valueChanges();
  }

  private getCollectionValueChangeWithCondition<T>(
    collection: string,
    property: string,
    value: any,
    condition: firebase.firestore.WhereFilterOp = '=='
  ) {
    return new Observable<T[]>(subscriber => {
      this.db.firestore.collection(collection)
      .where(
        property,
        condition,
        value
      ).onSnapshot(next => {
          const dataSet: T[] = next.docs.map(doc => {
            return {
              ref: doc.id,
              ...doc.data() as T
            }
          });
          subscriber.next(dataSet);
      });
    });
  }

  private async listItemsFromCollectionWithCustomCondition<T>(
    collectionName: string,
    property: string,
    operator: any,
    value: any
  ): Promise<T[]> {
    const docs = await this.db.firestore
    .collection(`${collectionName}`)
    .where(property, operator, value)
    .get();
    if (docs && docs.empty) {
      return [];
    }
    const items: T[] = [];
    docs.forEach((item)  => {
      items.push({
        ...item.data() as T,
        ref: item.id});
    });
    return items;
  }

  private async listItemsFromCollectionWithCondition<T>(
    collectionName: string,
    property: string,
    value: any
  ): Promise<T[]> {
    const docs = await this.db.firestore
    .collection(`${collectionName}`)
    .where(property, '==', value)
    .get();
    if (docs && docs.empty) {
      return [];
    }
    const items: T[] = [];
    docs.forEach((item)  => {
      items.push({
        ...item.data() as T,
        ref: item.id});
    });
    return items;
  }

}
