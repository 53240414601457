import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
@Pipe({ name: 'rm_date' })
export class DatePipe implements PipeTransform {
  transform(value: any, dateOnly = false): any {
    const time = moment(value);
    if (time) {
      // if (navigator && navigator.language && navigator.language.indexOf('fr') === -1) {
      //   if (!dateOnly) {
      //     return moment(value).local().format('MMM Do YYYY, h:mm a');
      //   }
      //   return moment(value).local().format('MMM Do YYYY');
      // }
      if (!dateOnly) {
        return moment(value).local().format('Do MMM YYYY à HH:mm');
      }
      return moment(value).local().format('Do MMM YYYY');
    }
    return value;
  }
}
