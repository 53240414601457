import { Component, Input, OnInit } from '@angular/core';
import { FireStoreService } from '../../../services/fire.store';
import { EmployeeInvoice } from '@roadmate/roadmate-common';

@Component({
  selector: 'rm-employee-invoice-render',
  template: `
    <a 
      *ngFor="let item of value.files; let i = index"
      [href]="item.url" target="_blank"
    >
      Document {{i + 1}}
    </a>
  `,
})
export class EmployeeInvoiceRenderComponent implements OnInit {
  @Input() value: EmployeeInvoice;
  @Input() listName: string;
  public displayedValue: string;
  constructor(private fs: FireStoreService) {

  }

  ngOnInit() {
    
  }

}
