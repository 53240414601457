import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'truncate' })
export class TruncPipe implements PipeTransform {
  transform(value: string, allow: number, nodots = true, reverse = false): string {
    if (value && value.length && value.length > allow) {
      if (reverse) {
        return `${nodots ? '' : '...'}${value.substring(value.length - allow, value.length)}`; 
      }
      return `${value.substring(0, allow)}${nodots ? '' : '...'}`;
    }
    return value;
  }
}
