<div class="modal-dialog"
  [ngClass]="{
    'modal-warning': mode === 'warning',
    'modal-success': mode === 'success',
    'modal-primary': mode === 'primary',
    'modal-danger': mode === 'danger',
    'regular': size === 'regular',
    'small': size === 'small'
  }"
>
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title">{{modalTitle | translate}} <span *ngIf="headerSuffix">({{headerSuffix}})</span></h4>
      <button class="rm-close-white" type="button" (click)="close(false)">
        <i class="cil-x-circle"></i>
      </button>
    </div>
    <div class="card-body">
      <ng-template modal-holder></ng-template>
    </div>
    <div class="modal-footer" *ngIf="!dismissFooter">
      <button class="btn btn-secondary" type="button" (click)="close(false)">
        {{'cancel' | translate}}
      </button>
      <button *ngIf="actionlbl" class="btn btn-{{mode}}" type="button"  (click)="close(true)">
        <span>
          {{actionlbl | translate}}
        </span>
      </button>
    </div>
  </div>
</div>

