import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'rm_add_prefix'})
export class AddPrefixPipe implements PipeTransform {
  transform(value: string, prefix: string): any {
    if (!value) {
      return '';
    }
    return `${prefix}${value}`;
  }
}