
<div class="app-body">
  <main class="main d-flex align-items-center">
    <div [ngClass]="{'container': !fullFrame, 'full-width': fullFrame}">
      <div class="row align-items-center">
        <div class="col-md-12 mx-auto">
          <div class="card" [ngClass]="{'no-padding': !bodyPadding}">
            <div *ngIf="!hideHeader" class="card-header"><ng-content select="mat-card-title"></ng-content></div>
            <div [ngClass]="{'card-body': bodyPadding, 'no-overflow': !bodyPadding}">
              <ng-content select="mat-card-content"></ng-content>
            </div>
            <div class="card-footer"><ng-content select="mat-card-footer"></ng-content></div>
          </div>
        </div>
      </div>
    </div>
  </main>
</div>


