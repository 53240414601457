import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DropDownListOption, GroupedDDL } from '@roadmate/roadmate-common';


@Component({
  selector: 'rm-grouped-multi-select',
  templateUrl: './grouped-multi-select.component.html',
  styleUrls: ['./grouped-multi-select.component.scss']
})
export class GroupedMultiSelectComponent implements OnInit {
  @Input()
  groups: GroupedDDL[];
  @Input()
  placeholder: string;
  public showDDL = false;
  @Input()
  allowMultiple = true;
  @Input()
  public selectedItems: GroupedDDL[] = [];
  public selectedChips: DropDownListOption[] = [];
  @Output()
  public changeEmitter = new EventEmitter<GroupedDDL[]>();
  @ViewChild('ddl', { read: ElementRef, static: false })
  public ddl: ElementRef;
  @HostListener('document:click', ['$event'])
  documentClick(event: any): void {
    if (this.ddl && this.ddl.nativeElement &&  !this.ddl.nativeElement.contains(event.target)) {
      this.showDDL = false;
    }
  }
  constructor() {}

  ngOnInit(): void {
    if (!this.selectedItems) {
      this.selectedItems = [];
    }
    this.setSelectedChips();
  }

  private setSelectedChips() {
    this.selectedChips = [];
    this.selectedItems.forEach(el => {
      this.selectedChips = this.selectedChips.concat(el.ddl);
    });
    this.changeEmitter.emit(this.selectedItems);
  }

  public selectItem(event: Event, groupRef: string, orderRef: string) {
    if (event && event.stopPropagation) {
      event.stopPropagation();
    }
    const parent = this.selectedItems.find(el => el.value === orderRef);
    if (parent) {
      if (parent.ddl.find(el => el.value === groupRef)) {
        this.removeItem(null, groupRef);
        return;
      }
      parent.ddl = [];
      this.selectedItems = this.selectedItems.filter(el => el.ddl.length > 0);
      this.selectItem(null, groupRef, orderRef);
    } else {
      const order = this.groups.find(el => el.value === orderRef);
      const group = order ? order.ddl
      .find(item => item.value === groupRef) : null;
      if (order && group) {
        this.selectedItems.push({
          value: order.value,
          label: order.label,
          ddl: [group]
        });
        this.setSelectedChips();
      }
    }
  }

  public removeItem(event: Event, groupRef: string) {
    if (event && event.stopPropagation) {
      event.stopPropagation();
    }
    this.selectedItems.forEach(el => {
      el.ddl = el.ddl.filter(group => group.value !== groupRef);
    });
    this.selectedItems = this.selectedItems.filter(el => el.ddl.length > 0);
    this.setSelectedChips();
  }

}
