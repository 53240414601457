import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsComponent } from './reactive-forms.component';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { UploadInputModule } from '../upload-input/upload-input.module';
import { PipeModule } from '../pipes/pipe.module';
import { MigrationModule } from '../migration/migration.module';
import { CustomToolseModule } from '../custom-tools/custom-tools.module';
import { MultiSelectModule } from '../multi-select/multi-select.module';
import { ShouldHidePipe } from './custom-pipes/should-hide-field';


@NgModule({
  declarations: [ReactiveFormsComponent, ShouldHidePipe],
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    MigrationModule,
    UploadInputModule,
    PipeModule,
    CustomToolseModule,
    MultiSelectModule
  ],
  exports: [ReactiveFormsComponent],
  providers: [],
})
export class RMReactiveFormsModule {}
