<p>
  {{question | translate}}
</p>
<p *ngIf="dynamicValue">
  {{dynamicValue | translate}}. {{suffix | translate}}
</p>
<div class="rm-row" *ngIf="btns && btns.length">
  <button
    *ngFor="let btn of btns"
    [ngClass]="{'btn-secondary': !btn.action, 'btn-primary': btn.action}"
    class="btn"
    type="button"
    (click)="action(btn)"
  >
    {{btn.label | translate}}
  </button>
</div>
