import { Component, Input, OnInit } from '@angular/core';

import { DropDownListOption } from '@roadmate/roadmate-common';

@Component({
  selector: 'rm-multiselect-render',
  template: `
    {{renderValue}}
  `,
})
export class MultiSelectRenderComponent implements OnInit {

  renderValue: string;

  @Input() value: DropDownListOption[];
  @Input() rowData: any;

  ngOnInit() {
    this.renderValue = this.value?.join(', ') ?? '';
  }

}
