<div class="row">
  <div class="col-12">
    <h2>
      {{'card_transactions' | translate}}
    </h2>
  </div>
  <div class="col-12">
    <button 
      *ngIf="!cardTransactionsSource?.length"
      class="btn btn-primary"
      type="button"
      (click)="getUserCardTransactions()" 
      [disabled]="loading"
    >
      <span *ngIf="loading" class="c-loading-button-spinner spinner-border spinner-border-sm"></span>
      {{'get_transactions' | translate}}
    </button>
  </div>
  <div class="col-12" *ngIf="cardTransactionsSource?.length">
    {{'transaction_list'|translate}}
  </div>
  <div class="col-12">
    <ng2-smart-table
      *ngIf="cardTransactionsSettings && cardTransactionsSource"
      [settings]="cardTransactionsSettings"
      [source]="cardTransactionsSource"
    >
    </ng2-smart-table>
  </div>
</div>