import { Component, OnInit } from '@angular/core';
import { AbstractEntryComponent } from '../abstract.entry-component';
import { RoadMateFile, SupportConversation, SupportMessage,
  SupportMessageReactions, UserSituation, error_messages,
  fileStoringWorkflows } from '@roadmate/roadmate-common';
import { FireFunctionsService, FireStoreService } from '../../../services';
import { Observable } from 'rxjs';

@Component({
  selector: 'rm-conversation',
  templateUrl: './conversation.component.html',
  styleUrls: ['./conversation.component.scss']
})
export class ConversationComponent  extends AbstractEntryComponent<boolean> implements OnInit {
  public agentRef: string;
  public companyRef: string;
  private email: string;
  public conversation: SupportConversation;
  public messages: Observable<SupportMessage[]>;
  public messageText: string = '';
  public fileType = fileStoringWorkflows.support;
  public currentTab: 'messages' | 'situation' | 'viewCardTransactions' = 'messages';
  public userSituation: UserSituation;
  public loading: boolean = false;
  public cardTransactionsSource = [];
  public cardTransactionsSettings;
  public set data(value) {
    if (!value || !value.conversation?.ref) {
      throw error_messages.MISSING_PARAMETER;
    }
    this.conversation = value.conversation;
  }

  constructor(
    private fs: FireStoreService,
    private ff: FireFunctionsService
  ) {
    super();
  }

  ngOnInit(): void {
    this.agentRef = this.conversation.agentRef;
    this.companyRef = this.conversation.companyRef;
    this.email = this.conversation.email;
    if (!this.conversation || !this.conversation.ref || !this.agentRef || !this.companyRef || !this.email) {
      throw error_messages.MISSING_PARAMETER;
    }
    this.getAllMessages();
  }

  public setTab(tab: 'messages' | 'situation' | 'viewCardTransactions') {
    this.currentTab = tab;
    if (!this.userSituation) {
      this.getStatus();
    }
  }

  private async getStatus() {
    this.loading = true;
    try {
      const {agentRef, companyRef, email} = this.conversation;
      const response = await this.ff.adminGetUserStatus(agentRef, companyRef, email);
      this.userSituation = response.result;
    } catch (e) {
      console.error('', e);
    } finally {
      this.loading = false;
    }
  }

  private getAllMessages() {
    this.messages = this.fs.getMessagesFromConversation(this.conversation.uid, this.conversation.ref);
  }

  public async createMessageFromFile(data: {doc: RoadMateFile, fieldName: string}) {
    if (!data.doc?.url) {
      return;
    }
    const message: SupportMessage = {
      uid: this.fs.currentAppUser.uid,
      conversationRef: this.conversation.ref,
      message: '',
      file: data.doc,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      sender: 'agent',
      reaction: SupportMessageReactions.support_no_reaction,
      email: this.fs.currentAppUser.email
    };
    await this.fs.createSupportMessage(
      this.conversation.uid,
      this.conversation.ref,
      message
    );
  }

  public async sendMessage() {
    if (!this.messageText) {
      return;
    }
    const message: SupportMessage = {
      uid: this.fs.currentAppUser.uid,
      conversationRef: this.conversation.ref,
      message: this.messageText,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      sender: 'agent',
      reaction: SupportMessageReactions.support_no_reaction,
      email: this.fs.currentAppUser.email
    };
    await this.fs.createSupportMessage(
      this.conversation.uid,
      this.conversation.ref,
      message
    );
    this.messageText = '';
  }

  public async selectMessage(message: SupportMessage) {
    if (message.sender === 'agent') {
      return;
    }
    if (message.file?.url) {
      window.open(message.file.url, '_blank');
    }
    if (message.isRead) {
      return;
    }
    await this.fs.setMessageAsRead(
      this.conversation.uid,
      this.conversation.ref,
      message.ref
    );
  }

  public async closeConversation() {
    await this.fs.updateConversation(
      this.conversation.uid,
      this.conversation.ref,
      {
        status: 'resolved',
        updatedAt: new Date().toISOString()
      }
    );
    this.close(null);
  }
}
