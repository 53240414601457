import { Component, OnInit } from '@angular/core';
import { FormGroup, FormArray, FormControl } from '@angular/forms';
import { AbstractEntryComponent } from '../abstract.entry-component';

@Component({
  selector: 'rm-edit-kyx',
  templateUrl: './edit-kyx.component.html',
  styleUrls: ['./edit-kyx.component.scss']
})
export class EditKyxComponent extends AbstractEntryComponent<string[]> implements OnInit {
  public stringArrayForm: FormGroup;
  public kyxRef: string;
  constructor() {
    super();
  }

  public ngOnInit(): void {
    this.stringArrayForm = new FormGroup(
      {comments: new FormArray([])}
    );
  }

  public addItemToArray() {
    const list = this.stringArrayForm.get('comments') as FormArray;
    list.push(new FormControl(''));
  }

  public removeItemFormArray(index: number) {
    const list = this.stringArrayForm.get('comments') as FormArray;
    list.removeAt(index);
  }

  public save() {
    const list: string[] = this.stringArrayForm.value;
    this.close(list);
  }
}
