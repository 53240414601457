import { Component, OnInit } from '@angular/core';
import { appRoles, capitalize, DropDownListOption, error_messages, isEmail, ObjectDefList, Treezor } from '@roadmate/roadmate-common';
import { FireAuthService, FireFunctionsService, FireStoreService, listLevel, RMToasterService } from '../../../services';
import { AbstractEntryComponent } from '../abstract.entry-component';

enum Tabs {
  newuser = 'newuser',
  existinguser= 'existinguser'
}

@Component({
  selector: 'rm-add-new-am',
  templateUrl: './add-new-am.component.html',
  styleUrls: ['./add-new-am.component.scss']
})
export class AddNewAccountManagerComponent extends AbstractEntryComponent<boolean> implements OnInit {
  public currentTab: Tabs = Tabs.newuser;
  public allTabs = Tabs;
  public appUserDef;
  public isAccountManager = false;
  public isSuperAdmin = false;
  public agentCompaniesList: DropDownListOption[];
  public loading = false;
  public email = '';
  public set data(value) {
    if (!value) {
      return;
    }
    // this.selectedItems = value.selectedGroups ? value.selectedGroups : [];
  }
  constructor(
    private fs: FireStoreService,
    private ff: FireFunctionsService,
    private fa: FireAuthService,
    private toast: RMToasterService
  ) {
    super();
  }

  ngOnInit(): void {
    this.getFormDefinition().then(() => null);
    this.isAccountManager = this.fa.userRoles.accountmanager;
    this.isSuperAdmin = this.fa.userRoles.superadmin;
  }

  private async getFormDefinition() {
    const appUserDef = this.fs.getObjectDefinitionByRef(ObjectDefList.appusers);
    const copy = JSON.parse(JSON.stringify(appUserDef));
    delete copy.isAdmin;
    delete copy.site;
    delete copy.internalId;
    this.appUserDef = copy;
    this.isAccountManager = this.fa.userRoles.accountmanager;
    this.isSuperAdmin = this.fa.userRoles.superadmin;
    const agentCompaniesList = await this.fs.getOneList('companies', listLevel.agent, this.fs.currentAgent.ref);
    this.agentCompaniesList = agentCompaniesList.map(el => ({label: el.legalName, value: el.ref}));
  }

  public setTab(tab: Tabs) {
    this.currentTab = tab;
  }

  public async saveAppUser(appUser: Treezor.AppUser) {
    if (!this.isAccountManager && !this.isSuperAdmin) {
      this.toast.showGenericError(`Niveau d'accès insuffisant pour réaliser cette opération.`);
      return;
    }
    this.loading = true;
    const isOk = await this.ff.verifyUserExists(appUser.email);
    if (isOk.message !== error_messages.NOT_FOUND) {
      this.toast.showGenericError(`Cet utilisateur existe déjà. Veuillez utiliser l'onglet correspondant`);
      this.loading = false;
      return;
    }
    try {
      appUser.agentRef = this.fs.currentAgent.ref;
      appUser.createdAt = (new Date()).toISOString();
      appUser.role = appRoles.accountManager;
      appUser.firstname = capitalize(appUser.firstname);
      appUser.lastname =  capitalize(appUser.lastname);
      appUser.email = appUser.email.toLowerCase().trim();
      const res = await this.fs.createAgentUser(appUser);
      if (res) {
        this.toast.showSuccessToast(`Opération effectuée avec succès. ${appUser.title} ${appUser.lastname} doit désormais créer son compte. Un email lui sera envoyé par nos soins.`);
        this.close(true);
      } else {
        this.toast.showGenericError(`Cet utilisateur n'a pas pu être créé. Veuillez contacter le support.`);
        this.close(false);
      }
    } catch (e) {
      console.error('', e);
      this.toast.showGenericError(`Cet utilisateur n'a pas pu être créé. Veuillez contacter le support.`);
    } finally {
      this.loading = false;
    }
  }

  public async grantAccess() {
    if (!isEmail(this.email)) {
      this.toast.showGenericError(`L'adresse email saisie est incorrecte.`);
      return;
    }
    this.loading = true;
    try {
      const response = await this.ff.setCustomClaim(
        this.email,
        appRoles.accountManager,
        '',
        true,
        this.fs.currentAgent.ref
      );
      if (response.message === error_messages.OK) {
        this.toast.showSuccessToast(`L'opération s'est correctement déroulée.`);
        return;
      }
      this.toast.showGenericError();
      this.close(null);
    } catch (e) {
      console.error('', e);
    } finally {
      this.loading = false;
    }
    
  }
}
