import { Component, OnInit } from '@angular/core';
import { AbstractEntryComponent } from '../abstract.entry-component';
import { DropDownListOption, RoadMateFile, SupportConversation, SupportMessage, SupportMessageReactions, companySupportCategories, employeeSupportCotegories, fileStoringWorkflows } from '@roadmate/roadmate-common';
import { FireStoreService } from '../../../services';
import { Observable } from 'rxjs';
import * as moment from 'moment';

@Component({
  selector: 'rm-employee-conversation',
  templateUrl: './employee-conversation.component.html',
  styleUrls: ['./employee-conversation.component.scss']
})
export class EmployeeConversationComponent  extends AbstractEntryComponent<boolean> implements OnInit {
  public conversation: SupportConversation;
  public messages: Observable<SupportMessage[]>;
  private allMessages: SupportMessage[] = []; 
  public messageText: string = '';
  public category: string;
  public subject = '';
  public loading = false;
  public cataegories: DropDownListOption[] = [];
  public fileType = fileStoringWorkflows.support;
  public companyRef: string;
  public agentRef: string;
  public selectedMessage: string;
  public currentReaction: SupportMessageReactions;
  public reactions = SupportMessageReactions;
  private subs = [];
  public set data(value) {
    if (value.role === 'employee') {
      this.cataegories = Object.keys(employeeSupportCotegories).map(key => {
        return {
          value: key,
          label: employeeSupportCotegories[key]
        };
      });
    } else {
      this.cataegories = Object.keys(companySupportCategories).map(key => {
        return {
          value: key,
          label: companySupportCategories[key]
        };
      });
    }
    if (!value || !value.conversation?.ref) {
      return;
    }
    this.conversation = value.conversation;
  }

  constructor(
    private fs: FireStoreService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.agentRef = this.fs.currentAppUser.agentRef;
    this.companyRef = this.fs.currentAppUser.companyRef;
    if (this.conversation?.ref) {
      this.getAllMessages();
    }
  }

  public selectCategory(item: DropDownListOption) {
    this.category = item.value;
  }

  public async createMessageFromFile(data: {doc: RoadMateFile, fieldName: string}) {
    if (!data.doc?.url) {
      return;
    }
    const message: SupportMessage = {
      uid: this.fs.currentAppUser.uid,
      conversationRef: this.conversation.ref,
      message: '',
      file: data.doc,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      sender: 'user',
      reaction: SupportMessageReactions.support_no_reaction,
      email: this.fs.currentAppUser.email
    };
    await this.fs.createSupportMessage(
      this.conversation.uid,
      this.conversation.ref,
      message
    );
  }

  public async reactToMessageWith(reaction: SupportMessageReactions) {
    if (!this.selectedMessage && this.allMessages.length && this.conversation.lastSender === 'agent') {
      this.selectedMessage = this.allMessages[this.allMessages.length - 1].ref;
    }
    if (!this.selectedMessage) {
      return;
    }
    const uid = this.fs.currentAppUser.uid;
    await this.fs.reactToSupportMessage(uid, this.conversation.ref, this.selectedMessage, reaction);
    this.selectedMessage = '';
    this.currentReaction = SupportMessageReactions.support_no_reaction;
  }

  public selectMessage(message: SupportMessage) {
    if (message.file?.url) {
      window.open(message.file.url, '_blank');
      return;
    }
    if (this.conversation.status === 'resolved') {
      return;
    }
    if (this.selectedMessage === message.ref) {
      this.selectedMessage = '';
      this.currentReaction = SupportMessageReactions.support_no_reaction;
      return;
    }
    this.selectedMessage = '';
    this.currentReaction = SupportMessageReactions.support_no_reaction;
    if (message.sender === 'user') {
      return;
    }
    this.selectedMessage = message.ref;
    this.currentReaction = message.reaction;
  }

  public async createConversation() {
    this.loading = true;
    try {
      const conv: SupportConversation = {
        uid: this.fs.currentAppUser.uid,
        firstname: this.fs.currentAppUser.firstname,
        lastname: this.fs.currentAppUser.lastname,
        email: this.fs.currentAppUser.email,
        companyRef: this.fs.currentAppUser.companyRef,
        agentRef: this.fs.currentAppUser.agentRef,
        status: 'open',
        subject: this.subject,
        category: this.category,
        createdAt: moment().utc().toISOString(),
        updatedAt: new Date().toISOString(),
        role: this.fs.currentAppUser.role,
        lastSender: 'user'
      };
      const ref = await this.fs.createSupportConversation(conv);
      conv.ref = ref;
      this.conversation = conv;
      this.getAllMessages();
    } catch (e) {
      console.error('', e);
    } finally {
      this.loading = false;
    }
  }

  private getAllMessages() {
    this.messages = this.fs.getMessagesFromConversation(this.conversation.uid, this.conversation.ref);
    this.subs.push(
      this.messages.subscribe(messages => {
        this.allMessages = messages;
        if (this.allMessages.length) {
          this.allMessages.sort((a, b) => {
              return (new Date(a.createdAt)).getTime() - (new Date(b.createdAt)).getTime();
            }
          );
          const lastMessage = this.allMessages[this.allMessages.length - 1];
          if (lastMessage.sender === 'agent') {
            this.selectedMessage = lastMessage.ref;
          }
        }
      })
    );
  }

  public async sendMessage() {
    if (!this.messageText) {
      return;
    }
    const message: SupportMessage = {
      uid: this.fs.currentAppUser.uid,
      conversationRef: this.conversation.ref,
      message: this.messageText,
      createdAt: moment().utc().toISOString(),
      updatedAt: new Date().toISOString(),
      sender: 'user',
      reaction: SupportMessageReactions.support_no_reaction,
      email: this.fs.currentAppUser.email
    };
    await this.fs.createSupportMessage(
      this.conversation.uid,
      this.conversation.ref,
      message
    );
    this.messageText = '';
  }

  public async closeConversation() {
    await this.fs.updateConversation(
      this.conversation.uid,
      this.conversation.ref,
      {
        status: 'resolved',
        updatedAt: new Date().toISOString()
      }
    );
    this.close(true);
  }

  public ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.subs.forEach(s => s.unsubscribe());
  }
}
