import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FireFunctionsService, FireStoreService, PdfPrinterService, RMToasterService, UploadFileService } from '../../services';
import * as SignaturePadNative from 'signature_pad';
import { fileStoringWorkflows } from '@roadmate/roadmate-common';

@Component({
  selector: 'rm-attestation',
  templateUrl: './attestation.component.html',
  styleUrls: ['./attestation.component.scss']
})
export class AttestationComponent implements OnInit {
  @Input()
  htmlText: string;
  @Input()
  beforSignatureText: string;
  @Input()
  pdfText: string;
  @Input()
  attestationObject: string;
  @Input()
  attestationType = 'ALL';
  @Input()
  isIKV = false;
  @Output()
  public urlReadyEmitter: EventEmitter<string> = new EventEmitter<string>();
  public loading = false;
  public signaturePad: SignaturePadNative.default;
  public dataSignature: string;
  private counter = 0;
  constructor(
    private fs: FireStoreService,
    private elementRef: ElementRef,
    private pdfMaker: PdfPrinterService,
    private uploader: UploadFileService,
    private ff: FireFunctionsService,
    private toast: RMToasterService,
    private chg: ChangeDetectorRef,
  ) { }

  ngOnInit(): void { }

  public ngAfterContentInit(): void {
    const canvas: any = this.elementRef.nativeElement.querySelector('canvas');
    if (!canvas) {
      if (this.counter > 3) {
        this.toast.showGenericError(`Une erreur s'est produite lors de la préparation de la zone de signature. Veuillez imprimer l'attestation, la signer et nous l'envoyer.`);
        return;
      }
      setTimeout(() => {
        this.ngAfterContentInit();
      }, 500);
      this.counter++;
      return;
    }
    const ratio = Math.max(window.devicePixelRatio || 1, 1);
    canvas.width = canvas.offsetWidth * ratio;
    canvas.height = canvas.offsetHeight * ratio;
    canvas.getContext("2d").scale(ratio, ratio);
    this.signaturePad = new SignaturePadNative.default(canvas, {
      minWidth: 0.5,
      maxWidth: 2.5,
      penColor: 'rgb(20, 33, 44)',
      throttle: 0,
    });
    this.signaturePad.on = this.onBegin.bind(this);
    this.signaturePad.off = this.onEnd.bind(this);
  }

  public onBegin() {
    console.log('start drawing');
  }

  public onEnd() {
    this.dataSignature = this.signaturePad.toDataURL();
  }

  public removeSignature() {
    this.signaturePad.clear();
    this.dataSignature = null;
    this.chg.detectChanges();
  }

  public async saveAttestation() {
    this.loading = true;
    try {
      this.dataSignature = this.signaturePad.toDataURL();
      const pdfBase64 = await this.pdfMaker.generatePdf(
        this.pdfText, this.beforSignatureText, this.dataSignature, this.isIKV
      );
      const year = (new Date()).getFullYear();
      const timestamp = (new Date()).getTime();
      const url = await this.uploader.uploadBase64File(
      fileStoringWorkflows.attestations,
      `${fileStoringWorkflows.attestations}-${this.attestationType}-${year}-[${this.fs.currentAppUser.email}]-[${timestamp}].pdf`,
      `${pdfBase64}`);
      this.urlReadyEmitter.emit(url);
    } catch (e) {
      console.error('', e);
      this.toast.showGenericError('Une erreur s\'est produite lors de la génération de l\'attestation. Veuillez réessayer plus tard.');
    } finally {
      this.loading = false;
    }
  }
}
