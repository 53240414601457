<div class="image">
  <img [src]="img" alt="validation token illustration" *ngIf="!showComponentAsImage" />
  <ng-container *ngIf="showComponentAsImage">
    <ng-container [ngSwitch]="showComponentAsImage">
      <ng-container *ngSwitchCase="'activate-card'">
        <rm-validation-card></rm-validation-card>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
<div class="row">
  <p class="pad-15" [innerHTML]="description | translate"></p>
</div>
<div class="row">
  <div class="col-sm-12">
    <div class="form-group">
      <!-- <label for="name">{{'transfer_to' | translate}}</label> -->
      <input class="form-control" id="token" type="text" [(ngModel)]="token" placeholder="{{placeholder}}" (keyup.enter)="close(token)">
    </div>
  </div>
</div>
<!-- <div class="row pad-15">
  <i class=""></i> {{'need_help' | translate}} <a href="#">{{'go_to_help_center' | translate}}</a>
</div> -->

<div class="rm-row start detached">
  <button class="btn btn-secondary" type="button" (click)="close(null)" >
    {{'cancel' | translate}}
  </button>
  <button class="btn btn-primary" type="button" (click)="close(token)" [disabled]="!token || !isValidToken(token)" >
    {{'confirm' | translate}}
  </button>
</div>
<div class="rm-row start detached" *ngIf="timeout && requestRef && !otpSentByEmail">
  <button class="btn btn-primary" type="button" (click)="resendCode()" [disabled]="loading">
    <span *ngIf="loading" class="c-loading-button-spinner spinner-border spinner-border-sm"></span>
    {{'receive_otp_by_email' | translate}}
  </button>
</div>
<div class="rm-row start detached" *ngIf="otpSentByEmail">
  {{'otp_was_sent_by_email' | translate}}
</div>
