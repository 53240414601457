import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { appRoutes } from './static';
import { DefaultLayoutComponent } from './containers';
import { MasterCanActivate } from './services/can-activate/master.auth';
import { appRoles } from '@roadmate/roadmate-common';


export const routes: Routes = [
  {
    path: '',
    redirectTo: appRoutes.login,
    pathMatch: 'full',
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: ''
    },
    children: [
      // UNAUTHENTICATED
      { path: appRoutes.logout,
        loadChildren: () => import('./routes/unauthenticated/logout/logout.module').then(m => m.LogoutModule),
        data: {
          title: 'Déconnexion'
        }
      },
      { path: appRoutes.login,
        loadChildren: () => import('./routes/unauthenticated/login/login.module').then(m => m.LoginModule),
        data: {
          title: 'Connexion'
        }
      },
      { path: appRoutes.invoiceBundle,
        loadChildren: () => import('./routes/unauthenticated/invoice-bundle/invoice-bundle-page.module').then(m => m.InvoiceBundlePageModule),
        data: {
          title: 'Factures et justificatifs'
        }
      },
      { path: appRoutes.unauthorized,
        loadChildren: () => import('./routes/unauthenticated/unauthorized/unauthorized.module').then(m => m.UnauthorizedModule),
        data: {
          title: 'Autorisation insuffisante'
        }
      },
      { path: appRoutes.scheduledTransfer,
        loadChildren: () => import('./routes/unauthenticated/scheduled-transfer/unauth-scheduled-transfer.module').then(m => m.UnAuthScheduledTransferModule),
        data: {
          title: 'Attribution de budget programmée'
        }
      },
      { path: appRoutes.subscribe,
        loadChildren: () => import('./routes/unauthenticated/subscribe/subscribe.module').then(m => m.SubscribeModule),
        data: {
          title: 'Créer un compte'
        }
      },
      { path: appRoutes.contact,
        loadChildren: () => import('./routes/unauthenticated/contact-us/contact-us.module').then(m => m.ContactUsModule),
        data: {
          title: 'Contactez-nous'
        }
      },
      { path: appRoutes.activateAccount,
        loadChildren: () => import('./routes/unauthenticated/activate-account/activate-account.module').then(m => m.ActivateAccountModule),
        data: {
          title: 'Activation de compte'
        }
      },
      { path: appRoutes.updatePassword,
        loadChildren: () => import('./routes/unauthenticated/update-password/update-password.module').then(m => m.UpdatePasswordModule),
        data: {
          title: 'Modification de mot de passe'
        }
      },
      // Super Admin
      { path: `${appRoutes.superadmin}`,
        loadChildren: () => import('./routes/superadmin/superadmin.module').then(m => m.SuperAdminModule),
        data: {
          title: 'Super Admin',
          [appRoles.superadmin]: true,
        },
        canActivate: [MasterCanActivate]
      },
      // Admin
      { path: `${appRoutes.admin}`,
        loadChildren: () => import('./routes/admin/admin.module').then(m => m.AdminModule),
        data: {
          title: '',
          [appRoles.admin]: true,
        },
        canActivate: [MasterCanActivate]
      },
      // Account Manager
      { path: appRoutes.accountManager,
        loadChildren: () => import('./routes/account-manager/account-manager.module').then(m => m.AccountManagerModule),
        data: {
          title: '',
          [appRoles.accountManager]: true,
        },
        canActivate: [MasterCanActivate]
      },
      // Employee
      { path: appRoutes.employee,
        loadChildren: () => import('./routes/employee/employee.module').then(m => m.EmployeeModule),
        data: {
          title: '',
          [appRoles.employee]: true,
        },
        canActivate: [MasterCanActivate]
      },
      // Agent
      { path: appRoutes.agentProfile,
        loadChildren: () => import('./routes/agent/agent-profile/agent-profile.module').then(m => m.AgentProfileModule),
        data: {
          title: 'Vos Paramètres',
          [appRoles.agent]: true,
        },
        canActivate: [MasterCanActivate]
      },
      { path: appRoutes.agentCompanies,
        loadChildren: () => import('./routes/agent/agent-companies/agent-companies.module').then(m => m.AgentCompaniesModuleModule),
        data: {
          title: 'Gérer les entreprises',
          [appRoles.agent]: true,
        },
        canActivate: [MasterCanActivate]
      },
      { path: appRoutes.agentMobilityManagement,
        loadChildren: () => import('./routes/superadmin/mid-mcc-center/mid-mic-center.module').then(m => m.MidMccModule),
        data: {
          title: 'Gérer la mobilité',
          [appRoles.agent]: true,
        },
        canActivate: [MasterCanActivate]
      },
      { path: `${appRoutes.agentManageUsers}`,
        loadChildren: () => import('./routes/agent/agent-manage-users/agent-manage-users.module').then(m => m.AgentManageUsersModule),
        data: {
          title: 'Gérer les utilisateurs',
          [appRoles.agent]: true,
        },
        canActivate: [MasterCanActivate]
      }
    ]
  },
  { path: '**', redirectTo: '/' },
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
