import {Component} from '@angular/core';
import { FireStoreService } from '../../../services';
import { AbstractEntryComponent } from '../abstract.entry-component';


@Component({
  selector: 'rm-display-html',
  templateUrl: 'display-html.component.html'
})
export class DisplayHtmlComponent extends AbstractEntryComponent<any> {
  public html: string;
  public title: string;
  public dynamicValue: string;
  public btns: {label: string; action: boolean}[] = [];
  public iframeHeight = 200;
  public set data(value) {
    if (!value) {
      return;
    }
    this.html = value.html ? value.html : '';
    this.btns = value.btns ? value.btns : [{label: 'ok', action: true}] ;
    this.dynamicValue = value.dynamicValue ? value.dynamicValue : '';
    if (this.html) {
      this.setiFrame();
    }
  }
  constructor(
    private fs: FireStoreService
  ) {
    super();
  }

  private setiFrame() {
    const iframe = document.getElementById('rm-iframe') as HTMLIFrameElement;
    if (!iframe) {
      setTimeout(() => this.setiFrame(), 500);
      return;
    }
    iframe.contentWindow.document.open();
    const angentName = 'RoadMate'; //this.fs.currentAgent?.name.toLocaleLowerCase() === 'roadmate' ? 'RoadMate' : `${this.fs.currentAgent.name} By RoadMate`;
    const footer = `${angentName}, la solution qui  vous permet piloter votre mobilité et maîtriser votre budget.`
    let middleImage = this.fs.currentAgent.cardImageUrl ? this.fs.currentAgent.cardImageUrl : 'https://www.roadmate.io/images/roadmate-card-noname.png';
    let html = this.html.replace('[TITLE]', 'Titre du mail')
    .replace('[CONTENT]', `<p>Bonjour,</p>
    <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum
    </p>`)
    .replace('[URL]', middleImage)
    .replace('[FOOTER]', footer)
    .replace('[CTA_TEXT]', `Ouvrir l'application`);

    iframe.contentWindow.document.write(html);
    iframe.contentWindow.document.close();
    this.iframeHeight = 800;
  }
}
