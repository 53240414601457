import { Component, Input, OnInit } from '@angular/core';
import { RefundRequestType } from '@roadmate/roadmate-common';

@Component({
  selector: 'rm-translate-render',
  template: `
    {{renderValue | translate}}
  `,
})
export class PaymentTypeRenderComponent implements OnInit {

  renderValue: string;

  @Input() value: string;
  @Input() rowData: any;

  ngOnInit() {
    if (this.value === RefundRequestType.PAYMENT_BY_CARD) {
      this.renderValue = 'BY_CARD';
    } else if (this.value === RefundRequestType.BUDGET_EXIPRED) {
      this.renderValue = RefundRequestType.BUDGET_EXIPRED;
    } else {
      this.renderValue = 'BY_PAYOUT';
    }
  }

}