export enum entryComponentList {
  editKyx = 'editKyx',
  kybDialog = 'kybDialog',
  addGroup = 'addGroup',
  addUser = 'addUser',
  addUserInfo = 'addUserInfo',
  addUsersToGroup = 'addUsersToGroup',
  editObject = 'editObject',
  confirmTransfer = 'confirmTransfer',
  confirmPayout = 'confirmPayout',
  verifyToken = 'verifyToken',
  editUser = 'editUser',
  logoutWarning = 'logoutWarning',
  assignMid = 'assignMid',
  displayWhiteList = 'displayWhiteList',
  editCompany = 'editCompany',
  confirmRefund = 'confirmRefund',
  askQuestion = 'askQuestion',
  displayHTML = 'displayHTML',
  updateSetting = 'updateSetting',
  processRefund = 'processRefund',
  processAutomaticTransfer = 'processAutomaticTransfer',
  deleteUsers = 'deleteUsers',
  confirmDeletion = 'confirmDeletion',
  addAM = 'add-am',
  transactionDetails = 'transactionDetails',
  selectChoice = 'select-choice',
  displayMobilityList = 'display-mobility-list',
  conversation = 'conversation',
  employeeConversation = 'employee-conversation',
  confirmBudgetAdjustment = 'confirmBudgetAdjustment',
  ikvComponent = 'ikvComponent',
  refundsAndTransactions = 'refundsAndTransactions',
  displayMerchant = 'displayMerchant',
}
