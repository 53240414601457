import { Component, OnInit } from '@angular/core';
import { DropDownListOption } from '@roadmate/roadmate-common';
import { AbstractEntryComponent } from '../abstract.entry-component';

@Component({
  selector: 'rm-select-one-choice',
  templateUrl: './select-one-choice.component.html',
  styleUrls: ['./select-one-choice.component.scss']
})
export class SelectOneChoiceComponent extends AbstractEntryComponent<string> implements OnInit {
  public choices: DropDownListOption[] = [];
  public title = 'select_one_choice';
  public description = '';
  public set data(value) {
    if (value.choices) {
      this.choices = value.choices;
    }
    if (value.title) {
      this.title = value.title;
    }
    if (value.description) {
      this.description = value.description;
    }
  }
  constructor() {
    super();
  }

  ngOnInit(): void {

  }

  public selectItem(value: string) {
    this.close(value);
  }
}
