<h4 *ngIf="standalone">
  {{'update_setting' | translate}}
</h4>
<div *ngIf="setting" class="row">
  <div [ngClass]="{'col-sm-3':  !standalone, 'col-sm-4': standalone}">
    <div class="form-group">
      <label *ngIf="standalone" for="name">{{'name' | translate}}</label>
      <div class="form-control" disabled="">
        {{setting.name | translate}}
      </div>
    </div>
  </div>
  <div class="col-sm-4">
    <div class="form-group">
      <label *ngIf="standalone"  for="name">{{'description' | translate}}</label>
      <div class="form-control" disabled="">
        {{setting.description | translate}}
      </div>
    </div>
  </div>
  <div [ngClass]="{'col-sm-3': !standalone, 'col-sm-4': standalone}">
    <div class="form-group">
      <label *ngIf="standalone"  for="name">{{'value' | translate}}</label>
      <ng-container [ngSwitch]="setting.type">
        <ng-container *ngSwitchCase="'boolean'">
          <div class="rm-row checkbox">
            <label class="c-switch c-switch-primary">
              <input
                [(ngModel)]="setting.value"
                class="c-switch-input"
                type="checkbox"
                [checked]="setting.value"
              > <span class="c-switch-slider"></span>
            </label>
            <label class="form-check-label" style="margin-left:15px">
              {{setting.name | translate}}
            </label>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'number'">
          <input
            class="form-control"
            type="number"
            [(ngModel)]="setting.value"
          >
        </ng-container>
        <ng-container *ngSwitchCase="'percent'">
          <input
            class="form-control"
            type="number"
            [(ngModel)]="setting.value"
          >
        </ng-container>
        <ng-container *ngSwitchCase="'select'">
          <select class="form-control"
            [(ngModel)]="setting.value"
          >
            <option *ngFor="let item of setting.values" [value]="item">
              {{item | translate}}
            </option>
          </select>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <input
              class="form-control"
              type="text"
              [(ngModel)]="setting.value"
            >
        </ng-container>
      </ng-container>
    </div>
  </div>
  <div class="col-sm-2" *ngIf="!standalone">
    <button class="btn btn-primary" type="button" (click)="updateSetting()" [disabled]="loading">
      <span *ngIf="loading" class="c-loading-button-spinner spinner-border spinner-border-sm"></span>
      {{'save' | translate}}
    </button>
  </div>
</div>
<div *ngIf="setting" class="row">
  
</div>
<div *ngIf="setting" class="row">
  
</div>
<div *ngIf="setting && standalone" class="row">
  <div class="col-sm-6">
    <button class="btn btn-primary" type="button" (click)="updateSetting()" [disabled]="loading" style="margin-right: 10px;">
      <span *ngIf="loading" class="c-loading-button-spinner spinner-border spinner-border-sm"></span>
      {{'save' | translate}}
    </button>
    <button  *ngIf="mode !== 'self'" class="btn btn-light" type="button" (click)="done()" [disabled]="loading">
      <span *ngIf="loading" class="c-loading-button-spinner spinner-border spinner-border-sm"></span>
      {{'cancel' | translate}}
    </button>
  </div>
  <div class="col-sm-6">
    
  </div>
</div>
