import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'rm-render-datetime',
  template: `
    {{value | rm_date}}
  `,
})
export class DateTimeRenderComponent implements OnInit {

  renderValue: string;

  @Input() value: string;

  ngOnInit() {}

}
