<div class="c-multi-select c-multi-select-multiple"
    #ddl
    (click)="showDDL = !showDDL"
    [ngClass]="{'c-show': showDDL}">
  <span class="c-multi-select-selection">
    <ng-container *ngIf="selectedItems && selectedItems.length">
      {{selectedItems.length}} {{itemName | translate}}(s) {{'selected' | translate}}(s)
    </ng-container>
    <ng-container *ngIf="!selectedItems || selectedItems.length === 0">
      {{placeholder | translate}}
    </ng-container>
  </span>
  <div class="c-multi-select-options" *ngIf="array && array.length">
    <div class="c-multi-select-option"
        *ngFor="let item of array; let i = index"
        (click)="selectItem(i)"
        [ngClass]="{'c-multi-selected': selectedItems | array_contains:'value':item.value}">
      {{item.label | translate}}
    </div>
  </div>
</div>
