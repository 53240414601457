import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserSituationComponent } from './user-situation.component';
import { TranslateModule } from '@ngx-translate/core';
import { PipeModule } from '../pipes/pipe.module';
import { FormsModule } from '@angular/forms';
import { RMReactiveFormsModule } from '../reactive-forms/reactive-forms.module';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { ExpenseLineStatusModule } from '../expenseline-status/expenseline-status.module';

@NgModule({
  declarations: [
    UserSituationComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    PipeModule,
    FormsModule,
    RMReactiveFormsModule,
    Ng2SmartTableModule,
    ExpenseLineStatusModule
  ],
  exports: [
    UserSituationComponent
  ],
  providers: [],
})
export class UserSituationModule {}
