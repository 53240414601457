import { AddGroupComponent } from './add-group/add-group.component';
import { AddUserComponent } from './add-user/add-user.component';
import { AddUsersInfoComponent } from './add-users-info/add-users-info.component';
import { AddUsersToGroupComponent } from './add-users-to-group/adduserstogroup.component';
import { ConfirmTransferComponent } from './confirm-transfer/confirm-transfer.component';
import { EditKyxComponent } from './edit-kyx/edit-kyx.component';
import { UpdateObjectComponent } from './edit-object.ts/edit-object.component';
import { KybDialogComponent } from './kyb-dialog/kyb-dialog.component';
import { VerifyTokenComponent } from './verify-toekn/verify-token.component';
import { EditUserComponent } from './edit-user/edit-user.component';
import { ConfirmPayoutComponent } from './confirm-payout/confirm-payout.component';
import { entryComponentList } from './entrycomponents.list';
import { LogoutWarningComponent } from './logout-warning/logout-warning.component';
import { AssignMidComponent } from './assign-mid/assign-mid.component';
import { DisplayWhiteListTreeComponent } from './display-whitelist/display-whitelist.component';
import { EditCompanyComponent } from './edit-company/edit-company.component';
import { ConfirmRefundComponent } from './confirm-refund/confirm-refund.component';
import { AskQuestionComponent } from './ask-question/ask-question.component';
import { DisplayHtmlComponent } from './display-html/display-html.component';
import { UpdateSettingComponent } from './update-setting/update-setting.component';
import { ProcessRefundComponent } from './process-refund/process-refund.component';
import { ImportDeleteUsersComponent } from './import-delete-users/import-delete-users.component';
import { ConfirmUserDeletionComponent } from './confirm-user-deletion/confirm-user-deletion.component';
import { AddNewAccountManagerComponent } from './add-new-account-manager/add-new-am.component';
import { TransactionDetailsComponent } from './transaction-details/transaction-details.component';
import { SelectOneChoiceComponent } from './select-one-choice/select-one-choice.component';
import { DisplayEligibleMobilityComponent } from './display-eligible-mobility/display-eligible-mobility.component';
import { ConversationComponent } from './conversation/conversation.component';
import { EmployeeConversationComponent } from './employee-conversation/employee-conversation.component';
import { ConfirmBudgetAdjustmentComponent } from './confirm-budget-adjustment/confirm-budget-adjustment.component';
import { IkvComponent } from './ikv/ikv.component';
import { DeleteUserDetailsComponent } from './deleted-user-modal/deleted-user-modal.component';
import { DisplayMobilityPartnerComponent } from './display-mobility-partner/display-mobility-partner.component';


export const getComponent = (component: entryComponentList): any => {
  switch (component) {
    case entryComponentList.editKyx:
      return EditKyxComponent;
    case entryComponentList.kybDialog:
      return KybDialogComponent;
    case entryComponentList.addGroup:
      return AddGroupComponent;
    case entryComponentList.addUser:
      return AddUserComponent;
    case entryComponentList.addUserInfo:
      return AddUsersInfoComponent;
    case entryComponentList.addUsersToGroup:
      return AddUsersToGroupComponent;
    case entryComponentList.editObject:
      return UpdateObjectComponent;
    case entryComponentList.confirmTransfer:
      return ConfirmTransferComponent;
    case entryComponentList.confirmPayout:
      return ConfirmPayoutComponent;
    case entryComponentList.verifyToken:
      return VerifyTokenComponent;
    case entryComponentList.editUser:
      return EditUserComponent;
    case entryComponentList.logoutWarning:
      return LogoutWarningComponent;
    case entryComponentList.assignMid:
      return AssignMidComponent;
    case entryComponentList.displayWhiteList:
      return DisplayWhiteListTreeComponent;
    case entryComponentList.editCompany:
      return EditCompanyComponent;
    case entryComponentList.confirmRefund:
      return ConfirmRefundComponent;
    case entryComponentList.askQuestion:
      return AskQuestionComponent;
    case entryComponentList.displayHTML:
      return DisplayHtmlComponent;
    case entryComponentList.updateSetting:
      return UpdateSettingComponent;
    case entryComponentList.processRefund:
      return ProcessRefundComponent;
    case entryComponentList.deleteUsers:
      return ImportDeleteUsersComponent;
    case entryComponentList.confirmDeletion:
      return ConfirmUserDeletionComponent;
    case entryComponentList.addAM:
      return AddNewAccountManagerComponent;
    case entryComponentList.transactionDetails:
      return TransactionDetailsComponent;
    case entryComponentList.selectChoice:
      return SelectOneChoiceComponent;
    case entryComponentList.displayMobilityList:
      return DisplayEligibleMobilityComponent;
    case entryComponentList.conversation:
      return ConversationComponent;
    case entryComponentList.employeeConversation:
      return EmployeeConversationComponent;
    case entryComponentList.confirmBudgetAdjustment:
      return ConfirmBudgetAdjustmentComponent;
    case entryComponentList.ikvComponent:
      return IkvComponent;
    case entryComponentList.refundsAndTransactions:
      return DeleteUserDetailsComponent;
    case entryComponentList.displayMerchant:
      return DisplayMobilityPartnerComponent;
    default:
      break;
  }
}
