import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CalendarModule } from 'primeng-lts/calendar';
import { EditKyxComponent } from './edit-kyx/edit-kyx.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipeModule } from '../pipes/pipe.module';
import { KybDialogComponent } from './kyb-dialog/kyb-dialog.component';
import { AddGroupComponent } from './add-group/add-group.component';
import { AddUserComponent } from './add-user/add-user.component';
import { AddUsersInfoComponent } from './add-users-info/add-users-info.component';
import { AddUsersToGroupComponent } from './add-users-to-group/adduserstogroup.component';
import { RMReactiveFormsModule } from '../reactive-forms/reactive-forms.module';
import { MigrationModule } from '../migration/migration.module';
import { UploadInputModule } from '../upload-input/upload-input.module';
import { UpdateObjectComponent } from './edit-object.ts/edit-object.component';
import { ConfirmTransferComponent } from './confirm-transfer/confirm-transfer.component';
import { VerifyTokenComponent } from './verify-toekn/verify-token.component';
import { MultiSelectModule } from '../multi-select/multi-select.module';
import { EditUserComponent } from './edit-user/edit-user.component';
import { AnimatedSVGModule } from '../animated-svg-components/animated-svg.module';
import { ConfirmPayoutComponent } from './confirm-payout/confirm-payout.component';
import { LogoutWarningComponent } from './logout-warning/logout-warning.component';
import { AssignMidComponent } from './assign-mid/assign-mid.component';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { TreeModule } from 'primeng-lts/tree';
import { TooltipModule } from 'primeng-lts/tooltip';
import { DisplayWhiteListTreeComponent } from './display-whitelist/display-whitelist.component';
import { DdlRenderComponent } from './ng2-smart-components/ddl-render.component';
import { MultiSelectRenderComponent } from './ng2-smart-components/multiselect-render.component';
import { TranslateRenderComponent } from './ng2-smart-components/translate-render.component';
import { DateTimeRenderComponent } from './ng2-smart-components/datetime-render.component';
import { HasInvoiceRenderComponent } from './ng2-smart-components/has-envoice.component';
import { DdlValueRenderComponent } from './ng2-smart-components/ddl-value-render.component';
import { EditCompanyComponent } from './edit-company/edit-company.component';
import { BooleanRenderComponent } from './ng2-smart-components/boolean-render.component';
import { FileRenderComponent } from './ng2-smart-components/file-render.component';
import { CarouselModule } from 'primeng-lts/carousel';
import { DropdownModule } from 'primeng-lts/dropdown';
import { ConfirmRefundComponent } from './confirm-refund/confirm-refund.component';
import { AskQuestionComponent } from './ask-question/ask-question.component';
import { DisplayHtmlComponent } from './display-html/display-html.component';
import { UpdateSettingComponent } from './update-setting/update-setting.component';
import { ProcessRefundComponent } from './process-refund/process-refund.component';
import { ImageRenderComponent } from './ng2-smart-components/img-render.component';
import { CurrencyRenderComponent } from './ng2-smart-components/currency-render-component';
import { ImportDeleteUsersComponent } from './import-delete-users/import-delete-users.component';
import { ConfirmUserDeletionComponent } from './confirm-user-deletion/confirm-user-deletion.component';
import { UrlRenderComponent } from './ng2-smart-components/url.render.component';
import { MonthRenderComponent } from './ng2-smart-components/month-render.component';
import { DateRenderComponent } from './ng2-smart-components/date-render.component';
import { AddNewAccountManagerComponent } from './add-new-account-manager/add-new-am.component';
import { ValidationCardComponent } from './verify-toekn/validation-card/validation-card.component';
import { TransactionDetailsComponent } from './transaction-details/transaction-details.component';
import { ViewRefundRequestComponent } from './view-refund-request/view-refund-request.component';
import { SelectOneChoiceComponent } from './select-one-choice/select-one-choice.component';
import { DisplayEligibleMobilityComponent } from './display-eligible-mobility/display-eligible-mobility.component';
import { ProductNameRenderComponent } from './ng2-smart-components/productname-render.component';
import { ConversationComponent } from './conversation/conversation.component';
import { EmployeeConversationComponent } from './employee-conversation/employee-conversation.component';
import { RefundRecapComponent } from './process-refund/refund-recap/refund-recap.component';
import { ConfirmBudgetAdjustmentComponent } from './confirm-budget-adjustment/confirm-budget-adjustment.component';
import { PaymentTypeRenderComponent } from './ng2-smart-components/payment-type.render.component';
import { IkvComponent } from './ikv/ikv.component';
import { AttestationModule } from '../attestation/attestation.module';
import { EmployeeInvoiceRenderComponent } from './ng2-smart-components/employee-invoice.render';
import { UserSituationModule } from '../user-situation/user-situation.module';
import { TransactionsRenderComponent } from './ng2-smart-components/transactions.render';
import { TransactionViewerModule } from '../transactions-viewer/transactions-viewer.module';
import { ChartsModule } from 'ng2-charts';
import { UserRefundsAndTransactionsComponent } from './user-refunds-transactions/user-refunds-transactions.component';
import { DeleteUserDetailsComponent } from './deleted-user-modal/deleted-user-modal.component';
import { EmailToNamePipe } from './conversation/email-to-name.pipe';
import { ParameterRenderComponent } from './ng2-smart-components/parameter-render.component';
import { HTMLRenderComponent } from './ng2-smart-components/html-render.component';
import { RefundRequestMonthRenderComponent } from './ng2-smart-components/refundrequest-month-render.component';
import { Ng2SmartExporterModule } from '../ng2-smart-exporter/ng2-smart-exporter.module';
import { DisplayMobilityPartnerComponent } from './display-mobility-partner/display-mobility-partner.component';
import { OfferCardComponent } from './display-mobility-partner/offer-card.component';
import { ExpenseLineStatusModule } from '../expenseline-status/expenseline-status.module';
import { EmployeeAttestationRenderComponent } from './ng2-smart-components/employee-attestation-renderer';


const components = [
  OfferCardComponent,
  DeleteUserDetailsComponent,
  UserRefundsAndTransactionsComponent,
  TransactionsRenderComponent,
  RefundRecapComponent,
  EditKyxComponent,
  KybDialogComponent,
  AddGroupComponent,
  AddUserComponent,
  AddUsersInfoComponent,
  AddUsersToGroupComponent,
  UpdateObjectComponent,
  ConfirmTransferComponent,
  VerifyTokenComponent,
  EditUserComponent,
  ConfirmPayoutComponent,
  LogoutWarningComponent,
  AssignMidComponent,
  DisplayWhiteListTreeComponent,
  DdlRenderComponent,
  MultiSelectRenderComponent,
  TranslateRenderComponent,
  DateTimeRenderComponent,
  DateRenderComponent,
  HasInvoiceRenderComponent,
  DdlValueRenderComponent,
  EditCompanyComponent,
  BooleanRenderComponent,
  FileRenderComponent,
  ConfirmRefundComponent,
  AskQuestionComponent,
  DisplayHtmlComponent,
  UpdateSettingComponent,
  ProcessRefundComponent,
  ImageRenderComponent,
  CurrencyRenderComponent,
  ImportDeleteUsersComponent,
  ConfirmUserDeletionComponent,
  UrlRenderComponent,
  MonthRenderComponent,
  RefundRequestMonthRenderComponent,
  EmployeeInvoiceRenderComponent,
  EmployeeAttestationRenderComponent,
  AddNewAccountManagerComponent,
  ValidationCardComponent,
  TransactionDetailsComponent,
  ViewRefundRequestComponent,
  SelectOneChoiceComponent,
  DisplayEligibleMobilityComponent,
  ProductNameRenderComponent,
  ConversationComponent,
  EmployeeConversationComponent,
  ConfirmBudgetAdjustmentComponent,
  PaymentTypeRenderComponent,
  IkvComponent,
  EmailToNamePipe,
  ParameterRenderComponent,
  HTMLRenderComponent,
  DisplayMobilityPartnerComponent
]

@NgModule({
  declarations: [...components],
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    RMReactiveFormsModule,
    PipeModule,
    MigrationModule,
    UploadInputModule,
    FormsModule,
    MultiSelectModule,
    AnimatedSVGModule,
    Ng2SmartTableModule,
    TreeModule,
    TooltipModule,
    CarouselModule,
    DropdownModule,
    CalendarModule,
    AttestationModule,
    UserSituationModule,
    TransactionViewerModule,
    ChartsModule,
    Ng2SmartExporterModule,
    ExpenseLineStatusModule
  ],
  exports: [...components],
  providers: [],
  entryComponents: [...components]
})
export class RmEntryComponentsModule {}
