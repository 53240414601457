<ul class="nav nav-tabs" role="tablist">
  <li class="nav-item" (click)="setTab(allTabls.settings)">
    <a 
      class="nav-link"
      data-toggle="tab"
      [ngClass]="{'active': currentTab === allTabls.settings}"
      role="tab"
      aria-controls="home"
    >
      {{'company_settings' | translate}}
    </a>
  </li>
  <li class="nav-item" (click)="setTab(allTabls.profile)">
    <a class="nav-link"
      data-toggle="tab"
      [ngClass]="{'active': currentTab === allTabls.profile}"
      role="tab"
      aria-controls="home">
      {{'company_information' | translate}}
    </a>
  </li>
  <li class="nav-item" (click)="setTab(allTabls.invoicesettings)">
    <a 
      class="nav-link"
      data-toggle="tab"
      [ngClass]="{'active': currentTab === allTabls.invoicesettings}"
      role="tab"
      aria-controls="home"
    >
      {{allTabls.invoicesettings | translate}}
    </a>
  </li>
  <li class="nav-item" (click)="setTab(allTabls.invoiceList)">
    <a 
      class="nav-link"
      data-toggle="tab"
      [ngClass]="{'active': currentTab === allTabls.invoiceList}"
      role="tab"
      aria-controls="home"
    >
      {{'invoice_list' | translate}}
    </a>
  </li>
  <li class="nav-item" (click)="setTab(allTabls.notifications)">
    <a 
      class="nav-link"
      data-toggle="tab"
      [ngClass]="{'active': currentTab === allTabls.notifications}"
      role="tab"
      aria-controls="home"
    >
      {{'notifications' | translate}}
    </a>
  </li>
  <li class="nav-item" (click)="setTab(allTabls.kyb)">
    <a 
      class="nav-link"
      data-toggle="tab"
      [ngClass]="{'active': currentTab === allTabls.kyb}"
      role="tab"
      aria-controls="home"
    >
      {{'kyb' | translate}}
    </a>
  </li>
</ul>
<div class="tab-content">
  <div class="tab-pane" role="tabpanel" [ngClass]="{'active': currentTab === allTabls.profile}">
    <h3>{{'company_products' | translate}} {{company?.legalName}}</h3>
    <div class="rm-row">
      <div class="col-3" *ngFor="let product of products; let i = index">
        <div class="card">
          <img [src]="product.img" class="card-img-top">
          <div class="card-body">
            <h5 
              class="card-title"
            >
              {{('PRODUCT_'+product.code) | translate}}
              <i class="cil-info" class="green cil-check-alt" *ngIf="hasOrderThisProduct(product)"></i>
            </h5>
            <p class="card-text">
              {{product.description}}
            </p>
            <button
              class="btn btn-primary"
              type="button"
              (click)="addProduct(product)"
              [disabled]="loading === i"
              *ngIf="!hasOrderThisProduct(product);"
            >
              <span *ngIf="intent !== product.code">{{'add' | translate}}</span>
              <span *ngIf="intent === product.code">
                Vous êtes sûr ?
              </span>
              <span *ngIf="loading === i" class="c-loading-button-spinner spinner-border spinner-border-sm"></span>
            </button>
            <button
                class="btn btn-primary"
                type="button"
                (click)="openIban(product.url)"
                *ngIf="hasOrderThisProduct(product) && product.url"
              >
              {{'download_iban' | translate}}
              <i class="cil-external-link"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="tab-pane" role="tabpanel" [ngClass]="{'active': currentTab === allTabls.settings}">
    <h4 class="push-down">{{'applied_settings' | translate}} {{company.legalName}}</h4>
    <div *ngIf="settingViewing && !currentSetting; else updateSetting">
      <ng2-smart-table
        *ngIf="tableSettings && settings"
        [settings]="tableSettings"
        [source]="settings"
        (userRowSelect)="selecteSetting($event)"
      >
      </ng2-smart-table>
    </div>
    <ng-template #updateSetting>
      <rm-update-setting
        [mode]="'company'"
        [companyRef]="company.ref"
        [agentRef]="company.agentRef"
        [setting]="currentSetting"
        (saveSettingEmitter)="doneEditing($event)"
      ></rm-update-setting>
    </ng-template>

  </div>
  <div class="tab-pane" role="tabpanel" [ngClass]="{'active': currentTab === allTabls.invoicesettings}">
    <mat-progress-bar [mode]="'indeterminate'" *ngIf="loading === 3"></mat-progress-bar>
    <div *ngIf="loading !== 3">
      <h3>{{'invoice_management' | translate}}</h3>
      <div class="detached" *ngIf="invoiceSettingValues?.length">
        <rm-update-setting
          *ngFor="let sett of invoiceSettingValues"
          [mode]="'company'"
          [companyRef]="company.ref"
          [agentRef]="company.agentRef"
          [setting]="sett"
          [standalone]="false"
        ></rm-update-setting>
      </div>
    </div>
  </div>
  <div class="tab-pane" role="tabpanel" [ngClass]="{'active': currentTab === allTabls.invoiceList}">
    <div class="push-down detached">
      <h3>{{'invoice_list' | translate}}</h3>
      <ng-container *ngIf="invoices?.length; else noinvoices">
        <div class="row" *ngIf="invoices?.length; else noinvoice">
          <div class="col-12">
            <ng2-smart-table
              *ngIf="invoices.length && settings"
              [settings]="tableinvoiceSettings"
              [source]="invoices"
            >
            </ng2-smart-table>
          </div>
        </div>
        <ng-template #noinvoice>
          <div class="row">
            <div class="col-12">
              Aucune facture disponible pour le moment
            </div>
          </div>
        </ng-template>
        
      </ng-container>
      <ng-template #noinvoices>
        <div class="row">
          <div class="col-12">
            {{'no_invoices_available_yet' | translate}}
          </div>
        </div>
      </ng-template>
    </div>
  </div>
  <div class="tab-pane" role="tabpanel" [ngClass]="{'active': currentTab === allTabls.notifications}">
    <div class="push-down detached">
      <h3>{{'notifications_compose' | translate}}</h3>
      <div class="row detached">
        <div class="col-6">
          <rm-reactive-forms
            *ngIf="notificationDef && initNotification && loading !== 1"
            [objectDefinition]="notificationDef"
            [objectToUpdate]="initNotification"
            (saveEmitter)="sendNotification($event)"
          >
          </rm-reactive-forms>
        </div>
      </div>
      <mat-progress-bar class="detached" *ngIf="loading === 1" [mode]="'indeterminate'"></mat-progress-bar>
    </div>
  </div>
  <div class="tab-pane" role="tabpanel" [ngClass]="{'active': currentTab === allTabls.kyb}">
    <div class="push-down detached">
      <h3>{{'compnay_kyb' | translate}}</h3>
      <div class="row detached" >
        <div class="col-6" *ngIf="!kyb">
          <button class="btn btn-primary" type="button" (click)="createKyb()" [disabled]="loadingKyb">
            <span *ngIf="loadingKyb" class="c-loading-button-spinner spinner-border spinner-border-sm"></span>
            {{'Créer le kyb' | translate}}
          </button>
          <mat-progress-bar [mode]="'indeterminate'" *ngIf="loadingKyb"></mat-progress-bar>
        </div>
        <div class="col-6" *ngIf="kyb">
          <rm-reactive-forms
            *ngIf="kybDef && kyb"
            [objectDefinition]="kybDef"
            [objectToUpdate]="kyb"
            [displayOnly]="true"
          >
          </rm-reactive-forms>
        </div>
      </div>
      <mat-progress-bar class="detached" *ngIf="loading === 2" [mode]="'indeterminate'"></mat-progress-bar>
    </div>
  </div>
</div>




