import { TranslateService } from '@ngx-translate/core';
import { Field, fieldTypes, FormDefinition } from '@roadmate/roadmate-common';
import { BooleanRenderComponent } from '../modules/entry-components/ng2-smart-components/boolean-render.component';
import { CurrencyRenderComponent } from '../modules/entry-components/ng2-smart-components/currency-render-component';
import { DateRenderComponent } from '../modules/entry-components/ng2-smart-components/date-render.component';
import { DateTimeRenderComponent } from '../modules/entry-components/ng2-smart-components/datetime-render.component';
import { DdlRenderComponent } from '../modules/entry-components/ng2-smart-components/ddl-render.component';
import { DdlValueRenderComponent } from '../modules/entry-components/ng2-smart-components/ddl-value-render.component';
import { FileRenderComponent } from '../modules/entry-components/ng2-smart-components/file-render.component';
import { HasInvoiceRenderComponent } from '../modules/entry-components/ng2-smart-components/has-envoice.component';
import { ImageRenderComponent } from '../modules/entry-components/ng2-smart-components/img-render.component';
import { MonthRenderComponent } from '../modules/entry-components/ng2-smart-components/month-render.component';
import { MultiSelectRenderComponent } from '../modules/entry-components/ng2-smart-components/multiselect-render.component';
import { ProductNameRenderComponent } from '../modules/entry-components/ng2-smart-components/productname-render.component';
import { TranslateRenderComponent } from '../modules/entry-components/ng2-smart-components/translate-render.component';
import { UrlRenderComponent } from '../modules/entry-components/ng2-smart-components/url.render.component';
import { PaymentTypeRenderComponent } from '../modules/entry-components/ng2-smart-components/payment-type.render.component';
import { EmployeeInvoiceRenderComponent } from '../modules/entry-components/ng2-smart-components/employee-invoice.render';
import { TransactionsRenderComponent } from '../modules/entry-components/ng2-smart-components/transactions.render';
import { ParameterRenderComponent } from '../modules/entry-components/ng2-smart-components/parameter-render.component';
import { HTMLRenderComponent } from '../modules/entry-components/ng2-smart-components/html-render.component';
import { RefundRequestMonthRenderComponent } from '../modules/entry-components/ng2-smart-components/refundrequest-month-render.component';
import { EmployeeAttestationRenderComponent } from '../modules/entry-components/ng2-smart-components/employee-attestation-renderer';

const displayableFieldTypes = [
  fieldTypes.checkbox,
  fieldTypes.email,
  fieldTypes.date,
  fieldTypes.number,
  fieldTypes.phone,
  fieldTypes.string,
  fieldTypes.address,
  fieldTypes.boolean,
  fieldTypes.autocomplete
]

export const getTableSettings = async (
  definitionObject: FormDefinition,
  translateService: TranslateService,
  allowFilters = true,
  actions: any = {
    add: false,
    edit: false,
    delete: false,
  },
  perPage = 20,
  label = {
    edit: 'Modifier',
    add: 'Ajouter',
    delete: 'Supprimer'
  },
  selectMode = 'single'
) => {
  const filteredFields: Field[] = Object.keys(definitionObject)
  .sort((key1, key2) => {
    return Math.abs(definitionObject[key1].order) - Math.abs(definitionObject[key2].order);
  }).map(el => definitionObject[el]);

  const fieldsDescription = filteredFields.map(el => el.description || el.name);
  const translatedFields = await translateService.get(fieldsDescription).toPromise();
  const settings: any = {
    noDataMessage: 'Aucune donnée à afficher pour le moment',
    selectedRowIndex: 0,
    switchPageToSelectedRowPage: true,
    selectMode,
    columns: {

    },
    mode: 'external',
    actions,
    pager: {
      perPage
    },
    width: '100%'
  };
  if (actions.edit) {
    settings.edit = {
      editButtonContent: label?.edit ?? 'Modifier',
      confirmSave: false,
    }
  }
  if (actions.delete) {
    settings.delete = {
      deleteButtonContent: label?.delete ?? 'Supprimer',
      confirmDelete: true,
    }
  }
  if (actions.add) {
    settings.add = {
      addButtonContent: label?.add ?? 'Ajouter',
      createButtonContent: 'Confirmer',
      cancelButtonContent: 'Annuler',
      confirmCreate: true,
    }
  }
  filteredFields
  .forEach(
    (field) => {
      settings.columns[field.name] = {
        title: translatedFields[field.description || field.name],
        filter: field.filter && allowFilters,
        hide: !field.lightDisplay,
        editor: {
          type: getEditorType(field)
        },
        sort: (field.formtype === fieldTypes.date || !!field.filter)
      }
      switch (field.displaytype) {
        case fieldTypes.ddlObject: {
          settings.columns[field.name].type = 'custom';
          settings.columns[field.name].renderComponent = DdlRenderComponent;
          break;
        }
        case fieldTypes.multiselect: {
          settings.columns[field.name].type = 'custom';
          settings.columns[field.name].renderComponent = MultiSelectRenderComponent;
          break;
        }
        case fieldTypes.translate: {
          settings.columns[field.name].type = 'custom';
          settings.columns[field.name].renderComponent = TranslateRenderComponent;
          break;
        }
        case fieldTypes.file: {
          settings.columns[field.name].type = 'custom';
          settings.columns[field.name].renderComponent = FileRenderComponent;
          break;
        }
        case fieldTypes.currency: {
          settings.columns[field.name].type = 'custom';
          settings.columns[field.name].renderComponent = CurrencyRenderComponent;
          break;
        }
        case fieldTypes.image: {
          settings.columns[field.name].type = 'custom';
          settings.columns[field.name].renderComponent = ImageRenderComponent;
          break;
        }
        case fieldTypes.boolean:
        case fieldTypes.checkbox: {
          settings.columns[field.name].type = 'custom';
          settings.columns[field.name].renderComponent = BooleanRenderComponent;
          break;
        }
        case fieldTypes.datetime: {
          settings.columns[field.name].type = 'custom';
          settings.columns[field.name].renderComponent = DateTimeRenderComponent;
          break;
        }
        case fieldTypes.date: {
          settings.columns[field.name].type = 'custom';
          settings.columns[field.name].renderComponent = DateRenderComponent;
          break;
        }
        case fieldTypes.hasInvoices: {
          settings.columns[field.name].type = 'custom';
          settings.columns[field.name].renderComponent = HasInvoiceRenderComponent;
          break;
        }
        case fieldTypes.ddlValue: {
          settings.columns[field.name].type = 'custom';
          settings.columns[field.name].renderComponent = DdlValueRenderComponent;
          break;
        }
        case fieldTypes.employeeInvoice: {
          settings.columns[field.name].type = 'custom';
          settings.columns[field.name].renderComponent = EmployeeInvoiceRenderComponent;
          break;
        }
        case fieldTypes.employeeAttestation: {
          settings.columns[field.name].type = 'custom';
          settings.columns[field.name].renderComponent = EmployeeAttestationRenderComponent;
          break;
        }
        case fieldTypes.url: {
          settings.columns[field.name].type = 'custom';
          settings.columns[field.name].renderComponent = UrlRenderComponent;
          break;
        }
        case fieldTypes.month: {
          settings.columns[field.name].type = 'custom';
          settings.columns[field.name].renderComponent = MonthRenderComponent;
          break;
        }
        case fieldTypes.refundRequestMonth: {
          settings.columns[field.name].type = 'custom';
          settings.columns[field.name].renderComponent = RefundRequestMonthRenderComponent;
          break;
        }
        case fieldTypes.productName: {
          settings.columns[field.name].type = 'custom';
          settings.columns[field.name].renderComponent = ProductNameRenderComponent;
          break;
        }
        case fieldTypes.paymentType: {
          settings.columns[field.name].type = 'custom';
          settings.columns[field.name].renderComponent = PaymentTypeRenderComponent;
          break;
        }
        case fieldTypes.transactions: {
          settings.columns[field.name].type = 'custom';
          settings.columns[field.name].renderComponent = TransactionsRenderComponent;
          break;
        }
        case fieldTypes.parameter: {
          settings.columns[field.name].type = 'custom';
          settings.columns[field.name].renderComponent = ParameterRenderComponent;
          break;
        }
        case fieldTypes.html: {
          settings.columns[field.name].type = 'custom';
          settings.columns[field.name].renderComponent = HTMLRenderComponent;
          break;
        }
        default: {
          break;
        }
      }
  });
  return settings;
}

const getEditorType = (field: Field) => {
  switch(field.formtype) {
    case fieldTypes.boolean:
    case fieldTypes.checkbox:
      return 'checkbox';
    case fieldTypes.textarea:
      return 'textarea';
    default:
      return 'text'
  }
  // 'text' | '' | 'completer' | 'list' | ''
}

export const getPrimeNgTableSetting = async (
  definitionObject: FormDefinition,
  translateService: TranslateService
  ) => {
    const filteredFields: Field[] = Object.keys(definitionObject)
    .sort((key1, key2) => {
      return definitionObject[key1].order - definitionObject[key2].order;
    })
    .map(el => definitionObject[el]);
    const fieldsDescription = filteredFields.map(el => el.description || el.name);
    const translatedFields = await translateService.get(fieldsDescription).toPromise();
    const settings: {field: string, header: string, type: string}[] = [];
    filteredFields.forEach(
      field => {
        if (field.lightDisplay) {
          settings.push({
            field: field.name,
            header: translatedFields[field.name],
            type: field.displaytype
          });
        }
      }
    );
    return settings;
  }
