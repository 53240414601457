import { Pipe, PipeTransform } from '@angular/core';
import { DropDownListOption } from '@roadmate/roadmate-common';

@Pipe({name: 'rm_search_pipe'})
export class SearchePipe implements PipeTransform {
  transform(list: DropDownListOption[], searchText: string): any {
    if (!searchText) {
      return list;
    }
    searchText = searchText.toLowerCase();
    return list.filter((item) => {
      return item.label.toLowerCase().includes(searchText);
    });
  }
}