import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'rm_limit' })
export class LimitPipe implements PipeTransform {
  transform(value: any[], limit: number): any {
    if (value && value.length > limit && limit) {
      return value.slice(0, limit);
    }
    return value;
  }
}

@Pipe({ name: 'rm_sort_by_int_with_limit' })
export class SortByPipe implements PipeTransform {
  transform(value: any[], property: string, order: 'asc' | 'desc', limit: number): any {
    if (!value || !property || !order) {
      return [];
    }
    const sorted = value.sort((a, b) => {
      return order === 'asc' ? a[property] - b[property] : b[property] - a[property];
    });
    if (sorted && sorted.length > limit && limit > 0) {
      return sorted.slice(0, limit);
    }
    return sorted;
  }
}

