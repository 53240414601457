import { AngularFireAnalytics } from '@angular/fire/analytics';
import { Injectable } from '@angular/core';
import { Treezor } from '@roadmate/roadmate-common';

@Injectable({
  providedIn: 'root'
})
export class FireAnalyticsService {
  constructor(
    private analytics: AngularFireAnalytics
  ) {
    if (!localStorage.getItem('hasAnalyticsOptin')) {
      localStorage.setItem('hasAnalyticsOptin', 'true');
    }
  }

  public logEvent(eventName: string, data) {
    const hasOption = JSON.parse(localStorage.getItem('hasAnalyticsOptin'));
    if (hasOption) {
      this.analytics.logEvent(eventName, data);
    }
  }

  public setCurrentScreen(page: string, data) {
    const hasOption = JSON.parse(localStorage.getItem('hasAnalyticsOptin'));
    if (hasOption) {
      this.analytics.setCurrentScreen(page, data);
    }
  }

  public setCurrentUser(user: Partial<Treezor.AppUser>, data) {
    const hasOption = JSON.parse(localStorage.getItem('hasAnalyticsOptin'));
    if (hasOption) {
      this.analytics.setUserProperties(user, data);
    }
  }

  public disableAnalytics() {
    localStorage.setItem('hasAnalyticsOptin', 'false');
  }

  public enableAnalytics() {
    localStorage.setItem('hasAnalyticsOptin', 'true');
  }
}
