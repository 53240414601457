import { Component, OnInit } from '@angular/core';
import { AbstractEntryComponent } from '../abstract.entry-component';
import { TransportCategory, TransportWhiteList } from '@roadmate/roadmate-common';
import { RNode } from '../../../static/rnode';

@Component({
  selector: 'rm-display-eligible-mobility',
  templateUrl: './display-eligible-mobility.component.html',
  styleUrls: ['./display-eligible-mobility.component.scss']
})
export class DisplayEligibleMobilityComponent extends AbstractEntryComponent<boolean> implements OnInit {
  public whiteList: TransportWhiteList;
  public transports: TransportCategory[];
  public treeNode: RNode[];
  public loading = false;
  public set data(value) {
    if (!value || !value.whiteList?.treeNode.length) {
      return;
    } else {
      this.whiteList = value.whiteList;
      this.transports = value.whiteList.treeNode;
      this.getAllCategories();
    }
  }
  constructor (
  ) {
    super();
  }

  ngOnInit(): void { }

  private async getAllCategories() {
    if (!this.transports.length) {
      return;
    }
    this.treeNode = this.transports.map(el => {
      return {
        label: el.name,
        data: el.ref,
        selectable: true,
        type: 'category',
        isSelected: el.isAuthorized,
        key: el.icon && el.icon.length ? el.icon[0].url : '',
        children: this.getChildren(el)
      }
    });
  }

  public getChildren(transport: TransportCategory) {
    const nodes: RNode[] = transport.merchants?.length ?
    transport.merchants.map(merchant => {
      return {
        label: merchant.label,
        data: merchant.value,
        selectable: true,
        type: 'merchant',
        key: merchant.img,
        isSelected: merchant.isSelected,
      }
    })
    : [];
    if (transport.subCategories?.length) {
      transport.subCategories.forEach(
        sub => {
          nodes.push({
            label: sub.name,
            data: sub.ref,
            selectable: true,
            type: 'subCategory',
            key: sub.icon && sub.icon.length ? sub.icon[0].url : '',
            isSelected: sub.isAuthorized,
            children: this.getChildren(sub)
          });
        }
      )
    }
    return nodes;
  }

  public expandAll(){
    this.treeNode.forEach( node => {
        this.expandRecursive(node, true);
    } );
  }

  public collapseAll(){
    this.treeNode.forEach( node => {
        this.expandRecursive(node, false);
    } );
  }

  private expandRecursive(node:RNode, isExpand:boolean){
    node.expanded = isExpand;
    if (node.children){
      node.children.forEach( childNode => {
        this.expandRecursive(childNode, isExpand);
      } );
    }
  }
}
