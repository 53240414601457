export const alertEditors = [
  {
    value: 'ryme.gueldi@roadmate.io',
    label: 'Ryme'
  },
  {
    label: 'Thierry',
    value: 'thierry.cosme@roadmate.io'
  },
  {
    label: 'Mehdi',
    value: 'mehdi.tanouti@roadmate.io'
  }
];
