import { Component, Input, OnInit } from '@angular/core';
import { RMToasterService } from '../../services/toaster.service';
import { modes } from '../../static';

@Component({
  selector: 'rm-toaster',
  templateUrl: './rm-toaster.component.html',
  styleUrls: ['./rm-toaster.component.scss']
})
export class RMToasterComponent implements OnInit {
  @Input() title: string;
  @Input() message: string;
  @Input() mode: modes;
  public allModes = modes;
  public isVisible = false;
  constructor(private toast: RMToasterService) {
    this.toast.toaster.subscribe(
      newToast => {
        this.message = newToast.message;
        this.title = newToast.title;
        this.isVisible = newToast.isVisible;
        this.mode = newToast.mode;
      }
    )
  }

  ngOnInit(): void {
    if (!this.mode) {
      this.mode = modes.primary;
    }
  }

  public close() {
    this.toast.close();
  }
}
