import { Component, Input, OnInit, Output, EventEmitter, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { actions, companyLists, DropDownListOption, RelationList } from '@roadmate/roadmate-common';
import { FireStoreService } from '../../services/fire.store';
import { MessengerService } from '../../services/messenger.service';



@Component({
  selector: 'rm-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss']
})
export class MultiSelectComponent implements OnInit, OnDestroy {
  @Input() array: DropDownListOption[] = [];
  @Input() itemName: string;
  @Input() placeholder: string;
  @Input() parentForm: FormGroup;
  @Input() property: string;
  @Input() autoSelect = true;
  @Input()
  public selectedItems: DropDownListOption[] = [];
  @ViewChild('ddl', { read: ElementRef, static: false })
  public ddl: ElementRef;
  public showDDL = false;
  @Output() changeEmitter: EventEmitter<DropDownListOption[]> = new EventEmitter<DropDownListOption[]>();
  public showDropDownListOption = false;
  private subs = [];
  constructor(
    private messenger: MessengerService,
    // private fs: FireStoreService
  ) { }

  ngOnInit(): void {
    this.subs.push( this.messenger.dom.subscribe(
      dom => {
        if (this.ddl && this.ddl.nativeElement &&  !this.ddl.nativeElement.contains(dom)) {
          this.showDDL = false;
        }
      }
    ));
    this.subs.push( this.messenger.parcel.subscribe(
      parcel => {
        if (parcel.action === actions.companyLists && this.property === 'beneficiaryGroup') {
          const newList: RelationList[] = [...parcel.data];
          this.array = newList.find(item => item.name === companyLists.groups).list;
        }
      }
    ));
    // this.initBeneficiaryGroupList();
    // this.initMerchantList();
    if (this.array && this.array.length === 1 && this.autoSelect) {
      this.selectItem(0);
    }
  }

  // private async initMerchantList() {
  //   if (this.property !== 'merchants') {
  //     return;
  //   }
  //   const list = await this.fs.getOneList('merchants');
  //   this.array = list;
  // }

  // private initBeneficiaryGroupList() {
  //   if (this.property === 'beneficiaryGroup') {
  //     this.setCompanyOrdersArrayFromGroups();
  //   }
  // }

  // private setCompanyOrdersArrayFromGroups() {
  //   this.array = this.fs.getListByName(companyLists.groups);
  // }

  public selectItem(index: number) {
    const selectedItem = this.array[index];
    const item = this.selectedItems.find(el => el.value === selectedItem.value);
    if (item) {
      this.selectedItems = this.selectedItems.filter(el => el.value !== selectedItem.value);
      if (this.parentForm && this.property) {
        this.removeFromArray(item);
      }
    } else {
      this.selectedItems = this.selectedItems.concat(selectedItem);
      if (this.parentForm && this.property) {
        this.addItemToFormArray(selectedItem);
      }
    }
    this.changeEmitter.emit(this.selectedItems);
  }

  private addItemToFormArray(item: DropDownListOption) {
    const formArray = this.parentForm.get(this.property) as FormArray;
    if (item.img) {
      formArray.push(
        new FormGroup({
          label: new FormControl(item.label),
          value: new FormControl(item.value),
          img: new FormControl(item.img)
        })
      );
    } else {
      formArray.push(
        new FormGroup({
          label: new FormControl(item.label),
          value: new FormControl(item.value)
        })
      );
    }
    this.parentForm.get(this.property).markAsDirty();
  }

  private removeFromArray(item: DropDownListOption) {
    const values = this.parentForm.get(this.property).value as DropDownListOption[];
    const index = values.indexOf(values.find(el => el.value === item.value));
    if (index > -1) {
      const formArray = this.parentForm.get(this.property) as FormArray;
      formArray.removeAt(index);
      this.parentForm.get(this.property).markAsDirty();
    }
  }

  ngOnDestroy(): void {
    this.subs.forEach(el => el.unsubscribe());
  }
}
