import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'rm-render-datetime',
  template: `
    {{value | rm_date: true}}
  `,
})
export class DateRenderComponent implements OnInit {

  renderValue: string;

  @Input() value: string;

  ngOnInit() {}

}
