import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExpenseLineStatusComponent } from './expenseline-status.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [ExpenseLineStatusComponent],
  imports: [
    CommonModule,
    TranslateModule
  ],
  exports: [ExpenseLineStatusComponent],
  providers: [],
})
export class ExpenseLineStatusModule {}
