import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FireAuthService, FireFunctionsService, FireStoreService, RMToasterService } from '../../services';
import { LocalDataSource } from 'ng2-smart-table';
import { Subscription } from 'rxjs';
import { FormDefinition, RefundRequestType, asyncForEach, fieldTypes } from '@roadmate/roadmate-common';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'rm-ng2-smart-exporter',
  templateUrl: './ng2-smart-exporter.component.html',
  styleUrls: ['./ng2-smart-exporter.component.scss']
})
export class Ng2SmartTablerExporterComponent implements OnInit {
  private selections = 0;
  private rows: any[];
  @Input()
  showExporter = false;
  @Input() settings;
  @Input() set data(rows: any[]) {
    this.rows = rows;
    this.setData();
  };
  @Input() showCount = false;
  @Input() objectName: string;
  @Input() fileHeaders: string[];
  @Input() displayDownload = true;
  @Input()
  agentRef = '';
  @Input()
  companyRef = '';
  @Output()
  userRowsSelectedEmitter: EventEmitter<any[]> = new EventEmitter();
  @Output()
  rowSelectedEmitter: EventEmitter<any[]> = new EventEmitter();
  @Output()
  updateActionEmitter: EventEmitter<any> = new EventEmitter();
  @Output()
  deleteActionEmitter: EventEmitter<any> = new EventEmitter();
  @Output()
  clickActionEmitter: EventEmitter<any> = new EventEmitter();
  public currentFile: string;
  public list: any;
  public arrayOnDisplay: any[] = [];
  public loading = false;
  private subs: Subscription[] = [];
  private definition: FormDefinition;
  constructor(
    private fs: FireStoreService,
    private ff: FireFunctionsService,
    private toast: RMToasterService,
    private translate: TranslateService,
    private fa: FireAuthService
  ) { }

  ngOnInit(): void {
    this.setData();
    if ((!this.agentRef || !this.companyRef) && !this.fa.userRoles.superadmin) {
      this.agentRef = this.fs.currentAgent.ref;
      this.companyRef = this.fs.currentCompany.ref;
    }
  }

  private async setData() {
    if (!this.list) {
      this.list = new LocalDataSource(this.rows);
      this.arrayOnDisplay = [...this.rows];
      this.subs.push(
        this.list.onChanged().subscribe(el => {
          this.arrayOnDisplay = this.list.filteredAndSorted;
      }));
    } else {
      await this.list.empty();
      await this.list.load(this.rows);
    }
  }

  public async exportSelection() {
    if (!this.arrayOnDisplay.length) {
      await this.toast.showTranslatedError(
        'no_data_available_to_export'
      );
      return;
    }
    if ((!this.agentRef || !this.companyRef) && !this.fa.userRoles.superadmin) {
      await this.toast.showTranslatedError();
      return;
    }
    this.loading = true;
    try {
      this.currentFile = '';
      const list = await this.fs.getObjectsList();
      this.definition = JSON.parse(JSON.stringify(list[this.objectName])) as FormDefinition;
      const objectsToExport: any[] = JSON.parse(JSON.stringify(this.arrayOnDisplay));
      const cleanedArray: any[] = [];
      const headers: string[] = Object.keys(this.definition).filter(key => this.definition[key].lightDisplay).map(
        el => el
      ).sort(
        (a, b) => this.definition[a].order - this.definition[b].order
      );
      let linkIndex = -1;
      let linkPropertyName = '';
      let linkLabel = '';
      headers.forEach((propertyName, index) => {
        if (this.definition[propertyName].displaytype === fieldTypes.url) {
          linkIndex = index;
          linkPropertyName = propertyName;
          linkLabel = this.settings.columns[propertyName].title;
        }
      });
      await asyncForEach (
        objectsToExport, async (item) => {
          const cleanedObject: any = {};
          await asyncForEach(headers, async (key) => {
            if (this.definition[key].displaytype === fieldTypes.translate) {
              cleanedObject[key] = await this.translate.get(item[key]).toPromise();
            } else if (this.definition[key].displaytype === fieldTypes.paymentType) {
              cleanedObject[key] = await this.translate.get(`TYPE_${item[key]}`).toPromise();
            } else {
              cleanedObject[key] = item[key];
            }
          });
          if (Object.keys(cleanedObject).length) {
            cleanedArray.push(cleanedObject);
          }
        }
      );
      const response = await this.ff.exportFileFromSelection(
        this.agentRef,
        this.companyRef,
        this.fileHeaders ?? headers,
        cleanedArray,
        this.objectName,
        linkIndex,
        linkPropertyName,
        linkLabel
      );
      this.currentFile = response.result?.url ?? '';
      if (this.currentFile) {
        await this.toast.showTranslatedSuccess(
          'your_file_is_ready_to_download'
        );
      } else {
        await this.toast.showTranslatedError();
        return;
      }
    } catch (e) {
      await this.toast.showTranslatedError();
    } finally {
      this.loading = false;
    }
  }

  public userSelectRow(data: {selected: any[]}) {
    this.userRowsSelectedEmitter.emit(data.selected);
  }

  public selectItem(obj: {data: any}) {
    if (this.selections === 0) {
      this.selections = 1;
      return;
    }
    this.selections++;
    this.rowSelectedEmitter.emit(obj.data);
  }

  public deleteAction(selectedRow: {data: any, source: any}) {
    this.deleteActionEmitter.emit(selectedRow.data);
  }

  public editAction(selectedRow: {data: any, source: any}) {
    this.updateActionEmitter.emit(selectedRow.data);
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }
}
