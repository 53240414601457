import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'rm_img_file_extension'})
export class FileExtensionImagePipe implements PipeTransform {
  transform(fileName: string): string {
    if (!fileName || fileName.length < 4) {
      return '/assets/svg/unknown.svg'
    }
    const extension = fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length).toLocaleLowerCase();
    switch (extension) {
      case 'jpeg':
      case 'jpg':
        return '/assets/svg/jpg.svg';
      case 'png':
        return '/assets/svg/png.svg'
      case 'pdf':
        return '/assets/svg/pdf.svg'
      case 'docx':
      case 'doc':
        return '/assets/svg/docx.svg';
      case 'xls':
        return '/assets/svg/xls.svg';
      case 'xlsx':
        return '/assets/svg/xlsx.svg';
      default:
        return '/assets/svg/unknown.svg';
    }
  }
}