import { Pipe, PipeTransform } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Pipe({
  name: 'formcontrol_valid',
  pure: false
})
export class FormControlValidPipe implements PipeTransform {
  transform(formGroup: FormGroup, property: string): boolean {
    if (!formGroup
        || !(formGroup instanceof FormGroup)
        || !property
        || !formGroup.get(property)) {
      return false;
    }
    return formGroup.get(property).valid;
  }
}
