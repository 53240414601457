import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector:'rm-hasinvoice',
  template: `
  <div *ngIf="value">
    <i class="cil-paperclip" *ngIf="value"></i>
    <a href="{{value}}" target="_blank" *ngIf="value">{{'open_invoice'|translate}}</a>
  </div>
  <div *ngIf="!value && rowData?.email && rowData?.merchantName">
    <a href="mailto:{{rowData.email}}?subject=Facture manquante sur votre transaction {{rowData.merchantName}}" target="_blank">
      {{'ask_for_invoice'|translate}}  
    </a>
  </div>
  <div *ngIf="!value && rowData?.email && !rowData?.merchantName">
    <a href="mailto:{{rowData.email}}?subject=Facture manquante sur une de vos transactions" target="_blank">
      {{'ask_for_invoice'|translate}}  
    </a>
  </div>
  <div *ngIf="!value && !rowData?.email">
    <a href="mailto:{{rowData.email}}?subject=Facture manquante sur une de vos transactions" target="_blank">
      {{'no_invoice_attached'|translate}}  
    </a>
  </div>
  `,
})
export class HasInvoiceRenderComponent implements OnInit {

  @Input() value: boolean;
  @Input() rowData: any;

  ngOnInit() {
    if (!this.value) {
      console.log('rowData', this.rowData);
    }
  }

}
