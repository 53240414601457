import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'rm-render-datetime',
  template: `
    {{value | number: '1.2-2'}}€
  `,
})
export class CurrencyRenderComponent implements OnInit {

  renderValue: string;

  @Input() value: number;

  ngOnInit() {}

}
