import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'rm_safe' })
export class UrlSanitizerPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }
  transform(value, style = '') {
    if (!style) {
      return this.sanitizer.bypassSecurityTrustStyle(value);
    } else if (style === 'base64') {
      return this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/jpg;base64,${value}`);
    } 
    return this.sanitizer.bypassSecurityTrustResourceUrl(value);
  }
}
