import { Component, Input, OnInit } from '@angular/core';
import { AbstractEntryComponent } from '../abstract.entry-component';
import { Treezor } from '@roadmate/roadmate-common';

@Component({
  selector: 'rm-deleted-user-modal',
  template: `
    <rm-user-refunds-transactions *ngIf="targetObject" [appUser]="targetObject"></rm-user-refunds-transactions>
  `
})
export class DeleteUserDetailsComponent extends AbstractEntryComponent<any> implements OnInit {
  public set data(value) {
    if (!value) {
      return;
    }
    this.targetObject = value.targetObject;
  }
  public targetObject: Treezor.AppUser;
  constructor() {
    super();
  }

  ngOnInit(): void { }
}
