export enum editUserActions {
  makeAdmin = 'makeAdmin',
  makeAccountManager = 'makeAccountManager',
  revokeAccountManager = 'revokeAccountManager',
  makeEmployee = 'employee',
  revokeAdmin = 'revokeAdmin',
  suspendUser = 'suspendUser',
  rehabilitateUser = 'rehabilitateUser',
  convertPhysical = 'convertPhysical',
  lockCard = 'lockCard',
  unlockCard = 'unlockCard',
  throwCard = 'throwCard',
  createCard = 'createCard',
  generateNewCard = 'generateNewCard',
  sendInviteToEmployee = 'sendInviteToEmployee',
  deleteUser= 'deleteUser',
  destroyCard = 'destroyCard',
};
