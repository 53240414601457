import { Pipe, PipeTransform } from '@angular/core';
import { ExpenseLineStatus } from '@roadmate/roadmate-common';

@Pipe({ name: 'rm_filter' })
export class FilterArrayPipe implements PipeTransform {
  transform(items: any[], propertyName: string, propertyValue: any, searchForEqual = true): any {
    if (!items || !items.length) {
      return [];
    }
    if (searchForEqual) {
      return items.filter(el => el[propertyName] === propertyValue);
    } else {
      return items.filter(el => el[propertyName] !== propertyValue);
    }
  }
}

@Pipe({
  name: 'rm_filter_explines',
  pure: false
})
export class FilterValuesInArrayPipe implements PipeTransform {
  transform(items: any[], propertyName: string, propertyValues: string, searchForEqual = true): any {
    if (!items || !items.length) {
      return [];
    }
    if (searchForEqual) {
      switch (propertyValues) {
        case 'Provisionned':
          return items.filter(el => [ExpenseLineStatus.Scheduled, ExpenseLineStatus.Provisionned].indexOf(el[propertyName]) > -1);
        case 'Available':
          return items.filter(el => [ExpenseLineStatus.Active, ExpenseLineStatus.Available].indexOf(el[propertyName]) > -1);
        case 'Expired':
          return items.filter(el => [ExpenseLineStatus.Expired, ExpenseLineStatus.Suspended].indexOf(el[propertyName]) > -1);
      }
    } else {
      switch (propertyValues) {
        case 'Provisionned':
          return items.filter(el => [ExpenseLineStatus.Scheduled, ExpenseLineStatus.Provisionned].indexOf(el[propertyName]) === -1);
        case 'Available':
          return items.filter(el => [ExpenseLineStatus.Active, ExpenseLineStatus.Available].indexOf(el[propertyName])  === -1);
        case 'Expired':
          return items.filter(el => [ExpenseLineStatus.Expired, ExpenseLineStatus.Suspended].indexOf(el[propertyName])  === -1);
      }
    }
    return [];
  }
}
