import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardComponent } from './mat-card/card/mat-card.component';
import { ProgressBarComponent } from './progress/mat-progress-bar.component';
import { CardContentComponent } from './mat-card/content/mat-card-content.component';
import { CardHeaderComponent } from './mat-card/header/mat-card-header.component';
import { CardTitleComponent } from './mat-card/title/mat-card-title.component';
import { CardFooterComponent } from './mat-card/footer/mat-card-footer.component';
import { MainCardContainerComponent } from './main-card-container/main-card-container.component';
import { TranslateModule } from '@ngx-translate/core';

const components = [
  MatCardComponent,
  ProgressBarComponent,
  CardContentComponent,
  CardHeaderComponent,
  CardTitleComponent,
  CardFooterComponent,
  MainCardContainerComponent
]


@NgModule({
  declarations: [...components],
  imports: [ CommonModule, TranslateModule ],
  exports: [...components],
  providers: [],
})
export class MigrationModule {}
