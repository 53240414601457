import { Component, OnInit } from '@angular/core';
import { AbstractEntryComponent } from '../abstract.entry-component';
import { RoadMateTransfer } from '@roadmate/roadmate-common';

@Component({
  selector: 'rm-confirm-budget-adjustment',
  templateUrl: './confirm-budget-adjustment.component.html'
})
export class ConfirmBudgetAdjustmentComponent extends AbstractEntryComponent<boolean> implements OnInit {
  public transfer: Partial<RoadMateTransfer>;
  public email = '';
  public set data(value) {
    if (!value) {
      return;
    }
    this.transfer = value.transfer;
    this.email = value.email;
  }
  constructor() {
    super();
  }

  ngOnInit(): void { }

}
