import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { modes } from '../static/notifications';
import { TranslateService } from '@ngx-translate/core';

export interface RMToast {
  title?: string;
  message?: string;
  duration?: number;
  isVisible: boolean;
  mode?: modes;
}
@Injectable({
  providedIn: 'root'
})
export class RMToasterService {
  private currentToast: RMToast = {
    isVisible: false
  };
  public toaster: BehaviorSubject<RMToast> = new BehaviorSubject<RMToast>({
    isVisible: false
  });

  constructor(
    private translator: TranslateService
  ) {}

  public showToast(toast: RMToast){
    this.currentToast = toast;
    this.currentToast.mode = this.currentToast.mode ? this.currentToast.mode : modes.primary;
    this.toaster.next(this.currentToast);
    if (toast.duration && toast.duration > 1500) {
      setTimeout(() => {
        this.close();
      }, toast.duration);
    }
  }

  public showSuccessToast(message: string, duration = 5000){
    this.currentToast = {
      isVisible: true,
      title: 'Parfait',
      message,
      mode: modes.success
    };
    this.currentToast.mode = this.currentToast.mode ? this.currentToast.mode : modes.primary;
    this.toaster.next(this.currentToast);
    setTimeout(() => {
      this.close();
    }, duration);
  }

  public showWarningToast(message: string, title = '', duration = 5000){
    this.currentToast = {
      isVisible: true,
      title: title ?? 'Attention',
      message,
      mode: modes.warning
    };
    this.currentToast.mode = this.currentToast.mode ? this.currentToast.mode : modes.primary;
    this.toaster.next(this.currentToast);
    setTimeout(() => {
      this.close();
    }, duration);
  }

  public showGenericError (message = 'unknown_error') {
    this.currentToast = {
      isVisible: true,
      title: 'Oups',
      message,
      mode: modes.danger
    };
    this.toaster.next(this.currentToast);
    setTimeout(() => {
      this.close();
    }, 5000);
  }

  public async showTranslatedError (messageCode = 'unknown_error', duration = 5000) {
    const message = await this.translator.get(messageCode).toPromise();
    this.currentToast = {
      isVisible: true,
      title: 'Oups',
      message,
      mode: modes.danger
    };
    this.toaster.next(this.currentToast);
    setTimeout(() => {
      this.close();
    }, duration);
  }

  public async showTranslatedSuccess (messageCode = 'perfect', duration = 5000) {
    const message = await this.translator.get(messageCode).toPromise();
    this.currentToast = {
      isVisible: true,
      title: 'Succès',
      message,
      mode: modes.success
    };
    this.toaster.next(this.currentToast);
    setTimeout(() => {
      this.close();
    }, duration);
  }

  public async showTranslatedWarning (messageCode = 'fyi', duration = 5000) {
    const message = await this.translator.get(messageCode).toPromise();
    this.currentToast = {
      isVisible: true,
      title: 'Pour info',
      message,
      mode: modes.warning
    };
    this.toaster.next(this.currentToast);
    setTimeout(() => {
      this.close();
    }, duration);
  }

  public close() {
    this.currentToast = {
      isVisible: false
    };
    this.toaster.next(this.currentToast);
  }
}
