<div class="row" *ngIf="request" style="margin-bottom: 30px;">
  <div class="col-3">
    <div class="card fixed-card mb-3">
      <div class="card-header">
        <h5 class="bold">
          Profil
        </h5>
      </div>
      <div class="card-body">
        <ul class="list-group list-group-flush">
          <li class="list-group-item"><strong>Nom :</strong> {{request.lastname}} {{request.firstname}}</li>
          <li class="list-group-item"><strong>Email :</strong> {{request.email}}</li>
          <li class="list-group-item"><strong>Budget :</strong> <span class="badge text-bg-primary">{{request.product | translate}}</span></li>
          <li class="list-group-item" *ngIf="isRTT"><strong>Prise en charge réelle :</strong> {{percentage}}%</li>
          <li class="list-group-item" [ngClass]="{'red': companyRefunRate !== percentage}" *ngIf="isRTT"><strong>Prise en charge souhaitée :</strong> {{companyRefunRate}}%</li>
          <li class="list-group-item"><strong>Type :</strong> {{request.createdAt | rm_date}}</li>
          <!-- <li class="list-group-item"></li>
          <li class="list-group-item"></li> -->
        </ul>
      </div>
    </div>
  </div>
  <div class="col-3">
    <div class="card fixed-card mb-3">
      <div class="card-header">
        <h5 class="bold">
          Demande
        </h5>
      </div>
      <div class="card-body">
        <ul class="list-group list-group-flush">
          <li class="list-group-item"><strong>Type :</strong> <span class="badge text-bg-primary">
            {{request.type | translate}}
            </span>
          </li>
          <li class="list-group-item" *ngIf="!isIKV"><strong>Marchant déclaré : </strong> {{request.merchantName}}</li>
          <li class="list-group-item" *ngIf="!isIKV"><strong>Montant payé : </strong> {{request.invoices.amount | currency:'EUR'}}</li>
          <li class="list-group-item" *ngIf="isIKV"><strong>Km parcourrus : </strong> {{request.distance}} Km</li>
          <li class="list-group-item" *ngIf="isIKV"><strong>Dates : </strong> {{request.from | rm_display_month_day}} - {{request.to | rm_display_month_day}}</li>
          <li class="list-group-item"><strong>Montant à rembourser : </strong> {{request.amount | currency:'EUR'}}</li>
          <li class="list-group-item" *ngIf="isMonthly"><strong>Mois : </strong>  {{request.month | rm_month_zero_index: request.year}}</li>
          <li class="list-group-item"><strong>Justificatif : </strong> 
            <div class="row">
              <div class="col-12">
                <div class="row" *ngIf="request.invoices?.files?.length">
                  <div 
                    *ngFor="let item of request.invoices.files"
                    class="col-auto invoice-icon rm-clickable"
                    (click)="openFile(item.url)"
                  >
                    <img [src]="item.name | rm_img_file_extension" [title]="item.name" [alt]="item.name">
                    <div class="smaller-text">
                      {{item.name|truncate:15:false:true}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div class="col-3">
    <div class="card fixed-card mb-3">
      <div class="card-header">
        <h5 class="bold">
          Traitement
        </h5>
      </div>
      <div class="card-body">
        <ul class="list-group list-group-flush">
          <li class="list-group-item"><strong>Merchant : </strong> {{request.merchantRef | display_ddl_value: merchantsList}}</li>
          <li class="list-group-item"><strong>Snippet : </strong> {{request.addHtmlSnippet | display_ddl_value: htmlSnippetsList}}</li>
          <li class="list-group-item"><strong>Plateforme : </strong> 
            <span class="badge text-bg-primary" *ngIf="request.createdByDevice">
              {{request.createdByDevice | translate}}
            </span>
            <span class="badge text-bg-primary" *ngIf="request.createdByVersion">
              {{request.createdByVersion | translate}}
            </span>
            <span class="badge text-bg-primary" *ngIf="request.version && request.version !== request.createdByVersion">
              {{request.version | translate}}
            </span>
            <span class="badge text-bg-primary" *ngIf="request.deviceType && request.deviceType !== request.createdByDevice">
              {{request.deviceType | translate}}
            </span> 
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="col-3">
    <div class="card fixed-card mb-3">
      <div class="card-header">
        <h5 class="bold">
          Document AI
        </h5>
      </div>
      <div class="card-body">
        <ul class="list-group list-group-flush">
          <li class="list-group-item" *ngIf="!request.documentaiResponses">
            Document non traité
          </li>
          <ng-container *ngIf="request.documentaiResponses">
            <ng-container *ngFor="let item of request.documentaiResponses">
              <li class="list-group-item">
                <strong>Nom : </strong> {{item.traveler_name}}
              </li>
              <li class="list-group-item">
                <strong>Document : </strong> {{item.supplier_name}}
              </li>
              <li class="list-group-item">
                <strong>Date émission : </strong> {{item.receipt_date}}
              </li>
              <li class="list-group-item">
                <strong>Date de début : </strong> {{item.start_date}}
              </li>
              <li class="list-group-item">
                <strong>Date de fin : </strong> {{item.end_date}}
              </li>
              <li class="list-group-item">
                <strong>Montant : </strong> {{item.total_amount}}
              </li>
              <li class="list-group-item" *ngIf="request.similarInvoiceDetected">
                <strong class="red">Un justificatif similaire a déjà été </strong>
              </li>
              <li class="list-group-item" *ngIf="request.similarInvoiceUrl">
                <a href="{{request.similarInvoiceUrl}}" target="_blank">Visualiser le justificatif en question</a>
              </li>
              <li class="list-group-item" *ngIf="request.similarRequestRef">
                <strong>Ref de la demande : </strong> {{request.similarRequestRef}}
              </li>
            </ng-container>
          </ng-container>
        </ul>
      </div>
    </div>
    
  </div>
</div>
<div class="row">
  <div class="col-6">
    <h3>
      Fusionner cette demande avec une autre :
    </h3>
    <div class="form-group">
      <input
        type="text"
        class="form-control"
        [(ngModel)]="destinationRefundRef"
        placeholder="Ref de la demande à fusionner"
      >
    </div>
    <p>
      * La ref de la demande saisie ci-dessus prendra les valeur de la demande actuelle qui sera supprimée définitivement.
    </p>
    <div class="form-group">
      <button
        [disabled]="loading || !destinationRefundRef"
        class="btn btn-block btn-primary"
        (click)="mergeRequest()"
        type="button"
      >
        <span *ngIf="!loading">
          {{'merge' | translate}}
        </span>
        <span *ngIf="loading" class="c-loading-button-spinner spinner-border spinner-border-sm"></span>
      </button>
    </div>
  </div>
</div>
<div class="row" *ngIf="missingSettingForCompany">
  <div class="col-12">
    <p class="red bold">
      ⚠️ Les paramètres de cette entreprise (taux de remboursement et nb de mois à rembourser) n'a pas pu être pris en compte.
    </p>
  </div>
</div>
<div class="row" *ngIf="inconsistentAnnualRefundAmountMessage">
  <div class="col-12">
    <p class="red bold" [innerHTML]="inconsistentAnnualRefundAmountMessage"></p>
  </div>
</div>
<div class="row push-down" *ngIf="inconsistentAnnualRefundAmountMessage">
  <div class="col-2">
    <button class="btn btn-primary" type="button" (click)="checkIfNavigAnnuel(true)" [disabled]="loading">
      {{'apply_change' | translate}}
    </button>
  </div>
</div>
<div class="row">
  <div class="col-6">
    <h3>
      Notes à prendre en compte à propos de cet utilisateur
    </h3>
  </div>
  <div class="col-6">
    <h3>
      Notifications et messages envoyés à cet utilisateur
    </h3>
  </div>
  <div class="col-6">
    <p style="margin-bottom: 67px;">
      Ajouter une Note :
    </p>
    <div class="form-group">
      <textarea
        [(ngModel)]="newNote"
        class="form-control"
        rows="3"
      ></textarea>
    </div>
    <div class="form-group">
      <button
        [disabled]="loading || !newNote"
        class="btn btn-block btn-primary"
        (click)="addNote()"
        type="button"
      >
        <span *ngIf="!loading">
          {{'save' | translate}}
        </span>
        <span *ngIf="loading" class="c-loading-button-spinner spinner-border spinner-border-sm"></span>
      </button>
    </div>
    <ng-container *ngIf="notes?.length; else emptynotes">
      <p>
        Liste des notes écrites :
      </p>
      <div class="card note" *ngFor="let item of notes">
        <div class="card-header">
          {{item.createdAt | rm_iso_to_french_datetime}} | {{item.createdBy}}
        </div>
        <div class="card-body" [innerHTML]="item.content"></div>
      </div>
    </ng-container>
    <ng-template #emptynotes>
      <p>
        Aucune note pour cet utilisateur
      </p>
      <img src="/assets/svg/empty.svg">
    </ng-template>
  </div>
  <div class="col-6">
    <p>
      Envoyer un Message ou notification :
    </p>
    <div class="form-group">
      <div class="input-group">
        <span class="input-group-prepend">
          <span class="btn btn-primary" type="button">
            <i class="cil-calendar"></i>
          </span>
        </span>
        <input class="form-control" type="text" [(ngModel)]="newMessageSubject" placeholder="01/03/2021">
      </div>
    </div>
    <div class="form-group">
      <textarea
        [(ngModel)]="newMessage"
        class="form-control"
        rows="3"
      ></textarea>
    </div>
    <div class="row">
      <div class="col-6">
        <button
          [disabled]="loading || !newMessage || !newMessageSubject"
          class="btn btn-block btn-primary"
          (click)="sendMessage()"
          type="button"
        >
          <span *ngIf="!loading">
            {{'save' | translate}}
          </span>
          <span *ngIf="loading" class="c-loading-button-spinner spinner-border spinner-border-sm"></span>
        </button>
      </div>
      <div class="col-6">
        <rm-single-select
          [array]="messageCanals"
          [resetSelectedITem]="'notification'"
          (changeEmitter)="changeCanal($event)"
          [fullWidth]="true"
        ></rm-single-select>
      </div>
    </div>
    <ng-container *ngIf="notifications?.length; else emptynotifications">
      <p>
        Liste des notes écrites :
      </p>
      <div class="card note" *ngFor="let item of notifications">
        <div class="card-header">
          {{item.createdAt | rm_iso_to_french_datetime}} | {{item.createdBy}} | <strong>{{item.finalCanal}}</strong>
        </div>
        <div class="card-body">
          <strong>{{item.notification.title}} : </strong>
          <p [innerHTML]="item.notification.body"></p>
        </div>
      </div>
    </ng-container>
    <ng-template #emptynotifications>
      <p>
        Aucune notification ou message envoyé à cet utilisateur
      </p>
      <img src="/assets/svg/empty.svg">
    </ng-template>
  </div>
</div>