import { Injectable } from '@angular/core';
import { FireAuthService } from './fire.auth';
import { AngularFireStorage } from '@angular/fire/storage';
import { fileStoringWorkflows, FolderNames, PathTo } from '@roadmate/roadmate-common';
import { FireStoreService } from './fire.store';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';

export interface FileUploadProgress {
  fileName: string;
  progress: number;
}

@Injectable({
  providedIn: 'root'
})
export class UploadFileService {
  public progress$: BehaviorSubject<FileUploadProgress[]> = new BehaviorSubject<FileUploadProgress[]>([]);
  private progress: FileUploadProgress[] = [];
  constructor(
    private auth: FireAuthService,
    private fs: FireStoreService,
    private storage: AngularFireStorage
  ) { }

  public uploadBase64File(
    fileSavingWorkFlow: fileStoringWorkflows,
    fileName: string,
    base64Data: string,
    contentType = 'application/pdf') {
    return new Promise<string>((res, rej) => {
      const filePath = this.getFileName(fileSavingWorkFlow, fileName);
      const storageRef = this.storage.ref(filePath);
      const blob = this.b64toBlob(base64Data, contentType);
      const task = this.storage.upload(filePath, blob, {
        contentType
      });
      task.catch(error => {
        rej(error);
      });
      task.percentageChanges().subscribe(
        percent => {
          const currentFile = this.progress.find(el => el.fileName === fileName);
          if (!currentFile) {
            this.progress.push({
              fileName,
              progress: Math.floor(percent)
            });
          } else {
            currentFile.progress = Math.floor(percent);
          }
          if (percent === 100) {
            this.progress = this.progress.filter(el => el.fileName !== fileName);
          }
          this.progress$.next([...this.progress]);
        }
      );
      task.snapshotChanges()
      .pipe(
        finalize(() => {
          storageRef.getDownloadURL().subscribe(
            downloadURL => {
              if (downloadURL?.length) {
                res(downloadURL);
              } else {
                rej(new Error('Failed to load file to Storage'));
              }
            }
          );
        })
      )
      .subscribe();
    });
  }

  public startUploadFromWeb(file: File, fileName: string, fileSavingWorkFlow): Promise<string> {
    const prom = new Promise<string>((res, rej) => {
      const filePath = this.getFileName(fileSavingWorkFlow, fileName);
      const storageRef = this.storage.ref(filePath);
      const task = this.storage.upload(filePath, file);
      task.percentageChanges().subscribe(
        percent => {
          const currentFile = this.progress.find(el => el.fileName === file.name);
          if (!currentFile) {
            this.progress.push({
              fileName: file.name,
              progress: Math.floor(percent)
            });
          } else {
            currentFile.progress = Math.floor(percent);
          }
          if (percent === 100) {
            this.progress = this.progress.filter(el => el.fileName !== file.name);
          }
          this.progress$.next([...this.progress]);
        }
      );
      task.snapshotChanges()
      .pipe(
        finalize(() => {
          storageRef.getDownloadURL().subscribe(
            downloadURL => {
              if (downloadURL?.length) {
                res(downloadURL);
              } else {
                rej(new Error('Failed to load file to Storage'));
              }
            }
          );
        })
      )
      .subscribe();
    });
    return prom;
  }

  public async getSignedUrl(path: string): Promise<string> {
    return await this.storage.ref(path).getDownloadURL().toPromise();
  }

  private getFileName(fileSavingWorkFlow: fileStoringWorkflows, fileSystemName: string): string {
    let fileName = `/${this.auth.user.uid}/${(new Date()).getTime().toString()}`;
    const {agentRef, companyRef} = this.fs.currentAppUser;
    switch (fileSavingWorkFlow) {
      case fileStoringWorkflows.invoice:
        fileName = `/${PathTo.companyEmployeeInvoices(agentRef, companyRef)}/${fileSystemName}`;
        break;
      case fileStoringWorkflows.attestations:
        fileName = `/${PathTo.company(agentRef, companyRef)}/${fileStoringWorkflows.attestations}/${fileSystemName}`;
        break;
      case fileStoringWorkflows.ikv:
        fileName = `/${PathTo.company(agentRef, companyRef)}/${fileStoringWorkflows.ikv}/${fileSystemName}`;
        break;
      default:
        fileName = `/${PathTo.company(agentRef, companyRef)}/${FolderNames.assets}/${fileSystemName}`;
        break;
    }
    return fileName;
  }

  public b64toBlob(b64Data: string, contentType = 'image/jpg', sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }
}
