import { Component, Input, OnInit } from '@angular/core';

import {RoadMateDocument, Treezor } from '@roadmate/roadmate-common';

@Component({
  selector: 'rm-transactions-render',
  template: `
    <div class="custom-tooltip">
      <span *ngFor="let tr of value">
      {{tr.paymentStatus | translate}} >>
      </span>
      <div class="tooltip-content">
        <ul>
          <li *ngFor="let tr of value"><span></span>
            <div>
              <div class="title">{{tr.paymentAmount}}€</div>
              <div class="info">{{tr.paymentStatus | translate}}</div>
              <div class="type">{{tr.authorizationNote}}</div>
            </div><span class="number"><span>{{tr.authorizationIssuerTime | rm_iso_to_french_datetime}}</span></span>
          </li>
        </ul>
      </div>
    </div>
  `,
  styles: [`
    .custom-tooltip {
      position: relative;
      display: inline-block;
    }
    .custom-tooltip .tooltip-content {
      display: none;
      margin: 0;
      list-style: none;
      position: relative;
      padding: 16px 16px 1px 100px;
      color: #fff;
      font-size: 13px;
      background: #0f3057!important;
      background: linear-gradient(45deg,#0f3057 0%,#0f7097 100%)!important;
      border-color: #081027!important;
      z-index: 10;
      border-radius: 16px;
    }
    .custom-tooltip:hover .tooltip-content {
      display: block;
      position: absolute;
      bottom: -100%;
      left: -320px;
    }
    .custom-tooltip .tooltip-content ul {
      list-style-type: none;
    }
    .custom-tooltip .tooltip-content ul li {
      position: relative;
      /* margin-left: 30px; */
      background-color: rgba(255, 255, 255, 0.2);
      padding: 5px;
      border-radius: 6px;
      width: 150px;
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.08);
    }
    .custom-tooltip .tooltip-content ul li:not(:first-child) {
      /* margin-top: 60px; */
    }
    .custom-tooltip .tooltip-content ul li > span {
      width: 2px;
      height: 100%;
      background: #fff;
      left: -20px;
      top: 0;
      position: absolute;
    }
    .custom-tooltip .tooltip-content ul li > span:before, .custom-tooltip .tooltip-content ul li > span:after {
      content: "";
      width: 8px;
      height: 8px;
      border-radius: 50%;
      border: 2px solid #fff;
      position: absolute;
      background: #86b7e7;
      left: -3px;
      top: 0;
    }
    /* .custom-tooltip .tooltip-content ul li span:after {
      top: -5px;
    } */
    .custom-tooltip .tooltip-content ul li > div {
      margin-left: 10px;
    }
    .custom-tooltip .tooltip-content div .title, .custom-tooltip .tooltip-content div .type {
      font-weight: 600;
      font-size: 12px;
    }
    .custom-tooltip .tooltip-content div .info {
      font-weight: 300;
    }
    .custom-tooltip .tooltip-content div > div {
      margin-top: 0px;
    }
    .custom-tooltip .tooltip-content span.number {
      height: 100%;
    }
    .custom-tooltip .tooltip-content span.number span {
      position: absolute;
      font-size: 10px;
      left: -100px;
      font-weight: bold;
    }
    .custom-tooltip .tooltip-content span.number span:first-child {
      top: 0;
    }
    .custom-tooltip .tooltip-content span.number span:last-child {
      top: -5px;
    }
  `]
})
export class TransactionsRenderComponent implements OnInit {

  renderValue: string;
  @Input() value: Treezor.Card.CardTransaction[];
  ngOnInit() {
    this.renderValue = ''
  }

}
