import { Component, Input, OnInit } from '@angular/core';
import { DropDownListOption, MobilityAccount, Treezor, RoadMateRefundRequest } from '@roadmate/roadmate-common';
import { ChartOptions, ChartType } from 'chart.js';
import { Color, Label, SingleDataSet } from 'ng2-charts';
import { FireStoreService } from '../../../services';
import { TranslateService } from '@ngx-translate/core';
import { ObjectDefList } from '@roadmate/roadmate-common';
import { getTableSettings } from '../../../utils';

@Component({
  selector: 'rm-user-refunds-transactions',
  templateUrl: './user-refunds-transactions.component.html',
  styleUrls: ['./user-refunds-transactions.component.scss']
})
export class UserRefundsAndTransactionsComponent implements OnInit {
  @Input() public appUser: Treezor.AppUser;
  public isEmployee = false;
  public transactions: Treezor.Card.CardTransaction[] = [];
  // public refunds: RoadMateRefundRequest[] = [];
  public fetchingUserTransactions = false;
  public requestsSettings;
  public transactionSettings;
  public mobilityAccounts: MobilityAccount;
  public headersTranslated: string[] = [];
  public accountYears: DropDownListOption[] = [];
  public fmdUser = false;
  public transactionsSettings;
  public pieChartData: SingleDataSet = [0, 0, 0];
  public pieChartType: ChartType = 'pie';
  public pieChartOptions: ChartOptions = {
    responsive: true
  };
  public pieChartLegend = true;
  public pieChartPlugins = [];
  public chartReady = false;
  public pieChartLabels: Label[] = [
    'Forfait Mobilité Durable',
    'Prime Transport',
    'Remboursement Transport'
  ];
  public colors: Color[] = [
    {
      backgroundColor: ['#C3EDBF', '#FBB9C5', '#F9EFC7']
    }
  ];
  constructor(
    private fs: FireStoreService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.isEmployee = !!this.appUser.userId;
    if (this.isEmployee) {
      this.getUserTransactions();
    }
  }

  private async getUserTransactions() {
    this.fetchingUserTransactions = true;
    try {
      const {agentRef, companyRef, email, uid} = this.appUser;
      if (!agentRef || !companyRef || !email || !uid) {
        return;
      }
      const accounts = await this.fs.getMobilityAccountYears(agentRef, companyRef);
      this.accountYears = accounts.map(el => {
        return {
          value: `${el.year}`,
          label: `${el.year}`
        }
      });
      let currentYear = (new Date()).getFullYear();
      if (accounts.length === 1) {
        accounts[0].year = currentYear;
      }
      this.mobilityAccounts = await this.fs.getMobilityAccountYearEmployee(agentRef, companyRef, currentYear, uid);
      if (this.mobilityAccounts) {
        this.pieChartData = [
          this.mobilityAccounts.FMD,
          this.mobilityAccounts.PT,
          this.mobilityAccounts.RTT
        ];
        this.chartReady = true;
      }
      this.transactions = await this.fs.getUserRefundsAndTransactions(
        agentRef,
        companyRef,
        email
      );
      if (!this.transactions?.length) {
        return;
      }
      const list = await this.fs.getObjectsList();
      const cardTransactionDef = JSON.parse(JSON.stringify(list[ObjectDefList.cardTransactions]));
      const headers: string[] = Object.keys(cardTransactionDef).filter(key => cardTransactionDef[key].lightDisplay).map(
        el => el
      ).sort(
        (a, b) => cardTransactionDef[a].order - cardTransactionDef[b].order
      );
      if (this.appUser.userGroupIds?.find(el => el.value === 'FMD')) {
        this.fmdUser = true;
        cardTransactionDef.hasInvoices.lightDisplay = false;
        cardTransactionDef.productName.lightDisplay = false;
        cardTransactionDef.supportingFileLink.lightDisplay = true;
        cardTransactionDef.adminType.lightDisplay = true;
      } else {
        cardTransactionDef.hasInvoices.lightDisplay = true;
        cardTransactionDef.supportingFileLink.lightDisplay = false;
        cardTransactionDef.adminType.lightDisplay = false;
      }
      this.transactionsSettings = await getTableSettings(cardTransactionDef, this.translate, true, {
        add: false,
        edit: false,
        delete: false
      });
      headers.forEach(header => {
        const column = this.transactionsSettings.columns[header];
        this.headersTranslated.push(
          column?.title ?? header
        )
      });
    } catch (e) {
      console.error('', e);
    } finally {
      this.fetchingUserTransactions = false;
    }
  }
}
