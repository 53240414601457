import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'rm-render-boolean',
  template: `
    <span style="font-wight:bold"> {{value ? 'Oui' : 'Non'}} </span>
  `,
})
export class BooleanRenderComponent implements OnInit {

  renderValue: string;

  @Input() value: boolean;
  @Input() rowData: boolean;

  ngOnInit() {
    // console.log(this);
  }

}
