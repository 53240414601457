<ul class="nav nav-tabs" role="tablist">
  <li class="nav-item" (click)="setTab(allTabs.newuser)">
    <a class="nav-link" data-toggle="tab" [ngClass]="{'active': currentTab === allTabs.newuser}"
      role="tab" aria-controls="home">
      {{allTabs.newuser | translate}}
    </a>
  </li>
  <li class="nav-item" (click)="setTab(allTabs.existinguser)">
    <a class="nav-link" data-toggle="tab" [ngClass]="{'active': currentTab === allTabs.existinguser}" role="tab"
      aria-controls="home">
      {{allTabs.existinguser | translate}}
    </a>
  </li>
</ul>
<div class="tab-content">
  <div class="tab-pane" role="tabpanel" [ngClass]="{'active': allTabs.newuser === currentTab}">
    <h3>{{'add_new_am'|translate}}</h3>
    <p>{{'user_should_not_be_already_created'|translate}}</p>
    <rm-reactive-forms
      [objectDefinition]="appUserDef"
      [setLoading]="loading"
      (saveEmitter)="saveAppUser($event)"
    >
    </rm-reactive-forms>
  </div>
  <div class="tab-pane" role="tabpanel" [ngClass]="{'active': allTabs.existinguser === currentTab}">
    <h3>
      {{'assign_am_priviledge_to_existing_user'|translate}}
    </h3>
    <p>
      {{'input_email_to_grant_access' | translate}}
    </p>
    <div class="form-group">
      <div class="input-group">
        <div class="input-group-append">
          <span class="btn btn-primary" type="button">
            <i class="cil-at"></i>
          </span>
        </div>
        <input class="form-control"  type="email" [(ngModel)]="email">
      </div>
    </div>
    <div class="form-group">
      <button class="btn btn-primary" type="button" (click)="grantAccess()" [disabled]="loading">
        <span *ngIf="loading" class="c-loading-button-spinner spinner-border spinner-border-sm"></span>
        {{'confirm' | translate}}
      </button>
    </div>
  </div>
</div>