export namespace RoadMateMath {
  export const add = (a: number, b: number): number => {
    const aScaled = parseInt((a * 100).toFixed(0));
    const bScaled = parseInt((b * 100).toFixed(0));
    return parseFloat(((aScaled + bScaled)/100).toFixed(2));
  }

  export const minus = (a: number, b: number): number => {
    const aScaled = parseInt((a * 100).toFixed(0));
    const bScaled = parseInt((b * 100).toFixed(0));
    return parseFloat(((aScaled - bScaled)/100).toFixed(2));
  }

  export const normalize = (value: number, decimals = 2) => parseFloat((Math.floor(value*100)/100).toFixed(decimals));

  export const toFloat = (value: string) => parseFloat(parseFloat(value).toFixed(2));
}
