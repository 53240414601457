import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AppRoles, CardSituation, DropDownListOption, error_messages, ExpenseLine, ExpenseLineStatus, GroupedDDL,
  ObjectDefList, RoadMateOrders, RoadMateResponse, Treezor, UserSituation, RoadMateRefundRequest, MobilityAccount } from '@roadmate/roadmate-common';
import { FireAuthService, FireFunctionsService, RMToasterService } from '../../../services';
import { FireStoreService } from '../../../services/fire.store';
import { editUserActions } from '../../../static';
import { AbstractEntryComponent } from '../abstract.entry-component';
import { TranslateService } from '@ngx-translate/core';
import { getTableSettings } from '../../../utils';

@Component({
  selector: 'rm-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent extends AbstractEntryComponent<{
  action: editUserActions,
  cardId?: number | string
}> implements OnInit {
  public targetObject: Treezor.AppUser;
  public objectDef;
  public isReadOnly = false;
  public savingLoader = false
  public currentTab = 0;
  public ordersLoaded = false;
  public savedSuccess = '';
  public orders: RoadMateOrders[] = [];
  public ordersDDL: DropDownListOption[] = [];
  public cards: CardSituation[];
  public hasNoCard: boolean = true;
  public loadingCards = false;
  public statusCode = Treezor.Card.StatusCode;
  public groupSelectionDirty = false;
  public groups: GroupedDDL[];
  public expenseLines: ExpenseLine[];
  public loading = false;
  public isSuperAdmin = false;
  public isAccountManager = false;
  public treezorUser: Treezor.User.Definition;
  public treezorUserDef;
  public objectDefName: string;
  public userACL: AppRoles;
  private companyRef: string;
  private agentRef: string;
  public isSelf = false;
  public initialOrders = [];
  public initialGroups = [];
  public isInvitedButNotSubscribed = false;
  public isEmployee = false;
  public isAdminOrAccountManager = false;
  public set data(value) {
    if (!value || !value.objectDef || !value.targetObject || !value.companyRef || !value.agentRef) {
      throw error_messages.PLEASE_SELECT_ONE_COMPANY;
      return;
    } else {
      this.agentRef = value.agentRef;
      this.companyRef = value.companyRef;
      this.objectDef = value.objectDef;
      this.objectDefName = value.objectDefName ?? '';
      this.targetObject = value.targetObject;
      this.suspended = !!this.targetObject.suspended;
      this.targetObject.userGroupIds = this.targetObject.userGroupIds ?? [];
      this.initialOrders = this.getOrdersFromObject(this.targetObject.userGroupIds);
      this.initialGroups = this.getGroupsFromObject(this.targetObject.userGroupIds);
      this.selectedGroups = JSON.parse(JSON.stringify(this.targetObject.userGroupIds ?? []));
      this.isReadOnly = !!value.isReadOnly;
    }
  }
  public adminItem = true;
  public cardTypeItem = true;
  public cardStatusItem = true;
  public cardLimitItem = true;
  public loadingUser = false;
  public suspended = false;
  private selectedGroups: GroupedDDL[] = [];
  public requests: RoadMateRefundRequest[];
  public requestsSettings;
  constructor(
    private fs: FireStoreService,
    private fa: FireAuthService,
    private ff: FireFunctionsService,
    private ref: ChangeDetectorRef,
    private toast: RMToasterService,
    private translate: TranslateService
  ) {
    super();
  }

  ngOnInit(): void {
    this.getUserCardDetails();
    this.getOrders ();
    this.getDefinitions();
    this.isSuperAdmin = this.fa.userRoles.superadmin;
    this.isAdminOrAccountManager = this.fa.userRoles.admin || this.fa.userRoles.accountmanager;
    this.isAccountManager = this.fa.userRoles.accountmanager;
    this.isSelf = this.fa.user.uid === this.targetObject.uid;
    this.isEmployee = !!this.targetObject.userId;
  }

  private getOrdersFromObject(input: GroupedDDL[]) {
    return input.map(el => {
      return {
        value: el.value,
        label: el.label,
        byBatch: false
      }
    }).sort(
      (a, b) => {
        return a.value < b.value ? -1 : 1
      }
    );
  }

  public async deleteCard(destroy = true) {
    const card = this.cards.find(el => !el.isRevoked);
    if (!card) {
      this.toast.showGenericError('Card not found');
      return;
    }
    this.close({
      action: destroy ? editUserActions.destroyCard : editUserActions.throwCard,
      cardId: card.cardId
    });
  }

  public orderNewCard() {
    this.close({
      action: editUserActions.createCard,
    });
  }

  private getGroupsFromObject (userGroupIds: GroupedDDL[]) {
    const groups: DropDownListOption[] = [];
    userGroupIds.forEach(order => {
      order.ddl?.forEach(group => groups.push({
        ...group,
        parentValue: order.value,
        createdAt: group.createdAt ?? (new Date()).toISOString(),
        byBatch: false
      }))
    });
    return groups.sort(
      (a, b) => {
        return a.value < b.value ? -1 : 1
      }
    );
  }

  private async getDefinitions() {
    const list = await this.fs.getObjectsList();
    this.treezorUserDef = list[ObjectDefList.treezorUser];
  }

  public async createTreezorUser() {
    this.loading = true;
    try {
      const response = await this.ff.adminUpdateTreezorUser(this.targetObject);
      if (!response.error && response.message === error_messages.OK) {
        this.toast.showSuccessToast('Done!');
        return;
      }
      this.toast.showGenericError();
    } catch (e) {
      console.error('', e);
    } finally {
      this.loading = false;
    }
  }

  public async getTreezorUser() {
    this.loading = true;
    try {
      const response = await this.ff.getTreezorUser(
        this.targetObject.agentRef,
        this.targetObject.companyRef,
        this.targetObject.uid
      );
      if (response.error || response.message !== error_messages.OK) {
        this.toast.showGenericError(response.message);
      } else {
        this.treezorUser = response.result;
      }
    } catch (e) {
      console.error('', e);
      this.toast.showGenericError();
    } finally {
      this.loading = false;
    }
  }

  public async sendObjectToSender(objectUpdated) {
    this.loading = true;
    try {
      if (this.objectDefName === ObjectDefList.appusers) {
        const success = await this.fs.updateAppUser(objectUpdated);
        if (success) {
          this.targetObject = {...this.targetObject, ...objectUpdated};
          this.toast.showSuccessToast('Done!');
          this.close(null);
          return;
        }
        this.toast.showGenericError();
      } else if (this.objectDefName === ObjectDefList.treezorUser) {
        this.updateTreezorUser(objectUpdated)
      }
    } catch (e) {
      this.toast.showGenericError();
    } finally {
      this.loading = false;
    }
  }

  public setTab(index: number) {
    this.currentTab = index;
    if (index === 3 && !this.treezorUser) {
      this.getTreezorUser();
    }
    if (index === 5 && !this.requests) {
      this.getRequests();
    }
  }

  private async getRequests() {
    this.loading = true;
    try {
      const {email} = this.targetObject;
      if (!email) {
        this.toast.showGenericError('Impossible de charger les demandes de remboursement car email est indéfini.');
        return;
      }
      const requests = await this.fs.getRefundRequestsForUser(email);
      if (!requests || !requests.length) {
        this.toast.showWarningToast('Aucune demande de remboursement trouvée pour cet utilisateur.');
        return;
      }
      this.requests = requests.sort((a, b) => { return a.createdAt.localeCompare(b.createdAt) });
      const list = await this.fs.getObjectsList();
      const req = JSON.parse(JSON.stringify(list[ObjectDefList.refundRequests]));
      this.requestsSettings = await getTableSettings(req, this.translate);
    } catch (e) {
      console.error('', e);
    } finally {
      this.loading = false;
    }
  }

  public convertCardToPhysical(event) {
    event.stopPropagation();
    this.close({
      action: editUserActions.convertPhysical
    });
  }

  private async getUserCardDetails () {
    if (!this.targetObject.email) {
      return;
    }
    this.loadingCards = true;
    try {
      const userSituationRequest: RoadMateResponse<UserSituation> = await this.ff.adminGetUserStatus(
        this.agentRef,
        this.companyRef,
        this.targetObject.email
      );
      if (!userSituationRequest || userSituationRequest.error) {
        if (userSituationRequest.message === error_messages.NOT_FOUND) {
          await this.toast.showTranslatedWarning('this_is_not_a_beneficiary');
          return;
        } else if (
          userSituationRequest.message === error_messages.NOT_SUBSCRIBED_YET
        ) {
          this.isInvitedButNotSubscribed = true;
          await this.toast.showTranslatedWarning('this_user_not_subscribed_yet');
          return;
        } else if (userSituationRequest.message === error_messages.UNAUTHORIZED) {
          await this.toast.showTranslatedWarning('UNAUTHORIZED');
        }
        return;
      }
      if (userSituationRequest?.result) {
        this.cards = userSituationRequest.result.cards ?? [];
        const expenseLines = userSituationRequest.result.expenseLines ?? [];
        this.expenseLines = expenseLines
        .filter(ex =>
          ex.status === ExpenseLineStatus.Available ||
          ex.status === ExpenseLineStatus.Active ||
          ex.status === ExpenseLineStatus.Reliquat || 
          ex.status === ExpenseLineStatus.Suspended
        );
        this.userACL =  userSituationRequest.result.acl;
        this.hasNoCard = !(this.cards?.length > 0);
        this.ref.detectChanges();
      } else {
        this.toast.showGenericError('Impossible de charger les informations de cet utilisateur');
      }
    } catch (e) {
      console.error('', e);
    } finally {
      this.loadingCards = false;
    }
  }

  public sendNewInvitation() {
    this.close({
      action: editUserActions.sendInviteToEmployee
    });
  }

  public makeAdmin(event, tomakeAdmin: boolean) {
    event.stopPropagation();
    this.close({
        action: tomakeAdmin ? editUserActions.makeAdmin : editUserActions.revokeAdmin
    });
  }

  public makeAccountManager(event, tomakeAccountManager: boolean) {
    event.stopPropagation();
    this.close({
        action: tomakeAccountManager ? editUserActions.makeAccountManager : editUserActions.revokeAccountManager
    });
  }

  public makeEmployee(event) {
    event.stopPropagation();
    this.close({
        action: editUserActions.makeEmployee
    });
  }

  public updateCartStatus(event, status: Treezor.Card.StatusCode, cardId: string) {
    event.stopPropagation();
    switch (status) {
      case Treezor.Card.StatusCode.LOCK:
        this.close({
          action: editUserActions.lockCard,
          cardId
        });
        break;
      case Treezor.Card.StatusCode.UNLOCK:
        this.close({
          action: editUserActions.unlockCard,
          cardId
        });
        break;
      case Treezor.Card.StatusCode.STOLEN:
      case Treezor.Card.StatusCode.LOST:
        this.close({
          action: editUserActions.throwCard,
          cardId
        });
        break;
    }
  }

  public async syncTreezorUser() {
    this.loading = true;
    try {
      const response = await this.ff.adminSyncTreezorUser(this.targetObject.email);
      if (!response.error && response.message === error_messages.OK) {
        this.toast.showSuccessToast('Done!');
        this.treezorUser = null;
        this.getTreezorUser();
      } else {
        this.toast.showGenericError();
      }
    } catch (e) {
      console.error('', e);
      this.toast.showGenericError();
    } finally {
      this.loading = false;
    }
  }

  private removeBusinessFields (user) {
    Object.keys(user).forEach(key => {
      const index = ['legalName', 'legalNameEmbossed', 'legalRegistrationNumber', 'legalTvaNumber',
      'legalRegistrationDate', 'entityType', 'incomeRange', 'legalForm', 'legalShareCapital',
      'legalSector', 'legalAnnualTurnOver', 'legalNetIncomeRange', 'legalNumberOfEmployeeRange',
      'sepaCreditorIdentifier', 'walletCount', 'payinCount', 'totalRows', 'activityOutsideEu',
      'economicSanctions', 'residentCountriesSanctions', 'involvedSanctions', 'informationStatus',
      'isFreezed', 'kycLevel', 'kycReview', 'kycReviewComment', 'modifiedDate', 'timezone', 'userGroupIds', 'userTag', 'userStatus'
    ].indexOf(key);
      if (index > -1) {
        delete user[key];
      }
    });
  }


  public async updateTreezorUser(user: Partial<Treezor.User.EmployeeProfile>) {
    this.loading = true;
    try {
      if (user.userTypeId === Treezor.User.UserType.person) {
        this.removeBusinessFields(user);
      }
      const response = await this.ff.adminUpdateTreezorUser(user, this.companyRef);
      if (!response || response.error || response.message !== error_messages.OK) {
        this.toast.showGenericError(response.message);
      } else {
        this.toast.showSuccessToast('saved_success');
      }
    } catch (e) {
      console.error('', e);
      this.toast.showGenericError();
    } finally {
      this.loading = false;
    }
  }

  public suspendUser(event, suspend: boolean) {
    event.stopPropagation();
    this.close({
      action: suspend ? editUserActions.suspendUser : editUserActions.rehabilitateUser
    });
  }

  public deleteUser(event) {
    event.stopPropagation();
    this.close({
      action: editUserActions.deleteUser
    });
  }

  public generateNewCard() {
    this.close({
      action: editUserActions.generateNewCard
    })
  }

  private async getOrders () {
    try {
      this.ordersLoaded = false;
      this.ref.detectChanges();
      this.orders = await this.fs.getClientOrders(this.agentRef, this.companyRef);
      this.ordersDDL = this.orders.map(el => {
        return {
          value: el.ref,
          label: el.product.name
        }
      });
      this.groups = this.orders.map(el => {
        return {
          value: el.ref,
          label: el.product.name,
          ddl: el.beneficiaryGroup ?
          el.beneficiaryGroup.map(item => {
            return {
              value: item.value,
              label: item.label
            }
          }) : []
        }
      });
    } catch (e) {
      console.error('', e);
    } finally {
      this.ordersLoaded = true;
      this.ref.detectChanges();
    }
  }

  public async saveGroups() {
    this.savingLoader = true;
    this.ref.detectChanges();
    try {
      const newSelectedOrders = this.getOrdersFromObject(this.selectedGroups);
      const newSelectedGroups = this.getGroupsFromObject(this.selectedGroups);
      const addedOrders = newSelectedOrders.filter(el => this.initialOrders.every(order => order.value !== el.value));
      const deletedOrders = this.initialOrders.filter(el => newSelectedOrders.every(order => order.value !== el.value));

      const addedGroups = newSelectedGroups.filter(el => this.initialGroups.every(group => group.value !== el.value));
      const deletedGroups = this.initialGroups.filter(el => newSelectedGroups.every(group => group.value !== el.value));
      const {agentRef, companyRef} = this.targetObject;
      if (!agentRef || !companyRef) {
        this.toast.showGenericError();
        return;
      }
      if (deletedOrders.length) {
        await this.fs.removeUserFromOrders(agentRef, companyRef, deletedOrders, this.targetObject.email);
      }
      if (deletedGroups.length) {
        await this.fs.removeUserFromGroups(agentRef, companyRef, deletedGroups, this.targetObject.email);
      }

      if (addedOrders.length) {
        await this.fs.addUserToOrders(agentRef, companyRef, addedOrders, this.targetObject.email);
      }
      if (addedGroups.length) {
        await this.fs.addGroupToUsers(agentRef, companyRef, addedGroups, this.targetObject.email);
      }
      
      this.targetObject.userGroupIds = this.selectedGroups;
      this.fs.updateAppUser(this.targetObject);
      this.savedSuccess = 'saved_success';
    } catch (e) {
      console.error('', e);
    } finally {
      this.savingLoader = false;
      this.ref.detectChanges();
    }
  }

  public updateUserGroups(selectedGroups: GroupedDDL[]) {
    this.savedSuccess = '';
    this.selectedGroups = selectedGroups;
    this.groupSelectionDirty = true;
    this.ref.detectChanges();
    // this.targetObject.userGroupIds = selectedGroups;
  }

  public async uploadExpenseLines() {
    this.loading = true;
    try {
      // const response = await this.ff.adminGetsUserExpenseLines(this.targetObject.email, this.targetObject.companyRef);
      // this.expenseLines = response.result;
    } catch (e) {
      console.error('', e);
    } finally {
      this.loading = false;
    }
  }

  public async suspendExpenseLine(expense: ExpenseLine, suspend = true) {
    this.loading = true;
    try {
      await this.ff.suspendExpenseLine(expense, suspend, this.targetObject.email, this.targetObject.companyRef);
      await this.getUserCardDetails();
    } catch (e) {
      console.error('', e);
    } finally {
      this.loading = false;
    }
  }
}
