import { AbstractControl } from '@angular/forms';

export const verifyDate = () => {
  return (control: AbstractControl): {[key: string]: boolean} | null => {
      const value = control.value;
      if (control.value === '') {
          return null;
      }
      if (control.value.split('/').length !== 3) {
          return { date_slash_missing: true };
      }
      const dateFragments = control.value.split('/');
      const year = parseInt(dateFragments[2], 10);
      const month = parseInt(dateFragments[1], 10);
      const day = parseInt(dateFragments[0], 10);
      if (year < 1900) {
          return { incorrect_year: true };
      } else if (month > 12) {
          return { incorrect_month: true };
      } else if (day > 31) {
          return { incorrect_day: true };
      } else {
          return null;
      }
    };
};

export const verifyPhone = () => {
  return (control: AbstractControl): {[key: string]: boolean} | null => {
      const value = control.value;
      if (control.value === '') {
          return null;
      }
      if (control.value.length < 12) {
        return { short_phone: true };
      }
      if(control.value[0] !== '+') {
        return { missing_prefix_plus_phone: true };
      }
      const phone: string = control.value;
      const phoneNumber = phone.substr(1, phone.length);
      if (isNaN(parseInt(phoneNumber, 10))) {
        return { only_numbers_allowed: true };
      } else {
        return null;
      }
    };
};


export const verifyDateTime = () => {
  return (control: AbstractControl): {[key: string]: boolean} | null => {
      const value = control.value;
      if (control.value === '') {
          return null;
      }
      if (control.value.split('/').length !== 3) {
          return { date_slash_missing: true };
      }
      const dateFragments = control.value.split('/');
      const timeString: string = dateFragments[2].substr(5, 5)
      const year = parseInt(dateFragments[2].substr(0, 4), 10);
      const month = parseInt(dateFragments[1], 10);
      const day = parseInt(dateFragments[0], 10);
      if (!timeString && timeString.split(':').length !== 2) {
        return { missing_time: true };
      }
      const hour = parseInt(timeString.split(':')[0], 10);
      const minute = parseInt(timeString.split(':')[1], 10);
      if (hour > 23 || hour < 0 || minute < 0 || minute > 59) {
        return { incorrect_time: true };
      }
      if (year < 1900) {
          return { incorrect_year: true };
      } else if (month > 12) {
          return { incorrect_month: true };
      } else if (day > 31) {
          return { incorrect_day: true };
      } else {
          return null;
      }
    };
};
