import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
registerLocaleData(localeFr);
import * as moment from 'moment';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

moment.locale('fr');
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireFunctionsModule, REGION } from '@angular/fire/functions';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
// Import containers
import { DefaultLayoutComponent } from './containers';

export const APP_CONTAINERS = [
  DefaultLayoutComponent,
  RMToasterComponent
];
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
} from '@coreui/angular';

// Import routing module
import { AppRoutingModule } from './app.routing';
import { RmModalModule } from './modules/rm-modals/rm-modal.module';
import { RmEntryComponentsModule } from './modules/entry-components/entry-components.module';
import { RMToasterComponent } from './modules/rm-toaster/rm-toaster.component';
import { AbstractRoute } from './routes/abstract.route';
import { AbstractComponent } from './routes/abstract.component';


@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
	  HttpClientModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireFunctionsModule,
    AngularFireAnalyticsModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    RmModalModule,
    RmEntryComponentsModule
  ],
  declarations: [
    AppComponent,
    AbstractRoute,
    AbstractComponent,
    ...APP_CONTAINERS,
  ],
  providers: [
    TranslateService,
    { provide: LOCALE_ID, useValue: 'fr-FR'},
    { provide: REGION, useValue: 'europe-west1' }
  ],
  bootstrap: [ AppComponent ]
})
export class AppModule { }
