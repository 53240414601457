import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl, FormArray, FormGroup } from '@angular/forms';

@Pipe({ name: 'rm_get_controls_array' })
export class ControlsFromFormPipe implements PipeTransform {
  transform(formGroup: FormGroup, arrayName: string): AbstractControl[] {
    if (!formGroup || !(formGroup instanceof FormGroup) || !arrayName) {
      return [];
    }
    const formArray = formGroup.get(arrayName) as FormArray;
    return formArray.controls;
  }
}
