import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'rm-translate-render',
  template: `
    {{renderValue | translate}}
  `,
})
export class TranslateRenderComponent implements OnInit {

  renderValue: string;

  @Input() value: string;
  @Input() rowData: any;

  ngOnInit() {
    this.renderValue = this.value;
  }

}
