import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
import { 
  appRoles, AvailableIntegration, ConfirmOperation, ConfirmReturnMessage, 
  error_messages, ExpenseLine, GroupedDDL, NotificationScope, Attestation, 
  operations, RefundRequestType, RejectedTransaction, RoadMateFile, RoadMatePayoutRequest, RoadMateResponse, RoadMateTransfer,
  RoadMateWalletHelper, TransportCategory, TransportWhiteList, Treezor, UserDeletionImport, UserExpenseLines, UserSituation
} from '@roadmate/roadmate-common';
import { AES } from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class FireFunctionsService {

  constructor(private functions: AngularFireFunctions) {}

  public async createAppUser(user: Treezor.AppUser) {
    return await this.callFunction<any, string>('createAppUser', {
      user
    });
  }

  public async saGetUserCardTransactions(agentRef: string, companyRef: string, email: string) {
    return await this.callFunction<any, Treezor.Card.PaymentHistory[]>('saGetUserCardTransactions', {
      agentRef,
      companyRef,
      email
    });
  }

  public async sendTermsAndConditions(email: string) {
    return await this.callFunction<any, error_messages>('sendYouSignTermsAndConditions', {
      email
    });
  }

  public async saMergeRefundRequests(sourceRefundRef: string, destinationRefundRef: string) {
    return await this.callFunction<any, string>('saMergeRefundRequest', {
      sourceRefundRef,
      destinationRefundRef
    });
  }

  public async saSendMessageToUser(email: string, subject: string, message: string, canal: string) {
    return await this.callFunction<any, any>('saSendMessageToUser', {
      email,
      message,
      canal,
      subject
    });
  }

  public async saRmSR(body: any) {
    return await this.callFunction<any, any>('saRmsr', {...body});
  } 

  public async submitIdemiaTrackingFile(fileName: string) {
    return await this.callFunction<any, string>('saSendTrackingCode', {
      fileName
    });
  }

  public async togglePrimeTransport(expenseLineRef: string) {
    return await this.callFunction<any, string>('togglePrimeTransport', {expenseLineRef});
  }

  public async installNewIntegration(agentRef: string, companyRef: string, integration:AvailableIntegration) {
    return await this.callFunction<any, any>('adminInstallNewIntegration', {
      agentRef,
      companyRef,
      integration
    });
  }

  public async requestOtpByEmail(requestRef: string) {
    return await this.callFunction<any, any>('requestOtpByEmail', {requestRef});
  }

  public async exportFileFromSelection(
    agentRef: string,
    companyRef: string,
    headers: string[],
    docs: any[],
    objectName: string,
    linkIndex = -1,
    linkPropertyName = '',
    linkLabel = ''
  ) {
    return await this.callFunction<any, RoadMateFile>('adminExportFromSelection', {
      headers,
      docs,
      objectName,
      agentRef,
      companyRef,
      linkIndex,
      linkPropertyName,
      linkLabel
    });
  }

  public async startAddingUsersFromIntegration(
    agentRef: string, companyRef: string, integrationRef: string, products: GroupedDDL[], appUsers: Treezor.AppUser[] = []
  ) {
    return await this.callFunction<any, any>('startAddingUsersFromIntegration', {
      agentRef,
      companyRef,
      integrationRef,
      products,
      appUsers
    });
  }

  public async updateTreezorUser(user: Partial<Treezor.User.EmployeeProfile>, companyRef?: string) {
    return await this.callFunction<any, any>('selfUpdateEmployee', {user, companyRef: companyRef ?? ''});
  }

  public async propagateTransportCategory(whiteListRef: string, categories: TransportCategory[]) {
    return await this.callFunction<any, any>('saPropagateTransportCategory', {
      whiteListRef,
      categories
    });
  }

  public async saSendPushNotification(title: string, body: string, scope: NotificationScope, agentRef: string, companyRef: string) {
    return await this.callFunction<any, any>('saSendPushNotification', {
      title,
      body,
      scope,
      agentRef,
      companyRef
    });
  }

  public async toggleUserDeletion(agentRef: string, companyRef: string, queueRef: string, value: boolean) {
    return await this.callFunction<any, any>('toggleUD', {
      value,
      agentRef,
      companyRef,
      queueRef
    });
  }

  // Employee functions
  public async setPhone(phone: string): Promise<RoadMateResponse<string>> {
    return await this.callFunction<any, string>('updateUserPhone', {phone});
  }

  public async confirmEmployeeOperation(confirmation: ConfirmOperation): Promise<RoadMateResponse<ConfirmReturnMessage>> {
    return await this.callFunction<ConfirmOperation, ConfirmReturnMessage>('confirmOperation', confirmation);
  }

  public async saveKyc(user: Partial<Treezor.User.EmployeeProfile>): Promise<RoadMateResponse<boolean>> {
    return await this.callFunction<any, boolean>('saveKyc', user);
  }

  public async getCardImage(cardId: string, uid: string): Promise<RoadMateResponse<string>>  {
    const encodedCardId = AES.encrypt(cardId, uid).toString();
    return await this.callFunction<any, any>('getUserCardImage', {cardId: encodedCardId});
  }

  public async requestEmployeeOperation(operation: operations, metadata?: any): Promise<RoadMateResponse<string>> {
    const request: ConfirmOperation = {
      metadata: metadata ? metadata : null,
      operation,
    };
    return await this.callFunction<any, string>('requestOperation', request);
  }

  public async setPin(
    token: string,
    code: string,
    cardId: string,
    operationRef: string,
    uid: string
  ): Promise<RoadMateResponse<ConfirmReturnMessage>> {
    const encodedPassword = AES.encrypt(code, uid).toString();
    const encodedCardId = AES.encrypt(cardId, uid).toString();
    const confirmation: ConfirmOperation = {
      ref: operationRef,
      operation: operations.setPin,
      metadata: {
        cardId: encodedCardId,
        password: encodedPassword
      },
      token
    };
    return this.confirmEmployeeOperation(confirmation);
  }

  public async activateCard(
    token: string,
    cardId: string,
    operationRef: string,
    uid: string
  ): Promise<RoadMateResponse<ConfirmReturnMessage>> {
    const encodedCardId = AES.encrypt(cardId, uid).toString();
    const confirmation: ConfirmOperation = {
      ref: operationRef,
      operation: operations.activateCard,
      metadata: {
      cardId: encodedCardId
      },
      token
    };
    return this.confirmEmployeeOperation(confirmation);
  }

  public async activatePhysicalCard(
    cardId: number,
    publicToken: string,
  ): Promise<RoadMateResponse<boolean>> {
    return await this.callFunction<any, boolean>('activatePhysicalCard', {cardId, publicToken});
  }

  public async lockUnlock(
    token: string,
    cardId: string,
    lockStatus: Treezor.Card.LockStatus,
    operationRef: string,
    uid: string
  ): Promise<RoadMateResponse<ConfirmReturnMessage>> {
    const encodedCardId = AES.encrypt(cardId, uid).toString();
    const confirmation: ConfirmOperation = {
      ref: operationRef,
      operation: operations.lockCard,
      metadata: {
      cardId: encodedCardId,
      lockStatus
      },
      token
    };
    return this.confirmEmployeeOperation(confirmation);
  }

  public async unlockPin(
    token: string,
    cardId: string,
    operationRef: string,
    uid: string
  ): Promise<RoadMateResponse<ConfirmReturnMessage>> {
    const encodedCardId = AES.encrypt(cardId, uid).toString();
    const confirmation: ConfirmOperation = {
      ref: operationRef,
      operation: operations.unlockPin,
      metadata: {
        cardId: encodedCardId
      },
      token
    };
    return this.confirmEmployeeOperation(confirmation);
  }

  public async updateCardOptions(cardOption: number, cardId: number, email: string) {
    return await this.callFunction<any, string>('updateCardOptions', { cardOption, cardId, email });
  }

  public async activateLineExpense(expenseRef: string, companyRef: string) {
    return await this.callFunction<any, string>('switchExpenseLine', {expenseRef, companyRef});
  }

  public async sendAttestationToUser(attestation: Attestation) {
    return await this.callFunction<Attestation, string>('sendAttestationToUser', attestation);
  }

  public async saveInvoiceRefrence(file: RoadMateFile, paymentId: string) {
    return await this.callFunction<any, string>('saveInvoiceReference', {
      file,
      paymentId
    });
  }
  // END EMployee functions
  // START Admin functions

  public async removeBudgetFromEmployeeEL(
    operation: Partial<RoadMateTransfer>,
    agentRef: string,
    companyRef: string,
    targetUserEmail: string,
    expenselineRef: string,
  ) {
    return await this.callFunction<any, RoadMateResponse<any>>(
      'removeBudgetFromEmployeeEL', 
      {
        operation,
        agentRef,
        companyRef,
        targetUserEmail,
        expenselineRef
      }
    );
  }
  public async addUsersToDeletionList(agentRef: string, companyRef: string, list: UserDeletionImport) {
    return await this.callFunction<any, {
      alreadyProcessed: string[];
      malformatted: string[];
      doublons: string[];
    }>('adminCreateUserDeletionList', {
      scheduledAt: list.schedule ? list.startDate : '',
      userEmails: list.emails,
      notifyUsers: list.notifyUsers,
      deleteOneNow: !!list.deleteOneNow,
      agentRef,
      companyRef
    });
  }

  public async removeUsersFromDeletionList(agentRef: string, companyRef: string, emails: string[], queueRef: string) {
    return await this.callFunction<any, RoadMateResponse<any>>('adminRemoveUserFromDeletionList', {
      userEmails: emails,
      agentRef,
      companyRef,
      queueRef
    });
  }

  public async updateUserUponSignIn() {
    return await this.callFunction<any, ConfirmReturnMessage>('updateUserUponSignIn', {});
  }

  public async removeScheduledQueue(agentRef: string, companyRef: string, queueRef: string) {
    return await this.callFunction<any, RoadMateResponse<any>>('adminRemoveScheduledQueue', {
      queueRef,
      agentRef,
      companyRef
    });
  }

  public async adminGenerateFEC(startDate: string, endDate: string, productName: string, adminType: string, agentRef: string, companyRef: string) {
    return await this.callFunction<any, RoadMateFile>('adminGenerateFEC', {startDate, endDate, productName, adminType, agentRef, companyRef});
  }

  public async processUserRefundTransfer(requestRef: string, outcome: boolean, type: RefundRequestType, bpc = false) {
    return await this.callFunction<any, UserSituation>('processUserRefundTransfer', {requestRef, outcome, type, bpc});
  }

  public async adminGetUserStatus(agentRef: string, companyRef: string, email: string) {
    return await this.callFunction<any, UserSituation>('adminGetUserStatus', {agentRef, companyRef, email});
  }

  public async adminUpdateCardsLimits(companyRef: string) {
    return await this.callFunction<any, string>('adminUpdateCardsLimits', {companyRef});
  }

  public async syncTreezorUserTransaction(email: string, companyRef: string, sync = false) {
    return await this.callFunction<any, string>('adminSyncUserTransactions', {email, companyRef, sync});
  }

  public async deleteTreezorUser(userId: string, agentRef: string, companyRef: string) {
    return await this.callFunction<any, string>('adminDeleteTreezorUser', {userId, agentRef, companyRef});
  }

  public async syncWhiteList () {
    return await this.callFunction<any, {
      nbWhiteLists: number;
    nbMidCreated: number;
    nbMidUpdated: number;
    nbMccCreated: number;
    nbMccUpdated: number;
    }>('adminUpdateAllWhiteLists', {});
  }

  public async sendInvitationEmailToEmployee(email: string, companyRef: string) {
    return await this.callFunction<any, string>('sendInvitationEmailToEmployee', {
      email,
      companyRef
    });
  }

  public async sendInvitationToAllUnsubscribedUsers(agentRef: string, companyRef: string) {
    return await this.callFunction<any, string>('adminSendInvitationToUsers', {
      agentRef,
      companyRef
    });
  }

  public async saveNewWhiteList (whitelist: Partial<TransportWhiteList>) {
    return await this.callFunction<any, UserExpenseLines[]>('addNewWhiteList', whitelist);
  }

  public async adminSyncTreezorCompany(ref: string, userId: number) {
    return await this.callFunction<any, any>('adminSyncTreezorCompany', {ref, userId});
  }

  public async adminSyncTreezorUser(email: string) {
    return await this.callFunction<any, any>('adminSyncTreezorUser', {email});
  }

  public async adminUpdateTreezorUser(user: Partial<Treezor.User.EmployeeProfile>, companyRef?: string) {
    return await this.callFunction<any, any>('adminUpdateEmployee', {user, companyRef: companyRef ?? ''});
  }

  public async adminUpdateCompany(agentRef: string, company: Partial<Treezor.User.Definition>) {
    return await this.callFunction<any, any>('adminUpdateCompany', {company, agentRef});
  }

  public async getTreezorUser(agentRef: string, companyRef: string, uid: string) {
    return await this.callFunction<any, Treezor.User.Definition>('getTreezorUser', {agentRef, companyRef, uid});
  }

  public async cancelPayout(walletId: string, operationId: number, type: 'payout' | 'transfer') {
    return await this.callFunction<any, RoadMateWalletHelper[]>('cancelPayoutOpertation', {walletId, operationId, type});
  }

  public async getWalletTransactions(){
    return await this.callFunction<any, RoadMateWalletHelper[]>('getWalletTransactions', {});
  }

  public async getRoadMateWallets(): Promise<RoadMateResponse<RoadMateWalletHelper[]>> {
    return await this.callFunction<any, RoadMateWalletHelper[]>('getWalletsAndBalances', {});
  }

  public async addBeneficiaire(agentRef: string, companyRef: string, beneficiaire: Treezor.Beneficiary.Definition) {
    return await this.callFunction<any, any>('addBenediciaire', {agentRef, companyRef, beneficiaire});
  }

  public async updateEmployee(employee: Treezor.User.Definition): Promise<RoadMateResponse<any>> {
    return await this.callFunction<any, any>('updateEmployee', employee);
  }

  public async setUserGroupCount() {
    return await this.callFunction<any, any>('setUserGroupCount', {});
  }

  public async updateClaim(email: string, role: appRoles, grant: boolean, companyRef: string, agentRef: string) {
    return await this.callFunction<any, any>('setCustomRoles', {
      claim: role,
      companyRef,
      email,
      agentRef,
      grant
    });
  }

  public async suspendUser(email, suspend, companyRef, agentRef: string){
    return await this.callFunction<any, any>('suspendUser', {
      claim: suspend,
      companyRef,
      email,
      agentRef
    });
  }

  public async confirmOperation(agentRef: string, companyRef: string, operation: Partial<ConfirmOperation>): Promise<RoadMateResponse<any>> {
    return await this.callFunction<any, any>('adminConfirmOperation', {agentRef, companyRef, operation});
  }

  public async refundTransfer(agentRef: string, companyRef: string, operation: Partial<RoadMateTransfer>): Promise<RoadMateResponse<any>> {
    return await this.callFunction<any, any>('refundTransfer', {agentRef, companyRef, operation});
  }

  public async cancelProvisionnedExpenseLine(agentRef: string, companyRef: string, expenseLineRef: string): Promise<RoadMateResponse<any>> {
    return await this.callFunction<any, any>('cancelProvisionnedExpenseLine', {
      agentRef,
      companyRef,
      expenseLineRef
    });
  }

  public async refundProvisionnedBudget(agentRef: string, companyRef: string, remainingBudget: number): Promise<RoadMateResponse<any>> {
    return await this.callFunction<any, any>('refundProvisionnedBudget', {
      agentRef,
      companyRef,
      remainingBudget
    });
  }

  public async startTransfer(agentRef: string, companyRef: string, transfer: Partial<RoadMateTransfer>): Promise<RoadMateResponse<string>> {
    return this.requestOperation(agentRef, companyRef, operations.transfer, transfer);
  }

  public async startPayout(agentRef: string, companyRef: string, payout: Partial<RoadMatePayoutRequest>): Promise<RoadMateResponse<string>> {
    return this.requestOperation(agentRef, companyRef, operations.payout, payout);
  }

  public async requestOperation(agentRef: string, companyRef: string, operation: operations, metadata?: any): Promise<RoadMateResponse<string>> {
    const request: ConfirmOperation = {
      metadata: metadata ? metadata : null,
      operation,
    };
    return await this.callFunction<any, string>('adminRequestOperation', {agentRef, companyRef, request});
  }

  public async startAddingUsers(agentRef: string, companyRef: string, fileName: string, products: GroupedDDL[]) {
    return await this.callFunction<any, any>('startAddingUsers', {agentRef, companyRef, fileName, products});
  }

  public async setCustomClaim(email: string, claim: appRoles, companyRef: string, grant: boolean, agentRef: string) {
    return await this.callFunction<any, any>('setCustomRoles', {email, claim, grant, companyRef, agentRef});
  }
  public async getInformationResults(data) {
    return await this.callFunction<any, any>('businessInformation', data);
  }

  public async getSearchResults(data, endpoint) {
    return await this.callFunction<any, any>(endpoint, data);
  }

  public async verifyUserExists(email: string) {
    return await this.callFunction<any, any>('verifyUserExists', {email});
  }

  public async logUserLogin() {
    return await this.callFunction<any, any>('verifyUserExists', {});
  }

  public async suspendExpenseLine(expenseLine, suspend: boolean, email: string, companyRef: string) {
    return await this.callFunction<any, ExpenseLine[]>('adminSuspendUserExpenseLine',
    {email, expenseRef: expenseLine.ref, suspend, companyRef}
    );
  }
  // START SA Functions
  public async saApproveRejectTransactions(transactions: RejectedTransaction[], approve: boolean) {
    return await this.callFunction<any, boolean>('saApproveRejectTransactions',
      {
        transactions,
        approve
      }
    );
  }
  // END SA FUNCTIONS
  private async callFunction<T, R>(functionName, payload: T): Promise<RoadMateResponse<R>> {
    const func = this.functions.httpsCallable<T, RoadMateResponse<R>>(functionName);
    try {
      return await func(payload).toPromise();
    } catch (e) {
      console.error(functionName, e);
      const response = new RoadMateResponse<any>();
      response.setError(error_messages.SERVER_ERROR);
      return response;
    }
  }
}
