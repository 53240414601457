<div class="convo" *ngIf="conversation">
  <div class="row">
    <div class="col-12">
      <h3>
        {{conversation.category | translate}}
      </h3>
      <p>
        <strong>{{'description'|translate}}</strong> : {{conversation.subject}}
      </p>
    </div>
  </div>
  <div class="row left">
    <div class="col-10" class="bubble read">
      <div [innerHTML]="'HI_HOW_CAN_WE_HELP_YOU_TODAY' | translate"></div>
    </div>
  </div>
  <ng-container *ngIf="messages | async as convoMessages">
    <ng-container *ngIf="convoMessages.length;">
      <div class="row" *ngFor="let item of convoMessages | rm_array_sort:'createdAt':'asc'" [ngClass]="{
        'right': item.sender === 'user',
        'left': item.sender === 'agent'
      }">
        <div 
          class="bubble rm-clickable" 
          [ngClass]="{
            'read': item.isRead, 
            'selected': item.ref === selectedMessage,
            'reaction-nok': item.reaction === 'support_nok_reaction',
            'reaction-ok': item.reaction === 'support_ok_reaction',
            'reaction-thinking': item.reaction === 'support_explain_reaction'
          }" 
          (click)="selectMessage(item)"
        >
          <div *ngIf="item.message" class="bubble-text" [innerHTML]="item.message"></div>
          <div style="text-decoration: underline;" *ngIf="!item.message && item.file?.url">
            {{'ATTECHED_FILE' | translate}}
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
<div class="row" *ngIf="conversation && conversation.status !== 'resolved'">
  <div class="col-12">
    <div class="input-group">
      <input 
        type="text" 
        class="form-control" 
        [(ngModel)]="messageText" 
        (keyup.enter)="sendMessage()" 
        placeholder="{{'TYPE_MESSAGE' | translate}}"
      >
      <div class="input-group-append">
        <!-- <button class="btn btn-outline-warning" type="button" (click)="closeConversation()">
          Cloturer la conversation
        </button> -->
        <button class="btn btn-outline-primary" type="button" (click)="sendMessage()">
          Envoyer
        </button>
      </div>
    </div>
  </div>
  <div class="col-12 rm-row dispatch-away" style="margin-top: 10px;">
    <rm-upload-input
      *ngIf="agentRef && companyRef"
      (urlReadyEmitter)="createMessageFromFile($event)"
      [workflow]="fileType"
      [companyRef]="companyRef"
      [agentRef]="agentRef"
    ></rm-upload-input>
    <ul class="list-group list-group-horizontal" *ngIf="selectedMessage || conversation.lastSender === 'agent'">
      <li class="list-group-item rm-clickable" [ngClass]="{'selected': currentReaction === reactions.support_ok_reaction}" (click)="reactToMessageWith(reactions.support_ok_reaction)" [pTooltip]="'support_ok_reaction' | translate" [tooltipZIndex]="1051">👍</li>
      <li class="list-group-item rm-clickable" [ngClass]="{'selected': currentReaction === reactions.support_nok_reaction}" (click)="reactToMessageWith(reactions.support_nok_reaction)" [pTooltip]="'support_nok_reaction' | translate" [tooltipZIndex]="1051">👎</li>
      <li class="list-group-item rm-clickable" [ngClass]="{'selected': currentReaction === reactions.support_explain_reaction}" (click)="reactToMessageWith(reactions.support_explain_reaction)" [pTooltip]="'support_explain_reaction' | translate" [tooltipZIndex]="1051">🤔</li>
      <li class="list-group-item rm-clickable" (click)="reactToMessageWith(reactions.support_no_reaction)" [pTooltip]="'support_no_reaction' | translate" [tooltipZIndex]="1051">❌</li>
    </ul>
  </div>
</div>
<div *ngIf="!conversation" style="min-height: 50vh;">
  <div class="row">
    <div class="col-12">
      <h3>
        {{'CREATE_NEW_TICKET' | translate}}
      </h3>
    </div>
  </div>
  <div class="row detached">
    <div class="col-12 col-lg-6">
      <rm-single-select
        [fullWidth]="true"
        [array]="cataegories"
        (changeEmitter)="selectCategory($event)"
        [placeholder]="'select_category'"
      ></rm-single-select>
    </div>
  </div>
  <div class="row detached">
    <div class="col-12 col-lg-6">
      <textarea 
        class="form-control" 
        [(ngModel)]="subject" 
        placeholder="{{'description' | translate}}"
      ></textarea>
    </div>
  </div>
  <div class="row detached">
    <div class="col-12 col-lg-6">
      <button 
        class="btn btn-primary" 
        type="button" 
        (click)="createConversation()" 
        [disabled]="loading || !subject || !category"
      >
        <span *ngIf="loading" class="c-loading-button-spinner spinner-border spinner-border-sm"></span>
        {{'CREATE_TICKET' | translate}}
      </button>
    </div>
  </div>
</div>