import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'rm-checking-identity',
    templateUrl: './checking-identity.component.html',
    styleUrls: ['./checking-identity.component.scss']
})
export class CheckingIDentityComponent implements OnInit {
    constructor() { }

    ngOnInit(): void { }
}
