import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormDefinition, Merchant, Mid, ObjectDefList } from '@roadmate/roadmate-common';
import { FireStoreService } from '../../../services';
import { getTableSettings } from '../../../utils';
import { AbstractEntryComponent } from '../abstract.entry-component';

@Component({
  selector: 'rm-assign-mid',
  templateUrl: './assign-mid.component.html',
  styleUrls: ['./assign-mid.component.scss']
})
export class AssignMidComponent extends AbstractEntryComponent<boolean> implements OnInit {
  public merchants: Merchant[] = [];
  public seletedMids: Mid[];
  public merchantDef: FormDefinition;
  public settings;
  public loading = false;
  public set data(value) {
    if (!value) {
      return;
    }
    this.seletedMids = value.seletedMids;
  }

  constructor(
    private fs: FireStoreService,
    private translate: TranslateService
  ) {
    super();
  }

  ngOnInit(): void {
    this.initList();
  }

  private async initList() {
    this.merchants = await this.fs.getMerchants();
    const list = await this.fs.getObjectsList();
    this.merchantDef = list[ObjectDefList.merchant];
    this.settings = await getTableSettings(this.merchantDef, this.translate);
  }

  public async selectMerchant(selectedRow: {isSelected: boolean, data: Merchant}) {
    this.loading = true;
    try {
      const merchant = selectedRow.data as Merchant;
      this.fs.addMidToMerchant(selectedRow.data, this.seletedMids);
      this.close(true);
    } catch (e) {
      console.error('', e);
      this.close(false);
    } finally {
      this.loading = false;
    }
  }
}
