import { Component, OnInit } from '@angular/core';
import { AbstractEntryComponent } from '../abstract.entry-component';
import { RoadMatePayoutRequest } from '@roadmate/roadmate-common';

@Component({
  selector: 'rm-confirm-payout',
  templateUrl: './confirm-payout.component.html'
})
export class ConfirmPayoutComponent extends AbstractEntryComponent<any> implements OnInit {
  public transfer: Partial<RoadMatePayoutRequest>;
  public set data(value) {
    if (!value) {
      return;
    }
    this.transfer = value;
  }
  constructor() {
    super();
  }

  ngOnInit(): void { }

}
