<div *ngIf="merchant">
  <div class="row">
    <div class="col-12">
      <img [src]="merchant.logo | rm_image_render" class="logo" alt="Placeholder" />
    </div>
  </div>
  <div class="row push-down">
    <div class="col-12">
      <p>
        <strong>
          {{merchant.brandName}}
        </strong>
        {{merchant.description}}
      </p>
    </div>
  </div>
  <div class="row push-down" *ngIf="merchant.applink">
    <div class="col-12">
      <button class="btn btn-primary" *ngIf="merchant.applink.web" (click)="openUrl(merchant.applink.web)">
        {{'open_website'|translate}}
      </button>
      <button class="btn btn-primary" *ngIf="merchant.applink.iosStore" (click)="openUrl(merchant.applink.iosStore)">
        {{'open_appstore'|translate}}
      </button>
      <button class="btn btn-primary" *ngIf="merchant.applink.androidStore"
        (click)="openUrl(merchant.applink.androidStore)">
        {{'open_playstore'|translate}}
      </button>
    </div>
  </div>
  <div class="row push-dowm" *ngIf="merchant.services?.length">
    <div class="col-12">
      <h4>
        {{'partner_services_available'|translate}}
      </h4>
    </div>
    <div class="col-12">
      <ng-container *ngIf="merchant.services.length === 1; else serviceCarousel">
        <rm-offer-card
          [voucher]="merchant.services[0]"
          [monthlyBudget]="monthlyBudget"
        ></rm-offer-card>
      </ng-container>
      <ng-template #serviceCarousel>
        <p-carousel [value]="merchant.services">
          <ng-template let-service pTemplate="item">
            <div class="rm-column" *ngIf="service">
              <div class="centered-container">
                <rm-offer-card
                  [voucher]="service"
                  [monthlyBudget]="monthlyBudget"
                ></rm-offer-card>
              </div>
            </div>
          </ng-template>
        </p-carousel>
      </ng-template>
    </div>

  </div>
  <div class="row push-dowm" *ngIf="merchant.vouchers?.length">
    <div class="col-12">
      <h4>
        {{'partner_offers_available'|translate}}
      </h4>
    </div>
    <div class="col-12 m-auto">
      <ng-container *ngIf="merchant.vouchers.length === 1; else manyVouchers">
        <rm-offer-card
          [voucher]="merchant.vouchers[0]"
          [monthlyBudget]="monthlyBudget"
        ></rm-offer-card>
      </ng-container>
      <ng-template #manyVouchers>
        <p-carousel [value]="merchant.vouchers">
          <ng-template let-voucher pTemplate="item">
            <div class="rm-column" *ngIf="voucher">
              <div class="centered-container">
                <rm-offer-card
                  [voucher]="voucher"
                  [monthlyBudget]="monthlyBudget"
                ></rm-offer-card>
              </div>
            </div>
          </ng-template>
        </p-carousel>
      </ng-template>
    </div>
  </div>
</div>