
<select class="c-multi-select" id="select-11" tabindex="-1" style="display: none;">
  <optgroup
    *ngFor="let group of groups"
    label="{{group.label}}"
  >
    <option
      *ngFor="let option of group.ddl"
      value="{{option.value}}"
    >
      {{option.label}}
    </option>
  </optgroup>
</select>
<div
  #ddl
  (click)="showDDL = !showDDL"
  [ngClass]="{'c-show': showDDL}"
  class="c-multi-select c-multi-select-multiple c-multi-select-selection-tags"
>
  <span class="c-multi-select-selection">
    <span class="c-multi-select-tag" *ngFor="let chip of selectedChips">
      {{chip.label}}
      <span class="c-multi-select-tag-delete close" aria-label="Close" (click)="removeItem($event, chip.value)">
        <span aria-hidden="true">×</span>
      </span>
    </span>
  </span>
  <input class="c-multi-select-search" size="2">
  <div class="c-multi-select-options">
    <div class="c-multi-select-optgroup" *ngFor="let group of groups">
      <div class="c-multi-select-optgroup-label">{{group.label}}</div>
      <div
        *ngFor="let option of group.ddl"
        (click)="selectItem($event, option.value, group.value)"
        [ngClass]="{'c-multi-selected': selectedChips | array_contains:'value':option.value}"
        class="c-multi-select-option"
      >
        {{option.label}}
      </div>
    </div>
  </div>
</div>

