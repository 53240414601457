<div id="toast-container" class="toast-bottom-full-width" *ngIf="isVisible">
  <div class="toast toast-info" [ngClass]="{
    'toast-warning': mode === 'warning',
    'toast-danger': mode === 'danger',
    'toast-success': mode === 'success'
  }">
    <div class="toast-title">
      {{title | translate}}
    </div>
    <div
      [innerHTML]="message | translate"
      class="toast-message push-down"
    >
    </div>
    <div class="rm-row">
      <button type="button" class="btn btn-light" (click)="close()">
        Ok
      </button>
    </div>
  </div>
</div>

