<div #sddl class="dropdown d-inline-block" [ngClass]="{'show': showDDL, 'fullWidth': fullWidth}" (click)="showDDL = !showDDL">
  <button
    class="btn btn-primary dropdown-toggle"
    type="button"
    [disabled]="!array || !array.length"
  >
    <ng-container *ngIf="!selectedItem">
      {{placeholder | translate}}
    </ng-container>
    <ng-container *ngIf="selectedItem">
      {{selectedItem.label | translate}}
      <strong *ngIf="showDescription && selectedItem.description">{{selectedItem.description}}</strong>
    </ng-container>
  </button>
  <div class="dropdown-menu ddl"  [ngClass]="{'show': showDDL}">
    <div class="dropdown-item" *ngFor="let item of array" (click)="selectItem(item)">
      <i class="cil-check-alt" *ngIf="selectedItem && selectedItem.value === item.value"></i>
        {{item.label | translate}}
      <strong *ngIf="showDescription && item.description">{{item.description}}</strong>
    </div>
  </div>
</div>
