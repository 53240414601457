<h3>
  {{title|translate}}
</h3>
<p *ngIf="description"></p>
<div class="row detached" *ngIf="choices.length">
  <div class="col-12">
    <div class="card">
      <ul class="list-group list-group-flush">
        <li 
          *ngFor="let item of choices; let i = index"
          (click)="selectItem(item.value)"
          class="list-group-item rm-clickable center" 
        >
          <div class="fs-4 fw-semibold">
            <i class="icon icon-2xl mt-5 mb-2" *ngIf="item.icon" [ngClass]="item.icon"></i>
            {{item.label | translate}}
          </div>
          <div class="fs-4 fw-semibold" *ngIf="item.description">
            <p>
              {{item.description | translate}}
            </p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>