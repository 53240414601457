import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'array_contains'})
export class ArrayContainsPipe implements PipeTransform {
  transform(array: any[], property: string, value: any): boolean {
    if (!array || !property) {
      return false;
    }
    return !!array.find(el => el[property] === value);
  }
}
