import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'rm-main-card-container',
  template: `
  <mat-card class="fade-in">
  <mat-card-title>{{title | translate}}</mat-card-title>
  <mat-card-content>
    <ng-content></ng-content>
  </mat-card-content>
  </mat-card>
  `
})
export class MainCardContainerComponent implements OnInit {
  @Input() title: string;
  constructor() { }

  ngOnInit(): void { }
}
