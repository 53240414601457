import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DropDownListOption } from '@roadmate/roadmate-common';

@Component({
  selector: 'rm-multi-check-box',
  templateUrl: './multi-check-box.component.html',
  styleUrls: ['./multi-check-box.component.scss']
})
export class MultiCheckBoxComponent implements OnInit {
  @Input() set setItems(items: DropDownListOption[]) {
    if (!items) {
      return;
    }
    this.items = items;
  }
  public searcheText = '';
  @Output() selectedItemsChangeEmitter:EventEmitter<DropDownListOption[]> = new EventEmitter<DropDownListOption[]>();
  public items: DropDownListOption[] = [];
  public selectedItems: DropDownListOption[] = [];
  constructor() { }

  ngOnInit(): void {
    this.selectAll();
  }

  public itemSelected(item: DropDownListOption) {
    const isSelected = !!this.selectedItems.find(el => el.value === item.value);
    if (isSelected) {
      this.selectedItems = this.selectedItems.filter(el => el.value !== item.value);
    } else {
      this.selectedItems = [...this.selectedItems, item];
    }
    this.emit();
  }

  public selectAll() {
    this.selectedItems = [...this.items];
    this.emit();
  }

  public unSelectAll() {
    this.selectedItems = [];
    this.emit();
  }

  private emit() {
    this.selectedItemsChangeEmitter.emit(this.selectedItems);
  }
}
