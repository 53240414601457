import { Component, Input, OnInit } from '@angular/core';

import {RoadMateDocument } from '@roadmate/roadmate-common';

@Component({
  selector: 'rm-file-render',
  template: `
    <img style="width: 45px; height:45px" [src]="renderValue" alt="logo" />
  `,
})
export class ImageRenderComponent implements OnInit {

  renderValue: string;

  @Input() value: RoadMateDocument[];
  @Input() rowData: any;

  ngOnInit() {
    this.renderValue = this.value[0]?.url ?? '';
  }

}
