import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import firebase from 'firebase/app';
import 'firebase/storage';
import { UploadTask } from '@angular/fire/storage/interfaces';
import { error_messages, fileStoringWorkflows, FolderNames } from '@roadmate/roadmate-common';
import { FireStoreService } from '../../../services/fire.store';


@Component({
    selector: 'rm-upload-task',
    templateUrl: './upload-task.component.html',
    styleUrls: ['./upload-task.component.scss']
})
export class UploadTaskComponent implements OnInit {
  @Input() file: File;
  @Input() fileSavingWorkFlow: fileStoringWorkflows;
  @Input() companyRef: string;
  @Input() agentRef: string;
  @Input() useruid: string;
  @Output() finishEmitter = new EventEmitter<{
    downloadURL: string;
    fileName: string;
  }>();
  task: UploadTask;
  snap;
  percentage: number;
  downloadURL: string;
  uniqueFileName: string;
  constructor(private fs: FireStoreService) { }

  ngOnInit() {
    this.startUpload();
  }

  startUpload() {
    try {
      let fileName = `/${this.fs.currentAppUser.uid}/${(new Date()).getTime().toString()}`;
      const iso = (new Date()).toISOString().replace(/ /g, '-').substring(0, 19);
      this.uniqueFileName = `${iso}-${this.file.name}`;
      switch (this.fileSavingWorkFlow) {
        case fileStoringWorkflows.idemia:{
          fileName = `/${FolderNames.idemia}/${this.uniqueFileName}`;
          break;
        }
        case fileStoringWorkflows.kyb:
          if (!this.companyRef) {
            throw error_messages.PLEASE_SELECT_ONE_COMPANY;
          }
          fileName = `/${FolderNames.agents}/${this.agentRef}/${FolderNames.companies}/${this.companyRef}/${FolderNames.kyb}/${this.uniqueFileName}`;
          break;
        case fileStoringWorkflows.createUsers:
          if (!this.companyRef || !this.agentRef) {
            throw error_messages.PLEASE_SELECT_ONE_COMPANY;
          }
          fileName = `/${FolderNames.agents}/${this.agentRef}/${FolderNames.companies}/${this.companyRef}/${FolderNames.createUsers}/${this.uniqueFileName}`;
          break;
        case fileStoringWorkflows.supportingLinks:
          fileName = `/${FolderNames.agents}/${this.agentRef}/${FolderNames.companies}/${this.companyRef}/${fileStoringWorkflows.supportingLinks}/${this.uniqueFileName}`
          break;
        case fileStoringWorkflows.support:
          fileName = `/${FolderNames.agents}/${this.agentRef}/${FolderNames.companies}/${this.companyRef}/${fileStoringWorkflows.support}/${this.uniqueFileName}`
          break;
        default:
          fileName = `/${FolderNames.assets}/${iso}-${this.uniqueFileName}`;
          break;
      }
      const storageRef = firebase.storage().ref();
      this.task = storageRef.child(fileName).put(this.file);
      this.task.on(firebase.storage.TaskEvent.STATE_CHANGED,
      (snapshot) => {
          this.snap = snapshot;
          this.percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      }, error => {
          console.error('File could not load', error);
          throw error;
      },
      async () => {
          this.downloadURL = await firebase.storage().ref(fileName).getDownloadURL();
          this.finishEmitter.emit({
            downloadURL: this.downloadURL,
            fileName: this.uniqueFileName,
          });
      });
    } catch (e) {
        console.error('Error while uploading the file', e);
        this.finishEmitter.emit(null);
    }
  }

  isActive(snapshot) {
    return snapshot.state === 'running' && snapshot.bytesTransferred < snapshot.totalBytes;
  }
}
