import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ModalComponent } from './rm-modal.component';
import { RMDynamicDirective } from './modal-holder.directive';

@NgModule({
  declarations: [ModalComponent, RMDynamicDirective],
  imports: [
    CommonModule,
    TranslateModule
  ],
  exports: [ModalComponent],
  providers: [],
})
export class RmModalModule {}
