<div class="transfer-form-container">
  <h4>
    {{'confirm_transfer_description' | translate}}
  </h4>
  <div class="row">
    <div class="col-sm-12">
      <div class="form-group">
        <label for="name">{{'transfer_from' | translate}}</label>
        <div class="form-control" disabled="">
          {{transfer.from.eventName | translate}}
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <div class="form-group">
        <label for="name">{{'transfer_to' | translate}}</label>
        <div class="form-control" disabled="">
          {{transfer.to.eventName | translate}}
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-6">
      <div class="form-group">
        <label for="name">{{'product_name' | translate}}</label>
        <div class="form-control" disabled="">
          {{transfer.productName | translate}}
        </div>
      </div>
    </div>
    <div class="col-sm-6">
      <div class="form-group">
        <label for="name">{{'group_name' | translate}}</label>
        <div class="form-control" disabled="">
          {{transfer.groupName}}
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <div class="form-group">
        <label for="name">{{'transfer_amount' | translate}}</label>
        <div class="form-control" disabled="">
          {{transfer.amount| currency:'EUR'}}
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-6">
      <div class="form-group">
        <label for="name">{{'concerned_employees' | translate}}</label>
        <div class="form-control" disabled="">
          {{transfer.usersCount}}
        </div>
      </div>
    </div>
    <div class="col-sm-6">
      <div class="form-group">
        <label for="name">{{'balance_per_user' | translate}}</label>
        <div class="form-control" disabled="">
          {{transfer.individualBalance | currency:'EUR'}}
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12 rm-column">
      <div class="users-block">
        <div class="rm-row clickabke title"  #showUsers="var" [var]="true" (click)="showUsers.var = !showUsers.var">
          <div class="col-10">{{'employee_beneficiary_list'|translate}}</div>
          <div class="col-2" style="text-align: right;">
            <i class="cil-chevron-circle-down-alt" *ngIf="!showUsers.var"></i>
            <i class="cil-chevron-circle-up-alt" *ngIf="showUsers.var"></i>
          </div>
        </div>
        <div class="rm-column list" [hidden]="!showUsers">
          <div class="rm-row user-item"  *ngFor="let item of transfer.selectedUsers; let i = index">
            <div class="rm-index">{{i +1}}</div>
            <label>{{item.label}}</label>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="rm-row detached center" style="padding:20px; background:#e8e9ea; border-radius: 5px;" >
    <button class="btn btn-primary" style="margin-right:20px" type="button" (click)="close(transfer)" *ngIf="!viewOnly">
      {{'submit' | translate}}
    </button>
    <button class="btn btn-light" type="button" (click)="close(null)">
      {{'cancel' | translate}}
    </button>
  </div>
</div>

