import { Pipe, PipeTransform } from '@angular/core';
import { RoadMateTimeZone } from '@roadmate/roadmate-common';


@Pipe({ name: 'iso_date_to_french' })
export class IsoDateToFrenchPipe implements PipeTransform {
  transform(value: string, short = false): any {
    if (!value || value.length < 10) {
      return '';
    }
    const date = value.substring(8, 10);
    const monthIndex = value.substring(5, 7);
    const month = RoadMateTimeZone.months[monthIndex];
    const year = value.substring(0, 4);
    if (!short) {
      return `${date === '01' ? '1er' : date} ${month} ${year}`;
    } else {
      return `${date}/${monthIndex}/${year}`;
    }
  }
}

@Pipe({ name: 'rm_iso_to_french_datetime' })
export class IsoToFrenchDateTimePipe implements PipeTransform {
  transform(value: string, short = false): any {
    if (!value || value.length < 10) {
      return '';
    }
    const date = value.substring(8, 10);
    const monthIndex = value.substring(5, 7);
    const month = RoadMateTimeZone.months[monthIndex];
    const hour = value.substring(11, 16);
    const year = value.substring(0, 4);
    if (!short) {
      return `${date}/${monthIndex}/${year} ${hour}`;
    } else {
      return `${date === '01' ? '1er' : date} ${month} ${year}`;
    }
  }
}