import { Component, Input, OnInit } from '@angular/core';
import { RoadMateTimeZone } from '@roadmate/roadmate-common';

@Component({
  selector: 'rm-render-month',
  template: `{{value}}`,
})
export class MonthRenderComponent implements OnInit {

  @Input() value: string;
  @Input() rowData: boolean;

  ngOnInit() {
    if (this.value && this.value.length >= 7) {
      const month = RoadMateTimeZone.months[this.value.substring(5,7)];
      const year = this.value.substring(0,4);
      if (month && year) {
        this.value = `${month} ${year}`;
      }
    }
    if (!Number.isNaN(parseInt(`${this.value}`))) {
      const monthIndex = parseInt(`${this.value}`) + 1;
      if (monthIndex === 0) {
        this.value = '';
        return;
      }
      this.value = RoadMateTimeZone.months[`${monthIndex}`.padStart(2, '0')];
    }
  }

}
