import { Pipe, PipeTransform } from '@angular/core';
import { RoadMateTimeZone } from '@roadmate/roadmate-common';

@Pipe({name: 'rm_month_zero_index'})
export class MonthZeroIndexPipe implements PipeTransform {
  transform(value: any, year: number): any {
    if (!isNaN(value) ) {
      const month = RoadMateTimeZone.months[`${value+1}`.padStart(2, '0')];
      return `${month} ${year}`;
    }
    return 'INVALID'
  }
}