import { Pipe, PipeTransform } from '@angular/core';
import { FormGroup } from '@angular/forms';

export interface ControlError {
  error: string;
  state: boolean;
}

@Pipe({
  name: 'rm_control_errors',
  pure: false
})
export class ControlErrorsPipe implements PipeTransform {
  transform(formGroup: FormGroup, controlName: string): ControlError[] {
    if (!formGroup
        || !(formGroup instanceof FormGroup)
        || !controlName
        || !formGroup.get(controlName)
        || !formGroup.get(controlName).errors) {
      return [];
    }
    return Object.keys(formGroup.get(controlName).errors)
    .filter(key => key !== 'required')
    .map(el => {
      return {
        error: el,
        state: true
      }
    });
  }
}
