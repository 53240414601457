import { Injectable } from '@angular/core';

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from '../../assets/scripts/custom-font';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Injectable({
  providedIn: 'root'
})
export class PdfPrinterService {
  constructor() {}

  public generatePdf(
    maintext: string,
    beforeSignature: string,
    signatureData64: string,
    ikv = false
  ): Promise<string> {
    return new Promise(
      (resolve, reject) => {
        if (!signatureData64) {
          reject('No signature data was passed');
          return;
        }
        pdfMake.fonts = {
          montserrat: {
            normal: 'montserrat-regular.woff',
            bold: 'montserrat-bold.woff',
            italics: 'montserrat-semibolditalic.woff',
            bolditalics: 'montserrat-semibolditalic.woff'
          },
        };
        pdfMake.createPdf(
          ikv ? this.getIKVDocDefinition(maintext, beforeSignature, signatureData64) :
          this.getDocDefinition(maintext, beforeSignature, signatureData64)
        ).getBase64((data: string) => {
          resolve(data);
        });
      }
    );
  }

  public getPdfBase64FromContent(info, footerText, content: any): Promise<string> {
    return new Promise(
      (resolve, reject) => {
        pdfMake.fonts = {
          Roboto: {
            normal: 'montserrat-regular.woff',
            bold: 'montserrat-bold.woff',
            italics: 'montserrat-semibolditalic.woff',
            bolditalics: 'montserrat-semibolditalic.woff'
          },
        };
        pdfMake.createPdf({
          info,
          pageSize: 'A4',
          pageBreakBefore: (currentNode: any, followingNodesOnPage: any) => {
            return currentNode.headlineLevel === 1 && followingNodesOnPage.length === 0;
          },
          pageMargins: [40, 60, 40, 60],
          footer: function(currentPage: number, pageCount: number) {
            return {
              columns: [
                {
                  text: `${footerText} ${currentPage}/${pageCount}`,
                  alignment: 'right',
                  fontSize: 7,
                  margin: [0,0,40,0]
                }
              ],
              margin: [50,5,10,10]
            }
          },
          ...content
        }).getBase64((data: string) => {
          resolve(data);
        });
      }
    );
  }

  public generatePayoutPdf(
    maintext: string,
    beforeSignature: string,
  ): Promise<string> {
    return new Promise(
      (resolve, reject) => {
        pdfMake.fonts = {
          montserrat: {
            normal: 'montserrat-regular.woff',
            bold: 'montserrat-bold.woff',
            italics: 'montserrat-semibolditalic.woff',
            bolditalics: 'montserrat-semibolditalic.woff'
          },
        };
        pdfMake.createPdf(
          this.getPayoutDocDefinition(maintext, beforeSignature)
        ).getBase64((data: string) => {
          resolve(data);
        });
      }
    );
  }

  /**
   * Create the body of PDF For IKV
   */
  private getIKVDocDefinition(
    mainText: string,
    beforeSignature: string,
    signatureData: string
  ) {
    return {
      content: [
        {
          text: `Attestation sur l'honneur concernant l'indémnité kilométrique vélo`,
          alignment: 'center',
          margin: [0, 30, 0, 10],
          color: '#000000',
          fontSize: 15,
          font: 'montserrat',
          bold: true,
        },
        {
          text: `Objet: Demande de remboursement de l'indémnité kilométrique vélo.`,
          alignment: 'left',
          margin: [0, 30, 0, 20],
          color: '#000000',
          fontSize: 12,
          font: 'montserrat',
          bold: true,
        },
        {
          text: `${mainText}`,
          alignment: 'left',
          margin: [0, 20, 0, 20],
          color: '#000000',
          fontSize: 12,
          bold: false,
          font: 'montserrat'
        },
        {
          text: `${beforeSignature}`,
          alignment: 'left',
          margin: [0, 10, 0, 10],
          color: '#000000',
          fontSize: 12,
          bold: false,
          font: 'montserrat'
        },
        {
          text: `Fait pour servir et valoir ce que de droit.`,
          alignment: 'left',
          margin: [0, 0, 0, 10],
          color: '#000000',
          fontSize: 12,
          bold: false,
          font: 'montserrat'
        },
        {
          text: `Signature`,
          alignment: 'left',
          margin: [0, 0, 0, 10],
          color: '#000000',
          fontSize: 12,
          bold: false,
          font: 'montserrat'
        },
        {
          image: signatureData,
          width: 238,
          alignment: 'center',
          margin: [0, 0, 0, 0]
        }
      ],
    };
  }

  /**
   * Create the body of PDF
   */
  private getDocDefinition(
    mainText: string,
    beforeSignature: string,
    signatureData: string
  ) {
    return {
      content: [
        {
          text: 'Attestation sur l’honneur concernant l’utilisation du Forfait Mobilités Durables',
          alignment: 'center',
          margin: [0, 30, 0, 10],
          color: '#000000',
          fontSize: 15,
          font: 'montserrat',
          bold: true,
        },
        {
          text: 'Objet: Utilisation des moyens de transport éligibles au Forfait Mobilités Durables.',
          alignment: 'left',
          margin: [0, 30, 0, 10],
          color: '#000000',
          fontSize: 12,
          font: 'montserrat',
          bold: true,
        },
        {
          text: `${mainText}`,
          alignment: 'left',
          margin: [0, 0, 0, 20],
          color: '#000000',
          fontSize: 12,
          bold: false,
          font: 'montserrat'
        },
        {
          text: `• Achat, location et entretien de vélo (mécanique ou à assistance électrique), trottinettes, EPDM ou scooters électriques,`,
          alignment: 'left',
          margin: [10, 0, 0, 5],
          color: '#000000',
          fontSize: 12,
          bold: false,
          font: 'montserrat'
        },
        {
          text: `• Covoiturage (conducteur ou passager),`,
          alignment: 'left',
          margin: [10, 0, 0, 5],
          color: '#000000',
          fontSize: 12,
          bold: false,
          font: 'montserrat'
        },
        {
          text: `• Engins de déplacement partagés (vélopartage, trottinettes et scooters en freefloating),`,
          alignment: 'left',
          margin: [10, 0, 0, 5],
          color: '#000000',
          fontSize: 12,
          bold: false,
          font: 'montserrat'
        },
        {
          text: `• Autopartage à motorisation non thermique (« frais d’alimentation d’un véhicule électrique, hybride rechargeable ou hydrogène »),`,
          alignment: 'left',
          margin: [10, 0, 0, 5],
          color: '#000000',
          fontSize: 12,
          bold: false,
          font: 'montserrat'
        },
        {
          text: `• Transports en commun hors abonnements.`,
          alignment: 'left',
          margin: [10, 0, 0, 5],
          color: '#000000',
          fontSize: 12,
          bold: false,
          font: 'montserrat'
        },
        {
          text: `${beforeSignature}`,
          alignment: 'left',
          margin: [0, 10, 0, 10],
          color: '#000000',
          fontSize: 12,
          bold: false,
          font: 'montserrat'
        },
        {
          text: `Fait pour servir et valoir ce que de droit.`,
          alignment: 'left',
          margin: [0, 0, 0, 10],
          color: '#000000',
          fontSize: 12,
          bold: false,
          font: 'montserrat'
        },
        {
          text: `Signature`,
          alignment: 'left',
          margin: [0, 0, 0, 10],
          color: '#000000',
          fontSize: 12,
          bold: false,
          font: 'montserrat'
        },
        {
          image: signatureData,
          width: 238,
          alignment: 'center',
          margin: [0, 0, 0, 0]
        },
      ],
    };
  }

  private getPayoutDocDefinition(
    mainText: string,
    beforeSignature: string,
  ) {
    return {
      content: [
        {
          text: 'Attestation sur l’honneur',
          alignment: 'center',
          margin: [0, 30, 0, 10],
          color: '#000000',
          fontSize: 15,
          font: 'montserrat',
          bold: true,
        },
        {
          text: 'Objet: Réalisation d\'un virement sortant',
          alignment: 'left',
          margin: [0, 30, 0, 10],
          color: '#000000',
          fontSize: 12,
          font: 'montserrat',
          bold: true,
        },
        {
          text: `${mainText}`,
          alignment: 'left',
          margin: [0, 0, 0, 20],
          color: '#000000',
          fontSize: 12,
          bold: false,
          font: 'montserrat'
        },
        {
          text: `${beforeSignature}`,
          alignment: 'left',
          margin: [0, 10, 0, 10],
          color: '#000000',
          fontSize: 12,
          bold: false,
          font: 'montserrat'
        },
        {
          text: `Fait pour servir et valoir ce que de droit.`,
          alignment: 'left',
          margin: [0, 0, 0, 10],
          color: '#000000',
          fontSize: 12,
          bold: false,
          font: 'montserrat'
        },
        {
          text: `Signature`,
          alignment: 'left',
          margin: [0, 0, 0, 10],
          color: '#000000',
          fontSize: 12,
          bold: false,
          font: 'montserrat'
        },
      ],
    };
  }
}
