import {Component, ElementRef, AfterViewInit, OnInit, ViewChild} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { actions, Agent, appRoles, kybStatus, Kyc, RoadMateEnvironmentNameSpace, Treezor, rm_storageKeys } from '@roadmate/roadmate-common';
import { environment } from '../../../environments/environment';
import { FireStoreService, RMToasterService } from '../../services';
import { FireAuthService } from '../../services/fire.auth';
import { FireFunctionsService } from '../../services/fire.functions';
import { MessengerService } from '../../services/messenger.service';
import { ModalParcel, ModalService } from '../../services/modal.service';
import { adminLinks, appRoutes, loggedInNotConfigured,
  loggedInNotValidated, loggedOut, superadminLinks, agentLinks, accountManagerLinks, amCompanyNotSelected, employeeNavLinks, employeeNotSetUp } from '../../static';


@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss']
})
export class DefaultLayoutComponent implements OnInit, AfterViewInit {
  minimized = false;
  public dashVersion = RoadMateEnvironmentNameSpace.dashVersion;
  public navItems = [...loggedOut];
  public opened = false;
  public isBiggerScreen = false;
  public currentPage = '';
  public routes = appRoutes;
  public adminlinks = [...adminLinks];
  public superadminlinks = [...superadminLinks];
  public companyName = '';
  public companyImg = '';
  public email = '';
  public fullName = '';
  public themeClass;
  public env = environment.env;
  public isAdmin = false;
  public userAppRole = '';
  public isSuperAdmin = false;
  public isAgent = false;
  public isEmployee = false;
  public isAccountManager = false;
  public toggle1 = false;
  public toggle2 = false;
  public toggle3 = false;
  public agentRef = '';
  public flash = true;
  public navbarBrandFull = {src: 'assets/svg/roadmate.svg', width: 89, height: 25, alt: 'RoadMate Logo', background: 'black'};
  public navbarBrandMinimized = {src: 'assets/img/brand/roadmate.svg', width: 30, height: 30, alt: 'RoadMate Logo'};
  public agentLogo = '/assets/img/logo2.png';
  public agent: Agent;
  @ViewChild('menu1', { read: ElementRef, static: false })
  public menu1: ElementRef;
  @ViewChild('menu2', { read: ElementRef, static: false })
  public menu2: ElementRef;
  @ViewChild('menu3', { read: ElementRef, static: false })
  public menu3: ElementRef;
  public isLoggedIn = false;
  public parcel: Observable<ModalParcel>;
  public selectedCompany: Treezor.User.Definition = null;
  public year = (new Date()).getFullYear();
  public loginAs = '';
  public currentEmail = '';
  constructor (
    private router: Router,
    translate: TranslateService,
    private ff: FireFunctionsService,
    private fs: FireStoreService,
    private messenger: MessengerService,
    private modalService: ModalService,
    public auth: FireAuthService,
    private toast: RMToasterService
  ) {
    this.parcel = this.modalService.messenger.asObservable();
    translate.setDefaultLang('fr');
    this.router.events.subscribe(
      value => {
        if (value instanceof NavigationEnd) {
          const state: NavigationEnd = value;
          let currentRoute = '';
          Object.values(appRoutes).some(route => {
            if (state.urlAfterRedirects.indexOf(route) > -1) {
              currentRoute = route;
              return true;
            }
            return false;
          });
          this.currentPage = currentRoute;
        }
      }
    );
  }

  public ngOnInit() {
    if(localStorage.getItem('agentCssClass')) {
      const body = document.body;
      const cssClass = localStorage.getItem('agentCssClass');
      if (!body.classList.contains(cssClass)) {
        body.classList.add(cssClass);
      }
    }
    this.messenger.parcel.asObservable().subscribe(parcel => {
      this.isLoggedIn = this.auth.isLoggedIn;
      switch (parcel.action) {
        case actions.goToHomeScreen: {
          this.router.navigateByUrl(`/${this.navItems[0].url}`);
          break;
        }
        case actions.customClaims: {
          this.agentRef = parcel.data.agentRef;
          break;
        }
        case actions.roles:
          this.currentEmail = this.auth.user.email;
          this.loginAs = localStorage.getItem(this.currentEmail);
          this.email = this.auth.user.email;
          this.isSuperAdmin = parcel.data[appRoles.superadmin];
          this.isAdmin = parcel.data[appRoles.admin];
          this.isAgent = parcel.data[appRoles.agent];
          this.isAccountManager = !!parcel.data[appRoles.accountManager];
          this.isEmployee =  !!parcel.data[appRoles.employee];
          this.userAppRole = this.isSuperAdmin ? appRoles.superadmin :
          (this.isAdmin ? appRoles.admin : (this.isAgent ? appRoles.agent : (this.isAccountManager ? appRoles.accountManager: appRoles.employee)));
          if (this.isSuperAdmin) {
            this.navItems = this.superadminlinks;
          } else if (this.isAgent) {
            this.navItems = agentLinks;
          } else if (this.isAdmin) {
            this.navItems = [];
            return;
          } else if (this.isAccountManager && this.loginAs !== appRoles.employee) {
            this.setAMNavLinks();
          } else if (this.isEmployee) {
            this.setEmployeeNavLinks();
          } else {
            this.navItems = loggedOut;
          }
          break;
        case actions.login:
          if (this.auth.isLoggedIn) {
            this.ff.logUserLogin();
          }
          break;
        case actions.logout:
          this.navItems = loggedOut;
          this.userAppRole = '';
          this.isLoggedIn = false;
          this.companyName = this.companyImg = this.email = this.fullName = '';
          break;
        case actions.userCompany:
          this.companyName = parcel.data.legalName;
          this.companyImg = parcel.data.img ? parcel.data.img : ''
          break;
        case actions.amSelectCompany:
          this.companyName = parcel.data.legalName;
          this.selectedCompany = parcel.data;
          this.setAMNavLinks();
          break;
        case actions.accountManagerAgent :
          this.companyName = parcel.data.legalName;
          break;
        case actions.companyDetails:
          const company: Treezor.User.Definition = parcel.data;
          if (this.auth && this.auth.userRoles.admin && this.auth.isLoggedIn && this.loginAs !== appRoles.employee) {
            this.navItems = company.kycReview === Treezor.User.kycReview.VALIDATED ?
            adminLinks : // Ne pas afficher tous les liens si admin n'a pas configuré le compte
            loggedInNotValidated
          }
          break;
        case actions.kyc: 
          const kyc = parcel.data as Kyc;
          const appUser = this.fs.currentAppUser;
          if (
            !appUser.mobile || !appUser.hasConfirmedPhone
            || kyc.status !== kybStatus.kyb_validate_by_treezor
            || (
              this.fs.currentAppUser.userGroupIds.some(el => el.value === 'FMD')
              && !this.fs.currentAppUser.attestations?.length
            )
          ) {
            this.navItems = employeeNotSetUp;
          } else {
            this.navItems = employeeNavLinks;
          }
          break;
        case actions.ordersCount:
          const count: number = parcel.data;
          if (count === 0) {
            this.navItems = loggedInNotConfigured;
          }
          break;
        case actions.agent:
          this.agent = parcel.data;
          if (this.agent.largeLogo.length) {
            const [imgUrl] = this.agent.largeLogo;
            const url = imgUrl.url ? imgUrl.url : this.navbarBrandFull.src;
            this.navbarBrandFull = {
              ...this.navbarBrandFull,
              src: url
            }
            this.agentLogo = url;
            this.flash = false;
            setTimeout(() => this.flash = true, 250);
          }
          if (this.agent.squareLogo.length) {
            const [imgUrl] = this.agent.squareLogo;
            const url = imgUrl.url ? imgUrl.url : this.navbarBrandMinimized.src;
            this.navbarBrandMinimized = {
              ...this.navbarBrandMinimized,
              src: url
            }
          }
          if (this.agent.cssClass) {
            const body = document.body;
            localStorage.setItem('agentCssClass', this.agent.cssClass);
            if (!body.classList.contains(this.agent.cssClass)) {
              body.classList.add(this.agent.cssClass);
            }
          } else {
            const body = document.body;
            localStorage.setItem('agentCssClass', '');
            if (body.classList.contains('agent')) {
              body.className = ''
            }
          }
          break;
        default:
        break;
      }
    });
  }

  private async setEmployeeNavLinks () {
    this.navItems = employeeNavLinks;
  }

  private setAMNavLinks () {
    if (this.selectedCompany) {
      this.navItems = accountManagerLinks;
    } else {
      this.navItems = amCompanyNotSelected;
    }
  }

  public toggleLoginAs() {
    if (this.loginAs === appRoles.employee) {
      localStorage.setItem(this.currentEmail, '');
    } else {
      localStorage.setItem(this.currentEmail, appRoles.employee);
    }
    localStorage.setItem(rm_storageKeys.redirectTo, '');
    this.auth.reloadApp();
  }

  public updateProfile() {
    if(this.isAgent && this.userAppRole === appRoles.agent) {
      this.router.navigateByUrl(`/${appRoutes.agentProfile}`);
    } else if (this.isAccountManager && this.userAppRole === appRoles.accountManager) {
      this.router.navigateByUrl(`/${appRoutes.accountManager}/${appRoutes.settings}/${appRoutes.profile}`);
    } else if (this.isEmployee && this.loginAs === appRoles.employee || (this.isEmployee && !this.isAccountManager && !this.isAdmin)) {
      this.router.navigateByUrl(`/${appRoutes.employee}/${appRoutes.myprofile}`);
    } else if (this.isAdmin && !this.loginAs) {
      this.router.navigateByUrl(`/${appRoutes.admin}/${appRoutes.settings}/${appRoutes.profile}`);
    }
    this.toggle3 = false;
  }

  public ngAfterViewInit(): void {
    this.messenger.dom.subscribe(
      dom => {
        if (this.menu1 && !this.menu1.nativeElement.contains(dom) && this.toggle1) {
          this.toggle1 = false;
        }
        if (this.menu2 && !this.menu2.nativeElement.contains(dom) && this.toggle2) {
          this.toggle2 = false;
        }
        if (this.menu3 && !this.menu3.nativeElement.contains(dom) && this.toggle3) {
          this.toggle3 = false;
        }
      }
    )
  }
  toggleMinimize(e) {
    this.minimized = e;
  }

  toggleMenu(target: 'menu1' | 'menu2' | 'menu3') {
    setTimeout(() => {
      switch (target) {
        case 'menu1':
          this.toggle1 = !this.toggle1;
          break;
        case 'menu2':
          this.toggle2 = !this.toggle2;
          break;
        case 'menu3':
          this.toggle3 = !this.toggle3;
          break;
        default:
          break;
      }
    }, 50);
  }
}
