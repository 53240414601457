import { Component, Input, OnInit } from '@angular/core';
import { FireStoreService } from '../../../services/fire.store';

@Component({
  selector: 'rm-ddlvalue-render',
  template: `
    {{displayedValue}}
  `,
})
export class DdlValueRenderComponent implements OnInit {
  @Input() value: string;
  @Input() listName: string;
  public displayedValue: string;
  constructor(private fs: FireStoreService) {

  }

  ngOnInit() {
    const ddls = this.fs.getListByName('whiteLists');
    if (ddls?.length) {
      this.displayedValue = ddls.find(el => el.value === this.value)?.label;
    }
  }

}
