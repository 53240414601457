import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { PipeModule } from '../pipes/pipe.module';
import { FormsModule } from '@angular/forms';
import { Ng2SmartTablerExporterComponent } from './ng2-smart-exporter.component';
import { Ng2SmartTableModule } from 'ng2-smart-table';

@NgModule({
  declarations: [Ng2SmartTablerExporterComponent],
  imports: [
    CommonModule,
    TranslateModule,
    PipeModule,
    FormsModule,
    Ng2SmartTableModule
  ],
  exports: [Ng2SmartTablerExporterComponent],
  providers: [],
})
export class Ng2SmartExporterModule { }
