import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'rm-flip-card',
    templateUrl: './flip-card.component.html',
    styleUrls: ['./flip-card.component.scss']
})
export class FlipCardComponent implements OnInit {
    public flip = false;
    @Input() public name: string;
    @Input() public lastFourDigits: string;
    @Input() public expiryDate: string;
    @Input() public cvc: string;
    @Input() public rectoOnly = false;
    constructor() { }

    ngOnInit(): void { }
}
