import { Component, OnInit } from '@angular/core';
import { isEmail, SelectableHolder } from '@roadmate/roadmate-common';
import { AbstractEntryComponent } from '../abstract.entry-component';


@Component({
  selector: 'app-confirm-user-deletion',
  templateUrl: './confirm-user-deletion.component.html',
  styleUrls: ['./confirm-user-deletion.component.scss']
})
export class ConfirmUserDeletionComponent extends AbstractEntryComponent<boolean> implements OnInit {
  emailList: SelectableHolder<string>[] = [];

  schedule: boolean;
  startDate: string;
  notifyUsers: boolean;
  public set data(value) {
    if (!value) {
      return;
    }
    this.setEmailsSelectables(value.emails);
    this.schedule = !!value.schedule;
    this.startDate = value.startDate;
    this.notifyUsers = !!value.notifyUsers
  }
  constructor() {
    super();
  }

  ngOnInit(): void { }

  private setEmailsSelectables(emails: string[]) {
    this.emailList = emails.map(el => {
      return {
        isSelected: isEmail(el),
        data: el
      }
    });
  }

  public confirm() {
    this.close(true);
  }
}
