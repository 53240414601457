import { INavData } from '@coreui/angular';

export const appRoutes = {
    activateAccount: 'activate-account',
    admin: 'admin',
    superadmin: 'superadmin',
    agent: 'agent',
    dashboard: 'dashboard',
    agents: 'agents',
    activity: 'activity',
    users: 'users',
    programs: 'programs',
    billing: 'billing',
    settings: 'settings',
    groups: 'groups',
    forms: 'forms',
    login: 'login',
    alerts: 'alerts',
    parameters: 'parameters',
    help: 'help',
    beneficiaries: 'beneficiaries',
    budget : 'budget',
    data: 'data',
    statements: 'statements',
    employeeInvoices: 'employee-invoices',
    yearlySpendings: 'yearly-spendings',
    customizations: 'customizations',
    manageUsers: 'manage-users',
    manageTickets: 'manage-tickets',
    support: 'support',
    manageCards: 'manage-cards',
    unauthorized: 'unauthorized',
    kyb: 'kyb',
    kybReview: 'kyb-review',
    compnaies: 'compnaies',
    cards: 'cards',
    wallets: 'wallets',
    subscribe: 'subscribe',
    logout: 'logout',
    accountConfig: 'configuration',
    contact: 'contact',
    integrations: 'integrations',
    updatePassword: 'set-password',
    payouts: 'payouts',
    faqs: 'faqs',
    manageFaqs: 'manage-faqs',
    reporting: 'reporting',
    midsmccs: 'manage-transports',
    manageMobility: 'manage-mobility',
    restaurantVoucher: 'titres-restaurant',
    agentProfile: 'agent-profile',
    agentCompanies: 'agent-companies',
    agentMobilityManagement: 'agent-mobility-management',
    companyProfile: 'company-profile',
    agentManageUsers: 'agent-manage-users',
    manageRefunds: 'manage-refunds',
    manageAutomaticTransfers: 'manage-automatic-transfers',
    accountManager: 'account-manager',
    agentManageDashboard: 'ac-dashboard',
    saInvoices: 'sa-invoice',
    companyInvoices: 'company-invoices',
    profile: 'profile',
    mybudgets: 'my-budgets',
    mycard: 'my-card',
    myrefunds: 'remboursements',
    employee: 'employee',
    myprofile: 'my-profile',
    employeeOnboarding: 'my-onboarding',
    kycInProgress: 'kyc-in-progress',
    myattestation: 'my-attestation',
    mytransactions: 'my-transactions',
    rejectedTransactions: 'rejected-transactions',
    view: 'view',
    entities: 'entities',
    administration: 'administration',
    system: 'system',
    scheduledTransfer: 'scheduled-transfer',
    integrationsManager: 'integrations-manager',
    invoiceBundle: 'invoice-bundle',
    trackingFile: 'tracking-file',
    specialTasks: 'special-tasks',
    partners: 'partners',
    manageOptins : 'email-optin-optout',
    cashflow: 'cashflow',
    yousignAttestation: 'yousign-attestation',
    customAttestation: 'custom-attestation',
};
export const appLink = 'http://roadmate.app.link/XLO5XBP2keb';
export const agentLinks: INavData[] = [
  {
    name: 'Profil Entreprise',
    url: `/${appRoutes.agentProfile}`,
    icon: 'cil-briefcase'
  },
  {
    name: 'Sociétés',
    url: `/${appRoutes.agentCompanies}`,
    icon: 'cil-address-book'
  },
  {
    name: 'Utilisateurs',
    url: `/${appRoutes.agentManageUsers}`,
    icon: 'cil-user'
  },
  {
    name: 'Déconnexion',
    url: `/${appRoutes.logout}`,
    icon: 'cil-account-logout'
  }
];
export const superadminLinks: INavData[] = [
  { name: 'BAU',
    icon: 'cil-paperclip',
    url: `${appRoutes.superadmin}/${appRoutes.administration}`,
    children: [
      {
        name: 'Tableau de bord',
        url: `/${appRoutes.superadmin}/${appRoutes.administration}/${appRoutes.dashboard}`,
        icon: 'cil-bar-chart'
      },
      {
        name: 'Remboursements',
        url: `/${appRoutes.superadmin}/${appRoutes.administration}/${appRoutes.manageRefunds}`,
        icon: 'cil-briefcase'
      },

      {
        name: 'Support',
        url: `/${appRoutes.superadmin}/${appRoutes.administration}/${appRoutes.support}`,
        icon: 'cil-speech'
      },
      {
        name: 'Suivi des cartes',
        url: `/${appRoutes.superadmin}/${appRoutes.administration}/${appRoutes.trackingFile}`,
        icon: 'cil-send'
      },
      {
        name: 'Factures',
        url: `/${appRoutes.superadmin}/${appRoutes.administration}/${appRoutes.saInvoices}`,
        icon: 'cil-spreadsheet'
      },
      {
        name: 'Gestion Kyc|Kyb',
        url: `/${appRoutes.superadmin}/${appRoutes.administration}/${appRoutes.kybReview}`,
        icon: 'cil-badge'
      },
      {
        name: 'Transferts automatiques',
        url: `/${appRoutes.superadmin}/${appRoutes.administration}/${appRoutes.manageAutomaticTransfers}`,
        icon: 'cil-loop'
      },
      
    ]
  },
  { name: 'Comptes',
    url: `/${appRoutes.superadmin}/${appRoutes.view}`,
    icon: 'cil-zoom',
    children: [
      {
        name: 'Agents',
        url: `/${appRoutes.superadmin}/${appRoutes.view}/${appRoutes.agents}`,
        icon: 'cil-user'
      },
      {
        name: 'Sociétés',
        url: `/${appRoutes.superadmin}/${appRoutes.view}/${appRoutes.compnaies}`,
        icon: 'cil-briefcase'
      },
      {
        name: 'Utilisateurs',
        url: `/${appRoutes.superadmin}/${appRoutes.view}/${appRoutes.manageUsers}`,
        icon: 'cil-user'
      },
    ]
  },
  { name: 'Formulaires',
    url: `/${appRoutes.superadmin}/${appRoutes.forms}`,
    icon: 'cil-bolt',
    children: [
      {
        name: 'Agent/Company/User',
        url: `/${appRoutes.superadmin}/${appRoutes.forms}/${appRoutes.entities}`,
        icon: 'cil-library-add'
      },
      {
        name: 'Objets',
        url: `/${appRoutes.superadmin}/${appRoutes.forms}/${appRoutes.settings}`,
        icon: 'cil-settings'
      },
      {
        name: 'FAQs',
        url: `/${appRoutes.superadmin}/${appRoutes.forms}/${appRoutes.manageFaqs}`,
        icon: 'cil-lightbulb'
      },
    ]
  },
  { name: 'Système',
    icon: 'cil-cog',
    url: `/${appRoutes.superadmin}/${appRoutes.system}`,
    children: [
      {
        name: 'Transactions Rejetées',
        url: `/${appRoutes.superadmin}/${appRoutes.system}/${appRoutes.rejectedTransactions}`,
        icon: 'cil-x-circle'
      },
      {
        name: 'Mid - Mcc',
        url: `/${appRoutes.superadmin}/${appRoutes.system}/${appRoutes.midsmccs}`,
        icon: 'cil-bus-alt'
      },
      {
        name: 'Gérer les intégrations',
        url: `/${appRoutes.superadmin}/${appRoutes.system}/${appRoutes.integrationsManager}`,
        icon: 'cil-sitemap'
      },
      {
        name: 'Cashflow',
        url: `/${appRoutes.superadmin}/${appRoutes.system}/${appRoutes.cashflow}`,
        icon: 'cil-chart-line'
      },
      {
        name: 'Opérations spéciales',
        url: `/${appRoutes.superadmin}/${appRoutes.system}/${appRoutes.specialTasks}`,
        icon: 'cil-input-power'
      },
    ]
  },
  {
    name: 'Déconnexion',
    url: `/${appRoutes.logout}`,
    icon: 'cil-account-logout'
  }
];
const getAdminOrAMLinks = (role: string): INavData[] => {
  return [
    {
      name: 'Tableau de bord',
      url: `/${role}/${appRoutes.dashboard}`,
      icon: 'cil-speedometer'
    },
    {
      name: 'Bénéficiaires',
      icon: 'cil-user',
      url: `${role}/${appRoutes.beneficiaries}`,
      children: [
        {
          name: 'Ajouter/Supprimer',
          url: `/${role}/${appRoutes.beneficiaries}/${appRoutes.users}`,
          icon: 'cil-user-follow'
        },
        {
          name: 'Groupes',
          url: `/${role}/${appRoutes.beneficiaries}/${appRoutes.groups}`,
          icon: 'cil-object-group'
        },
      ]
    },
    {
      name: 'Budget',
      url: `/${role}/${appRoutes.budget}`,
      icon: 'cil-money',
      children: [
        {
          name: 'Attribuer',
          url: `/${role}/${appRoutes.budget}/${appRoutes.wallets}`,
          icon: 'cil-wallet'
        },
        {
          name: 'Comptes entreprise',
          url: `/${role}/${appRoutes.budget}/${appRoutes.payouts}`,
          icon: 'cil-transfer'
        }
      ]
    },
    {
      name: 'Reporting',
      url: `/${role}/${appRoutes.data}`,
      icon: 'cil-book',
      children: [
        {
          name: 'Relevés mensuels',
          url: `/${role}/${appRoutes.data}/${appRoutes.statements}`,
          icon: 'cil-file'
        },
        {
          name: 'Consommations',
          url: `/${role}/${appRoutes.data}/${appRoutes.yearlySpendings}`,
          icon: 'cil-chart-pie'
        },
        {
          name: 'Justificatifs',
          url: `/${role}/${appRoutes.data}/${appRoutes.employeeInvoices}`,
          icon: 'cil-notes'
        },
      ]
    },
    {
      name: '',
      divider: true,
      class: 'mt-auto',
    },
    {
      name: 'Aide',
      url: `/${role}/${appRoutes.help}`,
      icon: 'cil-info',
      children: [
        {
          name: 'Support',
          url: `/${role}/${appRoutes.help}/${appRoutes.support}`,
          icon: 'cil-chat-bubble'
        },
        {
          name: 'FAQs',
          url: `/${role}/${appRoutes.help}/${appRoutes.faqs}`,
          icon: 'cil-lightbulb'
        },
        {
          name: 'Nous contacter',
          url: `/${role}/${appRoutes.help}/${appRoutes.contact}`,
          icon: 'cil-contact'
        },
      ]
    },
    {
      name: 'Paramètres',
      url: `/${role}/${appRoutes.settings}`,
      icon: 'cil-settings',
      children: [
        {
          name: 'Démarrage',
          url: `/${role}/${appRoutes.settings}/${appRoutes.accountConfig}`,
          icon: 'cil-flight-takeoff'
        },
        {
          name: 'Personnalisation',
          url: `/${role}/${appRoutes.settings}/${appRoutes.customizations}`,
          icon: 'cil-color-border'
        },
        {
          name: 'Mon Profil',
          url: `/${role}/${appRoutes.settings}/${appRoutes.profile}`,
          icon: 'cil-user'
        },
        {
          name: 'Intégrations',
          url: `/${role}/${appRoutes.settings}/${appRoutes.integrations}`,
          icon: 'cil-sitemap'
        },
        {
          name: 'Facturation',
          url: `/${role}/${appRoutes.settings}/${appRoutes.companyInvoices}`,
          icon: 'cil-spreadsheet'
        },
      ]
    },
    {
      name: 'Déconnexion',
      url: `/${appRoutes.logout}`,
      icon: 'cil-account-logout',
    },
  ];
}
export const adminLinks: INavData[] = getAdminOrAMLinks(appRoutes.admin);

export const accountManagerLinks: INavData[] = getAdminOrAMLinks(appRoutes.accountManager);

export const employeeNavLinks: INavData[] = [
  {
    name: 'Tableau de bord',
    url: `/${appRoutes.employee}/${appRoutes.dashboard}`,
    icon: 'cil-speedometer'
  },
  {
    name: 'Ma carte',
    url: `/${appRoutes.employee}/${appRoutes.mycard}`,
    icon: 'cil-credit-card'
  },
  {
    name: 'Mes transactions',
    url: `/${appRoutes.employee}/${appRoutes.mytransactions}`,
    icon: 'cil-notes'
  },
  {
    name: 'Mes budgets',
    url: `/${appRoutes.employee}/${appRoutes.mybudgets}`,
    icon: 'cil-money'
  },
  {
    name: 'Offre de mobilité',
    url: `/${appRoutes.employee}/${appRoutes.partners}`,
    icon: 'cil-bike'
  },
  {
    name: 'Mes remboursements',
    url: `/${appRoutes.employee}/${appRoutes.myrefunds}`,
    icon: 'cil-transfer'
  },
  {
    name: 'Support',
    url: `/${appRoutes.employee}/${appRoutes.support}`,
    icon: 'cil-speech'
  },
  {
    name: 'Contacter RoadMate',
    url: `/${appRoutes.contact}`,
    icon: 'cil-contact'
  },
  {
    name: 'Déconnexion',
    url: `/${appRoutes.logout}`,
    icon: 'cil-account-logout'
  },
  {
    name: 'CGU',
    url: 'https://www.roadmate.io/cgu',
    class: 'mt-auto',
    icon: 'icon-layers',
    variant: 'primary',
    attributes: { target: '_blank', rel: 'noopener' }
  }
];

export const employeeNotSetUp: INavData[] = [
  {
    name: 'Mon inscription',
    url: `/${appRoutes.employee}/${appRoutes.employeeOnboarding}`,
    icon: 'cil-pen-alt'
  },
  {
    name: 'Contacter RoadMate',
    url: `/${appRoutes.contact}`,
    icon: 'cil-contact'
  },
  {
    name: 'Déconnexion',
    url: `/${appRoutes.logout}`,
    icon: 'cil-account-logout'
  },
  {
    name: 'CGU',
    url: 'https://www.roadmate.io/cgu',
    class: 'mt-auto',
    icon: 'icon-layers',
    variant: 'primary',
    attributes: { target: '_blank', rel: 'noopener' }
  }
]

export const amCompanyNotSelected: INavData[] = [
  {
    name: 'Tableau de bord',
    url: `/${appRoutes.accountManager}/${appRoutes.dashboard}`,
    icon: 'cil-speedometer'
  },
  {
    name: '',
    divider: true,
    class: 'mt-auto',
  },
  {
    name: 'Aide',
    url: `/${appRoutes.accountManager}/${appRoutes.help}`,
    icon: 'cil-info',
    children: [
      {
        name: 'Support',
        url: `/${appRoutes.accountManager}/${appRoutes.help}/${appRoutes.support}`,
        icon: 'cil-chat-bubble'
      },
      {
        name: 'FAQs',
        url: `/${appRoutes.accountManager}/${appRoutes.help}/${appRoutes.faqs}`,
        icon: 'cil-lightbulb'
      },
      {
        name: 'Nous contacter',
        url: `/${appRoutes.accountManager}/${appRoutes.help}/${appRoutes.contact}`,
        icon: 'cil-contact'
      },
    ]
  },
  {
    name: 'Déconnexion',
    url: `/${appRoutes.logout}`,
    icon: 'cil-account-logout'
  },
]

export const loggedInNotConfigured: INavData[] = [
  {
    name: 'Configuration',
    url: `/${appRoutes.accountConfig}`,
    icon: 'cil-cog'
  },
  {
    name: 'Nous contacter',
    url: `/${appRoutes.contact}`,
    icon: 'cil-contact'
  },
  {
    name: 'Déconnexion',
    url: `/${appRoutes.logout}`,
    icon: 'cil-account-logout'
  },
  {
    name: 'CGU',
    url: 'https://www.roadmate.io/cgu',
    icon: 'icon-layers',
    class: 'mt-auto',
    variant: 'primary',
    attributes: { target: '_blank', rel: 'noopener' }
  }
];

export const loggedInNotValidated: INavData[] = [
  {
    name: 'Validation entreprise',
    url: `/${appRoutes.kyb}`,
    icon: 'cil-info'
  },
  {
    name: 'Nous contacter',
    url: `/${appRoutes.contact}`,
    icon: 'cil-contact'
  },
  {
    name: 'Déconnexion',
    url: `/${appRoutes.logout}`,
    icon: 'cil-account-logout'
  },
  {
    name: 'CGU',
    url: 'https://www.roadmate.io/cgu',
    icon: 'icon-layers',
    class: 'mt-auto',
    variant: 'primary',
    attributes: { target: '_blank', rel: 'noopener' }
  }
];
export const loggedOut: INavData[] = [
  {
    name: 'Connexion',
    url: `/${appRoutes.login}`,
    icon: 'cil-arrow-thick-to-right'
  },
  {
    name: 'Créer un compte',
    url: `/${appRoutes.subscribe}`,
    icon: 'cil-color-border'
  },
  // {
  //   name: 'Support',
  //   url: `/${appRoutes.support}`,
  //   icon: 'cil-contact'
  // },
  {
    name: 'CGU',
    url: 'https://www.roadmate.io/cgu',
    icon: 'icon-layers',
    class: 'mt-auto',
    variant: 'primary',
    attributes: { target: '_blank', rel: 'noopener' }
  }
];
