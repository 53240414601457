import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { EmployeeInvoice, error_messages, fileStoringWorkflows, GroupedObject, IKV, Merchant, Mid, Place, PowerSource, RefundDestination, RefundRequestType, RoadMateFile, RoadMateRefundRequest, RoadMateTimeZone, RoadMateTrip, SimpleTrip, Treezor } from '@roadmate/roadmate-common';
import { FileUploadProgress, FireFunctionsService, FireStoreService, RMToasterService, UploadFileService } from '../../../services';
import { AbstractEntryComponent } from '../abstract.entry-component';
import * as moment from 'moment';
import { Observable } from 'rxjs';

@Component({
  selector: 'rm-transaction-details',
  templateUrl: './transaction-details.component.html',
  styleUrls: ['./transaction-details.component.scss']
})
export class TransactionDetailsComponent extends AbstractEntryComponent<any> implements OnInit {
  @Input()
  public transaction: GroupedObject<Treezor.Card.CardTransaction>;
  public title: string;
  public loading = false;
  public set data(value) {
    if (!value?.transaction) {
      return;
    }
    this.transaction = value.transaction;
  }
  public isIphoneX = false;
  public currentMid: Mid;
  public currentMerchant: Merchant;
  public logo = '/assets/svg/euro.svg';
  public paymentType: 'payment' | 'refund' | 'decline' | 'processing_payment' | 'refund_request' = 'payment';
  public paymentDate: string;
  public productName: string;
  public isSportingGoods = false;
  public amount: number;
  public merchantName: string;
  public universalLink: string;
  public deepLink: string;
  public storeLink: string;
  public webSite: string;
  public progress: Observable<FileUploadProgress[]>;
  public invoices: EmployeeInvoice;
  public resizingInProgress = false;
  public removingFileUrl = '';
  public openingFile = false;
  public roadmateTrip: Partial<RoadMateTrip>;
  public paymentId: string;
  private firstTransaction: Treezor.Card.CardTransaction;
  public supportingFileLink: string;
  private subs = [];
  public viewingMerchant = false;
  public setTripDetails = false;
  public refundRequest: IKV;
  public fallbackurl = '/assets/img/placeholder.png';
  public request: RoadMateRefundRequest;
  public translations: {[key: string]: string} = {};
  @ViewChild('fileInput') fileInput: ElementRef;
  constructor(
    private fs: FireStoreService,
    private ff: FireFunctionsService,
    private toast: RMToasterService,
    private uploadService: UploadFileService
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.transaction?.list?.length) {
      this.getMidByRef();
      this.getEmployeeInvoice();
    }
    this.firstTransaction = this.transaction.list[0];
    if (this.firstTransaction?.supportingFileLink) {
      this.supportingFileLink = this.firstTransaction.supportingFileLink;
    }

    if (this.transaction.list.every(tr => !tr.type || tr.type === RefundRequestType.PAYMENT_BY_CARD)) {
      if (this.transaction.list.some(tr => tr.paymentStatus === Treezor.Card.PaymentStatus.Declined)) {
        this.paymentType = 'decline';
        this.isSportingGoods = ['5940', '5941', '5932'].indexOf(this.firstTransaction.mccCode) > -1 ;
      } else if (this.transaction.list.some(tr => tr.paymentStatus === Treezor.Card.PaymentStatus.Refund)) {
        this.paymentType = 'refund';
      } else if (this.transaction.list?.length === 1 && this.transaction.list[0].paymentStatus === Treezor.Card.PaymentStatus.Accepted) {
        this.paymentType = 'processing_payment';
      }
      this.paymentDate = this.firstTransaction.authorizationIssuerTime;
      this.merchantName = this.firstTransaction.merchantName;
    } else {
      this.paymentType = 'refund_request';
    }
    this.productName = this.firstTransaction.productName;
    this.amount = parseFloat(this.firstTransaction.paymentAmount);
    this.paymentId = this.firstTransaction.paymentId;
    if (this.paymentType === 'refund_request') {
      this.getRefundRequest()
    }
  }

  public openAttestation(url: string) {
    if (url) {
      // this.opener.openExternalUrl(url);
      window.open(url, '_blanc', null);
    }
  }

  private async getRefundRequest() {
    const [tr] = this.transaction.list;
    if (!tr.refundRequestRef) {
      return;
    }
    if (tr.type === RefundRequestType.REFUND_REQUEST_IKV) {
      this.refundRequest = await this.fs.getikvRequest(tr.refundRequestRef);
    } else {
      this.refundRequest = await this.fs.getRefundRequest(tr.refundRequestRef);
    }
  }

  private async getTrip() {
    this.roadmateTrip = await this.fs.getTripByPaymentId(this.paymentId);
    if (!this.roadmateTrip) {
      this.roadmateTrip = new SimpleTrip();
      this.roadmateTrip.paymentId = this.paymentId;
      this.roadmateTrip.date = this.firstTransaction.authorizationIssuerTime;
      if (this.currentMerchant) {
        this.roadmateTrip.powerSource = this.currentMerchant.powerSource ?? PowerSource.UNKNOWN;
        this.roadmateTrip.transportType = this.currentMerchant.transportType;
        this.roadmateTrip.vehiculeType = this.currentMerchant.vehiculeType;
      }
      // this.roadmateTrip.ref = await this.fs.createRoadMateTrip(this.roadmateTrip);
    } else {
      if (!this.roadmateTrip.departure) {
        this.roadmateTrip.departure = new Place();
      }
      if (!this.roadmateTrip.arrival) {
        this.roadmateTrip.arrival = new Place();
      }
      // this.watchTrip();
    }
  }

  public saveTrip(trip: RoadMateTrip) {
    if (trip) {
      this.roadmateTrip = trip;
    }
    this.setTripDetails = false;
  }

  private async getMidByRef() {
    const mid = this.transaction.list[0].merchantId;
    this.currentMid = await this.fs.getMidByRef(mid);
    if (this.currentMid?.merchantRef) {
      await this.getMerchant(this.currentMid?.merchantRef);
    }
    await this.getTrip();
  }

  private async getEmployeeInvoice() {
    const paymentId = this.transaction.list[0].paymentId;
    const invoice = await this.fs.getInvoiceByPaymentId(paymentId)
    .catch(e => {
      console.log('No invoice available');
    });
    if (invoice) {
      this.invoices = invoice;
    }
  }

  private async getMerchant(merchantRef: string) {
    this.currentMerchant = await this.fs.getMerchantByRef(merchantRef);
    if (this.currentMerchant?.logo?.[0].url) {
      this.logo = this.currentMerchant.logo[0].url;
      this.universalLink = this.currentMerchant.applink?.universalLink;
      // if (this.plt.is('iphone')) {
      //   this.deepLink = this.currentMerchant?.applink?.iosDeeplink;
      //   this.storeLink = this.currentMerchant?.applink?.iosStore;
      // } else if (this.plt.is('android')) {
      //   this.deepLink = this.currentMerchant?.applink?.androidDeeplink;
      //   this.storeLink = this.currentMerchant?.applink?.androidStore;
      // }
      this.webSite = this.currentMerchant?.applink?.web;
    }
  }

  public openApp(url: string) {
    // this.opener.openExternalUrl(url);
    window.open(url, '_blank', null);
  }

  public async openFile(file: RoadMateFile) {
    if (!file?.url) {
      return;
    }
    window.open(file.url, '_blank', null);
  }

  public async removeFile(file: RoadMateFile) {
    this.removingFileUrl = file.url;
    try {
      await this.fs.removeFileFromInvoice(this.invoices, file);
      this.invoices.files = this.invoices.files.filter(f => f.url !== file.url);
      this.toast.showSuccessToast('INVOICE_WAS_DELETED');
    } catch (e) {
      this.toast.showGenericError('INVOICE_COULD_NOT_BE_DELETED');
      console.error('', e);
    } finally {
      this.removingFileUrl = '';
    }
  }

  public displayMerchant() {
    if (this.currentMerchant) {
      this.viewingMerchant = true;
    }
  }

  public close() {
    if (this.viewingMerchant) {
      this.viewingMerchant = false;
      return;
    }
    if (this.setTripDetails) {
      this.setTripDetails = false;
      return;
    }
    // this.viewCtrl.dismiss();
  }

  public async submitRefundRequest() {
    const [tr] = this.transaction.list;
    if (!tr) {
      return;
    }
    const {expenseRef, merchantName, paymentAmount, paymentId} = tr;
    const {firstname, lastname, email} = this.fs.currentAppUser;
    this.refundRequest = {
      distance: 0,
      from: '',
      to: '',
      createdAt: moment().format(RoadMateTimeZone.dateTimeFormat),
      expenseLine: expenseRef,
      invoices: this.invoices,
      companyRef: this.fs.currentAppUser.companyRef,
      uid: this.fs.currentAppUser.uid,
      email,
      firstname,
      lastname,
      merchantName,
      refundDestination: RefundDestination.REFUND_BY_PAYOUT,
      updatedAt: moment().format(RoadMateTimeZone.dateTimeFormat),
      amount: parseFloat(paymentAmount),
      paymentId,
      status: 'PENDING',
      description: '',
      product: tr.productName,
      attestation: null,
      type: RefundRequestType.REFUND_REQUEST_PURCHASE,
      agentRef: this.fs.currentAppUser.agentRef,
      year: (new Date()).getFullYear(),
      month: (new Date()).getMonth(),
      assignedTo: ''
    };
    await this.fs.createRefundRequest(this.refundRequest);
  }

  public async selectFile(files: FileList) {
    const selectedFile = files.item(0);
    const file = selectedFile;
    const {uid} = this.fs.currentAppUser;
    const timestamp = (new Date()).getTime();
    const fileName = `${uid}-${timestamp}-${file.name}`;
    const doc: RoadMateFile = {
      addedAt: moment().local().format(RoadMateTimeZone.dateTimeFormat),
      name: fileName,
      size: file.size,
      type: file.type,
      uploadedBy: this.fs.currentAppUser.email,
      url: ''
    };
    this.loading = true;
    try {
      const [transaction] = this.transaction.list;
      const paymentID = transaction.paymentId;
      const date = transaction.authorizationIssuerTime.substring(0, 7);
      doc.subFolder = date;
      
      doc.url = await this.uploadService.startUploadFromWeb(file, fileName, fileStoringWorkflows.invoice);
      if (!doc.url) {
        this.toast.showGenericError('Cette facture n\'a pas pu être associée à cette transaction');
        return;
      }
      this.loading = false;
      this.resizingInProgress = true;
      const res = await this.ff.saveInvoiceRefrence(doc, paymentID);
      if (res && !res.error && res.message === error_messages.OK) {
        this.toast.showSuccessToast('La facture a bien été enregistrée');
        // const [firstTransaction] = this.transaction.list;
        await this.getEmployeeInvoice();
        // if (!firstTransaction.hasInvoices) {
        //   firstTransaction.hasInvoices = true;
        //   if (this.invoices?.files?.length) {
        //     this.invoices.files = this.invoices.files.concat([doc]);
        //   } else {
            
        //   }
        // }
        return;
      }
      this.toast.showGenericError('Cette facture n\'a pas pu être associée à cette transaction');
    } catch (error) {
        console.error(error);
    } finally {
      this.loading = false;
      this.resizingInProgress = false;
    }
  }

  public addFile() {
    const evt = document.createEvent('MouseEvents');
    evt.initEvent('click', true, false);
    this.fileInput.nativeElement.dispatchEvent(evt);
    return;
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }

  public done () {
    this.close();
  }
}
