<ul class="nav nav-tabs" role="tablist">
  <li class="nav-item" (click)="setTab('messages')">
    <a class="nav-link" data-toggle="tab" [ngClass]="{'active': currentTab === 'messages'}" role="tab"
      aria-controls="home">
      {{'messages' | translate}}
    </a>
  </li>
  <li class="nav-item" (click)="setTab('situation')">
    <a class="nav-link" data-toggle="tab" [ngClass]="{'active': currentTab === 'situation'}" role="tab"
      aria-controls="home">
      {{'user_situation' | translate}}
    </a>
  </li>
  <li class="nav-item" (click)="setTab('viewCardTransactions')">
    <a class="nav-link" data-toggle="tab" [ngClass]="{'active': currentTab === 'viewCardTransactions'}" role="tab"
      aria-controls="home">
      {{'viewCardTransactions' | translate}}
    </a>
  </li>
</ul>
<div class="tab-content">
  <div class="tab-pane" role="tabpanel" [ngClass]="{'active': currentTab === 'messages'}">
    <div class="convo">
      <div class="row">
        <div class="col-12">
          <h3>
            Catégorie: {{conversation.category | translate}}
          </h3>
          <!-- <p>
            <strong>{{'SUBJECT'|translate}}</strong> : {{conversation.subject}}
          </p> -->
          <p *ngIf="conversation.role">
            <span class="badge text-bg-primary">
              {{conversation.role | translate}}
            </span>
          </p>
          <p>
            <span class="badge text-bg-primary" *ngIf="conversation.createdByDevice">
              {{conversation.createdByDevice | translate}}
            </span>
            <span class="badge text-bg-primary" *ngIf="conversation.createdByVersion">
              {{conversation.createdByVersion | translate}}
            </span>
            <span class="badge text-bg-primary" *ngIf="conversation.version && conversation.version !== conversation.createdByVersion">
              {{conversation.version | translate}}
            </span>
            <span class="badge text-bg-primary" *ngIf="conversation.deviceType && conversation.deviceType !== conversation.createdByDevice">
              {{conversation.deviceType | translate}}
            </span>
          </p>
        </div>
      </div>
      <div class="row right">
        <div class="bubble">
          <div class="bubble-text" [attr.data-time]="conversation.createdAt | rm_iso_to_french_datetime">
            {{conversation.subject}}
          </div>
        </div>
      </div>
      <div class="row left">
        <div class="bubble read">
          <div class="bubble-text" [innerHTML]="'HI_HOW_CAN_WE_HELP_YOU_TODAY' | translate"></div>
        </div>
      </div>
      <ng-container *ngIf="messages | async as convoMessages">
        <ng-container *ngIf="convoMessages.length">
          <div class="row" *ngFor="let item of convoMessages | rm_array_sort:'createdAt':'asc'" [ngClass]="{
            'right': item.sender === 'user',
            'left': item.sender === 'agent'
          }">
            <div 
              class="bubble rm-clickable" 
              [ngClass]="{
                'read': item.isRead,
                'reaction-nok': item.reaction === 'support_nok_reaction',
                'reaction-ok': item.reaction === 'support_ok_reaction',
                'reaction-thinking': item.reaction === 'support_explain_reaction'
              }"
              (click)="selectMessage(item)"
            >
              <div *ngIf="item.message" class="bubble-text" [attr.data-time]="item.createdAt | rm_iso_to_french_datetime">
                <strong *ngIf="item.sender === 'agent' && item.email">
                  {{item.email | rm_email_to_name}} :
                </strong>
                <div [innerHTML]="item.message"></div>
              </div>
              <span style="text-decoration: underline;" *ngIf="!item.message && item.file?.url">
                {{'ATTECHED_FILE' | translate}}
              </span>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
    <div class="row" *ngIf="conversation && conversation.status !== 'resolved'">
      <div class="col-12">
        <div class="input-group">
          <input 
            type="text" 
            class="form-control" 
            [(ngModel)]="messageText" 
            (keyup.enter)="sendMessage()" 
            placeholder="{{'TYPE_MESSAGE' | translate}}"
          >
          <div class="input-group-append">
            <button class="btn btn-outline-success" type="button" (click)="closeConversation()">
              {{'close_ticket'|translate}}
            </button>
            <button class="btn btn-outline-primary" type="button" (click)="sendMessage()">
              {{'send' | translate}}
            </button>
          </div>
        </div>
      </div>
      <div class="col-12">
        <rm-upload-input
          *ngIf="agentRef && companyRef"
          (urlReadyEmitter)="createMessageFromFile($event)"
          [workflow]="fileType"
          [companyRef]="companyRef"
          [agentRef]="agentRef"
        ></rm-upload-input>
      </div>
    </div>
  </div>
  <div class="tab-pane" role="tabpanel" [ngClass]="{'active': currentTab === 'situation'}">
    <mat-progress-bar [mode]="'indeterminate'" *ngIf="loading"></mat-progress-bar>
    <rm-user-situation
      *ngIf="userSituation"
      [situation]="userSituation"
      [email]="conversation.email"
      [conversationInHand]="conversation"
      (closeEmitter)="close($event)"
    >
    </rm-user-situation>
  </div>
  <div class="tab-pane" role="tabpanel" [ngClass]="{'active': currentTab === 'viewCardTransactions'}">
    <rm-transactions-viewer
      *ngIf="conversation"
      [agentRef]="conversation.agentRef"
      [companyRef]="conversation.companyRef"
      [email]="conversation.email"
    >
    </rm-transactions-viewer>
  </div>
</div>
