<div class="rm-column">
  <div class="rm-row" *ngIf="items && items.length">
    <button
      class="btn btn-sm btn-ghost-primary"
      type="button"
      (click)="selectAll()"
    >
      {{'select_all' | translate}} ({{items.length}})
    </button>
    <button
      class="btn btn-sm btn-ghost-secondary"
      type="button"
      (click)="unSelectAll()"
    >
      {{'deselect_all' | translate}}
    </button>
  </div>
  <p *ngIf="!items || !items.length">
    {{'no_users_here'|translate}}
  </p>
  <select class="c-multi-select" tabindex="-1" style="display: none;">
    <option [value]="item.value" *ngFor="let item of items">{{item.label}}</option>
  </select>
  <div class="form-group" *ngIf="items?.length">
    <!-- <label>{{'transfer_amount' | translate}}</label> -->
    <div class="input-group">
      <div class="input-group-prepend">
        <span class="input-group-text"><i class="cil-search"></i></span>
      </div>
      <input
        class="form-control no-verify"
        type="text"
        [(ngModel)]="searcheText"
        placeholder="{{'search_user' | translate}}"
      >
      <div class="input-group-append rm-clickable" (click)="searcheText=''">
        <span class="input-group-text">
          <i class="cil-x-circle"></i>
        </span>
      </div>
    </div>
  </div>
  <div class="c-multi-select c-multi-select-multiple c-multi-select-inline c-show rm-height-limit">
    <div class="c-multi-select-options">
      <div
        *ngFor="let item of items | rm_search_pipe:searcheText; let i = index"
        class="c-multi-select-option"
        [ngClass]="{'c-multi-selected' : (item | rm_is_item_selected:selectedItems)}"
        (click)="itemSelected(item)"
      >
        {{item.label}}
      </div>
    </div>
  </div>
  <div class="form-group" *ngIf="items?.length">
    <label>
      {{'nb_selected_users'|translate}}  {{selectedItems.length}}
    </label>
  </div>
</div>
