<ul class="nav nav-tabs" role="tablist">
  <li class="nav-item" (click)="setTab(tabs.recap)">
    <a class="nav-link" data-toggle="tab" [ngClass]="{'active': currentTab === tabs.recap}" role="tab"
      aria-controls="home">
      {{tabs.recap | translate}}
    </a>
  </li>
  <li class="nav-item" (click)="setTab(tabs.viewrequest)">
    <a class="nav-link" data-toggle="tab" [ngClass]="{'active': currentTab === tabs.viewrequest}" role="tab"
      aria-controls="home">
      {{tabs.viewrequest | translate}}
    </a>
  </li>
  <li class="nav-item" (click)="setTab(tabs.viewsituation)">
    <a class="nav-link" data-toggle="tab" [ngClass]="{'active': currentTab === tabs.viewsituation}" role="tab"
      aria-controls="home">
      {{tabs.viewsituation | translate}}
    </a>
  </li>
  <li class="nav-item" (click)="setTab(tabs.whitelists)"  *ngIf="whiteLists?.length">
    <a class="nav-link" data-toggle="tab" [ngClass]="{'active': currentTab === tabs.whitelists}" role="tab"
      aria-controls="home">
      {{tabs.whitelists | translate}}
    </a>
  </li>
  <li class="nav-item" (click)="setTab(tabs.viewCardTransactions)"  *ngIf="userSituation">
    <a class="nav-link" data-toggle="tab" [ngClass]="{'active': currentTab === tabs.viewCardTransactions}" role="tab"
      aria-controls="home">
      {{tabs.viewCardTransactions | translate}}
    </a>
  </li>
</ul>
<div class="tab-content">
  <div class="tab-pane" role="tabpanel" [ngClass]="{'active': currentTab === tabs.recap}">
    <rm-refund-recap
    *ngIf="!loading"
    [request]="targetObject"
    (requestEmitter)="updateRequest($event)"
    (closeEmitter)="close($event)"
    ></rm-refund-recap>
  </div>
  <div class="tab-pane" role="tabpanel" [ngClass]="{'active': currentTab === tabs.viewrequest}">
    <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
    <strong *ngIf="targetObject.requestAutomation">
      Ceci est une demande de remboursement automatique à valider
    </strong>
    <strong *ngIf="targetObject.requestInstallment" class="orange">
      Ceci est une demande de remboursement fractionnée à valider
    </strong>
    <rm-reactive-forms
      *ngIf="objectDef && targetObject && !loading"
      [objectDefinition]="objectDef"
      [objectToUpdate]="targetObject"
      (saveEmitter)="updateRequest($event)"
      [showSaveTop]="true"
    >
    </rm-reactive-forms>
  </div>
  <div class="tab-pane" role="tabpanel" [ngClass]="{'active': currentTab === tabs.viewsituation}">
    <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
    <rm-user-situation
      *ngIf="userSituation"
      [situation]="userSituation"
      [requestInHand]="targetObject"
      [email]="targetObject.email"
      (closeEmitter)="close($event)"
      (updatedRequestRefundEmitter)="targetObject = $event"
    >
    </rm-user-situation>
  </div>
  <div class="tab-pane" role="tabpanel" [ngClass]="{'active': currentTab === tabs.whitelists}" *ngIf="whiteLists?.length">
    <div class="row">
      <div class="col-12">
        <h2>
          {{'whitelists' | translate}}
        </h2>
      </div>
      <div class="col-12" *ngFor="let item of whiteLists">
        <h5>{{item.name}}</h5>
        <p [innerHTML]="item.customRules"></p>
      </div>
    </div>
  </div>
  <div class="tab-pane" role="tabpanel" [ngClass]="{'active': currentTab === tabs.viewCardTransactions}" *ngIf="userSituation">
    <rm-transactions-viewer
      *ngIf="targetObject"
      [agentRef]="targetObject.agentRef"
      [companyRef]="targetObject.companyRef"
      [email]="targetObject.email"
    >
    </rm-transactions-viewer>
  </div>
</div>
