import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { RoadMateFile, fileStoringWorkflows } from '@roadmate/roadmate-common';
import { FireAuthService } from '../../services/fire.auth';
import * as moment from 'moment';
@Component({
    selector: 'rm-upload-input',
    templateUrl: './upload-input.component.html',
    styleUrls: ['./upload-input.component.scss']
})
export class UploadInputComponent implements OnInit {
  public loading = false;
  public file: File;
  public fileToSave: RoadMateFile;
  @Input() workflow: fileStoringWorkflows;
  @Input() companyRef: string;
  @Input() agentRef: string;
  @Input() fieldName: string;
  @Output() urlReadyEmitter = new EventEmitter<{
    doc: RoadMateFile,
    fieldName: string,
  }>();
  public fileInputName = 'file-' + (new Date()).getTime();
  constructor(private auth: FireAuthService) { }

  ngOnInit(): void { }

  public async pictureReady(ev: {
    downloadURL: string;
    fileName: string;
  }) {
    if (!ev) {
      return;
    }
    this.fileToSave.url = ev.downloadURL;
    this.fileToSave.name = ev.fileName;
    this.urlReadyEmitter.emit({
      doc: this.fileToSave,
      fieldName: this.fieldName,
    });
    this.loading = false;
  }

  public uploadHandler(file: FileList) {
      const fifi = file.item(0);
      this.fileToSave = {
        name: fifi.name,
        size: fifi.size,
        type: fifi.type,
        uploadedBy: this.auth.user.uid,
        url: '',
        addedAt: moment().format('YYYY-MM-DD HH:mm')
      };
      this.file = fifi;
      this.loading = true;
  }

  public addPhoto() {}
}
