import { Component, Input, OnInit } from '@angular/core';
import { FireStoreService } from '../../../services/fire.store';
import { Attestation } from '@roadmate/roadmate-common';

@Component({
  selector: 'rm-employee-attestation-render',
  template: `
  <ng-container *ngIf="value?.length; else noData">
    <a 
      *ngFor="let item of value; let i = index"
      [href]="item.url" target="_blank"
    >
      Document {{i + 1}}
    </a>
  </ng-container>
  <ng-template #noData>
    Aucun document
  </ng-template>
  `,
})
export class EmployeeAttestationRenderComponent implements OnInit {
  @Input() value: Attestation[];
  constructor() {}
  ngOnInit() {
    
  }

}
