import { Injectable } from '@angular/core';
import { auth } from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable } from 'rxjs';
import { rm_storageKeys, AppRoles, Treezor, actions } from '@roadmate/roadmate-common';
import { appRoutes } from '../static';
import { environment } from '../../environments/environment';
import { MessengerService } from './messenger.service';


@Injectable({
  providedIn: 'root'
})
export class FireAuthService {
  user$: Observable<any>;
  public user: firebase.User;
  public isAdmin = false;
  public idToken: string;
  public appUser: Treezor.AppUser;
  public userCompany: Treezor.User.Definition;
  private _userRoles: AppRoles = {
    admin: false,
    superadmin: false,
    employee: false,
    agent: false
  };
  public get isLoggedIn() {
    return !!(this.user && this.user.email);
  }
  public get isEmailVerified() {
    return !!(this.user && this.user.emailVerified);
  }
  public get userRoles(): AppRoles {
    return this._userRoles;
  }
  constructor(private afa: AngularFireAuth, private messenger: MessengerService) {
    const href = document.location.href;
    Object.values(appRoutes).some(
      fragment => {
        if (
          fragment === appRoutes.login
          || fragment === appRoutes.activateAccount
          || fragment === appRoutes.updatePassword
          || fragment === appRoutes.subscribe
        ) {
          return;
        }

        if (href.indexOf(`${fragment}`) > -1) {
          const root = href.indexOf('localhost') > -1 ? environment.localrootUrl : this.getRootUrl();
          localStorage.setItem(rm_storageKeys.redirectTo, href.split(root)[1]);
          this.reloadApp();
          return true;
        }
        return false;
      }
    );
    this.afa.authState.subscribe(
      (user: firebase.User) => {
        this.user = user;
        // Logged in
        if (user && user.email && user.emailVerified) {
          this.messenger.parcel.next({
            action: actions.login,
            data: null
          });
          this.getIdToken();
        }
      });
  }

  public async activateUser(code: string) {
    try {
      await this.afa.applyActionCode(code);
    } catch (e) {
      throw e;
    }
  }

  private getRootUrl () {
    return `${document.location.href.substring(0, document.location.href.indexOf('.io') + 3)}`;
  }

  public async setNewPassword(resetToken: string, newPassword: string) {
    await this.afa.confirmPasswordReset(resetToken, newPassword);
  }

  public async getIdToken() {
    this.idToken = await (await this.afa.currentUser).getIdToken();
    this.messenger.parcel.next({
      action: actions.idToken,
      data: 'idToken'
    });
    const rr = await this.user.getIdTokenResult(true);
    this._userRoles = {
      admin: !!rr.claims.admin,
      employee: !!rr.claims.employee,
      superadmin: !!rr.claims.superadmin,
      agent: !!rr.claims.agent,
      accountmanager: !!rr.claims.accountmanager
    };
    this.messenger.parcel.next({
      action: actions.customClaims,
      data: {
        agentRef: rr.claims.agentRef,
        companyRef: rr.claims.companyRef,
        employeeRef: rr.claims.employeeRef,
      }
    });
    this.messenger.parcel.next({
      action: actions.roles,
      data: this.userRoles
    });
  }

  public async loginUser(email: string, password: string, remeberMe = false) {
    if (remeberMe) {
      await this.afa.setPersistence(auth.Auth.Persistence.LOCAL);
    }
    try {
      return this.afa.signInWithEmailAndPassword(email, password);
    } catch (e) {
      console.error('', e);
      return null;
    }
  }

  public async doRegister(credentials) {
    try {
      return this.afa.createUserWithEmailAndPassword(credentials.email, credentials.password);
    } catch (e) {
      console.error('doRegister crashed', e);
      throw e;
    }
  }

  public async logout() {
    localStorage.setItem('alltrips', '');
    await this.afa.signOut();
    this.messenger.parcel.next({
      action: actions.logout,
      data: null
    });
    setTimeout(() => {
      this.reloadApp();
    }, 1000);
  }

  public lostPassword(email: string) {
    return this.afa.sendPasswordResetEmail(email);
  }

  public confirmRestPassword(code: string, newpassword: string) {
    return this.afa.confirmPasswordReset(code, newpassword);
  }

  public reloadApp() {
    const timespan = (new Date()).getTime();
    let url = '';
    if (document.location.href.indexOf('http://localhost') > -1) {
      url = `${environment.localrootUrl}`;
    } else {
      url = this.getRootUrl();
    }
    document.location.href = `${url}/?timestamp=${timespan}`;
  }
}
