<svg width="612" height="519" viewBox="0 0 612 519" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="undraw_message_sent_1030 1">
    <path id="Vector" d="M433.371 516.992H48.5373C35.9175 516.992 23.8145 511.978 14.8909 503.055C5.96738 494.131 0.954178 482.028 0.954178 469.408V293.097C0.954179 290.536 1.47577 288.003 2.48711 285.651C3.49844 283.298 4.97832 281.177 6.8365 279.416L210.76 86.1128C218.914 78.3842 229.72 74.0764 240.954 74.0764C252.188 74.0764 262.995 78.3842 271.148 86.1128L465.579 270.417C470.436 275.021 474.304 280.566 476.947 286.714C479.591 292.863 480.954 299.485 480.954 306.177V469.408C480.954 475.657 479.723 481.845 477.332 487.618C474.941 493.391 471.436 498.636 467.017 503.055C462.599 507.473 457.353 510.978 451.58 513.37C445.807 515.761 439.62 516.992 433.371 516.992V516.992Z" fill="#3F3D56"/>
    <path id="Vector_2" opacity="0.1" d="M0.954193 295.992H476.954V488.259C476.954 495.88 473.927 503.188 468.539 508.576C463.15 513.964 455.842 516.992 448.222 516.992H29.6865C22.0662 516.992 14.758 513.964 9.36967 508.576C3.98133 503.188 0.954193 495.88 0.954193 488.259V295.992V295.992Z" fill="black"/>
    <path id="Vector_3" d="M391.273 144.492H89.6353C75.1759 144.492 63.4542 156.213 63.4542 170.673V472.31C63.4542 486.77 75.1759 498.492 89.6353 498.492H391.273C405.732 498.492 417.454 486.77 417.454 472.31V170.673C417.454 156.213 405.732 144.492 391.273 144.492Z" fill="#3959F9"/>
    <path id="Vector_4" opacity="0.1" d="M417.454 303.942V498.492H63.454V303.942L240.454 389.492L417.454 303.942Z" fill="black"/>
    <path id="Vector_5" d="M240.454 400.492L13.6604 290.875C12.2576 290.197 10.7056 289.886 9.14992 289.972C7.59422 290.057 6.08575 290.537 4.76591 291.365C3.44608 292.193 2.35811 293.342 1.60396 294.705C0.84981 296.069 0.454179 297.601 0.454178 299.159V487.759C0.454177 491.532 1.19735 495.269 2.64128 498.755C4.08521 502.241 6.20164 505.408 8.86967 508.076C11.5377 510.744 14.7051 512.861 18.1911 514.304C21.677 515.748 25.4133 516.492 29.1865 516.492H451.722C455.495 516.492 459.231 515.748 462.717 514.304C466.203 512.861 469.371 510.744 472.039 508.076C474.707 505.408 476.823 502.241 478.267 498.755C479.711 495.269 480.454 491.532 480.454 487.759V302.39C480.454 300.489 479.971 298.619 479.051 296.955C478.131 295.292 476.803 293.889 475.193 292.878C473.582 291.868 471.741 291.283 469.843 291.179C467.945 291.074 466.051 291.453 464.339 292.281L240.454 400.492Z" fill="#3F3D56"/>
    <path id="Vector_6" d="M192.954 176.992H93.9542V190.992H192.954V176.992Z" fill="#F2F2F2"/>
    <path id="Vector_7" d="M379.954 227.992H85.9542V235.992H379.954V227.992Z" fill="#F2F2F2"/>
    <path id="Vector_8" d="M379.954 254.992H85.9542V262.992H379.954V254.992Z" fill="#F2F2F2"/>
    <path id="Vector_9" d="M379.954 281.992H85.9542V289.992H379.954V281.992Z" fill="#F2F2F2"/>
    <path id="Vector_10" d="M479.713 70.7706C479.112 76.1251 475.028 80.4384 473.515 85.6097C471.237 93.3932 475.175 101.746 480.563 107.807C484.91 112.647 490.174 116.576 496.05 119.366C501.927 122.156 508.299 123.752 514.797 124.062C516.705 124.23 518.629 124.02 520.457 123.445C522.089 122.788 523.558 121.784 524.763 120.502C528.82 116.428 530.998 110.784 531.866 105.1C532.734 99.4163 532.397 93.6258 532.057 87.8863C532.059 86.6998 531.843 85.5231 531.421 84.4143C530.868 83.358 530.108 82.4238 529.187 81.667C526.266 78.9874 523.048 76.6501 519.597 74.7006C518.037 74.0015 516.661 72.9488 515.578 71.6257C514.926 70.4923 514.504 69.2413 514.336 67.9444L513.277 62.5455C513.088 60.9897 512.556 59.495 511.721 58.169C509.849 55.6443 506.269 55.3007 503.129 55.1775L491.624 54.7262C488.045 54.5858 483.329 53.5596 479.836 54.2624C476.573 54.9187 477.657 57.925 478.25 60.6624C479.109 63.9675 479.6 67.3575 479.713 70.7706V70.7706Z" fill="#A0616A"/>
    <path id="Vector_11" opacity="0.1" d="M479.713 70.7706C479.112 76.1251 475.028 80.4384 473.515 85.6097C471.237 93.3932 475.175 101.746 480.563 107.807C484.91 112.647 490.174 116.576 496.05 119.366C501.927 122.156 508.299 123.752 514.797 124.062C516.705 124.23 518.629 124.02 520.457 123.445C522.089 122.788 523.558 121.784 524.763 120.502C528.82 116.428 530.998 110.784 531.866 105.1C532.734 99.4163 532.397 93.6258 532.057 87.8863C532.059 86.6998 531.843 85.5231 531.421 84.4143C530.868 83.358 530.108 82.4238 529.187 81.667C526.266 78.9874 523.048 76.6501 519.597 74.7006C518.037 74.0015 516.661 72.9488 515.578 71.6257C514.926 70.4923 514.504 69.2413 514.336 67.9444L513.277 62.5455C513.088 60.9897 512.556 59.495 511.721 58.169C509.849 55.6443 506.269 55.3007 503.129 55.1775L491.624 54.7262C488.045 54.5858 483.329 53.5596 479.836 54.2624C476.573 54.9187 477.657 57.925 478.25 60.6624C479.109 63.9675 479.6 67.3575 479.713 70.7706V70.7706Z" fill="black"/>
    <path id="Vector_12" d="M552.901 311.781C551.271 329.654 549.641 347.523 548.011 365.39C546.735 379.45 545.435 393.569 542.932 407.448C542.068 412.231 541.055 416.989 539.853 421.706C536.511 434.804 531.662 447.605 530.098 461.032C530.048 461.419 530.007 461.814 529.966 462.209C529.791 465.178 529.229 468.112 528.295 470.935C527.513 472.985 526.311 474.854 525.463 476.879C523.775 480.929 523.553 485.408 523.347 489.795C517.411 486.457 510.912 484.236 504.175 483.242C500.923 482.757 497.301 482.411 495.086 479.982C492.633 477.299 492.905 473.191 493.316 469.585C494.609 458.389 495.898 447.199 497.185 436.014C497.605 431.202 498.423 426.433 499.63 421.756C500.075 420.2 500.61 418.669 501.153 417.138C501.902 415.03 502.66 412.923 503.228 410.758C504.046 407.259 504.618 403.708 504.94 400.13C506.265 388.868 507.863 377.631 509.262 366.378L509.336 365.785C511.435 348.728 513.032 331.588 512.571 314.407C512.506 311.864 512.398 309.32 512.234 306.768C512.003 303.072 511.666 301.112 514.728 298.799C518.112 296.247 522.565 294.461 526.434 292.79C528.517 291.884 551.452 283.742 551.987 285.529C553.063 289.339 553.628 293.276 553.667 297.235C553.683 302.094 553.428 306.951 552.901 311.781Z" fill="#605D82"/>
    <path id="Vector_13" opacity="0.1" d="M542.932 407.448C542.068 412.231 541.055 416.989 539.853 421.706C536.511 434.804 531.662 447.605 530.098 461.032C530.048 461.419 530.007 461.814 529.966 462.209C519.742 447.605 511.131 431.898 501.153 417.138C501.902 415.03 502.66 412.923 503.228 410.758C504.046 407.259 504.619 403.708 504.94 400.13C506.265 388.868 507.863 377.631 509.262 366.378L509.336 365.785C509.922 368.174 510.758 370.495 511.831 372.708C518.532 386.324 534.453 393.206 542.24 406.238C542.479 406.641 542.71 407.045 542.932 407.448Z" fill="black"/>
    <path id="Vector_14" d="M459.967 287.719C460.305 298.64 462.053 309.462 463.797 320.248L467.397 342.501C469.367 354.681 471.418 367.109 477.182 378.017C481.493 386.178 487.705 393.141 493.325 400.462C507.14 418.461 517.519 438.849 530.619 457.375C534.427 462.76 539.869 468.583 546.368 467.459C550.633 466.721 553.731 463.17 557.083 460.432C561.036 457.204 565.799 454.842 568.909 450.796C572.019 446.75 572.628 440.025 568.415 437.146C566.891 436.302 565.272 435.639 563.593 435.173C557.061 432.689 552.834 426.311 550.144 419.861C547.454 413.412 545.824 406.477 542.24 400.479C534.453 387.447 518.529 380.565 511.827 366.944C508.073 359.315 507.715 350.522 507.428 342.024L506.696 320.362C506.616 317.994 506.554 315.53 507.517 313.364C510.081 307.598 517.822 307.061 524.09 306.33C531.204 305.496 538.095 303.322 544.4 299.922C548.01 297.972 551.537 295.487 553.468 291.867C555.231 288.564 555.474 284.691 555.68 280.953L557.851 241.695C558.037 238.318 558.208 234.826 557.017 231.659C554.028 223.709 543.989 221.111 535.503 221.49C519.713 222.196 505.137 228.988 490.249 233.575C486.561 234.739 482.817 235.715 479.031 236.501C475.69 237.172 471.646 236.893 468.459 237.968C462.792 239.878 462.936 252.272 462.132 257.413C460.473 267.426 459.748 277.572 459.967 287.719V287.719Z" fill="#605D82"/>
    <path id="Vector_15" d="M544.224 469.105C545.616 470.509 546.071 472.566 546.434 474.51C548.445 485.293 549.491 496.234 549.56 507.202C549.467 508.226 549.671 509.254 550.148 510.164C550.541 510.707 551.072 511.134 551.686 511.4C555.711 513.318 560.381 510.645 563.735 507.708C569.951 502.262 575.211 494.953 575.564 486.697C575.499 484.57 575.574 482.442 575.787 480.325C576.333 477.216 578.148 474.508 579.748 471.787C582.469 467.156 584.665 462.235 586.293 457.116C586.904 455.192 587.311 452.77 585.804 451.428C585.269 451.009 584.662 450.694 584.012 450.497L565.834 443.747C564.258 443.162 560.338 453.231 559.716 454.332C558.194 457.025 556.681 458.554 553.628 459.175C550.511 459.81 546.801 458.899 543.893 460.454C538.974 463.084 541.438 466.296 544.224 469.105Z" fill="#535070"/>
    <path id="Vector_16" d="M516.174 475.318C515.836 474.646 515.365 474.049 514.79 473.564C513.949 473.03 512.992 472.705 511.999 472.616C508.538 472.028 504.436 471.641 502.048 474.214C499.808 476.625 500.193 480.554 498.321 483.261C497.729 484.118 496.926 484.824 496.412 485.73C495.774 487.115 495.486 488.636 495.574 490.159C495.477 494.354 495.381 498.557 495.671 502.743C495.913 506.251 496.505 509.927 498.699 512.675C502.22 517.086 508.57 517.721 514.207 518.007L518.772 518.239C520.029 518.392 521.305 518.275 522.513 517.896C523.261 517.55 523.933 517.057 524.487 516.448C525.041 515.838 525.468 515.123 525.74 514.345C528.117 508.439 524.353 502.075 522.035 496.145C519.371 489.33 519.849 481.762 516.174 475.318Z" fill="#535070"/>
    <path id="Vector_17" d="M478.39 40.6C478.334 40.0012 478.422 39.3977 478.647 38.8399C479.316 37.6575 481.062 37.9793 482.379 38.3114C487.676 39.6468 493.257 38.438 498.71 38.75C504.164 39.0619 510.307 41.7883 511.403 47.1396C515.362 44.3333 518.774 40.8273 521.472 36.7942C522.986 34.5292 524.305 31.9349 524.099 29.2185C523.802 25.3005 520.514 22.3704 517.471 19.8856L508.397 12.4786C507.207 11.3842 505.831 10.5097 504.335 9.89578C502.24 9.17978 499.955 9.52092 497.741 9.52234C491.136 9.52657 484.094 6.52077 478.22 9.54027C475.766 10.8016 473.919 12.963 472.139 15.0706L464.041 24.6565C463.431 25.2674 462.986 26.0225 462.747 26.8516C462.516 28.0217 463.117 29.1764 463.327 30.3504C463.709 32.4919 462.787 34.7774 463.496 36.834C464.673 40.2481 468.819 40.7314 471.353 42.6887C472.297 43.4182 473.778 45.8904 474.903 45.8602C476.934 45.8057 478.338 42.1776 478.39 40.6Z" fill="#3F3D56"/>
    <path id="Vector_18" d="M492.131 68.8563C506.452 68.8563 518.062 57.2464 518.062 42.9248C518.062 28.6032 506.452 16.9932 492.131 16.9932C477.809 16.9932 466.199 28.6032 466.199 42.9248C466.199 57.2464 477.809 68.8563 492.131 68.8563Z" fill="#A0616A"/>
    <path id="Vector_19" d="M498.204 99.6764C497.325 97.1563 495.665 94.982 493.466 93.4687C491.267 91.9555 488.643 91.1821 485.975 91.2606C485.212 91.4027 484.423 91.303 483.719 90.9754C483.321 90.6695 483.003 90.2718 482.792 89.8163C481.215 86.9808 479.948 83.9841 479.011 80.8778C478.598 79.5052 478.032 77.8871 476.643 77.5302C476.069 77.455 475.484 77.5374 474.952 77.7687C474.421 78 473.962 78.3714 473.625 78.8432C472.966 79.793 472.245 80.6988 471.468 81.5547C468.206 84.4708 462.425 81.8129 458.963 84.4883C458.398 84.9253 457.931 85.4851 457.337 85.8826C456.743 86.2404 456.102 86.5151 455.433 86.699C448.749 88.8812 442.196 91.4478 435.807 94.3857C438.11 108.253 440.469 122.361 445.866 135.34C448.435 141.517 451.346 147.566 453.402 153.932C457.96 168.035 458.162 183.126 458.213 197.947C458.248 208.091 458.197 218.452 454.916 228.05C454.033 230.172 453.41 232.392 453.062 234.663C452.864 236.95 453.53 239.472 455.383 240.827C456.457 241.53 457.686 241.962 458.964 242.085C464.651 242.92 470.384 241.406 475.983 240.11C490.992 236.634 506.298 234.645 521.576 232.661L547.175 229.337C549.01 229.213 550.806 228.749 552.472 227.968C557.156 225.404 557.601 218.357 554.692 213.878C553.297 211.731 551.33 209.969 550.158 207.693C548.986 205.417 548.886 202.233 550.903 200.656C555.007 197.449 553.783 191.499 553.979 186.294C554.526 171.742 554.452 157.183 553.757 142.62C553.551 139.605 553.503 136.581 553.612 133.562C554.067 128.444 555.012 123.383 556.435 118.447L564.214 87.8187C562.182 84.6075 558.291 82.5702 554.605 81.6452C550.92 80.7203 547.081 80.448 543.451 79.3233C536.015 77.019 529.394 71.1713 521.628 71.7163C521.041 71.7268 520.463 71.8679 519.937 72.1293C518.185 73.1215 518.463 75.6456 518.351 77.6555C518.103 82.1024 514.832 85.819 511.22 88.4248C507.609 91.0305 503.483 92.9306 500.166 95.9023C499.578 96.3452 499.097 96.914 498.758 97.5671C498.418 98.2201 498.229 98.9409 498.204 99.6764V99.6764Z" fill="#3F3D56"/>
    <path id="Vector_20" d="M395.455 128.728C393.735 129.844 391.908 130.784 390 131.535C388.133 132.09 386.368 132.947 384.776 134.07C384.042 134.705 383.426 135.465 382.959 136.315C382.081 137.872 381.637 139.635 381.673 141.422C381.71 143.209 382.225 144.953 383.165 146.473C384.106 147.992 385.437 149.232 387.02 150.061C388.603 150.891 390.379 151.28 392.164 151.189C394.536 150.923 396.841 150.236 398.972 149.159L417.262 141.234C420.357 139.892 423.701 138.32 425.164 135.28C425.335 134.997 425.398 134.661 425.342 134.335C425.195 133.99 424.928 133.71 424.591 133.545C420.466 130.866 416.858 127.464 413.941 123.503C413.191 122.484 412.273 120.079 411.212 119.468C407.341 117.238 398.554 126.717 395.455 128.728Z" fill="#A0616A"/>
    <path id="Vector_21" d="M590.982 122.224L597.726 129.927C599.173 131.581 600.622 133.237 601.942 134.994C605.182 139.46 607.782 144.357 609.665 149.544C611.131 153.39 612.347 157.487 611.835 161.571C611.495 163.664 610.93 165.715 610.148 167.687C608.913 171.48 607.381 175.17 605.567 178.723C604.041 181.422 602.317 184.005 600.41 186.45C595.19 193.369 589.477 199.904 583.317 206.002C581.011 208.284 580.045 211.974 579.041 215.059C577.19 220.748 572.786 225.18 568.527 229.382C567.158 230.827 565.645 232.129 564.012 233.267C561.565 234.843 558.706 235.657 555.796 235.607C554.522 235.616 553.261 235.355 552.095 234.841C549.181 233.457 547.699 230.087 547.396 226.874C547.124 223.959 547.613 221.024 548.814 218.354C552.683 209.782 562.62 206.068 569.643 199.813C573.762 196.146 576.911 191.54 580.017 186.983C582.127 184.138 583.889 181.05 585.264 177.786C586.833 173.601 586.222 168.646 587.592 164.392C588.172 162.594 589.202 160.97 589.824 159.186C590.447 157.403 590.604 155.285 589.514 153.742C588.693 152.761 587.645 151.994 586.461 151.507C581.975 149.229 576.949 146.299 576.091 141.341C575.748 138.591 576.467 135.815 578.1 133.576C579.727 131.368 581.628 129.375 583.758 127.647C585.72 125.911 589.02 123.96 590.982 122.224Z" fill="#A0616A"/>
    <path id="Vector_22" d="M391.126 139.481C389.935 137.075 388.447 134.621 386.044 133.426C384.879 132.92 383.624 132.656 382.354 132.651C381.084 132.646 379.826 132.899 378.657 133.395C376.328 134.399 374.146 135.718 372.175 137.315L366.362 141.599C365.045 142.463 363.879 143.54 362.912 144.784C361.995 146.294 361.384 147.969 361.115 149.715C360.102 153.696 359.792 157.824 360.201 161.912C361.123 168.466 365.075 174.188 369.424 179.177C372.142 182.295 375.498 185.441 379.632 185.574C381.416 185.632 383.4 184.928 384.143 183.305C384.4 182.661 384.536 181.975 384.546 181.282C384.992 173.971 382.377 166.83 379.327 160.171C378.355 158.428 377.78 156.49 377.645 154.498C377.623 154.163 377.71 153.83 377.893 153.548C378.156 153.275 378.508 153.103 378.885 153.064C383.059 152.238 390.249 155.481 393.177 150.846C395.075 147.841 392.475 142.206 391.126 139.481Z" fill="#A0616A"/>
    <path id="Vector_23" d="M477.03 32.4403C476.975 31.8415 477.063 31.238 477.287 30.6802C477.956 29.4979 479.702 29.8196 481.019 30.1517C486.316 31.4871 491.897 30.2783 497.35 30.5903C502.804 30.9022 508.947 33.6286 510.043 38.9799C514.002 36.1736 517.414 32.6676 520.112 28.6345C521.626 26.3695 522.945 23.7751 522.739 21.0588C522.442 17.1408 519.154 14.2107 516.111 11.7259L507.037 4.3189C505.847 3.22447 504.471 2.34998 502.975 1.73609C500.88 1.02009 498.595 1.36121 496.381 1.36263C489.776 1.36687 482.734 -1.63893 476.86 1.38056C474.406 2.64187 472.559 4.80333 470.779 6.9109L462.681 16.4968C462.071 17.1077 461.626 17.8628 461.387 18.6919C461.156 19.862 461.757 21.0167 461.967 22.1907C462.349 24.3322 461.427 26.6177 462.136 28.6743C463.313 32.0884 467.459 32.5717 469.993 34.529C470.937 35.2585 472.418 37.7307 473.543 37.7005C475.574 37.6461 476.978 34.0178 477.03 32.4403Z" fill="#3F3D56"/>
    <path id="Vector_24" d="M421.954 103.505C420.907 104.18 419.988 105.036 419.24 106.032C418.289 107.469 418.096 109.284 417.279 110.801C415.531 114.049 411.444 115.204 408.894 117.869C408.489 118.235 408.207 118.718 408.088 119.251C408.076 119.974 408.318 120.678 408.773 121.241L421.07 140.572C425.164 140.933 429.092 138.368 433.188 138.708C434.717 138.835 436.282 139.363 437.749 138.912C438.799 138.446 439.783 137.844 440.675 137.122C443.119 135.576 446.374 135.396 448.424 133.358C450.391 131.402 450.642 128.341 450.619 125.566C450.652 123.157 450.406 120.751 449.888 118.398C448.077 110.978 442.402 105.284 438.845 98.7108C437.113 95.5108 436.17 93.6375 432.504 95.8347C428.819 98.0433 425.422 100.969 421.954 103.505Z" fill="#3F3D56"/>
    <path id="Vector_25" d="M571.295 93.7288C573.606 95.2294 575.69 97.0543 577.482 99.1474C579.355 101.563 580.416 104.519 582.215 106.99C583.96 109.385 586.334 111.24 588.312 113.445C591.313 116.79 593.345 120.871 595.341 124.896C590.466 131.254 585.151 137.261 579.435 142.873C578.798 143.623 577.96 144.174 577.019 144.461C576.38 144.529 575.733 144.438 575.137 144.195C574.541 143.952 574.015 143.565 573.605 143.069C572.719 142.14 572.092 140.995 571.245 140.03C569.247 137.968 566.584 136.681 563.727 136.394C560.896 136.12 558.047 136.081 555.209 136.277C554.586 136.346 553.956 136.24 553.39 135.971C552.969 135.68 552.628 135.289 552.398 134.832C549.068 129.167 550.139 122.054 551.314 115.589L554.009 100.754C554.4 97.9203 555.154 95.1491 556.252 92.5084C557.273 90.3267 559.556 86.627 562.363 87.2801C565.192 87.9385 568.915 92.0165 571.295 93.7288Z" fill="#3F3D56"/>
    <path id="Vector_26" d="M77.9542 191.141C100.598 191.141 118.954 172.751 118.954 150.066C118.954 127.381 100.598 108.992 77.9542 108.992C55.3105 108.992 36.9542 127.381 36.9542 150.066C36.9542 172.751 55.3105 191.141 77.9542 191.141Z" fill="white"/>
    <path id="Vector_27" d="M77.9542 101.992C68.2629 101.992 58.7893 104.865 50.7313 110.25C42.6733 115.634 36.3928 123.287 32.6841 132.24C28.9754 141.194 28.0051 151.046 29.8957 160.551C31.7864 170.056 36.4532 178.787 43.306 185.64C50.1588 192.493 58.8897 197.159 68.3948 199.05C77.8999 200.941 87.7521 199.97 96.7057 196.262C105.659 192.553 113.312 186.273 118.696 178.215C124.08 170.156 126.954 160.683 126.954 150.992C126.916 138.008 121.742 125.566 112.56 116.385C103.379 107.204 90.9381 102.03 77.9542 101.992V101.992ZM67.8951 177.117L42.7826 152.005L49.8263 144.961L67.9187 163.053L106.106 124.866L113.149 131.91L67.8951 177.117L67.8951 177.117Z" fill="#57B894"/>
    </g>
    </svg>
    