import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'rm-spinner',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderSpinnerComponent implements OnInit {
  constructor() { }

  ngOnInit(): void { }
}
