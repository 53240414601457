import { Component, Input, OnInit } from '@angular/core';
import { RoadMateRefundRequest, RoadMateTimeZone } from '@roadmate/roadmate-common';

@Component({
  selector: 'rm-render-month',
  template: `{{value}}`,
})
export class RefundRequestMonthRenderComponent implements OnInit {

  @Input() value: string;
  @Input() rowData: RoadMateRefundRequest;

  ngOnInit() {
    if (!Number.isNaN(parseInt(`${this.value}`))) {
      const monthIndex = parseInt(`${this.value}`) + 1;
      if (monthIndex === 0) {
        this.value = '';
        return;
      }
      const stringPad = `${monthIndex}`.padStart(2, '0');
      this.value = `${RoadMateTimeZone.months[stringPad]} ${this.rowData.year}`;
      return;
    }
  }

}
