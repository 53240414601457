import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'rm_plural' })
export class PluralPipe implements PipeTransform {
  transform(singular: string, count: number): any {
    if (singular && typeof singular === 'string' && singular.length) {
      if (count > 1) {
        return `${singular}s`
      }
    }
    return singular;
  }
}
