import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'rm_duration' })
export class DurationPipe implements PipeTransform {
  transform(value: any, showSeconds = false): any {
    value = parseInt(value, 10);
    if (!value) {
      return '--';
    }
    let j = 0;
    let h = Math.floor(parseInt(value, 10) / 3600);
    if (h > 24) {
      j = Math.floor(h / 24);
      h = h % 24;
    }
    const dayLabel = j > 1 ? 'jours' : 'jour';

    const rest = parseInt(value, 10) % 3600;
    const min = Math.floor(rest / 60);
    const sec = rest % 60;
    if (j > 30 && j < 365) {
      const months = Math.floor(j / 30);
      const rest = j % 30;
      return `${months} mois et ${rest} jours`
    }
    if (j >= 365) {
      const years = Math.floor(j / 365);
      const months = Math.floor((j%365) / 30);
      return `${years > 1 ? years + ' ans' : '1 année'} ${months > 0 ? 'et ' + months + ' mois' : j > 0 ? 'et '+ j + ' jours' : ''}`
    }
    if (j > 1) {
      return `${j} jours et ${h} heures`;
    }
    const res = `${j > 0 ? j + ' ' + dayLabel + ' ' : ''} ${h > 0 ? h + 'h et' : ' et'}${(value < 60) ? '' : ' ' + min + 'min'}${showSeconds ? ' ' + sec + 's' : ''}`;
    return res.trim();
  }
}
