import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'rm_uppercase' })
export class CapitalizePipe implements PipeTransform {
  transform(value: string): any {
    if (!value || typeof value !== 'string') {
      return value;
    }
    return value.charAt(0).toUpperCase() + value.substr(1);
  }
}

@Pipe({ name: 'rm_lowercase' })
export class LowerCasePipe implements PipeTransform {
  transform(value: string): any {
    if (!value) {
      return '';
    }
    return value.toLowerCase();
  }
}
