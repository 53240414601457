import { Component, Input, OnInit } from '@angular/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'mat-card',
  templateUrl: './mat-card.component.html',
  styleUrls: ['./mat-card.component.scss']
})
export class MatCardComponent implements OnInit {
  @Input()
  public isSmall;
  @Input()
  public fullFrame: boolean;
  @Input()
  hideHeader = true;
  @Input()
  bodyPadding = true;
  constructor() { }

  ngOnInit(): void { }

}
