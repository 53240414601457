import { Component, Input, OnInit } from '@angular/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'mat-progress-bar',
  templateUrl: './mat-progress-bar.component.html',
  styleUrls: ['./mat-progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit {
  @Input()
  public mode: 'indeterminate' | 'dynamique';
  @Input()
  public set progress(value: number) {
    this._progress = value;
  }
  public _progress = 0;


  constructor() { }

  ngOnInit(): void { }
}
