import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';
import { PipeModule } from '../pipes/pipe.module';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { TransactionsViewerComponent } from './transactions-viewer.component';


@NgModule({
  declarations: [
    TransactionsViewerComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    PipeModule,
    Ng2SmartTableModule
  ],
  exports: [
    TransactionsViewerComponent
  ],
  providers: [],
})
export class TransactionViewerModule {}
