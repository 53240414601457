import { UnixDateTimePipe } from './unix_date_time';
import { NgModule, LOCALE_ID } from '@angular/core';
import { TimePipe } from './time';
import { DurationPipe } from './duration';
import { PricePipe } from './price';
import { DatePipe } from './date';
import { DistancePipe, DistanceMeterPipe } from './distance';
import { FilterArrayPipe, FilterValuesInArrayPipe } from './filter';
import { GroupByPipe } from './groupby';
import { SortSmallestPipe } from './smallest';
import { MinutePipe } from './minutes';
import { IsSelectedArrayPipe } from './selected';
import { ParterLogoUrlPipe } from './partner.image';
import { TruncPipe } from './truncate';
import { CapitalizePipe, LowerCasePipe } from './capitalize';
import { CurrencyPipe } from './currency';
import { LimitPipe, SortByPipe } from './limit';
import { GetFirstElementPipe } from './array.first';
import { GetLastElementPipe } from './array.last';
import { IconTransportTypePipe } from './icon.transport';
import { UrlSanitizerPipe } from './sanitizer';
import { CreditCardPipe } from './creditcard.assets';
import { MathFloorPipe } from './math.floor';
import { ArrayContainsPipe } from './array.contains';
import { FormControlValidPipe } from './formcontrol-valid';
import { ControlsFromFormPipe } from './get-form-controls';
import { GetFieldValuePipe } from './display-field-value';
import { ControlErrorsPipe } from './get-formcontrol-erros';
import { PrettyjsonPipe } from './pretty.json';
import { JoinDropDownListPipe } from './join-dropdownlist';
import { DisplayIntMonthPipe, DisplayMonthAndDatePipe, DisplayMonthPipe } from './display-human-date.pipe';
import { IsoDateToFrenchPipe, IsoToFrenchDateTimePipe } from './iso-to-french-date.pipe';
import { ImageRenderPipe } from './imge.render.pipe';
import { VarDirective } from './var.directive';
import { ReduceToPropertyPipe } from './reduce.toproperty';
import { MonthPipe } from './month';
import { PluralPipe } from './plural.pipe';
import { RoadMateImagePipe } from './roadmate-image.pipe';
import { ThumbnailPipe } from './thumbnail.pipe';
import { FileExtensionImagePipe } from './get-file-extension-img.pipe';
import { SortArrayByTimePipe } from './array.sort.time';
import { DisplayDDLValuePipe } from './ddl.value.pipe';
import { MonthZeroIndexPipe } from './month.zero.index.pipe';
import { AddPrefixPipe } from './add-prefix.pipe';
import { SortWidgetPipe } from '../trips-kpis/widget-kpi.pipe';
import { CheckSpellingPipe } from './check-spelling.pipe';

const pipes = [
  CheckSpellingPipe,
  AddPrefixPipe,
  IsoToFrenchDateTimePipe,
  MonthZeroIndexPipe,
  DisplayDDLValuePipe,
  DisplayIntMonthPipe,
  SortArrayByTimePipe,
  DurationPipe,
  PricePipe,
  DatePipe,
  TimePipe,
  DistancePipe,
  DistanceMeterPipe,
  FilterArrayPipe,
  GroupByPipe,
  SortSmallestPipe,
  MinutePipe,
  IsSelectedArrayPipe,
  ParterLogoUrlPipe,
  TruncPipe,
  CapitalizePipe,
  LowerCasePipe,
  CurrencyPipe,
  LimitPipe,
  SortByPipe,
  SortWidgetPipe,
  GetFirstElementPipe,
  GetLastElementPipe,
  IconTransportTypePipe,
  UnixDateTimePipe,
  UrlSanitizerPipe,
  CreditCardPipe,
  MathFloorPipe,
  ArrayContainsPipe,
  FormControlValidPipe,
  ControlsFromFormPipe,
  GetFieldValuePipe,
  ControlErrorsPipe,
  PrettyjsonPipe,
  JoinDropDownListPipe,
  DisplayMonthAndDatePipe,
  DisplayMonthPipe,
  FilterValuesInArrayPipe,
  IsoDateToFrenchPipe,
  ImageRenderPipe,
  VarDirective,
  ReduceToPropertyPipe,
  MonthPipe,
  PluralPipe,
  RoadMateImagePipe,
  ThumbnailPipe,
  FileExtensionImagePipe
];
@NgModule({
  declarations: pipes,
  imports: [],
  exports: pipes,
  providers: [{
    provide: LOCALE_ID,
    useValue: 'fr-FR'
  }],
})
export class PipeModule { }
