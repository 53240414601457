import { Component, Input, OnInit } from '@angular/core';
import { Setting } from '@roadmate/roadmate-common';
@Component({
  selector: 'rm-render-parameter',
  template: `
  <ng-container [ngSwitch]="type">
    <ng-container *ngSwitchCase="'translate'">
      {{value | translate}}
    </ng-container>
    <ng-container *ngSwitchCase="'select'">
      {{value | translate}}
    </ng-container>
    <ng-container *ngSwitchCase="'boolean'">
      <span style="font-wight:bold"> {{value ? 'Oui' : 'Non'}} </span>
    </ng-container>
    <ng-container *ngSwitchCase="'date'">
      <span> {{value | rm_date}} </span>
    </ng-container>
    <ng-container *ngSwitchCase="'html'">
      <div [innerHTML]="value"></div>
    </ng-container>
    <ng-container *ngSwitchCase="'percent'">
      <span> {{value}}% </span>
    </ng-container>
    <ng-container *ngSwitchDefault>
      {{value}}
    </ng-container>
  </ng-container>
  `,
})
export class ParameterRenderComponent implements OnInit {

  renderValue: string;
  public type: string;
  @Input() value: any;
  @Input() rowData: Setting;

  ngOnInit() {
    this.type = this.rowData.type;
  }

}
