import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { appRoutes, idleValues } from '../../../static';
import { AbstractEntryComponent } from '../abstract.entry-component';

@Component({
  selector: 'rm-logout-warning',
  templateUrl: './logout-warning.component.html',
  styleUrls: ['./logout-warning.component.scss']
})
export class LogoutWarningComponent extends AbstractEntryComponent<boolean> implements OnInit, OnDestroy {
  public remaingTime = idleValues.countDown;
  public intervallId;
  constructor(
    private route: Router
  ) {
    super();
  }

  ngOnInit(): void {
    this.intervallId = setInterval(() => {
      if (this.remaingTime > 0) {
        this.remaingTime--;
      } else {
        this.logout(true);
      }
    }, 1000);
  }

  public logout(doIt: boolean) {
    if (doIt) {
      this.route.navigateByUrl(`/${appRoutes.logout}`);
    }
    this.close(doIt);
  }

  ngOnDestroy(): void {
    clearInterval(this.intervallId);
  }
}
